import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@components/icons/Warning';
import CloseIcon from '@components/icons/Close';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  confirmTitle: string;
  confirmDescription: string;
  onConfirm: () => void;
}

function WarningConfirm({
  isOpen,
  onClose,
  confirmTitle,
  confirmDescription,
  onConfirm,
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal
      closeOnOverlayClick
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent w="580px" maxW="580px">
        <ModalBody p="40px" pos="relative">
          <Box pos="absolute" right="16px" top="10px" cursor="pointer" onClick={onClose}>
            <CloseIcon />
          </Box>
          <Box textAlign="center" mb="20px">
            <Flex m="0 auto" w="44px" h="44px" bg="vmRed.100" borderRadius="10px" align="center" justify="center">
              <WarningIcon fontSize="24px" />
            </Flex>
          </Box>
          <Box
            as="h2"
            fontWeight="600"
            fontSize="24px"
            lineHeight="31px"
            color="trOrange.50"
            textAlign="center"
            mb="20px"
          >
            {confirmTitle}
          </Box>
          <Box mb="40px" fontSize="trNormal" color="vmBlack.50" textAlign="center">{confirmDescription}</Box>
          <Flex gap="20px" justify="center" align="center">
            <Button variant="solidPrimary" type="button" onClick={onClose}>{t('common.button.texts.cancel')}</Button>
            <Button
              type="button"
              onClick={onConfirm}
              color="vmWhite"
            >
              {t('common.button.texts.proceed')}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default memo(WarningConfirm);
