import {
  Box,
  Grid,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import {
  useForm,
  FormProvider,
  useFormState,
  FieldErrors,
} from 'react-hook-form';
import { Route, Routes, useNavigate } from 'react-router-dom';
import VMBarNextPage from './components/VMBarNextPage';
import Advanced from './pages/Advanced';
import Introductory from './pages/Introductory';
import PersonalData from './pages/PersonalData';
import Questioner from './pages/Questioner';
import ThankYou from './pages/ThankYou';
import VMControlSidebar from './parts/VMControlSidebar';
import VMFormControlHeader from './parts/VMFormControlHeader';
import getVmFormSchema from './schemas/vm-form.schema';
import { IFormValue } from './types/form-value';

function findPathToNavigate(errors: FieldErrors<IFormValue>) {
  if (errors?.question) {
    return 'question';
  }
  if (errors?.personalData) {
    return 'personal';
  }
  if (errors?.thankYou) {
    return 'thank-you';
  }
  if (errors?.advanced) {
    return 'advanced';
  }
  return '';
}

interface Props {
  defaultValues: IFormValue;
  isLoading: boolean;
  isUpdateMode?: boolean;
  onSubmitForm: (value: IFormValue) => Promise<void>;
}

function VMFormControl({
  defaultValues,
  isLoading,
  isUpdateMode,
  onSubmitForm,
}: Props) {
  const navigate = useNavigate();
  const methods = useForm<IFormValue>({
    defaultValues,
    resolver: yupResolver(getVmFormSchema()),
  });
  const { errors } = useFormState({ control: methods.control });

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const lastPath = window.location.pathname.split('/').pop();
      switch (lastPath) {
        case 'question': {
          if (errors?.question) {
            break;
          }
          navigate(findPathToNavigate(errors));
          break;
        }
        case 'personal': {
          if (errors?.personalData) {
            break;
          }
          navigate(findPathToNavigate(errors));
          break;
        }
        case 'thank-you': {
          if (errors?.thankYou) {
            break;
          }
          navigate(findPathToNavigate(errors));
          break;
        }
        case 'advanced': {
          if (errors?.advanced) {
            break;
          }
          navigate(findPathToNavigate(errors));
          break;
        }
        default: {
          navigate(findPathToNavigate(errors));
          break;
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmitForm)}>
        <Grid templateRows="64px auto" bg="vmGray.150" minH="100vh">
          <VMFormControlHeader isLoading={isLoading} isUpdateMode={isUpdateMode!} />
          <Grid templateColumns="198px 1fr 1fr">
            <VMControlSidebar />
            <Box>
              <Box py="40px" h="full">
                <Grid w="480px" maxW="full" mx="auto" h="full" gridGap="20px" templateRows="auto 44px">
                  <Box px="20px" h="calc(100vh - 64px - 64px - 40px - 40px)" overflow="overlay">
                    <Routes>
                      <Route index element={<Introductory />} />
                      <Route path="question" element={<Questioner />} />
                      <Route path="personal" element={<PersonalData />} />
                      <Route path="thank-you" element={<ThankYou />} />
                      <Route path="advanced" element={<Advanced />} />
                    </Routes>
                  </Box>
                  <Box px="20px">
                    <VMBarNextPage />
                  </Box>
                </Grid>
              </Box>
            </Box>
            <Box bg="vmWhite" />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

VMFormControl.defaultProps = {
  isUpdateMode: false,
};

export default VMFormControl;
