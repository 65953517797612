import { Icon, IconProps } from '@chakra-ui/react';

function WarningIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" fill="vmWhite" {...props}>
      <mask id="path-1-inside-1_4586_5078">
        <path d="M18 0H6C2.68629 0 0 2.68629 0 6V18C0 21.3137 2.68629 24 6 24H18C21.3137 24 24 21.3137 24 18V6C24 2.68629 21.3137 0 18 0Z" />
      </mask>
      <path d="M12.75 5.99997C12.75 5.58576 12.4142 5.24997 12 5.24997C11.5858 5.24997 11.25 5.58576 11.25 5.99997H12.75ZM11.25 13C11.25 13.4143 11.5858 13.75 12 13.75C12.4142 13.75 12.75 13.4143 12.75 13H11.25ZM12.75 18C12.75 17.5858 12.4142 17.25 12 17.25C11.5858 17.25 11.25 17.5858 11.25 18H12.75ZM11.25 18.0134C11.25 18.4276 11.5858 18.7634 12 18.7634C12.4142 18.7634 12.75 18.4276 12.75 18.0134H11.25ZM11.25 5.99997V13H12.75V5.99997H11.25ZM11.25 18V18.0134H12.75V18H11.25ZM6 1.5H18V-1.5H6V1.5ZM22.5 6V18H25.5V6H22.5ZM18 22.5H6V25.5H18V22.5ZM1.5 18V6H-1.5V18H1.5ZM6 22.5C3.51472 22.5 1.5 20.4853 1.5 18H-1.5C-1.5 22.1421 1.85786 25.5 6 25.5V22.5ZM22.5 18C22.5 20.4853 20.4853 22.5 18 22.5V25.5C22.1421 25.5 25.5 22.1421 25.5 18H22.5ZM18 1.5C20.4853 1.5 22.5 3.51472 22.5 6H25.5C25.5 1.85786 22.1421 -1.5 18 -1.5V1.5ZM6 -1.5C1.85786 -1.5 -1.5 1.85786 -1.5 6H1.5C1.5 3.51472 3.51472 1.5 6 1.5V-1.5Z" mask="url(#path-1-inside-1_4586_5078)" />
    </Icon>
  );
}

export default WarningIcon;
