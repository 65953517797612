import { useEffect, useRef } from 'react';

function useEffectOnlyInitial(effect: () => void, deps: any[]) {
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);
}

export default useEffectOnlyInitial;
