import {
  Box, FormControl, FormLabel, Input,
} from '@chakra-ui/react';
import ErrorMessage from '@components/ErrorMessage';
import { useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFormValue } from '../../types/form-value';

function Advanced() {
  const { t } = useTranslation();
  const { control, register } = useFormContext<IFormValue>();
  const { errors } = useFormState({ control });

  return (
    <Box>
      <FormControl
        pt={0}
        isInvalid={!!errors?.advanced?.validationMessages?.requiredMessage?.message}
      >
        <FormLabel>{t('forms.labels.required_message')}</FormLabel>
        <Input type="text" placeholder={t('forms.labels.required_message')} {...register('advanced.validationMessages.requiredMessage')} />
        <ErrorMessage error={errors?.advanced?.validationMessages?.requiredMessage?.message} />
      </FormControl>
      <FormControl
        isInvalid={!!errors?.advanced?.validationMessages?.emailMessage?.message}
      >
        <FormLabel>{t('forms.labels.email_message')}</FormLabel>
        <Input type="text" placeholder={t('forms.labels.email_message')} {...register('advanced.validationMessages.emailMessage')} />
        <ErrorMessage error={errors?.advanced?.validationMessages?.emailMessage?.message} />
      </FormControl>
    </Box>
  );
}

export default Advanced;
