import { Icon, IconProps } from '@chakra-ui/react';

function AddIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" fill="none" stroke="vmWhite" strokeWidth={1.5} {...props}>
      <path
        d="M8 1V15M1 8L15 8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default AddIcon;
