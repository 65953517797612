import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import { IVmQuestion } from '@typed/question.types';
import { makeCustomerCharAvatar } from '@utils/string.util';
import QuestionTextView from '@modules/Question/components/QuestionTextView';
import QuestionVideoView from '@modules/Question/components/QuestionVideoView';
import ShareRightIcon from '@components/icons/ShareRight';
import QuestionAnswer from './parts/QuestionAnswer';

interface Props {
  question: IVmQuestion;
}

function QuestionDetailContainer({
  question,
}: Props) {
  const name = `${question.firstname} ${question.lastname}`;

  return (
    <Box py="40px">
      <Flex align="center" gap="21px" justify="center" mb="35px">
        <Box fontSize="24px" lineHeight="31.2px" fontWeight={600} color="vmBlack.100" py="8.5px" px="8px" bg="vmGray.100" borderRadius="full">
          {makeCustomerCharAvatar(name)}
        </Box>
        <Box color="vmBlack.50" fontSize="16px" lineHeight="24px" fontWeight={600}>{name}</Box>
        <Link to={`/contacts/view/${question.contactId}`}><Box cursor="pointer"><ShareRightIcon fontSize="16px" /></Box></Link>
      </Flex>
      <Flex direction="column" align="center" gap="35px">
        {question.text && (
          <QuestionTextView question={question} />
        )}
        {question.videoToken && (
          <QuestionVideoView videoToken={question.videoToken} />
        )}
      </Flex>
      <QuestionAnswer question={question} />
    </Box>
  );
}

export default memo(QuestionDetailContainer);
