import { Route, Routes } from 'react-router-dom';
import FormAdding from './containers/FormAdding';
import FormList from './containers/FormList';
import FormCloning from './containers/FormCloning';
import FormUpdating from './containers/FormUpdating';

function Forms() {
  return (
    <Routes>
      <Route index element={<FormList />} />
      <Route path="f/:folderId" element={<FormList />} />
      <Route path="add/*" element={<FormAdding />} />
      <Route path="add-f/:folderId/*" element={<FormAdding />} />
      <Route path="clone/:formId/*" element={<FormCloning />} />
      <Route path="update/:formId/*" element={<FormUpdating />} />
    </Routes>
  );
}

export default Forms;
