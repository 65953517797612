import { memo } from 'react';
import { Box, Flex, useConst } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Select from '@components/Select';
import { MediaFilterType } from '@shared/MediaLibrary/types/media-filter';
import ListAction from '@components/ListAction';

type FilterItem = {
  label: string;
  value: MediaFilterType;
}

function getLabel(item: FilterItem) {
  return <Box>{item.label}</Box>;
}

function getKey(item: FilterItem) {
  return item.value;
}

interface Props {
  currentMediaType: MediaFilterType;
  onChange: (mediaType: MediaFilterType) => void;
  onKeywordChange: (keyword: string) => void;
  handleRefresh: () => void;
  isDisabled: boolean;
}

function MediaLibraryFilter({
  currentMediaType,
  onChange,
  onKeywordChange,
  handleRefresh,
  isDisabled,
}: Props) {
  const { t } = useTranslation();

  const filterItems = useConst<FilterItem[]>(() => ([
    { label: t('ml.labels.show_all_medias'), value: 'all' },
    { label: t('ml.labels.show_image_media'), value: 'image' },
    { label: t('ml.labels.show_video_media'), value: 'video' },
  ]));

  return (
    <Flex align="center" justify="end" gap="20px" w="full">
      <ListAction
        isLoading={isDisabled}
        havingReload
        havingSearch
        onKeywordChange={onKeywordChange}
        onReload={handleRefresh}
      />
      <Select<FilterItem, MediaFilterType>
        data={filterItems}
        getLabel={getLabel}
        getKey={getKey}
        placeholder=""
        isDisabled={isDisabled}
        value={currentMediaType}
        onChange={(event) => {
          if (event.target.value) {
            onChange(event.target.value);
          }
        }}
      />
    </Flex>
  );
}

export default memo(MediaLibraryFilter);
