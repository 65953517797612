import { Icon, IconProps } from '@chakra-ui/react';

function ShareIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 12" fill="vmPrimary.50" {...props}>
      <path
        d="M6.66667 3.00131V0.450142C6.66667 0.269545 6.546 0.106347 6.36133 0.0355475C6.17733 -0.0346516 5.962 0.000747862 5.81867 0.127346L0.152 5.07729C0.0546667 5.16188 0 5.27828 0 5.40008C0 5.52188 0.0546667 5.63828 0.152 5.72288L5.81867 10.6728C5.96333 10.7988 6.178 10.8342 6.36133 10.7646C6.546 10.6938 6.66667 10.5306 6.66667 10.35V7.80005H7.612C10.7027 7.80005 13.552 9.31203 15.0473 11.7432L15.0613 11.766C15.1507 11.9124 15.32 12 15.5 12C15.5413 12 15.5827 11.9958 15.624 11.9862C15.8453 11.9352 16 11.7558 16 11.55C16 6.88566 11.8287 3.08171 6.66667 3.00131Z"
      />
    </Icon>
  );
}

export default ShareIcon;
