import { chakra, Flex } from '@chakra-ui/react';

const ContextItem = chakra(Flex, {
  baseStyle: {
    display: 'flex',
    px: '23px',
    py: '15px',
    alignItems: 'center',
    gap: '15px',
    borderBottomWidth: '2px',
    borderBottomColor: 'vmGray.200',
    bg: 'vmWhite',
    cursor: 'pointer',
    _hover: {
      bg: 'vmGray.450',
    },
    _last: {
      borderBottomWidth: 0,
    },
  },
});

export default ContextItem;
