/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useState } from 'react';

interface UseRecorderReset {
  oldVideoToken: string | null;
  handleReset: (videoToken: string) => void;
  key: string;
}

const PREFIX = 'vm-recorder-k-';

function useRecorderReset(
  recorder: any,
  defaultVideoToken: string | null,
  cb: () => void,
): UseRecorderReset {
  const [oldToken, setOldToken] = useState<string | null>(defaultVideoToken ?? null);
  const [key, setKey] = useState<string>(`${PREFIX}${Date.now().toString()}`);

  const handleRecorderReset = useCallback(async (currentVideoToken: string) => {
    if (recorder) {
      cb();
      setKey(`${PREFIX}${Date.now().toString()}`);
      setOldToken(currentVideoToken);
    }
  }, [recorder, cb]);

  return {
    oldVideoToken: oldToken,
    handleReset: handleRecorderReset,
    key,
  };
}

export default useRecorderReset;
