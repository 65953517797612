import { Icon, IconProps } from '@chakra-ui/react';

function LockIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 12 14" fill="vmGray.600" stroke="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.89839 3.7772V4.85054C11.1142 5.22687 12 6.31829 12 7.62188V11.0777C12 12.6915 10.6508 14 8.98745 14H3.01327C1.34919 14 0 12.6915 0 11.0777V7.62188C0 6.31829 0.886551 5.22687 2.10161 4.85054V3.7772C2.10879 1.69035 3.85176 0 5.98924 0C8.15541 0 9.89839 1.69035 9.89839 3.7772ZM6.00361 1.49268C7.37878 1.49268 8.49654 2.58579 8.49654 3.93065V4.80911H3.50391V3.9174C3.51068 2.57916 4.62843 1.49268 6.00361 1.49268ZM6.59368 9.97005C6.59368 10.2947 6.32948 10.553 5.99754 10.553C5.67238 10.553 5.40818 10.2947 5.40818 9.97005V8.49932C5.40818 8.18132 5.67238 7.92295 5.99754 7.92295C6.32948 7.92295 6.59368 8.18132 6.59368 8.49932V9.97005Z"
      />
    </Icon>
  );
}

export default LockIcon;
