import { Icon, IconProps } from '@chakra-ui/react';

function MoreIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" stroke="none" fill="vmGray.600" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.99988C0 4.47988 4.47 -0.00012207 10 -0.00012207C15.52 -0.00012207 20 4.47988 20 9.99988C20 15.5199 15.52 19.9999 10 19.9999C4.47 19.9999 0 15.5199 0 9.99988ZM5.52 11.1999C4.86 11.1999 4.32 10.6599 4.32 9.99988C4.32 9.33988 4.86 8.80088 5.52 8.80088C6.18 8.80088 6.71 9.33988 6.71 9.99988C6.71 10.6599 6.18 11.1999 5.52 11.1999ZM8.8 9.99988C8.8 10.6599 9.34 11.1999 10 11.1999C10.66 11.1999 11.19 10.6599 11.19 9.99988C11.19 9.33988 10.66 8.80088 10 8.80088C9.34 8.80088 8.8 9.33988 8.8 9.99988ZM13.28 9.99988C13.28 10.6599 13.81 11.1999 14.48 11.1999C15.14 11.1999 15.67 10.6599 15.67 9.99988C15.67 9.33988 15.14 8.80088 14.48 8.80088C13.81 8.80088 13.28 9.33988 13.28 9.99988Z"
      />
    </Icon>
  );
}

export default MoreIcon;
