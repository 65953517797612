import { Icon, IconProps } from '@chakra-ui/react';

function ChevronRightIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 7 10" fill="none" stroke="vmWhite" {...props}>
      <path
        d="M1.5 9L5.5 5L1.5 1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default ChevronRightIcon;
