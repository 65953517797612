import { Icon, IconProps } from '@chakra-ui/react';

function ArrowPointRight(props: IconProps) {
  return (
    <Icon viewBox="0 0 15 10" stroke="vmWhite" strokeWidth={2} {...props}>
      <path
        d="M13.5 5L1.5 5M13.5 5L9.5 9M13.5 5L9.5 1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default ArrowPointRight;
