import {
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ZiggeoPlayer } from 'react-ziggeo';

interface Props {
  source: string | null;
  videoToken: string | null;
  setOuterRef?: (playerRef: any) => void;
  onLoaded?: () => void;
  volume?: 0 | 1;
}

function VmVideoPlayer({
  source,
  videoToken,
  setOuterRef,
  onLoaded,
  volume,
}: Props) {
  const [player, setPlayer] = useState(null);
  const [updateInstance, setUpdateInstance] = useState(false);

  useEffect(() => {
    if (player) {
      setUpdateInstance(false);
    }
  }, [player]);

  useEffect(() => {
    setUpdateInstance(true);
  }, [videoToken]);

  const collectRef = useCallback((e: any) => {
    setPlayer(e);
    setOuterRef!(e);
  }, [setOuterRef]);

  return (
    <ZiggeoPlayer
      updateInstance={updateInstance}
      width="100%"
      height="100%"
      apiKey={process.env.REACT_APP_ZIGGEO_API_KEY}
      video={videoToken}
      theme="default"
      themecolor="default"
      skipinitial
      source={source}
      onRef={collectRef}
      volume={volume}
      loop={false}
      onLoaded={onLoaded}
    />
  );
}

VmVideoPlayer.defaultProps = {
  setOuterRef: () => {},
  onLoaded: () => {},
  volume: 1,
};

export default memo(VmVideoPlayer);
