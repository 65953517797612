import { useDisclosure } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { getForm } from '@apis/form.api';
import { toast } from 'react-toastify';
import { IForm } from '@modules/Forms/types/form.types';

interface UseFormDetail {
  isLoadingForm: boolean;
  form: IForm | null;
}

function useFormDetail(formId: string): UseFormDetail {
  const [form, setForm] = useState<IForm | null>(null);
  const {
    isOpen: isLoadingForm,
    onOpen: startLoadingForm,
    onClose: finishLoadingForm,
  } = useDisclosure({ defaultIsOpen: true });

  const getFormDetail = useCallback(async () => {
    try {
      startLoadingForm();
      const result = await getForm(formId!);
      setForm(result);
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishLoadingForm();
  }, [finishLoadingForm, formId, startLoadingForm]);

  useEffect(() => {
    getFormDetail().then(() => {});
  }, [getFormDetail]);

  return {
    isLoadingForm,
    form,
  };
}

export default useFormDetail;
