import { Icon, IconProps } from '@chakra-ui/react';

function FolderIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" fill="vmGray.850" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4501 2.88018H14.5201C18.2101 2.88018 20.0101 4.85019 20.0001 8.89021V13.7602C20.0001 17.6202 17.6201 20.0003 13.7501 20.0003H6.24003C2.39001 20.0003 0 17.6202 0 13.7502V6.2402C0 2.10018 1.84001 0.000170661 5.47002 0.000170661H7.05003C7.98104 -0.00982938 8.85004 0.420172 9.42004 1.15018L10.3 2.32018C10.58 2.67018 11 2.88018 11.4501 2.88018ZM5.37023 13.2902H14.6303C15.0403 13.2902 15.3703 12.9502 15.3703 12.5402C15.3703 12.1202 15.0403 11.7902 14.6303 11.7902H5.37023C4.95023 11.7902 4.62023 12.1202 4.62023 12.5402C4.62023 12.9502 4.95023 13.2902 5.37023 13.2902Z"
      />
    </Icon>
  );
}

export default FolderIcon;
