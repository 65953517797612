import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import { LoginTitleStyled } from '@modules/Auth/styled/LoginTitle';

function SignUpDescription() {
  const { t } = useTranslation();

  return (
    <Box>
      <LoginTitleStyled>
        {t('sign_up.slogan')}
      </LoginTitleStyled>
    </Box>
  );
}

export default memo(SignUpDescription);
