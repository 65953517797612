import { chakra, Flex } from '@chakra-ui/react';

const TrustRecorderContainer = chakra(Flex, {
  baseStyle: {
    align: 'center',
    justify: 'center',
    bg: 'transparent',
    borderRadius: {
      base: 0,
      md: '10px',
    },
    overflow: 'hidden',
    h: 'full',
    w: 'full',
    pos: 'relative',
  },
});

export default TrustRecorderContainer;
