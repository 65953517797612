import { useCallback, useEffect, useState } from 'react';
import { IVmQuestionListItem } from '@typed/question.types';
import { toast } from 'react-toastify';
import { useDisclosure } from '@chakra-ui/react';
import { getAllQuestions, getAllQuestionsOfForm, getQuestionsOfContact } from '@apis/question.api';
import { useVmStore } from '@store/index';

interface UseQuestions {
  questions: IVmQuestionListItem[];
  isLoadingQuestions: boolean;
  getQuestions: () => Promise<void>;
  onRemoveQuestion: (questionId: string) => void;
}

function useQuestions(formId?: string, contactId?: string): UseQuestions {
  const brandId = useVmStore((state) => state.selectedBrandId);
  const [questions, setQuestions] = useState<IVmQuestionListItem[]>([]);
  const {
    isOpen: isLoadingQuestions,
    onOpen: startLoadingQuestions,
    onClose: finishLoadingQuestions,
  } = useDisclosure({ defaultIsOpen: true });

  const getQuestionsList = useCallback((currentFormId?: string, currentContactId?: string) => {
    if (currentFormId) {
      return getAllQuestionsOfForm(currentFormId);
    }
    if (currentContactId) {
      return getQuestionsOfContact(currentContactId);
    }
    return getAllQuestions(brandId!);
  }, [brandId]);

  const getQuestions = useCallback(async () => {
    try {
      startLoadingQuestions();
      const result = await getQuestionsList(formId, contactId);
      setQuestions(result);
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishLoadingQuestions();
  }, [finishLoadingQuestions, startLoadingQuestions, getQuestionsList, formId, contactId]);

  const onRemoveQuestion = useCallback((questionId: string) => {
    setQuestions((currentQuestions) => (
      currentQuestions.filter((item) => item.questionId !== questionId)));
  }, []);

  useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  return {
    questions,
    isLoadingQuestions,
    getQuestions,
    onRemoveQuestion,
  };
}

export default useQuestions;
