import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function usePageTitle(pageTitle: string | null) {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = pageTitle ?? t('page_titles.default');
  }, [pageTitle, t]);
}

export default usePageTitle;
