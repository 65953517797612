function getLastItemFromArray<T>(items: T[]): T | null {
  if (items.length === 0) {
    return null;
  }

  return items[items.length - 1];
}

const EMPTY_ARRAY: any[] = [];

function generateNumbersWithNumLength(numLength: number): number[] {
  if (Number.isNaN(numLength)) {
    // eslint-disable-next-line no-console
    console.warn('numLength must be a specific number instead of NaN');
    return [];
  }
  return Array(numLength).fill(null).map((_, index) => index);
}

function findItemsInArray<T extends {id: string}>(items: T[], ids: string[]): T[] {
  const result = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const item of items) {
    if (result.length === ids.length) break;
    if (ids.includes(item.id)) {
      result.push(item);
    }
  }
  return result;
}

export {
  getLastItemFromArray,
  generateNumbersWithNumLength,
  findItemsInArray,
  EMPTY_ARRAY,
};
