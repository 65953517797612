import {
  Box, Flex, useConst, useDisclosure,
} from '@chakra-ui/react';
import BrandControl from '@modules/Branding/components/BrandControl';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { BrandFormValues } from '@modules/Branding/types/brand-form.types';
import { useVmStore } from '@root/store';

function BrandAdding() {
  const brandBrand = useVmStore((state) => state.addBrand);
  const changeSelectedBrand = useVmStore((state) => state.changeSelectedBrand);
  const defaultValues = useConst<BrandFormValues>(() => ({
    name: '',
    languageCode: '',
    language: '',
  }));
  const { isOpen: isLoading, onOpen: startLoading, onClose: finishLoading } = useDisclosure();

  const onSubmit = useCallback(async (values: BrandFormValues) => {
    try {
      startLoading();
      const newBrands = await brandBrand(values);
      if (newBrands.length === 1) {
        changeSelectedBrand(newBrands[0].brandId);
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishLoading();
  }, [brandBrand, changeSelectedBrand, finishLoading, startLoading]);

  return (
    <Flex align="center" justify="center">
      <Box w="580px" p="40px" maxW="full" mx="auto" borderWidth="1px" borderRadius="10px" borderColor="vmGray.150" bg="vmWhite" display="inline-block">
        <Box fontSize="20px" lineHeight="28px" fontWeight={600}>Create Brand</Box>
        <BrandControl defaultValues={defaultValues} onSubmit={onSubmit} isLoading={isLoading} />
      </Box>
    </Flex>
  );
}

export default BrandAdding;
