import { useCallback } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { uploadMedia } from '@apis/media.api';
import { toast } from 'react-toastify';
import { Media } from '../types/media';

function useMediaUpload(brandId: string) {
  const {
    isOpen: isUploadingMedia,
    onOpen: startUploadingMedia,
    onClose: finishUploadingMedia,
  } = useDisclosure();

  const handleUpload = useCallback(async (
    file: Blob,
    fileName: string,
    callback: (file: Media) => Promise<void>,
  ) => {
    try {
      startUploadingMedia();
      const result = await uploadMedia(brandId, file, fileName);
      await callback(result);
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishUploadingMedia();
  }, [brandId, finishUploadingMedia, startUploadingMedia]);

  return {
    isUploading: isUploadingMedia,
    upload: handleUpload,
  };
}

export default useMediaUpload;
