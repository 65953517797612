import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import ErrorMessage from '@components/ErrorMessage';
import EditIcon from '@components/icons/Edit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useVmStore } from '@store/index';
import { AppState } from '@store/types';
import { Customer } from '@typed/customer.types';
import { useCallback } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as yup from 'yup';

const schema = yup.object({
  email: yup.string().trim().required('common.validation.required').email('common.validation.email'),
});

type FormValues = Pick<Customer, 'email'>;

function EmailUpdate() {
  const { t } = useTranslation();
  const updateCustomerEmail = useVmStore((state: AppState) => state.customerUpdateEmail);
  const {
    isOpen,
    onOpen,
    onClose,
  } = useDisclosure();
  const {
    isOpen: isUpdating,
    onOpen: startUpdating,
    onClose: finishUpdating,
  } = useDisclosure();
  const {
    control,
    handleSubmit,
    register,
  } = useForm<FormValues>({
    defaultValues: { email: '' },
    resolver: yupResolver(schema),
  });
  const { errors } = useFormState({ control });

  const onSubmit = useCallback(async (values: FormValues) => {
    try {
      startUpdating();
      await updateCustomerEmail(values.email);
      finishUpdating();
      onClose();
    } catch (error: any) {
      toast.error(error?.message);
      finishUpdating();
    }
  }, [finishUpdating, onClose, startUpdating, updateCustomerEmail]);

  return (
    <>
      <Box cursor="pointer" onClick={onOpen}>
        <EditIcon fontSize="16px" />
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent w="650px" maxW="full">
          <ModalCloseButton />
          <ModalBody>
            <Box mb="25px" as="h4" textAlign="center" fontSize="28px" lineHeight="37px">{t('account.labels.enter_personal_info')}</Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl pt={0} isInvalid={!!errors?.email?.message}>
                <FormLabel>{t('common.labels.email')}</FormLabel>
                <Input type="text" {...register('email')} placeholder={t('common.placeholders.email')} />
                <ErrorMessage error={errors?.email?.message} />
              </FormControl>
              <Box textAlign="center" mt="25px">
                <Button isLoading={isUpdating} isDisabled={isUpdating} type="submit">{t('common.button.texts.save')}</Button>
              </Box>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default EmailUpdate;
