import { confirmPublicQuestionEmail } from '@apis/pubic-form';
import {
  Box,
  Flex,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import { ErrorIcon } from '@components/icons/alerts';
import CheckCircleIcon from '@components/icons/public-form/CheckCircle';
import WarningText from '@components/WarningText';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

function PublicQuestionEmailConfirm() {
  const title = 'Your email address has been confirmed!';
  const notDoneTitle = 'Your email address has not been confirmed';
  const text = 'You can safely close this tab. We will not send you any further emails nor share your email address with others. We promise!';
  const { questionId } = useParams<'questionId'>();
  const [isDone, setIsDone] = useState<boolean>(false);
  const {
    isOpen: isConfirming,
    onOpen: startConfirming,
    onClose: finishConfirming,
  } = useDisclosure({ defaultIsOpen: true });

  const confirmEmailQuestion = useCallback(async () => {
    try {
      startConfirming();
      await confirmPublicQuestionEmail(questionId!);
      setIsDone(true);
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishConfirming();
  }, [finishConfirming, questionId, startConfirming]);

  useEffect(() => {
    confirmEmailQuestion();
  }, [confirmEmailQuestion]);

  if (isConfirming) {
    return (
      <Flex align="center" w="full" minH="100vh" bg="vmWhite">
        <Flex w="540px" borderRadius="10px" py="40px" minH="100vh" mx="auto">
          <Flex justify="center" w="full">
            <Spinner w="40px" h="40px" mx="auto" />
          </Flex>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex align="center" w="full" minH="100vh" bg="vmWhite">
      <Flex w="540px" borderRadius="10px" py="40px" minH="100vh" mx="auto">
        <Box>
          <Box textAlign="center" mb="20px">
            {!isDone ? (
              <ErrorIcon fontSize="40px" />
            ) : (
              <CheckCircleIcon fontSize="40px" color="vmGreen.50" />
            )}
          </Box>
          <Box
            textAlign="center"
            as="h2"
            fontSize="21px"
            color="#071e57"
            mb="25px"
            fontWeight={700}
          >
            {isDone ? title : notDoneTitle}
          </Box>
          <WarningText p="20px">
            {text}
          </WarningText>
        </Box>
      </Flex>
    </Flex>
  );
}

export default PublicQuestionEmailConfirm;
