import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDisclosure } from '@chakra-ui/react';
import { useVmStore } from '@store/index';
import { selectedBrandSelector } from '@store/slices/brand/selectors';
import { getVideos } from '@apis/video-library.api';
import { IVideoLibraryItem } from '../types/video-library.types';

interface UseVLVideos {
  isLoadingVideos: boolean;
  videos: IVideoLibraryItem[];
}

function useVLVideos(): UseVLVideos {
  const brandId = useVmStore(selectedBrandSelector);
  const [videos, setVideos] = useState<IVideoLibraryItem[]>([]);
  const {
    isOpen: isLoadingVideos,
    onOpen: startLoadingVideos,
    onClose: finishLoadingVideos,
  } = useDisclosure({ defaultIsOpen: true });

  const loadVideos = useCallback(async () => {
    if (!brandId) {
      finishLoadingVideos();
      return;
    }
    try {
      startLoadingVideos();
      const result = await getVideos(brandId!);
      setVideos(result);
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishLoadingVideos();
  }, [brandId, finishLoadingVideos, startLoadingVideos]);

  useEffect(() => {
    loadVideos();
  }, [loadVideos]);

  return {
    isLoadingVideos,
    videos,
  };
}

export default useVLVideos;
