import {
  Box,
  Button,
  Flex,
  useConst,
} from '@chakra-ui/react';
import {
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import useVideoEditorControl from '@modules/VideoEditor/hooks/useVideoEditorControl';
import { generateTimeByMilisecond } from '@utils/timer.util';
import useElementRef from '@hooks/useElementRef';
import useTrimerBar from '@modules/VideoEditor/hooks/useTrimerBar';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import usePreviewProgressBarTimer from '@modules/VideoEditor/hooks/usePreviewProgressBarTrimer';
import useVideoEditorPlayProgress from '@modules/VideoEditor/hooks/useVideoEditorPlayProgress';
import debounce from 'lodash.debounce';
import Back5SecIcon from '@components/icons/ve/Back5Sec';
import Next5SecIcon from '@components/icons/ve/Next5Sec';
import {
  MinusIcon,
  PauseIcon,
  PlayIcon,
  PlusIcon,
} from '@components/icons/ve';

interface Props {
  player: any;
  updateKey: string;
  onCloseTrimMode: () => void;
}

function VideoEditorSceneTrimer({
  player,
  updateKey,
  onCloseTrimMode,
}: Props) {
  const { getValues, setValue } = useFormContext();
  const defaultStartTime = useConst(() => getValues(`${updateKey}.trimmingStart`) ?? 0);
  const defaultEndTime = useConst(() => getValues(`${updateKey}.trimmingEnd`) ?? player.get('duration'));
  const { t } = useTranslation();
  const [currentStartTime, setCurrentStartTime] = useState<number>(defaultStartTime);
  const [currentEndTime, setCurrentEndTime] = useState<number>(defaultEndTime);
  const [trimerBarLeftRef, trimerBarLeft] = useElementRef<HTMLDivElement>();
  const [trimerBarRightRef, trimerBarRight] = useElementRef<HTMLDivElement>();
  const [progressWrapperRef, progressWrapper] = useElementRef<HTMLDivElement>();
  const [progressBarRef, progressBar] = useElementRef<HTMLDivElement>();
  const [progressElementRef, progressElement] = useElementRef<HTMLDivElement>();
  const {
    moveNext5Sec,
    movePrev5Sec,
    handleTogglePlay,
    isPlaying,
  } = useVideoEditorControl(player, currentStartTime, currentEndTime);
  const handleSetCursorTimer = useCallback(debounce((time: number, cursorPosition: 'left' | 'right') => {
    if (cursorPosition === 'left') {
      setCurrentStartTime(time);
    }
    if (cursorPosition === 'right') {
      setCurrentEndTime(time);
    }
  }, 500), []);
  const {
    handleUpdatePosition,
    handleCalculateStartPlayPoint,
  } = useVideoEditorPlayProgress(
    progressElement,
    progressWrapper,
    null,
    null,
    player,
    defaultStartTime,
    isPlaying,
    currentStartTime,
    currentEndTime,
    false,
  );
  usePreviewProgressBarTimer(
    progressWrapper,
    handleUpdatePosition,
    player,
    currentStartTime,
    currentEndTime,
  );
  const {
    handleCustomLeftTime,
    handleCustomRightTime,
  } = useTrimerBar(
    trimerBarLeft,
    trimerBarRight,
    progressWrapper,
    progressBar,
    player,
    handleSetCursorTimer,
    defaultStartTime,
    defaultEndTime,
  );

  const handleSaveTrimData = useCallback(() => {
    setValue(`${updateKey}.trimmingStart`, currentStartTime, { shouldDirty: true });
    setValue(`${updateKey}.trimmingEnd`, currentEndTime, { shouldDirty: true });
    player.seek(currentStartTime);
    if (player.get('playing')) {
      player.pause();
    }
    onCloseTrimMode();
  }, [setValue, updateKey, currentStartTime, currentEndTime, player, onCloseTrimMode]);

  useEffect(() => {
    handleCalculateStartPlayPoint();
  }, [handleCalculateStartPlayPoint]);

  return (
    <Box p="30px" bg="vmWhite" borderRadius="10px">
      <Flex align="center" gap="51px" justify="center">
        <Box
          onClick={movePrev5Sec}
        >
          <Back5SecIcon fontSize="30px" />
        </Box>
        <Box onClick={handleTogglePlay} cursor="pointer">
          {isPlaying ? (
            <PauseIcon fontSize="36px" />
          ) : (
            <PlayIcon fontSize="36px" />
          )}
        </Box>
        <Box
          onClick={moveNext5Sec}
        >
          <Next5SecIcon fontSize="30px" />
        </Box>
      </Flex>
      <Flex align="center" justify="space-between">
        <Flex align="center" gap="13px">
          <Box
            cursor="pointer"
            onClick={() => handleCustomLeftTime(
              -1,
              currentStartTime,
              currentEndTime,
            )}
          >
            <MinusIcon />
          </Box>
          <Box
            fontSize="16px"
            fontWeight={400}
            lineHeight="24px"
            textAlign="center"
            color="vmBlack.50"
            borderRadius="2px"
            bg="vmGray.250"
            py="2px"
            px="10px"
            id="trimer-time-start"
            userSelect="none"
            minW="90px"
          >
            {generateTimeByMilisecond(defaultStartTime * 1000)}
          </Box>
          <Box
            cursor="pointer"
            onClick={() => handleCustomLeftTime(
              1,
              currentStartTime,
              currentEndTime,
            )}
          >
            <PlusIcon />
          </Box>
        </Flex>
        <Flex align="center" gap="13px">
          <Box
            cursor="pointer"
            onClick={() => handleCustomRightTime(
              -1,
              currentStartTime,
              currentEndTime,
            )}
          >
            <MinusIcon />
          </Box>
          <Box
            fontSize="16px"
            fontWeight={400}
            lineHeight="24px"
            textAlign="center"
            color="vmBlack.50"
            borderRadius="2px"
            bg="vmGray.250"
            py="2px"
            px="10px"
            id="trimer-time-end"
            userSelect="none"
            minW="90px"
          >
            {generateTimeByMilisecond(Math.ceil(defaultEndTime * 1000))}
          </Box>
          <Box
            cursor="pointer"
            onClick={() => handleCustomRightTime(
              1,
              currentStartTime,
              currentEndTime,
            )}
          >
            <PlusIcon />
          </Box>
        </Flex>
      </Flex>
      <Box mt="12px">
        <Box h="12px" w="full" background="transparent" pos="relative" ref={progressWrapperRef}>
          <Box h="8px" w="full" bg="vmGray.350" borderRadius="2px" cursor="pointer" pos="relative">
            <Box id="progress-preview" display="none" pos="absolute" top="-20px" bg="vmBlack.50" zIndex={90} color="vmWhite" px="10px" py="3px" borderRadius="6px" fontSize="14px" transform="translate(-50%, -50%)">15:25</Box>
          </Box>
          <Box pos="relative">
            <Box pos="absolute" top="-8px" h="8px" w="full" borderTopLeftRadius="2px" borderBottomLeftRadius="2px">
              <Box pos="absolute" h="8px" w="0" borderTopLeftRadius="2px" borderBottomLeftRadius="2px" ref={progressElementRef}>
                <Box pos="absolute" zIndex={8} top="-8px" right="-3px" w="6px" h="24px" borderRadius="2px" bg="vmGreen.50" />
              </Box>
            </Box>
            <Box h="8px" w="full" pos="relative" top="-8px" borderTopLeftRadius="2px" borderBottomLeftRadius="2px" bg="vmBlack.50" ref={progressBarRef}>
              <Box w="full" pos="relative">
                <Box pos="absolute" top="-8px" left="-3px" w="6px" h="24px" zIndex={7} borderRadius="2px" bg="vmBlack.50" cursor="pointer" ref={trimerBarLeftRef} />
                <Box pos="absolute" top="-8px" right="-3px" w="6px" h="24px" zIndex={7} borderRadius="2px" bg="vmBlack.50" cursor="pointer" ref={trimerBarRightRef} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Flex align="center" justify="end" gap="20px" mt="30px">
        <Button
          type="button"
          variant="solidPrimary"
          color="vmBlack.150"
          borderColor="vmGray.350"
          _hover={{
            color: 'vmBlack.150',
            borderColor: 'vmGray.500',
          }}
          onClick={onCloseTrimMode}
        >
          {t('common.button.texts.cancel')}
        </Button>
        <Button
          type="button"
          bg="vmBlack.50"
          borderColor="vmBlack.50"
          color="vmWhite"
          _hover={{
            bg: 'vmBlack.150',
          }}
          onClick={handleSaveTrimData}
        >
          {t('common.button.texts.save')}
        </Button>
      </Flex>
    </Box>
  );
}

export default memo(VideoEditorSceneTrimer);
