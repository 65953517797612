import { Media } from '@shared/MediaLibrary/types/media';
import { ApiResponse } from '@typed/http.types';
import axios, { AxiosResponse } from 'axios';

async function getAllMedias(brandId: string): Promise<Media[]> {
  return axios.get(`/media-library/${brandId}`)
    .then((response: AxiosResponse<ApiResponse<Media[]>>) => response.data.result);
}

async function uploadMedia(brandId: string, file: Blob, filename: string): Promise<Media> {
  const formData = new FormData();
  formData.append('file', file, filename);
  return axios.put(`/media-library/${brandId}/upload-media`, formData)
    .then((response: AxiosResponse<ApiResponse<Media>>) => response.data.result);
}

async function downloadImageMedia(mediaId: string): Promise<string> {
  return axios.get(`/media-library/${mediaId}/download-image`)
    .then((response: AxiosResponse<ApiResponse<string>>) => response.data.result);
}

async function deleteMedia(mediaId: string) {
  return axios.delete(`/media-library/${mediaId}`);
}

export {
  getAllMedias,
  uploadMedia,
  downloadImageMedia,
  deleteMedia,
};
