import {
  Box,
  Button,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import * as yup from 'yup';
import VmVideoRecorder from '@components/VmVideoRecorder';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { addAnswer } from '@apis/answer.api';

interface FormValue {
  videoToken: string | null;
}

interface Props {
  questionId: string;
  customerId: string;
  brandId: string;
  formId: string;
  onRefetch: () => Promise<void>;
}

const schema = yup.object({
  videoToken: yup.string().trim().required('common.validation.required'),
});

function AnswerRecording({
  questionId,
  customerId,
  brandId,
  formId,
  onRefetch,
}: Props) {
  const methods = useForm<FormValue>({
    defaultValues: {
      videoToken: null,
    },
    resolver: yupResolver(schema),
  });
  const videoToken = methods.watch('videoToken');
  const {
    isOpen: isPostingAnswer,
    onOpen: startPostingAnswer,
    onClose: finishPostingAnswer,
  } = useDisclosure();

  const onSubmit = useCallback(async (values: FormValue) => {
    try {
      startPostingAnswer();
      await addAnswer({
        videoToken: values.videoToken!,
        questionId,
        customerId,
        brandId,
        formId,
      });
      onRefetch();
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishPostingAnswer();
  }, [brandId, customerId, finishPostingAnswer, formId, onRefetch, questionId, startPostingAnswer]);

  return (
    <FormProvider {...methods}>
      <Box as="form" onSubmit={methods.handleSubmit(onSubmit)} w="671px" maxW="full" borderRadius="10px" bg="vmGray.150" py="29px" px="25px">
        <Flex align="center" justify="space-between" gap="20px">
          <Box color="vmBlack.100" fontWeight={600} fontSize="24px" lineHeight="31.2px">Answer view</Box>
          <Box>
            <Button
              type="submit"
              py="5px"
              px="10px"
              bg="vmGray.450"
              color="vmBlack.100"
              borderRadius="100px"
              fontSize="14px"
              fontWeight={400}
              lineHeight="21px"
              borderColor="vmGray.450"
              textTransform="unset"
              isLoading={isPostingAnswer}
              isDisabled={isPostingAnswer || !videoToken}
            >
              reply
            </Button>
          </Box>
        </Flex>
        <Box mt="68px" px="30px">
          <VmVideoRecorder
            label=""
            name="videoToken"
            allowText={false}
            allowVideo
            showArrowBack={false}
            allowUpload={false}
          >
            <Box mt="53px">
              <Button type="button" isDisabled={!videoToken}>Delete</Button>
            </Box>
          </VmVideoRecorder>
        </Box>
        {/* <Flex align="center" justify="center" mt="33px">
          <Button type="button">Open in Video library?</Button>
        </Flex> */}
      </Box>
    </FormProvider>
  );
}

export default AnswerRecording;
