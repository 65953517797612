import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

function usePaddle() {
  const intervalRef = useRef<any | null>(null);
  const [isReady, setIsReady] = useState<boolean>(!!window.Paddle);
  const importPaddleScript = useCallback(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.paddle.com/paddle/paddle.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (!window.Paddle) {
      importPaddleScript();
      intervalRef.current = setInterval(() => {
        const isPaddleReady = !!window.Paddle;
        setIsReady(isPaddleReady);
        if (isPaddleReady) clearInterval(intervalRef.current);
      }, 1000);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [importPaddleScript]);
  return {
    isReady,
  };
}

export default usePaddle;
