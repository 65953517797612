import { Icon, IconProps } from '@chakra-ui/react';

function FormIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 18 20" fill="vmGray.50" {...props}>
      <path
        d="M10.3261 0C10.5987 0 10.8084 0.21 10.8084 0.46V3.68C10.8084 5.51 12.3914 7.01 14.3098 7.02C15.1066 7.02 15.7356 7.03 16.2178 7.03L16.3964 7.02926C16.7187 7.02694 17.1526 7.02 17.5282 7.02C17.7903 7.02 18 7.22 18 7.47V15.51C18 17.99 15.8928 20 13.293 20H4.94817C2.22248 20 0 17.89 0 15.29V4.51C0 2.03 2.11765 0 4.72801 0H10.3261ZM11.4479 12.9H5.7449C5.31508 12.9 4.95865 13.23 4.95865 13.64C4.95865 14.05 5.31508 14.39 5.7449 14.39H11.4479C11.8777 14.39 12.2341 14.05 12.2341 13.64C12.2341 13.23 11.8777 12.9 11.4479 12.9ZM9.28829 7.9H5.7449C5.31508 7.9 4.95865 8.24 4.95865 8.65C4.95865 9.06 5.31508 9.39 5.7449 9.39H9.28829C9.71811 9.39 10.0745 9.06 10.0745 8.65C10.0745 8.24 9.71811 7.9 9.28829 7.9ZM12.336 0.906C12.336 0.475 12.8843 0.261 13.1978 0.572C14.331 1.696 16.3113 3.661 17.4184 4.759C17.7245 5.062 17.5002 5.565 17.0588 5.566C16.1971 5.569 15.1812 5.566 14.4505 5.559C13.2911 5.559 12.336 4.648 12.336 3.542V0.906Z"
      />
    </Icon>
  );
}

export default FormIcon;
