import { Flex, Spinner } from '@chakra-ui/react';
import NotFound from '@components/NotFound';
import { useParams } from 'react-router-dom';
import PublicAnswerContainer from './container';
import usePublicAnswer from './hooks/usePublicAnswer';

function PublicAnswer() {
  const { answerId } = useParams<{answerId: string}>();

  const { isLoadingAnswer, answer } = usePublicAnswer(answerId!);

  if (isLoadingAnswer) {
    return (
      <Flex align="center" justify="center" minH="100vh">
        <Spinner />
      </Flex>
    );
  }

  if (!answer) {
    return (
      <NotFound />
    );
  }

  return (
    <PublicAnswerContainer answer={answer} />
  );
}

export default PublicAnswer;
