import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { memo, useCallback, useEffect } from 'react';
import {
  Box,
  Grid,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import ROUTES from '@constants/routes';
import { useTranslation } from 'react-i18next';
import LoginHeader from '@modules/Auth/components/LoginHeader';
import LoginDescription from '@modules/Auth/components/LoginDescription';

function Callback() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const getAccessToken = useCallback(async () => {
    try {
      await getAccessTokenSilently();
      navigate('/forms');
    } catch (error) {
      toast.error(t('callback.auth_fail'));
      navigate(ROUTES.AUTH.LOGIN);
    }
  }, [getAccessTokenSilently, navigate, t]);

  useEffect(() => {
    getAccessToken();
  }, [getAccessToken]);

  return (
    <Grid gridTemplateRows="64px auto" minH="100vh">
      <Box />
      <Flex align="center" justify="center">
        <Box
          bg="vmWhite"
          borderRadius="20px"
          padding="80px"
          boxShadow="0px 0px 25px 10px #ebebeb"
          w="640px"
        >
          <LoginHeader />
          <LoginDescription />
          <Box textAlign="center" mt="20px">
            <Spinner speed=".3s" size="xl" />
          </Box>
        </Box>
      </Flex>
    </Grid>
  );
}

export default memo(Callback);
