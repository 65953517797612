import { removeCharacterFromString } from './string.util';

function generateTimeByMilisecond(mil: number, maxFixed: number = 0): string {
  const first10Seconds = 10 * 1000;
  const oneMin = 60 * 1000;
  const oneHour = 60 * 60 * 1000;
  if (mil < first10Seconds) {
    const second = Number((mil / 1000).toFixed(maxFixed));
    const result = removeCharacterFromString(second.toString(), '.');
    return `00:${second < 10 ? `0${result}` : result}`;
  }
  if (mil < oneMin) {
    const second = Number((mil / 1000).toFixed(maxFixed));
    const result = removeCharacterFromString(second.toString(), '.');
    return `00:${second < 10 ? `0${result}` : result}`;
  }
  if (mil < oneHour) {
    const minute = Math.floor(mil / 1000 / 60);
    const second = Number(((mil / 1000) % 60).toFixed(maxFixed));
    const secondText = removeCharacterFromString(second.toString(), '.');
    return `${minute < 10 ? `0${minute}` : minute}:${second < 10 ? `0${secondText}` : secondText}`;
  }

  const hour = Math.floor(mil / 1000 / 60 / 60);
  const timeLap = (mil / 1000 / 60) % 60;
  const hourMinute = Math.floor(timeLap);
  const hourSecond = Number(((timeLap % hourMinute) * 60).toFixed(maxFixed));

  return `${hour < 10 ? `0${hour}` : hour}:${hourMinute < 10 ? `0${hourMinute}` : hourMinute}:${hourSecond < 10 ? `0${hourSecond}` : hourSecond}`;
}

export {
  // eslint-disable-next-line import/prefer-default-export
  generateTimeByMilisecond,
};
