import { Flex, Skeleton } from '@chakra-ui/react';
import useFolders from '@shared/List/hooks/useFolders';
import { memo } from 'react';
import VmFolderAdding from '../VmFolderAdding';
import VmFolderItem from '../VmFolderItem';

function VmFolderList() {
  const { isLoadingFolders, folders } = useFolders();

  if (isLoadingFolders) {
    return (
      <Flex align="center" gap="20px" flexWrap="wrap">
        <Skeleton w="192px" h="54px" borderRadius="10px" />
        <Skeleton w="192px" h="54px" borderRadius="10px" />
        <Skeleton w="192px" h="54px" borderRadius="10px" />
        <Skeleton w="192px" h="54px" borderRadius="10px" />
      </Flex>
    );
  }

  return (
    <Flex align="center" gap="20px" flexWrap="wrap">
      {folders.map((folder) => (
        <VmFolderItem key={folder.folderId} folder={folder} />
      ))}
      <VmFolderAdding />
    </Flex>
  );
}

export default memo(VmFolderList);
