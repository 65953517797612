import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { LoginTitleStyled, LoginBrandNameStyled, LoginDescriptionStyled } from '@modules/Auth/styled/LoginTitle';

function LoginDescription() {
  const { t } = useTranslation();

  return (
    <Box>
      <LoginTitleStyled px="40px">
        {t('login.welcome_to')}
        {' '}
        <LoginBrandNameStyled>Video Mentor</LoginBrandNameStyled>
        {' '}
        {t('login.platform_text')}
      </LoginTitleStyled>
      <LoginDescriptionStyled px="80px">
        {t('login.description')}
      </LoginDescriptionStyled>
    </Box>
  );
}

export default LoginDescription;
