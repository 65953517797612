import { chakra, Flex } from '@chakra-ui/react';

const RecorderYesNoButton = chakra(Flex, {
  baseStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    w: '65px',
    h: '65px',
    direction: 'column',
    gap: '8px',
    bg: 'vmWhite',
    cursor: 'pointer',
    borderRadius: '10px',
    borderWidth: '2px',
    borderColor: '#eaf3fd',
    fontWeight: 700,
    fontSize: '15px',
    textTransform: 'uppercase',
    color: 'vmBlack.50',
  },
});

export default RecorderYesNoButton;
