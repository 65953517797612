export default {
  parts: ['container', 'track', 'thumb'],
  baseStyle: {
    track: {
      borderRadius: 'full',
      p: '0.5',
      width: '1.875rem',
      height: '1rem',
      transitionProperty: 'common',
      transitionDuration: 'fast',
      _focusVisible: {
        boxShadow: 'outline',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      _checked: {
        bg: 'vmGreen.50',
      },
      bg: 'vmGray.50',
    },
    thumb: {
      bg: 'white',
      transitionProperty: 'transform',
      transitionDuration: 'normal',
      borderRadius: 'inherit',
      width: '1rem',
      height: '1rem',
      _checked: {
        transform: 'translateX(calc(1.875rem - 1rem))',
      },
    },
  },
};
