import { Icon, IconProps } from '@chakra-ui/react';

function UploadIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 21 14" fill="vmWhite" {...props}>
      <path
        d="M13.1724 6.56395e-10C11.8092 -1.78091e-05 10.473 0.362383 9.31338 1.0466C8.1538 1.73081 7.21669 2.7098 6.60706 3.87386C6.09816 4.83585 5.83277 5.89841 5.83222 6.97614C5.83513 7.14001 5.77332 7.29893 5.65907 7.42127C5.54483 7.5436 5.38654 7.6204 5.21568 7.63636C5.12404 7.64294 5.03197 7.63136 4.94524 7.60234C4.85851 7.57332 4.779 7.52749 4.71172 7.46773C4.64443 7.40797 4.59081 7.33557 4.55423 7.25508C4.51766 7.17458 4.49891 7.08774 4.49917 7C4.4995 6.11077 4.64857 5.2273 4.94074 4.38295C4.96024 4.32912 4.96424 4.27127 4.95233 4.21545C4.94041 4.15963 4.91302 4.10788 4.87301 4.06562C4.833 4.02336 4.78185 3.99213 4.72488 3.97519C4.66791 3.95825 4.60722 3.95622 4.54916 3.96932C3.39345 4.24286 2.3669 4.87784 1.63307 5.77308C0.899241 6.66831 0.500364 7.77229 0.5 8.90909C0.5 11.7091 2.98282 14 5.90721 14H13.164C15.1085 14.0011 16.9739 13.2646 18.3496 11.9526C19.7254 10.6406 20.4989 8.86049 20.5 7.00398C20.5011 5.14746 19.7297 3.36657 18.3555 2.05306C16.9813 0.739564 15.1169 0.00105487 13.1724 6.56395e-10ZM15.7968 8.87727C15.6689 8.99403 15.4989 9.05951 15.3219 9.06023C15.1473 9.05995 14.9798 8.99425 14.8553 8.87727L13.164 7.2625V12.0909C13.164 12.2597 13.0938 12.4215 12.9688 12.5409C12.8438 12.6602 12.6743 12.7273 12.4975 12.7273C12.3207 12.7273 12.1512 12.6602 12.0262 12.5409C11.9012 12.4215 11.831 12.2597 11.831 12.0909V7.2625L10.1397 8.87727C10.0129 8.98696 9.84675 9.04589 9.67571 9.04187C9.50467 9.03785 9.34179 8.97118 9.22081 8.85567C9.09983 8.74016 9.03 8.58466 9.02579 8.42136C9.02158 8.25806 9.0833 8.09946 9.19819 7.97841L12.0226 5.27386C12.149 5.15437 12.3196 5.08733 12.4975 5.08733C12.6754 5.08733 12.846 5.15437 12.9724 5.27386L15.7968 7.97841C15.9215 8.09771 15.9915 8.25933 15.9915 8.42784C15.9915 8.59635 15.9215 8.75798 15.7968 8.87727Z"
      />
    </Icon>
  );
}

export default UploadIcon;
