import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import NotFound from '@components/NotFound';
import BrandControl from '@modules/Branding/components/BrandControl';
import { BrandFormValues } from '@modules/Branding/types/brand-form.types';
import { useVmStore } from '@store/index';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

function BrandUpdating() {
  const { brandId } = useParams<'brandId'>();
  const updateBrand = useVmStore((state) => state.updateBrand);
  const brands = useVmStore((state) => state.brands);
  const brand = brands.find((item) => item.brandId === brandId!);
  const {
    isOpen: isUpdating,
    onOpen: startUpdating,
    onClose: finishUpdating,
  } = useDisclosure();

  const onUpdateBrand = useCallback(async (values: BrandFormValues) => {
    try {
      startUpdating();
      await updateBrand(brandId!, values);
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishUpdating();
  }, [brandId, finishUpdating, startUpdating, updateBrand]);

  if (!brand) {
    return (
      <NotFound />
    );
  }

  return (
    <Flex align="center" justify="center">
      <Box w="580px" p="40px" maxW="full" mx="auto" borderWidth="1px" borderRadius="10px" borderColor="vmGray.150" bg="vmWhite" display="inline-block">
        <Box fontSize="20px" lineHeight="28px" fontWeight={600}>Update Brand</Box>
        <BrandControl defaultValues={brand} isLoading={isUpdating} onSubmit={onUpdateBrand} />
      </Box>
    </Flex>
  );
}

export default BrandUpdating;
