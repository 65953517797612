import { chakra } from '@chakra-ui/react';

const LoginTitleStyled = chakra('h1', {
  baseStyle: {
    textAlign: 'center',
    fontSize: '29px',
    fontWeight: 600,
    mb: '15px',
  },
});

const LoginDescriptionStyled = chakra('h4', {
  baseStyle: {
    fontSize: '19px',
    color: 'vmGray.50',
    textAlign: 'center',
    fontWeight: 600,
  },
});

const LoginBrandNameStyled = chakra('span', {
  baseStyle: {
    color: 'vmGreen.50',
    fontWeight: 600,
  },
});

export {
  LoginTitleStyled,
  LoginDescriptionStyled,
  LoginBrandNameStyled,
};
