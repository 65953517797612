import { Box, Flex, Skeleton } from '@chakra-ui/react';
import { memo } from 'react';

function MediaLoadingItem() {
  return (
    <Flex direction="column" w="full" gap="10px">
      <Box w="full" borderRadius="4px">
        <Skeleton w="full" h="146px" />
      </Box>
      <Flex align="start" gap="10px">
        <Skeleton w="20px" h="20px" pt="3px" />
        <Flex direction="column" gap="5px" w="full">
          <Skeleton w="full" h="22px" />
          <Skeleton w="full" h="20px" />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default memo(MediaLoadingItem);
