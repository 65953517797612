import {
  Box,
  Flex,
  Input,
  Spinner,
} from '@chakra-ui/react';
import { CloudUploadIcon, ImageIcon, NoImageIcon } from '@components/icons/ve';
import {
  ChangeEventHandler,
  memo,
  useCallback,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  isImage: boolean;
  isUploading: boolean;
  onChooseMedia: () => void;
  handleUploadFile: ChangeEventHandler<HTMLInputElement>;
  previewUrl: string | null;
}

function VideoEditorUploaded({
  onChooseMedia,
  handleUploadFile,
  isUploading,
  isImage,
  previewUrl,
}: Props) {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleUpload = useCallback(async (event: any) => {
    await handleUploadFile(event);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }, [handleUploadFile]);

  return (
    <Flex align="center" gap="20px" w="full">
      {previewUrl ? (
        <Box pos="relative">
          <Box w="88px" h="88px" borderRadius="50%" overflow="hidden" bgImage={previewUrl} bgSize="cover" bgPos="center center" />
          {isImage}
        </Box>
      ) : (
        <Box w="88px" h="88px">
          <NoImageIcon fontSize="88px" />
        </Box>
      )}
      <Flex direction="column" w="full" gap="10px">
        <Flex
          align="center"
          justify="center"
          gap="12px"
          p="10px"
          borderColor="vmGray.250"
          borderWidth="2px"
          borderRadius="6px"
          _hover={{ bg: 'vmGray.250' }}
          cursor="pointer"
          opacity={isUploading ? 0.5 : 1}
          onClick={() => {
            if (inputRef.current && !isUploading) {
              inputRef.current.click();
            }
          }}
        >
          {isUploading ? (
            <Spinner />
          ) : (
            <CloudUploadIcon fontSize="20px" />
          )}
          <Box
            fontSize="16px"
            lineHeight="24px"
            fontWeight={600}
            color="vmBlack.100"
          >
            {t('video_editor.labels.upload_new_media')}
          </Box>
        </Flex>
        <Input
          ref={inputRef}
          onChange={handleUpload}
          id="media-upload-new-file-2"
          hidden
          type="file"
          accept=".png, .jpg, .jpeg, .mp4, .mpg, .avi, .mov, .wmv, .webm, .mkv"
        />
        <Flex
          align="center"
          justify="center"
          gap="12px"
          p="10px"
          borderColor="vmGray.250"
          borderWidth="2px"
          borderRadius="6px"
          _hover={{ bg: 'vmGray.250' }}
          opacity={isUploading ? 0.5 : 1}
          cursor="pointer"
          onClick={() => {
            if (!isUploading) {
              onChooseMedia();
            }
          }}
        >
          <ImageIcon fontSize="20px" />
          <Box
            fontSize="16px"
            lineHeight="24px"
            fontWeight={600}
            color="vmBlack.100"
          >
            {t('video_editor.labels.choose_from_library')}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default memo(VideoEditorUploaded);
