import { Box, Flex } from '@chakra-ui/react';
import { generateNumbersWithNumLength } from '@utils/array.util';
import { memo, useMemo } from 'react';

interface Props {
  title: string;
  totalStep: number;
  currentStep: number;
  children?: JSX.Element;
}

function StepProcessHeader({
  title,
  totalStep,
  currentStep,
  children,
}: Props) {
  const steps = useMemo(() => {
    if (totalStep <= 0) return [];
    return generateNumbersWithNumLength(totalStep);
  }, [totalStep]);

  return (
    <Flex align="center" direction="column" justify="center" gap="20px">
      {children}
      <Box
        as="h2"
        fontWeight={600}
        fontSize="28px"
        color="vmBlack.100"
        lineHeight="36px"
      >
        {title}
      </Box>
      <Flex gap="10px" align="center" justify="center">
        {steps.map((step) => (
          <Box
            key={step}
            w={step === currentStep ? '16px' : '8px'}
            h="8px"
            bg={step === currentStep ? 'vmPrimary.50' : 'vmGray.400'}
            borderRadius="50px"
          />
        ))}
      </Flex>
    </Flex>
  );
}

StepProcessHeader.defaultProps = {
  children: null,
};

export default memo(StepProcessHeader);
