import { useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import LANGUAGES from '@constants/lang';

type Language = {
  value: string;
  title: string;
}

function useLanguages() {
  const getKey = useCallback((item: Language) => item.value, []);
  const getLabel = useCallback((item: Language) => (
    <Box>{item.title}</Box>
  ), []);

  return {
    languages: LANGUAGES,
    getKey,
    getLabel,
  };
}

export default useLanguages;
