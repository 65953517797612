import { Icon, IconProps } from '@chakra-ui/react';

function GridTypeIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 44 44" fill="none" {...props}>
      <rect x="8" y="8" width="12" height="12" fill="#B5B5B5" />
      <rect x="24" y="8" width="12" height="12" fill="#B5B5B5" />
      <rect x="24" y="24" width="12" height="12" fill="#B5B5B5" />
      <rect x="8" y="24" width="12" height="12" fill="#B5B5B5" />
    </Icon>
  );
}

export default GridTypeIcon;
