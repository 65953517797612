import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Flex,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import ROUTES from '@constants/routes';
import Sidebar from '@core/components/Sidebar';
import { useVmStore } from '@store/index';
import { customerIdSelector } from '@store/slices/customer/selectors';
import { useCallback, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useInitCrispChat from '@root/providers/useInitCrispChat';
import { getItem } from '@utils/localstorage.util';
import LOCAL_STORAGE_KEY from '@constants/localstorage';

interface Props {
  hasSidebar: boolean;
}

function Layout({
  hasSidebar,
}: Props) {
  const navigate = useNavigate();
  useInitCrispChat();
  const { user } = useAuth0();
  const customerId = useVmStore(customerIdSelector);
  const loadCustomer = useVmStore((state) => state.loadCustomer);
  const getAllBrands = useVmStore((state) => state.getBrands);
  const changeSelectedBrand = useVmStore((state) => state.changeSelectedBrand);
  const {
    isOpen: isLoadingCustomer,
    onOpen: startLoadingCustomer,
    onClose: finishLoadingCustomer,
  } = useDisclosure({ defaultIsOpen: true });
  const {
    isOpen: isLoadingBrand,
    onOpen: startLoadingBrand,
    onClose: finishLoadingBrand,
  } = useDisclosure({ defaultIsOpen: true });

  const handleGetBrands = useCallback(async () => {
    try {
      startLoadingBrand();
      const brands = await getAllBrands();
      const selectedBrand = getItem(LOCAL_STORAGE_KEY.BRAND_KEY);
      if (!selectedBrand && brands.length > 0) {
        changeSelectedBrand(brands[0].brandId);
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishLoadingBrand();
  }, [changeSelectedBrand, finishLoadingBrand, getAllBrands, startLoadingBrand]);

  const handleLoadCustomer = useCallback(async () => {
    if (!user) return;
    try {
      startLoadingCustomer();
      const result = await loadCustomer(user!.email!);
      if (!result.emailValidated) {
        navigate(`${ROUTES.AUTH.VERIFY}?email=${user.email}`);
        return;
      }
      await handleGetBrands();
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishLoadingCustomer();
  }, [finishLoadingCustomer, handleGetBrands, loadCustomer, navigate, startLoadingCustomer, user]);

  useEffect(() => {
    if (!customerId) {
      handleLoadCustomer();
    }
  }, [handleLoadCustomer, customerId]);

  if (isLoadingCustomer || isLoadingBrand) {
    return (
      <Flex align="center" justify="center" w="full" minH="100vh">
        <Spinner />
      </Flex>
    );
  }

  return (
    <Box className="main">
      {hasSidebar && (
        <Sidebar />
      )}
      <Box pl={hasSidebar ? '95px' : 0}>
        <Outlet />
      </Box>
    </Box>
  );
}

export default Layout;
