import { Icon, IconProps } from '@chakra-ui/react';

function StarterIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 74 80" fill="none" {...props}>
      <g transform="matrix(1.24933,0,0,1.24933,1.42109e-14,0.0426597)">
        <path id="Path" d="M14.93,6.776L5.168,6.776C4.104,6.776 3.242,7.618 3.242,8.659C3.242,9.699 4.104,10.541 5.168,10.541L14.93,10.541C15.995,10.541 16.857,9.699 16.857,8.659C16.857,7.618 15.995,6.776 14.93,6.776Z" fill="rgb(72, 220, 107)" />
        <path d="M14.93,22.588L5.168,22.588C4.104,22.588 3.242,23.43 3.242,24.471C3.242,25.511 4.104,26.353 5.168,26.353L14.93,26.353C15.995,26.353 16.857,25.511 16.857,24.471C16.857,23.43 15.995,22.588 14.93,22.588Z" fill="rgb(72, 220, 107)" />
        <path d="M11.689,14L1.927,14C0.862,14 0,14.842 0,15.882C0,16.923 0.862,17.765 1.927,17.765L11.689,17.765C12.754,17.765 13.615,16.923 13.615,15.882C13.615,14.842 12.754,14 11.689,14Z" fill="rgb(72, 220, 107)" />
        <path d="M55.615,24.518L45.346,24.518L45.346,16.206C45.346,12.556 41.709,10.635 39.566,13.269L26.025,29.892C24.703,31.515 24.703,34.145 26.025,35.769L33.788,45.298L24.332,56.906C23.01,58.529 23.01,61.159 24.332,62.782C25.655,64.406 27.797,64.406 29.12,62.782L40.968,48.237C42.291,46.613 42.291,43.983 40.968,42.36L33.206,32.83L38.575,26.239L38.575,28.674C38.575,30.969 40.091,32.83 41.96,32.83L55.615,32.83C57.484,32.83 59,30.969 59,28.674C59,26.379 57.484,24.518 55.615,24.518Z" fill="rgb(0, 112, 201)" />
        <path d="M32.476,4.385C30.653,3.516 29.26,3.439 28.088,4.943L18.524,17.224C17.166,18.968 17.166,21.794 18.524,23.539C19.883,25.283 22.084,25.283 23.442,23.539L30.99,13.846L33.532,16.198L36.93,11.836C37.498,11.106 38.168,10.54 38.901,10.097C37.007,7.428 34.865,5.524 32.476,4.385Z" fill="rgb(0, 112, 201)" />
        <path d="M24.153,38.427C23.282,37.363 22.701,36.052 22.395,34.635L4.248,56.827C2.906,58.469 2.906,61.128 4.248,62.769C5.591,64.41 7.765,64.41 9.107,62.769L26.582,41.398L24.153,38.427Z" fill="rgb(0, 112, 201)" />
        <ellipse cx="46.681" cy="6.024" rx="5.187" ry="6.024" fill="rgb(0, 112, 201)" />
      </g>
    </Icon>
  );
}

export default StarterIcon;
