import {
  Flex, FormControl, FormLabel, Input, Switch, Textarea,
} from '@chakra-ui/react';
import ErrorMessage from '@components/ErrorMessage';
import { useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFormValue } from '../../types/form-value';

function PersonalData() {
  const { t } = useTranslation();
  const { control, register, watch } = useFormContext<IFormValue>();
  const { errors } = useFormState({ control });
  const requireConsent = watch('personalData.requireConsent');
  return (
    <>
      <FormControl pt={0} isInvalid={!!errors?.personalData?.title?.message}>
        <FormLabel>{t('forms.labels.name_title')}</FormLabel>
        <Input type="text" {...register('personalData.title')} placeholder={t('forms.placeholders.name_title')} />
        <ErrorMessage error={errors?.personalData?.title?.message} />
      </FormControl>
      <FormControl isInvalid={!!errors?.personalData?.placeholder?.message}>
        <FormLabel>{t('forms.labels.name_placeholder')}</FormLabel>
        <Input type="text" {...register('personalData.placeholder')} placeholder={t('forms.placeholders.name_placeholder')} />
        <ErrorMessage error={errors?.personalData?.placeholder?.message} />
      </FormControl>
      <FormControl isInvalid={!!errors?.personalData?.email?.message}>
        <FormLabel>{t('forms.labels.email_title')}</FormLabel>
        <Input type="text" {...register('personalData.email')} placeholder={t('forms.placeholders.email_title')} />
        <ErrorMessage error={errors?.personalData?.email?.message} />
      </FormControl>
      <FormControl isInvalid={!!errors?.personalData?.emailPlaceholder?.message}>
        <FormLabel>{t('forms.labels.email_placeholder')}</FormLabel>
        <Input type="text" {...register('personalData.emailPlaceholder')} placeholder={t('forms.placeholders.email_placeholder')} />
        <ErrorMessage error={errors?.personalData?.emailPlaceholder?.message} />
      </FormControl>
      <FormControl>
        <Flex align="center" justify="space-between">
          <FormLabel mb={0}>{t('forms.labels.use_consent')}</FormLabel>
          <Switch {...register('personalData.requireConsent')} />
        </Flex>
      </FormControl>
      {requireConsent && (
        <FormControl isInvalid={!!errors?.personalData?.consentText?.message}>
          <FormLabel>{t('forms.labels.consent_box_text')}</FormLabel>
          <Textarea rows={5} {...register('personalData.consentText')} placeholder={t('forms.placeholders.consent_box_text')} />
          <ErrorMessage error={errors?.personalData?.consentText?.message} />
        </FormControl>
      )}
    </>
  );
}

export default PersonalData;
