import {
  createFolder,
  deleteFolder,
  getAllFolders,
  updateFolderData,
} from '@apis/folder.api';
import { AppState } from '@store/types';
import { StateCreator } from 'zustand';
import { FolderState } from './types';

const createFolderSlice: StateCreator<AppState, [], [], FolderState> = (set, get) => ({
  folders: [],
  loadFolders: async () => {
    const brandId = get().selectedBrandId!;
    const result = await getAllFolders(brandId);
    set(() => ({ folders: result }));
  },
  addFolder: async (name: string) => {
    const { customerId } = get().customer!;
    const brandId = get().selectedBrandId!;
    await createFolder({ name, brandId, customerId });
  },
  removeFolder: async (folderId: string) => {
    await deleteFolder(folderId);
    set((state) => ({ folders: state.folders.filter((item) => item.folderId !== folderId) }));
  },
  updateFolder: async (folderId: string, name: string) => {
    await updateFolderData(folderId, name);
    set((state) => {
      const folderIndex = state.folders.findIndex((item) => item.folderId === folderId);
      const newFolders = [...state.folders];
      newFolders[folderIndex] = {
        ...newFolders[folderIndex],
        name,
      };
      return { folders: newFolders };
    });
  },
});

export default createFolderSlice;
