import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react';
import CloseIcon from '@components/icons/Close';
import { ExclaimationIcon } from '@components/icons/ve';
import { useTranslation } from 'react-i18next';

interface Props {
  isLoading: boolean;
  title: string;
  message: string;
  isOpen: boolean;
  onClose: () => void;
}

function ErrorNotify({
  isLoading,
  title,
  message,
  isOpen,
  onClose,
}: Props) {
  const { t } = useTranslation();
  return (
    <Modal
      closeOnOverlayClick
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent w="580px" maxW="580px">
        <ModalBody p="40px" pos="relative">
          <Box pos="absolute" right="16px" top="10px" cursor="pointer" onClick={onClose}>
            <CloseIcon />
          </Box>
          <Box textAlign="center" mb="20px">
            <Flex m="0 auto" w="44px" h="44px" bg="vmRed.50" borderRadius="10px" align="center" justify="center">
              <ExclaimationIcon fontSize="24px" />
            </Flex>
          </Box>
          <Box
            as="h2"
            fontWeight="600"
            fontSize="24px"
            lineHeight="31px"
            color="vmRed.50"
            textAlign="center"
            mb="20px"
          >
            {title}
          </Box>
          <Box mb="40px" fontSize="trNormal" color="vmBlack.50" textAlign="center">
            {isLoading ? <Spinner /> : message}
          </Box>
          <Flex gap="20px" justify="center" align="center">
            <Button variant="solidPrimary" type="button" onClick={onClose}>{t('common.button.texts.close')}</Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ErrorNotify;
