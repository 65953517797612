import { Icon, IconProps } from '@chakra-ui/react';

function PlayIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 15 18" fill="vmBlack.50" stroke="none" {...props}>
      <path
        d="M14 7.26795C15.3333 8.03775 15.3333 9.96225 14 10.7321L3.5 16.7942C2.16667 17.564 0.500001 16.6018 0.500001 15.0622L0.500002 2.93782C0.500002 1.39822 2.16667 0.435971 3.5 1.20577L14 7.26795Z"
      />
    </Icon>
  );
}

export default PlayIcon;
