export default {
  parts: ['container', 'requiredIndicator', 'helperText'],
  baseStyle: {
    container: {
      pt: '25px',
      // borderBottomWidth: '2px',
      // borderBottomColor: 'vmGray.200',
      _fist: {
        pt: 0,
      },
      '& .chakra-form__error-message': {
        color: 'vmRed.50',
        mt: '10px',
        fontSize: '14px',
      },
    },
  },
};
