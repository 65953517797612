import {
  Box,
  Flex,
  Grid,
  Switch,
} from '@chakra-ui/react';
import usePlan from '@modules/Plan/hooks/usePlan';
import {
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PlanItem from '@modules/Plan/components/PlanItem/PlanItem';
import { useVmStore } from '@store/index';
import { customerEmailSelector, customerSubscriptionSelector } from '@store/slices/customer/selectors';
import { Plan } from '@modules/Plan/types/plan.types';

function ListPlan() {
  const { t } = useTranslation();
  const customerSubscription = useVmStore(customerSubscriptionSelector);
  const customerEmail = useVmStore(customerEmailSelector);
  const plans = usePlan();
  const [timeType, setTimeType] = useState<'monthly' | 'yearly'>('yearly');
  const [unit, setUnit] = useState<'EUR' | 'USD'>('USD');

  const handleChangeTimeType = useCallback(() => {
    setTimeType((currentTimeType) => {
      if (currentTimeType === 'monthly') {
        return 'yearly';
      }
      if (currentTimeType === 'yearly') {
        return 'monthly';
      }
      return 'yearly';
    });
  }, []);

  const handleChangeUnit = useCallback(() => {
    setUnit((currentUnitType) => {
      if (currentUnitType === 'EUR') {
        return 'USD';
      }
      if (currentUnitType === 'USD') {
        return 'EUR';
      }
      return 'USD';
    });
  }, []);

  const handlePaySuccess = useCallback(async () => {
    toast.success(t('plan.purchase.message.purchase_success'));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }, [t]);

  const handlePayment = useCallback((productId: number) => {
    window.Paddle.Checkout.open({
      product: productId,
      email: customerEmail!,
      locale: 'en',
      isUSDCurrency: unit === 'USD',
      message: timeType === 'yearly' ? t('plan.purchase.message.yearly') : t('plan.purchase.message.monthly'),
      disableLogout: true,
      successCallback: handlePaySuccess,
      passthrough: 'Standard',
    });
  }, [timeType, unit, customerEmail, t, handlePaySuccess]);

  const handlePurchase = useCallback(async (plan: Plan) => {
    const { productId } = plan[timeType];
    handlePayment(productId);
  }, [handlePayment, timeType]);

  const hasYearlyConfig = useMemo(() => plans.every((item) => !item.isBillOnce), [plans]);

  return (
    <Box>
      {hasYearlyConfig && (
        <Grid gridTemplateColumns="1fr 1fr">
          <Flex align="center" gap="10px" justify="center">
            <Box>{t('plan.purchase.labels.monthly')}</Box>
            <Box><Switch isChecked={timeType === 'yearly'} onChange={handleChangeTimeType} /></Box>
            <Box>{t('plan.purchase.labels.yearly')}</Box>
          </Flex>
          <Flex align="center" gap="10px" justify="center">
            <Box>{t('plan.purchase.labels.price_in_euro')}</Box>
            <Box><Switch isChecked={unit === 'USD'} onChange={handleChangeUnit} /></Box>
            <Box>{t('plan.purchase.labels.or_usd')}</Box>
          </Flex>
        </Grid>
      )}
      <Grid gridTemplateColumns="1fr 1fr" gridGap="30px" mt="20px">
        {plans.map((plan) => (
          <PlanItem
            key={plan.key}
            plan={plan}
            type={timeType}
            unit={unit}
            onPurchase={handlePurchase}
            isCurrentPlan={Boolean(
              customerSubscription!.subscription === plan.name
              && !customerSubscription?.trial
              && !customerSubscription?.trialEnded
              && customerSubscription?.active,
            )}
          />
        ))}
      </Grid>
    </Box>
  );
}

export default memo(ListPlan);
