export default {
  baseStyle: {
    '--skeleton-start-color': 'colors.vmGray.150',
    '--skeleton-end-color': 'colors.vmGray.750',
    opacity: 0.7,
    borderRadius: '0.125rem',
    background: 'vmGray.150',
    borderColor: 'vmGray.750',
  },
};
