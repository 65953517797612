import { IFormValue } from '@modules/Forms/forms/VMFormControl/types/form-value';
import { IForm } from '@modules/Forms/types/form.types';
import { ApiResponse } from '@typed/http.types';
import axios, { AxiosResponse } from 'axios';
import { IFromListItem } from '@modules/Forms/types/form-list.types';

async function createForm(data: IFormValue) {
  return axios.post('/forms', data);
}

async function getAllRootForms(brandId: string): Promise<IFromListItem[]> {
  return axios.get(`/forms/${brandId}/all`).then((response: AxiosResponse<ApiResponse<IFromListItem[]>>) => response.data.result);
}

async function getForm(formId: string): Promise<IForm> {
  return axios.get(`/forms/${formId}`).then((response: AxiosResponse<ApiResponse<IForm>>) => response.data.result);
}

async function updateForm(formId: string, data: IFormValue) {
  return axios.put(`/forms/${formId}`, data);
}

async function deleteForm(formId: string) {
  return axios.delete(`/forms/${formId}`);
}

async function moveFormToFolder(formId: string, folderId: string) {
  const queryParams: {[key: string]: string} = {};
  if (folderId) {
    queryParams.folderId = folderId;
  }
  const query = new URLSearchParams(queryParams);
  return axios.put(`/forms/${formId}/move-folder?${query.toString()}`);
}

export {
  createForm,
  getAllRootForms,
  getForm,
  updateForm,
  deleteForm,
  moveFormToFolder,
};
