import {
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Flex,
  Grid,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import VideoEditorUploader from '@modules/VideoEditor/components/VideoEditorUploader/VideoEditorUploader';
import { VideoEditorIntroOutro, VideoEditorScene } from '@modules/VideoEditor/types/video-editor.types';
import MediaLibrarySelector from '@modules/VideoEditor/components/MediaLibrarySelector/MediaLibrarySelector';
import useMediaUpload from '@shared/MediaLibrary/hooks/useMediaUpload';
import { generateGuid } from '@utils/guid.util';
import VideoEditorScenePreview from '@modules/VideoEditor/components/VideoEditorScenePreview/VideoEditorScenePreview';
import { Media } from '@shared/MediaLibrary/types/media';
import CloseIcon from '@components/icons/Close';
import { PlusIcon } from '@components/icons/ve';

interface Props {
  defaultScene: VideoEditorScene | null;
  brandId: string;
  onCreateScene: (scene: VideoEditorScene) => void;
  title: string;
  description: string;
}

function VideoEditorAddScene({
  defaultScene,
  onCreateScene,
  brandId,
  title,
  description,
}: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    isOpen: isLibraryOpen,
    onClose: closeLibrary,
    onOpen: openLibrary,
  } = useDisclosure();
  const [selectedIntro, setSelectedIntro] = useState<VideoEditorIntroOutro | null>(defaultScene);

  const handleSetIntro = useCallback(async (file: Media) => {
    const defaultSceneData: VideoEditorIntroOutro = defaultScene === null ? ({
      videoToken: null,
      videoUrl: null,
      videoImageUrl: null,
      width: 0,
      height: 0,
      codedWidth: 0,
      codedHeight: 0,
      mediaId: '00000000-0000-0000-0000-000000000000',
      imageUrl: null,
      imageDuration: null,
      videoVideoUrl: null,
      videoDuration: null,
      trimmingStart: null,
      trimmingEnd: null,
      id: generateGuid(),
    }) : defaultScene;
    const newIntro: VideoEditorIntroOutro = file.type === 'image' ? ({
      ...defaultSceneData,
      imageUrl: file.imageUrl,
      mediaId: file.id,
      imageDuration: 2,
      id: defaultSceneData.id ? defaultSceneData.id : generateGuid(),
    }) : ({
      ...defaultSceneData,
      videoVideoUrl: file.videoVideoUrl,
      videoDuration: file.videoDuration,
      trimmingStart: null,
      trimmingEnd: null,
      videoToken: file.videoToken,
      videoUrl: file.videoUrl,
      videoImageUrl: file.videoImageUrl,
      width: file.videoWidth ?? 0,
      height: file.videoHeight ?? 0,
      mediaId: file.id,
      id: defaultSceneData.id ? defaultSceneData.id : generateGuid(),
    });
    setSelectedIntro(newIntro);
    closeLibrary();
  }, [closeLibrary, defaultScene]);

  const createIntroScene = useCallback(() => {
    if (!selectedIntro) return;
    onCreateScene(selectedIntro);
  }, [selectedIntro, onCreateScene]);
  const previewUrl = useMemo(() => {
    if (selectedIntro?.imageUrl) {
      return selectedIntro.imageUrl;
    }

    if (selectedIntro?.videoImageUrl) {
      return `https://${selectedIntro.videoImageUrl}`;
    }

    return null;
  }, [selectedIntro]);

  const {
    upload,
    isUploading,
  } = useMediaUpload(brandId);

  const handleUploadFile = useCallback(async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = [...file.name.split('.')].pop();
      const allowVideoExtensions = ['video/x-ms-wmv', 'video/mp4', 'video/mpeg', 'video/avi', 'video/quicktime', 'video/webm'];
      const allowImageExtensions = ['image/jpeg', 'image/png'];
      if (
        allowVideoExtensions.includes(file.type)
        || allowImageExtensions.includes(file.type)
        || fileExtension === 'mkv'
      ) {
        const fileName: string = file.name.replace(/[^\w\-.]/g, '');
        await upload(
          new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type }),
          fileName,
          handleSetIntro,
        );
      }
    }
  }, [upload, handleSetIntro]);

  const havingData = !!(selectedIntro?.imageUrl || selectedIntro?.videoToken);

  return (
    <Box>
      {!isLibraryOpen ? (
        <Box pt="64px">
          <Grid w="full" templateColumns="480px auto" minH="calc(100vh - 64px)" pt="20px" px="20px" pb="20px">
            <Flex direction="column" gap="2px" bg="vmGray.450" borderRadius="10px" overflow="hidden">
              <Flex align="center" justify="center" pos="relative" bg="vmWhite">
                <Box
                  fontSize="24px"
                  lineHeight="32.4px"
                  fontWeight={600}
                  px="20px"
                  py="27px"
                >
                  {title}
                </Box>
              </Flex>
              <Flex align="center" justify="center" gap="40px" direction="column" background="vmWhite" h="-webkit-fill-available" px="20px">
                <Box
                  fontSize="16px"
                  fontWeight={400}
                  lineHeight="24px"
                  color="vmBlack.50"
                >
                  {description}
                </Box>
                <VideoEditorUploader
                  onChooseMedia={openLibrary}
                  isUploading={isUploading}
                  handleUploadFile={handleUploadFile}
                  isImage={!!selectedIntro?.imageUrl}
                  previewUrl={previewUrl}
                />
              </Flex>
              <Flex align="center" gap="2px" mt="auto">
                <Flex
                  align="center"
                  justify="center"
                  gap="12px"
                  bg="vmWhite"
                  p="15px"
                  w="full"
                  cursor={isUploading ? 'not-allowed' : 'pointer'}
                  opacity={isUploading ? 0.5 : 1}
                  onClick={() => {
                    if (!isUploading) {
                      navigate('./..');
                    }
                  }}
                >
                  <CloseIcon stroke="vmRed.50" />
                  <Box fontSize="16px" lineHeight="24px" fontWeight={600} color="vmBlack.100">
                    {t('common.button.texts.cancel')}
                  </Box>
                </Flex>
                <Flex
                  align="center"
                  justify="center"
                  gap="12px"
                  bg="vmWhite"
                  p="15px"
                  w="full"
                  cursor={isUploading || !havingData ? 'not-allowed' : 'pointer'}
                  opacity={isUploading || !havingData ? 0.5 : 1}
                  onClick={() => {
                    if (!havingData) return;
                    if (!isUploading) {
                      createIntroScene();
                    }
                  }}
                >
                  <PlusIcon stroke="vmPrimary.50" />
                  <Box fontSize="16px" lineHeight="24px" fontWeight={600} color="vmBlack.100">
                    {t('video_editor.labels.create_scene')}
                  </Box>
                </Flex>
              </Flex>
            </Flex>
            <Flex align="center" justify="center">
              {havingData && (
                <VideoEditorScenePreview
                  updateKey=""
                  scene={selectedIntro}
                  isDisableTrimMode
                />
              )}
            </Flex>
          </Grid>
        </Box>
      ) : (
        <MediaLibrarySelector
          brandId={brandId}
          onClose={closeLibrary}
          onSelectFile={handleSetIntro}
          selectedId={selectedIntro?.mediaId ?? null}
        />
      )}
    </Box>
  );
}

export default memo(VideoEditorAddScene);
