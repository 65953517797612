import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Textarea,
} from '@chakra-ui/react';
import ErrorMessage from '@components/ErrorMessage';
import { useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFormValue } from '../../types/form-value';

function ThankYou() {
  const { t } = useTranslation();
  const { control, register, watch } = useFormContext<IFormValue>();
  const { errors } = useFormState({ control });
  const isCustomRedirectEnabled = watch('thankYou.redirect');

  return (
    <>
      {!isCustomRedirectEnabled && (
        <>
          <FormControl pt={0} isInvalid={!!errors?.thankYou?.title?.message}>
            <FormLabel>{t('forms.labels.thank_you_title')}</FormLabel>
            <Input type="text" {...register('thankYou.title')} placeholder={t('forms.placeholders.thank_you_title')} />
            <ErrorMessage error={errors?.thankYou?.title?.message} />
          </FormControl>
          <FormControl isInvalid={!!errors?.thankYou?.text?.message}>
            <FormLabel>{t('forms.labels.thank_you_text')}</FormLabel>
            <Textarea rows={5} {...register('thankYou.text')} placeholder={t('forms.placeholders.thank_you_text')} />
            <ErrorMessage error={errors?.thankYou?.text?.message} />
          </FormControl>
        </>
      )}
      <FormControl>
        <Flex align="center" justify="space-between">
          <FormLabel mb={0}>{t('forms.labels.redirect')}</FormLabel>
          <Switch {...register('thankYou.redirect')} />
        </Flex>
      </FormControl>
      {isCustomRedirectEnabled && (
        <FormControl isInvalid={!!errors?.thankYou?.redirectUrl?.message}>
          <FormLabel>{t('forms.labels.redirect_url')}</FormLabel>
          <Input type="text" {...register('thankYou.redirectUrl')} placeholder={t('forms.placeholders.redirect_url')} />
          <ErrorMessage error={errors?.thankYou?.redirectUrl?.message} />
        </FormControl>
      )}
    </>
  );
}

export default ThankYou;
