import { Box, Flex } from '@chakra-ui/react';
import CheckIcon from '@components/icons/Check';

function AuthDescription() {
  return (
    <Flex direction="column" gap="40px">
      <Box pos="relative" w="full">
        <Box
          bgImage="linear-gradient(180deg, transparent 95%, #48DC6B 0)"
          bgRepeat="no-repeat"
          bgSize="51% 100%"
          bgPos="360px 0"
          textDecoration="none"
          display="inline"
          fontWeight={700}
          fontSize="40px"
          lineHeight="52px"
          color="#071E57"
        >
          Collect and display authentic testimonials & ratings in minutes
        </Box>
      </Box>
      <Flex direction="column" gap="20px">
        <Flex align="center" gap="10px">
          <CheckIcon fontSize="16px" />
          <Box color="#000" fontSize="16px" lineHeight="24px" fontWeight={400}>Easy to set up</Box>
        </Flex>
        <Flex align="center" gap="10px">
          <CheckIcon fontSize="16px" />
          <Box color="#000" fontSize="16px" lineHeight="24px" fontWeight={400}>Test 14 days for free</Box>
        </Flex>
        <Flex align="center" gap="10px">
          <CheckIcon fontSize="16px" />
          <Box color="#000" fontSize="16px" lineHeight="24px" fontWeight={400}>Cancel at any time</Box>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default AuthDescription;
