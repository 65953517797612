function downloadFile(blobText: string, fileName: string) {
  const linkSource = `data:application/pdf;base64,${blobText}`;
  const downloadLink = document.createElement('a');
  document.body.appendChild(downloadLink);
  downloadLink.href = linkSource;
  downloadLink.target = '_self';
  downloadLink.download = fileName;
  downloadLink.click();
}

export {
  // eslint-disable-next-line import/prefer-default-export
  downloadFile,
};
