import { Icon, IconProps } from '@chakra-ui/react';

function ThreeDotsIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 22 22" fill="none" stroke="vmGray.600" strokeWidth={1.5} {...props}>
      <path
        d="M11 7V7.01M11 11V11.01M11 15V15.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default ThreeDotsIcon;
