import { Icon, IconProps } from '@chakra-ui/react';

function VideoIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 17" {...props} fill="none" stroke="vmGray.50" strokeWidth={1.5}>
      <path
        d="M22.5395 3.348C22.4207 2.88753 22.1788 2.46563 21.8381 2.12491C21.4975 1.78419 21.0703 1.53673 20.5996 1.4075C18.8796 1 12 1 12 1C12 1 5.12036 1 3.40045 1.44631C2.92972 1.57553 2.50247 1.823 2.16186 2.16372C1.82124 2.50444 1.57933 2.92634 1.46055 3.3868C1.14578 5.08042 0.991808 6.79849 1.00057 8.51941C0.989351 10.2533 1.14333 11.9844 1.46055 13.6908C1.5915 14.137 1.83883 14.5428 2.17865 14.8692C2.51847 15.1955 2.93929 15.4313 3.40045 15.5537C5.12036 16 12 16 12 16C12 16 18.8796 16 20.5996 15.5537C21.0703 15.4245 21.4975 15.177 21.8381 14.8363C22.1788 14.4956 22.4207 14.0737 22.5395 13.6132C22.8518 11.9323 23.0058 10.2274 22.9994 8.51941C23.0106 6.78553 22.8567 5.05436 22.5395 3.348Z"
        stroke="#92ABCA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75012 11.6921L15.4998 8.51941L9.75012 5.3467V11.6921Z"
        stroke="#92ABCA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default VideoIcon;
