import { AppState } from '@store/types';

function getFoldersSelector(state: AppState) {
  return state.folders;
}

export {
  // eslint-disable-next-line import/prefer-default-export
  getFoldersSelector,
};
