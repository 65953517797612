import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import ErrorMessage from '@components/ErrorMessage';
import Select from '@components/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import useLanguages from '@hooks/useLanguages';
import getBrandSchema from '@modules/Branding/schemas/brand.schema';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BrandFormValues } from '@modules/Branding/types/brand-form.types';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  defaultValues: BrandFormValues;
  isLoading: boolean;
  onSubmit: (value: BrandFormValues) => Promise<void>;
}

function BrandControl({
  defaultValues,
  isLoading,
  onSubmit,
}: Props) {
  const { t } = useTranslation();
  const {
    languages,
    getKey,
    getLabel,
  } = useLanguages();
  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm<BrandFormValues>({
    defaultValues,
    resolver: yupResolver(getBrandSchema()),
  });
  const { errors } = useFormState({ control });

  const onSubmitBrand = useCallback((values: BrandFormValues) => {
    onSubmit(values).then(() => reset());
  }, [onSubmit, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmitBrand)}>
      <FormControl isInvalid={!!errors?.name?.message}>
        <FormLabel>{t('common.labels.name')}</FormLabel>
        <Input type="text" placeholder={t('common.placeholders.name')} {...register('name')} />
        <ErrorMessage error={errors?.name?.message} />
      </FormControl>
      <FormControl isInvalid={!!errors?.languageCode?.message}>
        <FormLabel>{t('common.labels.language')}</FormLabel>
        <Controller
          control={control}
          name="languageCode"
          render={({ field }) => (
            <Select
              data={languages}
              getKey={getKey}
              getLabel={getLabel}
              placeholder={t('common.placeholders.language')}
              {...field}
              onChange={(event) => {
                field.onChange(event);
                setValue('language', languages.find((item) => item.value === event.target.value)!.title);
              }}
            />
          )}
        />
        <ErrorMessage error={errors?.languageCode?.message} />
      </FormControl>
      <Flex mt="30px" align="center" gap="20px">
        <Link to="/brands">
          <Button type="button" variant="solidPrimary" isDisabled={isLoading}>
            {t('common.button.texts.cancel')}
          </Button>
        </Link>
        <Button type="submit" isDisabled={isLoading} isLoading={isLoading}>
          {t('common.button.texts.save')}
        </Button>
      </Flex>
    </form>
  );
}

export default BrandControl;
