import { Icon, IconProps } from '@chakra-ui/react';

function CameraSelectorIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 22 16" fill="vmWhite" stroke="none" {...props}>
      <path
        d="M10.8956 0C13.5598 0 15.42 1.7803 15.42 4.33164V11.6684C15.42 14.2197 13.5598 16 10.8956 16H4.52434C1.86012 16 0 14.2197 0 11.6684V4.33164C0 1.7803 1.86012 0 4.52434 0H10.8956ZM19.7538 2.53758C20.2367 2.29934 20.8032 2.32425 21.2641 2.60581C21.725 2.88628 22 3.37359 22 3.90638V12.0943C22 12.6281 21.725 13.1144 21.2641 13.3948C21.0122 13.5475 20.7306 13.6255 20.4468 13.6255C20.2103 13.6255 19.9738 13.5714 19.7527 13.462L18.1236 12.665C17.5208 12.3682 17.1468 11.7726 17.1468 11.111V4.88858C17.1468 4.22584 17.5208 3.63024 18.1236 3.33569L19.7538 2.53758Z"
      />
    </Icon>
  );
}

export default CameraSelectorIcon;
