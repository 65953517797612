import {
  Box,
  Button,
  Flex,
  Grid,
  useConst,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowLeftIcon from '@components/icons/ArrowLeft';
import ArrowPointRight from '@components/icons/ArrowPointRight';
import { useCallback, useMemo } from 'react';

type PageMap = Record<'QUESTION' | 'PERSONAL' | 'THANKYOU' | 'ADVANCED', { path: string; title: string }>;

function VMBarNextPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const lastPath = location.pathname.split('/').pop();
  const isLastStep = lastPath === 'advanced';

  const pages = useConst<PageMap>(() => ({
    QUESTION: {
      path: 'question',
      title: 'Question',
    },
    PERSONAL: {
      path: 'personal',
      title: 'Personal data',
    },
    THANKYOU: {
      path: 'thank-you',
      title: 'Thank you',
    },
    ADVANCED: {
      path: 'advanced',
      title: 'Advanced',
    },
  }));

  const nextPage = useMemo(() => {
    switch (lastPath) {
      case pages.QUESTION.path: {
        return pages.PERSONAL.title;
      }
      case pages.PERSONAL.path: {
        return pages.THANKYOU.title;
      }
      case pages.THANKYOU.path: {
        return pages.ADVANCED.title;
      }
      default: {
        return pages.QUESTION.title;
      }
    }
  }, [lastPath, pages]);

  const handleNavigate = useCallback(() => {
    switch (lastPath) {
      case pages.QUESTION.path: {
        return navigate(pages.PERSONAL.path);
      }
      case pages.PERSONAL.path: {
        return navigate(pages.THANKYOU.path);
      }
      case pages.THANKYOU.path: {
        return navigate(pages.ADVANCED.path);
      }
      default: {
        return navigate(pages.QUESTION.path);
      }
    }
  }, [lastPath, pages, navigate]);

  const backPath = useMemo<string | null>(() => {
    switch (lastPath) {
      case pages.QUESTION.path: {
        return '';
      }
      case pages.PERSONAL.path: {
        return pages.QUESTION.path;
      }
      case pages.THANKYOU.path: {
        return pages.PERSONAL.path;
      }
      case pages.ADVANCED.path: {
        return pages.THANKYOU.path;
      }
      default: {
        return null;
      }
    }
  }, [
    lastPath,
    pages.ADVANCED.path,
    pages.PERSONAL.path,
    pages.QUESTION.path,
    pages.THANKYOU.path,
  ]);

  return (
    <Grid mt="auto" templateColumns={backPath !== null ? '44px auto' : 'auto'} gridGap="20px">
      {backPath !== null && (
        <Button type="button" variant="iconOnly" size="iconOnly" bg="vmWhite" borderColor="vmWhite" onClick={() => navigate(backPath)}>
          <ArrowLeftIcon />
        </Button>
      )}
      {!isLastStep && (
        <Button type="button" bg="vmRed.100" borderColor="vmRed.100" color="vmWhite" onClick={handleNavigate}>
          <Flex align="center" gap="10px">
            <Box as="span" fontSize="15px" fontWeight={700} lineHeight="24px" color="vmWhite">
              Next step:
              {' '}
              {nextPage}
            </Box>
            <ArrowPointRight fontSize="12px" />
          </Flex>
        </Button>
      )}
    </Grid>
  );
}

export default VMBarNextPage;
