import {
  Box,
  Input,
  InputGroup,
  InputRightAddon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import {
  forwardRef,
  memo,
  Ref,
  useCallback,
} from 'react';
import { ColorResult, ChromePicker } from 'react-color';

interface Props {
  value: string | null;
  onChange: (event: { target: { value: string } }) => void;
}

function InputColor({ value, onChange }: Props, ref: Ref<HTMLDivElement>) {
  const handleColorChange = useCallback((color: ColorResult) => {
    const { rgb, hex } = color;
    onChange({ target: { value: rgb.a === 1 ? hex : `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})` } });
  }, [onChange]);

  return (
    <Box ref={ref}>
      <InputGroup>
        <Input type="text" value={value ?? ''} onChange={onChange} />
        <InputRightAddon p={0} overflow="hidden">
          <Popover trigger="click" placement="top">
            <PopoverTrigger>
              <Box
                borderTopRightRadius="10px"
                borderBottomRightRadius="10px"
                borderWidth="2px"
                borderLeftWidth="0"
                borderColor="vmGray.350"
                w="44px"
                h="full"
                bg={value ?? undefined}
              />
            </PopoverTrigger>
            <PopoverContent _focus={{ boxShadow: 'none' }} maxW="244px">
              <PopoverArrow />
              <PopoverBody>
                <ChromePicker
                  color={value ?? undefined}
                  onChange={handleColorChange}
                />
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </InputRightAddon>
      </InputGroup>
    </Box>
  );
}

export default memo(forwardRef(InputColor));
