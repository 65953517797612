import {
  forwardRef,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
// components
import Select from '@components/Select';
import TrustRecorderVideoTime from '@components/TrustRecorder/components/TrustRecorderVideoTime/TrustRecorderVideoTime';
import useTrustRecorder from '@components/TrustRecorder/hooks/useTrustRecorder';
import VideoRecorder from '@components/VideoRecorder';
import ArrowBackIcon from '@components/icons/question/ArrowBack';
// hooks
import useMaxSizeNotify from '@hooks/useMaxSizeNotify';
import useElementRef from '@hooks/useElementRef';
import useRecorderPlayingState from '@hooks/useRecorderPlayingState';
// types
import { ZiggeoProgressType } from '@typed/ziggeo-recorder';
import useRecorderRef from '@components/VmVideoRecorder/hooks/useRecorderRef';
// styles
import TrustRecorderContainer from './styled/TrustRecorderContainer';
import TrustRecorderWrapper from './styled/TrustRecorderWrapper';
import TrustRecorderPermissionRequest from './components/TrustRecorderPermissionRequest/TrustRecorderPermissionRequest';
import TrustRecorderDevicePermissionRequest from './components/TrustRecorderDevicePermissionRequest/TrustRecorderDevicePermissionRequest';
import CameraSelectorIcon from './icons/CameraSelector';
import DeviceSettingIcon from './icons/DeviceSetting';
import PlayIcon from './icons/Play';
import MicrophoneSelectorIcon from './icons/MicrophoneSelector';

function getKey(item: MediaDeviceInfo) {
  return item.deviceId;
}

function getLabel(item: MediaDeviceInfo) {
  return (
    <Box>{item.label}</Box>
  );
}

interface Props {
  previousVideoToken: string | null;
  value: string | null; // videoToken value
  onChange: (event: { target: { value: string | null} }) => void;
  onGoBack: () => void;
  onSetRecordRef?: (recorder: any) => void;
  simulate?: boolean;
  showArrowBack?: boolean;
  children: ReactNode | null;
  isExist: boolean;
  recorderKey: string;
  onStartVideoProgress: () => void;
}

function TrustRecorder({
  previousVideoToken,
  value,
  onChange,
  onGoBack,
  onSetRecordRef,
  isExist,
  simulate,
  showArrowBack,
  children,
  recorderKey,
  onStartVideoProgress,
}: Props, ref: Ref<HTMLDivElement>) {
  const startingTimeRef = useRef<number>(0);
  const { recorder, setRecorderRef } = useRecorderRef();
  const [canStopRecorder, setCanStopRecorder] = useState<boolean>(false);
  const [containerRef, container] = useElementRef<HTMLDivElement>();
  const [isProcessingVideo, setIsProcessingVideo] = useState<boolean>(false);
  const { isPlaying, isReadyToPlay } = useRecorderPlayingState(recorder, previousVideoToken);
  const {
    isOpen: isSettingCamera,
    onToggle: toggleSettingCamera,
    onClose: closeSettingCamera,
  } = useDisclosure();
  const {
    isOpen: isSettingMicro,
    onToggle: toggleSettingMicro,
    onClose: closeSettingMicro,
  } = useDisclosure();
  const {
    isOpen: isRecording,
    onToggle: toggleRecording,
  } = useDisclosure();
  const { isMatch } = useMaxSizeNotify(container, 600);

  const {
    cameraDevices,
    audioDevices,
    needToAskForCameraPermission,
    // isCameraExist,
    // isMicrophoneExist,
    isGettingDeviceState,
    isVerified,
    isPermissionDenied,
    selectedCamera,
    selectedMicro,
    changeSelectedCamera,
    changeSelectedMicro,
    requestDevicePermission,
  } = useTrustRecorder();

  const handleSaveRecorderInstance = useCallback((newRecorder: any) => {
    if (onSetRecordRef) {
      onSetRecordRef(newRecorder);
    }
    setRecorderRef(newRecorder);
  }, [onSetRecordRef, setRecorderRef]);

  const isEnableRecordButton = (
    !isRecording
    || canStopRecorder
  );

  useEffect(() => () => {
    setIsProcessingVideo(false);
  }, []);

  if (!isVerified) return null;

  if (isPermissionDenied) {
    return (
      <Box ref={ref} w="full">
        <TrustRecorderPermissionRequest
          simulate={simulate ?? false}
          showArrowBack={showArrowBack!}
          onGoBack={onGoBack}
          oldToken={previousVideoToken}
          onChange={onChange}
        />
      </Box>
    );
  }

  if (needToAskForCameraPermission) {
    return (
      <Box ref={ref} w="full">
        <TrustRecorderDevicePermissionRequest
          simulate={simulate ?? false}
          isGettingDevice={isGettingDeviceState}
          onChange={onChange}
          onGoBack={onGoBack}
          oldVideoToken={previousVideoToken}
          onRequest={requestDevicePermission}
          showArrowBack={showArrowBack!}
        />
      </Box>
    );
  }

  return (
    <TrustRecorderWrapper
      ref={ref}
      position={{
        base: isExist ? 'relative' : 'fixed',
        md: 'relative',
      }}
      overflow={{
        base: 'hidden',
        md: 'auto',
      }}
      height={{
        base: 'full',
        md: 'full',
      }}
      zIndex={isExist ? 0 : 999999}
    >
      <TrustRecorderContainer objectFit="cover">
        <Flex
          className="video-wrapper"
          borderRadius={{
            base: isExist ? '10px' : 0,
            md: '10px',
          }}
          overflow="hidden"
          align="center"
          justify="center"
          w="full"
          h="full"
          bg="vmWhite"
          sx={{
            '& > div > div': {
              h: 'full',
              maxH: {
                base: '100%',
                md: '600px',
              },
              '& > div[data-selector="recorder-player"]': {
                h: 'full',
              },
            },
            '& .ba-videoplayer-noie8 .ba-videoplayer-playbutton-container': {
              display: 'none',
            },
            '& .ba-videoplayer-rerecord-bar': {
              display: 'none',
            },
            '& .ba-player-dashboard': {
              display: 'none',
            },
            '& .ba-videoplayer-video': {
              bg: 'vmWhite',
            },
            '& .ba-videorecorder-container': {
              bgColor: '#fff',
              borderRadius: {
                base: 0,
                md: '10px',
              },
              h: 'full',
            },
            '& .ba-videorecorder-container > video': {
              borderRadius: {
                base: 0,
                md: '10px',
              },
            },
            '& .ba-videorecorder-chooser-container': {
              bg: 'vmBlack.150',
            },
            '& .ba-videorecorder-dashboard': {
              display: 'none',
            },
            '& .ba-videoplayer-playbutton-container': {
              display: 'none',
            },
            '& > .video-wrapper > div': {
              h: 'full',
              w: 'full',
              d: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '& > div': {
                h: 'full',
                maxH: {
                  base: '100%',
                  md: '600px',
                },
                '& > div[data-selector="recorder-player"]': {
                  h: 'full',
                },
              },
            },
            '& .ba-videorecorder-chooser-button-container': {
              display: 'none',
            },
            '& .ba-videoplayer-playbutton-button': {
              display: 'none',
            },
            '& .ba-videoplayer-rerecord-button': {
              display: 'none',
            },
            '& .ba-videorecorder-controlbar': {
              display: 'none',
            },
            '& .ba-videorecorder-backbar': {
              display: 'none',
            },
            '& > div': {
              maxH: {
                base: 'full',
                md: '600px',
              },
            },
            '.ba-videoplayer-video-container': {
              bg: 'vmWhite',
            },
            '& video': {
              left: {
                base: '50% !important',
                md: '0 !important',
              },
              pos: 'absolute',
              objectFit: {
                base: 'cover !important',
                md: 'contain !important',
              },
              transform: {
                base: 'translate(-50%, -50%)',
                md: 'none !important',
              },
              background: {
                base: 'transparent none repeat scroll 0% 0%',
                md: 'transparent !important',
              },
              bgColor: {
                base: 'transparent',
                md: 'transparent !important',
              },
              top: {
                base: '50% !important',
                md: '0 !important',
              },
            },
          }}
        >
          <VideoRecorder
            key={recorderKey}
            allowUpload={false}
            proceedInVerfiedProgress
            allowRecord
            forceOverwrite
            deleteOldStreams={!!previousVideoToken}
            skipinitial
            localplayback
            videoToken={previousVideoToken}
            onChange={(data) => {
              onChange({ target: { value: data.video } });
            }}
            setOuterRef={handleSaveRecorderInstance}
            onProgressAction={(action: ZiggeoProgressType) => {
              if (action === ZiggeoProgressType.UPLOADING) {
                setIsProcessingVideo(true);
              }
              if (action === ZiggeoProgressType.VERIFIED) {
                setIsProcessingVideo(false);
              }
            }}
            simulate={simulate}
          />
        </Flex>
        <Flex
          direction="column"
          gap="20px"
          pos="absolute"
          align="center"
          justify="center"
          w="full"
          h="full"
          zIndex={75}
        >
          {!isPlaying && (isProcessingVideo || isReadyToPlay) && (
            <Flex
              align="center"
              justify="center"
              w="44px"
              h="44px"
              bg="rgba(255, 255, 255, 0.25)"
              borderRadius="50%"
              cursor="pointer"
              onClick={() => {
                recorder.play();
              }}
              zIndex={90}
            >
              <PlayIcon fontSize="22px" />
            </Flex>
          )}
          {!isExist && children && (
            <Box>
              {children}
            </Box>
          )}
        </Flex>
        {!(isRecording || isProcessingVideo || value) && (
          <Flex align="center" justify="center" pos="absolute" zIndex={9999 + 1} top="30px" w="full" gap="20px" ref={containerRef}>
            <Flex align="center" justify="end" gap="10px" pos="relative">
              <Box
                w="265px"
                pos="absolute"
                top={{
                  base: '65px',
                  md: isMatch ? '65px' : 'auto',
                }}
                left={{
                  base: '50%',
                  md: isMatch ? '50%' : 'unset',
                }}
                right={{
                  base: 'auto',
                  md: isMatch ? 'auto' : '60px',
                }}
                transform={{
                  base: 'translateX(calc(-50% + 38px))',
                  md: isMatch ? 'translateX(calc(-50% + 38px))' : 'none',
                }}
              >
                {isSettingCamera && (
                  <Select
                    data={cameraDevices}
                    getKey={getKey}
                    getLabel={getLabel}
                    onChange={(event) => {
                      const videoElement: any = document.getElementsByClassName('ba-videorecorder-video')[0];
                      if (videoElement) {
                        const device = cameraDevices.find((item) => (
                          item.deviceId === event.target.value
                        ));
                        if (!device) return;
                        const source = event.target.value;
                        recorder.select_camera(source);
                        changeSelectedCamera(device);
                      }
                    }}
                    placeholder=""
                    value={selectedCamera?.deviceId}
                  />
                )}
              </Box>
              <Flex
                align="center"
                justify="center"
                w="48px"
                h="48px"
                pos="relative"
                bg={isSettingCamera ? 'vmWhite' : 'rgba(255, 255, 255, 0.25)'}
                borderRadius="8px"
                cursor="pointer"
                onClick={() => {
                  if (!isRecording) {
                    closeSettingMicro();
                    toggleSettingCamera();
                  }
                }}
              >
                <CameraSelectorIcon fill={isSettingCamera ? 'vmBlack.150' : 'vmWhite'} fontSize="22px" />
                {!isSettingCamera && (
                  <Box pos="absolute" top="-10px" right="-2px" zIndex={9999}>
                    <DeviceSettingIcon fontSize="10px" />
                  </Box>
                )}
              </Flex>
            </Flex>
            <Flex align="center" gap="10px" pos="relative">
              <Flex
                align="center"
                justify="center"
                w="48px"
                h="48px"
                pos="relative"
                bg={isSettingMicro ? 'vmWhite' : 'rgba(255, 255, 255, 0.25)'}
                borderRadius="8px"
                cursor="pointer"
                onClick={() => {
                  if (!isRecording) {
                    closeSettingCamera();
                    toggleSettingMicro();
                  }
                }}
              >
                <MicrophoneSelectorIcon fill={isSettingMicro ? 'vmBlack.150' : 'vmWhite'} fontSize="22px" />
                {!isSettingMicro && (
                  <Box pos="absolute" top="-10px" right="-2px" zIndex={9999}>
                    <DeviceSettingIcon fontSize="10px" />
                  </Box>
                )}
              </Flex>
              <Box
                w="265px"
                pos="absolute"
                top={{
                  base: '65px',
                  md: isMatch ? '65px' : 'auto',
                }}
                left={{
                  base: '50%',
                  md: isMatch ? '50%' : '60px',
                }}
                transform={{
                  base: 'translateX(calc(-50% - 30px))',
                  md: isMatch ? 'translateX(calc(-50% - 30px))' : 'none',
                }}
              >
                {isSettingMicro && (
                  <Select
                    data={audioDevices}
                    getKey={getKey}
                    getLabel={getLabel}
                    onChange={(event) => {
                      const videoElement: any = document.getElementsByClassName('ba-videorecorder-video')[0];
                      if (videoElement) {
                        const device = audioDevices.find((item) => (
                          item.deviceId === event.target.value
                        ));
                        if (!device) return;
                        const source = event.target.value;
                        recorder.select_microphone(source);
                        changeSelectedMicro(device);
                      }
                    }}
                    placeholder=""
                    value={selectedMicro?.deviceId}
                  />
                )}
              </Box>
            </Flex>
          </Flex>
        )}
        {(!(isRecording || isProcessingVideo)) && !isMatch && showArrowBack && (
          <Flex
            align="center"
            justify="center"
            direction="column"
            gap="20px"
            pos="absolute"
            zIndex={99999}
            bottom="50px"
            cursor="pointer"
            w="40px"
            h="40px"
            left="40px"
            borderRadius="50%"
            bg="vmWhite"
            onClick={() => onGoBack()}
          >
            <ArrowBackIcon fontSize="20px" />
          </Flex>
        )}
        {!isProcessingVideo && !value && (
          <Flex align="center" justify="center" direction="column" gap="20px" pos="absolute" zIndex={9999} bottom="50px" w="full">
            <Flex align="center" gap="5px" px="9px" py="4px" bg="vmWhite" borderRadius="8px">
              <Box w="8px" h="8px" bg="vmRed.50" borderRadius="50%" />
              <TrustRecorderVideoTime recorder={recorder} onStopRecorder={setCanStopRecorder} />
            </Flex>
            <Box
              w="40px"
              h="40px"
              bg={isEnableRecordButton ? 'vmRed.50' : '#9b1111'}
              opacity={isEnableRecordButton ? 1 : 0.8}
              borderRadius={isRecording ? '8px' : '50%'}
              borderWidth="2px"
              borderColor="vmWhite"
              cursor={isEnableRecordButton ? 'pointer' : 'not-allowed'}
              onClick={() => {
                if (isProcessingVideo) return;
                if (!isRecording) {
                  toggleRecording();
                  startingTimeRef.current = Date.now();
                  recorder.record();
                  onStartVideoProgress();
                } else {
                  const startTime = startingTimeRef.current;
                  const now = Date.now();
                  const COUNTDOWN = 3;
                  if ((now - startTime) > ((COUNTDOWN * 1000) + 5500)) {
                    toggleRecording();
                    recorder.stop();
                  }
                }
              }}
            />
          </Flex>
        )}
      </TrustRecorderContainer>
    </TrustRecorderWrapper>
  );
}

TrustRecorder.defaultProps = {
  onSetRecordRef: () => {},
  simulate: false,
  showArrowBack: true,
};

const TrustRecorderFowardRef = forwardRef(TrustRecorder as any);

export default TrustRecorderFowardRef as unknown as typeof TrustRecorder;
