import { Icon, IconProps } from '@chakra-ui/react';

function CloseIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 14 14" stroke="vmGray.600" strokeWidth="1" fill="none" {...props}>
      <path
        d="M13 1L1 13M1 1L13 13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default CloseIcon;
