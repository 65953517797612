import { memo } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import BuildingIcon from '@components/icons/plan/Building';

function ExtraPlan() {
  const { t } = useTranslation();
  return (
    <Box
      p="20px"
      borderWidth="1px"
      borderColor="vmGray.450"
      borderRadius="10px"
      pos="relative"
      overflow="hidden"
    >
      <Box
        textAlign="center"
        color="vmBlack.50"
        fontWeight="bold"
        fontSize="26px"
      >
        <Box>{t('plan.extra_plan.need_traffic')}</Box>
      </Box>
      <Box textAlign="center" fontSize="60px" minH="90px">
        <BuildingIcon />
      </Box>
      <Box mt="20px">
        <Box as="a" href="mailto:sales@usetrust.io">
          <Button type="button" w="full">{t('plan.extra_plan.contact_us')}</Button>
        </Box>
      </Box>
    </Box>
  );
}

export default memo(ExtraPlan);
