import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormErrorMessage } from '@chakra-ui/react';

interface FormErrorObject {
  key: string,
  params: {[key: string]: string | number};
}

interface Props {
  error?: string | FormErrorObject;
}

function ErrorMessage({ error }: Props) {
  const { t } = useTranslation();
  if (error === undefined) {
    return null;
  }

  if (typeof error === 'string') {
    return (
      <FormErrorMessage as="p">{t(error)}</FormErrorMessage>
    );
  }

  return (
    <FormErrorMessage as="p">{t(error.key, error.params)}</FormErrorMessage>
  );
}

ErrorMessage.defaultProps = {
  error: undefined,
};

export default memo(ErrorMessage);
