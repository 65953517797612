import { useCallback, useState } from 'react';

interface UseSelectedMediaFiles {
  selectedFiles: {[key: string]: boolean};
  handleFileSelected: (checkedState: {[key: string]: boolean}) => void;
  havingSelectedFile: boolean;
}

function useSelectedMediaFiles(
  allowMultiple: boolean,
  defaultSelectedIds: string[],
): UseSelectedMediaFiles {
  const [selectedFiles, setSelectedFiles] = useState<{[key: string]: boolean}>(
    defaultSelectedIds.reduce((hashMap, id) => ({
      ...hashMap,
      [id]: true,
    }), {}),
  );
  const havingSelectedFile = (
    Object.keys(selectedFiles).filter((id) => selectedFiles[id]).length > 0
  );

  const handleFileSelected = useCallback((checkedState: {[key: string]: boolean}) => {
    setSelectedFiles((prev) => {
      if (allowMultiple) {
        return {
          ...prev,
          ...checkedState,
        };
      }
      return {
        ...checkedState,
      };
    });
  }, [allowMultiple]);

  return {
    selectedFiles,
    handleFileSelected,
    havingSelectedFile,
  };
}

export default useSelectedMediaFiles;
