import { memo, useCallback } from 'react';
import { Box, Flex, Spinner } from '@chakra-ui/react';
import DownloadIcon from '@components/icons/ml/Download';
import { useTranslation } from 'react-i18next';

interface Props {
  isDownloading: boolean;
  onDownloadVideo: () => void;
  title?: string;
  isDisabled?: boolean;
}

function BottomBarDownloader({
  isDownloading,
  onDownloadVideo,
  title,
  isDisabled,
}: Props) {
  const { t } = useTranslation();

  const handleDownload = useCallback(() => {
    if (isDisabled) return;
    onDownloadVideo();
  }, [onDownloadVideo, isDisabled]);

  return (
    <Flex
      cursor="pointer"
      gap="10px"
      align="center"
      w="full"
      opacity={isDisabled ? 0.6 : 1}
    >
      {isDownloading ? (
        <Spinner />
      ) : (
        <DownloadIcon stroke="vmPrimary.50" />
      )}
      <Box
        as="span"
        fontSize="trNormal"
        fontWeight={600}
        lineHeight="24px"
        color="vmGray.400"
        onClick={handleDownload}
        textTransform="uppercase"
      >
        {t(title!)}
      </Box>
    </Flex>
  );
}

BottomBarDownloader.defaultProps = {
  title: 'ml.labels.download',
  isDisabled: false,
};

export default memo(BottomBarDownloader);
