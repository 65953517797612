import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';
import ArrowDropIcon from '@components/icons/ArrowDrop';
import ArrowRightIcon from '@components/icons/ArrowRight';
import SettingIcon from '@components/icons/Setting';
import TickIcon from '@components/icons/Tick';
import ROUTES from '@constants/routes';
import { useVmStore } from '@store/index';
import { customerEmailSelector, customerNameSelector } from '@store/slices/customer/selectors';
import { makeCustomerCharAvatar } from '@utils/string.util';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from 'react-router-dom';
import MenuItem from '../../styled/MenuItemStyled';

function SidebarAccountMenu() {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const brands = useVmStore((state) => state.brands);
  const selectedBrandId = useVmStore((state) => state.selectedBrandId);
  const changeSelectedBrand = useVmStore((state) => state.changeSelectedBrand);
  const location = useLocation();
  const customerName = useVmStore(customerNameSelector);
  const customerEmail = useVmStore(customerEmailSelector);
  const {
    isOpen,
    onClose,
    onOpen,
  } = useDisclosure();
  const {
    isOpen: isOpenBrandDropdown,
    onClose: closeBrandDropdown,
    onOpen: openBrandDropdown,
  } = useDisclosure();
  const brand = brands.find((brandItem) => brandItem.brandId === selectedBrandId);

  const handleLogout = useCallback(async () => {
    logout({ logoutParams: { returnTo: `${window.location.origin}${ROUTES.AUTH.LOGIN}` } });
  }, [logout]);

  useEffect(() => {
    onClose();
  }, [location, onClose]);

  return (
    <Popover placement="right-end" isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
      <PopoverTrigger>
        <Box
          as="span"
          py="8px"
          px="10.5px"
          fontSize="20px"
          fontWeight={400}
          lineHeight="30px"
          bg="vmGray.100"
          borderWidth="1px"
          borderColor="vmBlack.100"
          color="vmBlack.100"
          borderRadius="full"
          display="inline-block"
          cursor="pointer"
        >
          {makeCustomerCharAvatar(customerName ?? '', 2)}
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent ml="34px" _focusVisible={{ outline: 'none' }}>
          <PopoverBody>
            <Box w="360px" p="20px" bg="vmGray.150" borderRadius="8px">
              <Flex direction="column" gap="15px">
                <Link reloadDocument={false} to={ROUTES.ACCOUNT}>
                  <Box as="section" py="4px" px="8px" pos="relative" textAlign="left" bg="vmWhite" borderRadius="8px" pr="42px" cursor="pointer">
                    <Box as="h4" fontSize="17px" lineHeight="30px" fontWeight={400} color="vmRed.100">{customerName}</Box>
                    <Box as="h5" fontSize="13px" fontWeight={400} lineHeight="25.5px" color="vmGray.550">{customerEmail}</Box>
                    <Flex pos="absolute" right="16px" top={0} bottom={0} margin="auto" align="center">
                      <ArrowRightIcon />
                    </Flex>
                  </Box>
                </Link>
                <Box>
                  <Box fontSize="13px" fontWeight={400} lineHeight="19.5px" color="vmGray.550" textTransform="uppercase" mb="4px">Organization</Box>
                  <Flex px="8px" py="4px" align="center" justify="space-between" borderRadius="8px" bg="vmWhite">
                    <Box fontSize="17px" lineHeight="30px" fontWeight={400} color="vmBlack.100">{brand?.name}</Box>
                    <Flex align="center" gap="10px">
                      {brand && (
                        <Link to={`/brands/view/${brand.brandId}`}>
                          <Box role="link"><SettingIcon fontSize="18px" /></Box>
                        </Link>
                      )}
                      <Popover isLazy placement="bottom-start" isOpen={isOpenBrandDropdown} onClose={closeBrandDropdown} onOpen={openBrandDropdown}>
                        <PopoverTrigger>
                          <Box role="button"><ArrowDropIcon stroke="vmRed.100" strokeWidth="1.5" /></Box>
                        </PopoverTrigger>
                        <PopoverContent minW="302px" mt="-7px" ml="30px">
                          <PopoverBody borderRadius="8px">
                            <Box p="8px">
                              <Flex direction="column" gap="4px">
                                {brands.map((item) => (
                                  <Box role="button" key={item.brandId}>
                                    <Box
                                      py="4px"
                                      px="8px"
                                      pr="30px"
                                      borderRadius="8px"
                                      bg="vmGray.1020"
                                      color="vmBlack.100"
                                      pos="relative"
                                      onClick={() => {
                                        changeSelectedBrand(item.brandId);
                                        closeBrandDropdown();
                                      }}
                                    >
                                      {item.name}
                                      <Box pos="absolute" top="50%" transform="translateY(-50%)" right="7px"><TickIcon stroke="vmGreen.50" /></Box>
                                    </Box>
                                  </Box>
                                ))}
                                <Link to="/brands/add">
                                  <Box px="8px" py="4px" mt="2px" role="button" fontSize="17px" fontWeight={400} lineHeight="30px" color="vmRed.100">
                                    + New organization
                                  </Box>
                                </Link>
                              </Flex>
                            </Box>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </Flex>
                </Box>
                <Box w="full" h="1px" bg="vmGray.350" />
                <Flex direction="column" gap="10px" sx={{ '& .active > p': { color: 'vmPrimary.50' } }}>
                  <NavLink to={ROUTES.PLAN} className={({ isActive }) => (isActive ? 'active' : '')}>
                    <MenuItem as="p">{t('sidebar.menu.title.plan_and_billing')}</MenuItem>
                  </NavLink>
                  <NavLink to="/media-library" className={({ isActive }) => (isActive ? 'active' : '')}>
                    <MenuItem as="p">{t('sidebar.menu.title.media_library')}</MenuItem>
                  </NavLink>
                  <NavLink to="/settings" className={({ isActive }) => (isActive ? 'active' : '')}>
                    <MenuItem as="p">{t('sidebar.menu.title.settings')}</MenuItem>
                  </NavLink>
                </Flex>
                <Box w="full" h="1px" bg="vmGray.350" />
                <Flex align="center" gap="10px">
                  <Flex w="24px" h="24px" align="center" justify="center" bg="vmWhite" borderRadius="100%">
                    <ArrowRightIcon transform="rotate(180deg)" fontSize="12px" />
                  </Flex>
                  <MenuItem as="p" onClick={handleLogout}>{t('sidebar.menu.title.log_out')}</MenuItem>
                </Flex>
              </Flex>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

export default SidebarAccountMenu;
