export default {
  baseStyle: {
    field: {
      border: '2px solid #E4EEFA',
      h: 'full',
      w: 'full',
    },
  },
  variants: {
    default: {
      field: {
        transition: 'all 0s',
        color: '#282E3D',
        borderColor: '#E4EEFA',
        _placeholder: {
          color: '#B5C1CE',
          fontSize: 'ngNormal',
        },
        _hover: {
          borderColor: '#D8E2EE',
          '& ~ div:not(.chakra-form__error-message)': {
            boxShadow: 'inset 0px -2px 0px #D8E2EE, inset -2px 0px 0px #D8E2EE, inset 0px 2px 0px #D8E2EE',
          },
        },
        _focus: {
          borderColor: 'vmPrimary.50',
          color: '#282E3D',
          '& ~ div:not(.chakra-form__error-message)': {
            boxShadow: 'inset 0px -2px 0px #0070C9, inset -2px 0px 0px #0070C9, inset 0px 2px 0px #0070C9',
          },
        },
        _readOnly: {
          background: '#FBFCFE',
        },
      },
      addon: {
        py: '10px',
        px: '14px',
        fontSize: 'vmNormal',
        lineHeight: '24px',
        color: 'vmBlack.150',
        borderRadius: '0 10px 10px 0',
        boxShadow: 'inset 0px -2px 0px #E4EEFA, inset -2px 0px 0px #E4EEFA, inset 0px 2px 0px #E4EEFA',
        transition: 'all 0s',
      },
    },
  },
  sizes: {
    default: {
      field: {
        fontSize: '16px',
        lineHeight: '24px',
        padding: '8px 15px',
        h: 'auto',
        borderRadius: '10px',
      },
    },
  },
  defaultProps: {
    variant: 'default',
    size: 'default',
  },
};
