import { Box, Flex, Grid } from '@chakra-ui/react';
import VideoIcon from '@components/icons/question/Video';

interface Props {
  onSwitchToRecord: () => void;
}

function QuestionAskResponseType({ onSwitchToRecord }: Props) {
  return (
    <>
      <Box textAlign="center" fontSize="16px" lineHeight="24px" fontWeight={400} color="vmBlack.50">Reply by</Box>
      <Grid mt="15px" templateColumns="1fr 1fr" gridGap="10px" w="580px" maxW="full" px="42px" py="20px" bg="vmGray.150" borderRadius="10px">
        <Flex align="center" gap="10px" py="15px" px="20px" bg="vmWhite" borderBottomWidth="2px" borderColor="vmGray.200" onClick={onSwitchToRecord} cursor="pointer">
          <VideoIcon fontSize="22px" />
          <Box fontSize="16px" lineHeight="24px" fontWeight={600} color="vmGray.400">Record a video</Box>
        </Flex>
        <Flex align="center" gap="10px" py="15px" px="20px" bg="vmWhite" borderBottomWidth="2px" borderColor="vmGray.200">
          <VideoIcon fontSize="22px" />
          <Box fontSize="16px" lineHeight="24px" fontWeight={600} color="vmGray.400">Video + screensharing</Box>
        </Flex>
      </Grid>
    </>
  );
}

export default QuestionAskResponseType;
