import { getContact, updateContact } from '@apis/contact.api';
import {
  Box,
  Flex,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import NotFound from '@components/NotFound';
import ContactControl from '@modules/Contact/forms/ContactControl';
import { IVmContact, IVmContactUpdateModel } from '@typed/contact.types';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

function ContactUpdate() {
  const navigate = useNavigate();
  const { contactId } = useParams<'contactId'>();
  const [contact, setContact] = useState<IVmContact | null>(null);
  const {
    isOpen: isLoadingContact,
    onOpen: startLoadingContact,
    onClose: finishLoadingContact,
  } = useDisclosure({ defaultIsOpen: true });
  const {
    isOpen: isUpdatingContact,
    onOpen: startUpdatingContact,
    onClose: finishUpdatingContact,
  } = useDisclosure();

  const loadContact = useCallback(async () => {
    try {
      startLoadingContact();
      const result = await getContact(contactId!);
      setContact(result);
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishLoadingContact();
  }, [contactId, finishLoadingContact, startLoadingContact]);

  const onSubmit = useCallback(async (values: IVmContactUpdateModel) => {
    try {
      startUpdatingContact();
      await updateContact(contactId!, values);
      toast.success('The contact has been updated successfully');
      navigate('/contacts');
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishUpdatingContact();
  }, [contactId, finishUpdatingContact, navigate, startUpdatingContact]);

  useEffect(() => {
    loadContact();
  }, [loadContact]);

  if (isLoadingContact) {
    return (
      <Flex align="center" justify="center" w="full" minH="100vh">
        <Spinner />
      </Flex>
    );
  }

  if (!contact) {
    return (
      <NotFound />
    );
  }

  return (
    <Flex align="center" justify="center" minH="100vh">
      <Box w="580px" p="40px" maxW="full" mx="auto" borderWidth="1px" borderRadius="10px" borderColor="vmGray.150" bg="vmWhite" display="inline-block">
        <Box fontSize="20px" lineHeight="28px" fontWeight={600}>Update Contact</Box>
        <ContactControl
          defaultValues={contact}
          isLoading={isUpdatingContact}
          onSubmit={onSubmit}
        />
      </Box>
    </Flex>
  );
}

export default ContactUpdate;
