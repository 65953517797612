import { Icon, IconProps } from '@chakra-ui/react';

function DragIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 10 18" fill="vmGray.600" stroke="none" {...props}>
      <path d="M3 2C3 2.82843 2.32843 3.5 1.5 3.5C0.671573 3.5 0 2.82843 0 2C0 1.17157 0.671573 0.5 1.5 0.5C2.32843 0.5 3 1.17157 3 2Z" />
      <path d="M10 2C10 2.82843 9.32843 3.5 8.5 3.5C7.67157 3.5 7 2.82843 7 2C7 1.17157 7.67157 0.5 8.5 0.5C9.32843 0.5 10 1.17157 10 2Z" />
      <path d="M3 9C3 9.82843 2.32843 10.5 1.5 10.5C0.671573 10.5 0 9.82843 0 9C0 8.17157 0.671573 7.5 1.5 7.5C2.32843 7.5 3 8.17157 3 9Z" />
      <path d="M10 9C10 9.82843 9.32843 10.5 8.5 10.5C7.67157 10.5 7 9.82843 7 9C7 8.17157 7.67157 7.5 8.5 7.5C9.32843 7.5 10 8.17157 10 9Z" />
      <path d="M3 16C3 16.8284 2.32843 17.5 1.5 17.5C0.671573 17.5 0 16.8284 0 16C0 15.1716 0.671573 14.5 1.5 14.5C2.32843 14.5 3 15.1716 3 16Z" />
      <path d="M10 16C10 16.8284 9.32843 17.5 8.5 17.5C7.67157 17.5 7 16.8284 7 16C7 15.1716 7.67157 14.5 8.5 14.5C9.32843 14.5 10 15.1716 10 16Z" />
    </Icon>
  );
}

export default DragIcon;
