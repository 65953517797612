import * as yup from 'yup';
import { IFormValue } from '../types/form-value';

interface FormContext {
  schema: any;
  value: IFormValue;
}

interface RootFormContext {
  from: FormContext[];
}

type ValidationContext = yup.TestContext & RootFormContext & FormContext;

function getVmFormSchema() {
  return yup.object({
    intro: yup.object({
      title: yup.string().trim().required('common.validation.required'),
      text: yup.string().trim().required('common.validation.required'),
      enableVideoQuestion: yup.boolean().test({
        name: 'videoAndTextOptionRequiredAtLeast',
        message: 'common.validation.must_be_choose_at_least',
        test() {
          const { from } = this as ValidationContext;
          const formSettings = from[from.length - 1].value as IFormValue;
          if (!formSettings.intro.enableTextQuestion && !formSettings.intro.enableVideoQuestion) {
            return false;
          }
          return true;
        },
      }),
      videoMessageText: yup.string().nullable().when('showVideoMessage', ([showVideoMessage], schema) => (
        showVideoMessage ? yup.string().nullable().trim().required('common.validation.required') : schema
      )),
      videoMessageToken: yup.string().nullable().when('showVideoMessage', ([showVideoMessage], schema) => (
        showVideoMessage ? yup.string().nullable().trim().required('common.validation.required') : schema
      )),
    }),
    question: yup.object({
      titleVideoQuestion: yup.string().trim().required('common.validation.required'),
      textVideoQuestion: yup.string().trim().required('common.validation.required'),
      titleTextQuestion: yup.string().trim().required('common.validation.required'),
      textTextQuestion: yup.string().trim().required('common.validation.required'),
    }),
    advanced: yup.object({
      validationMessages: yup.object({
        requiredMessage: yup.string().trim().required('common.validation.required'),
        emailMessage: yup.string().trim().required('common.validation.required'),
      }),
    }),
    personalData: yup.object({
      title: yup.string().trim().required('common.validation.required'),
      placeholder: yup.string().trim().required('common.validation.required'),
      email: yup.string().trim().required('common.validation.required'),
      emailPlaceholder: yup.string().trim().required('common.validation.required'),
      consentText: yup.string().nullable().when('requireConsent', ([isRequireConsent], schema) => (
        isRequireConsent ? yup.string().nullable().trim().required('common.validation.required') : schema
      )),
    }),
    thankYou: yup.object({
      title: yup.string().nullable().when('redirect', ([isRedirect], schema) => (
        !isRedirect ? yup.string().nullable().trim().required('common.validation.required') : schema
      )),
      text: yup.string().nullable().when('redirect', ([isRedirect], schema) => (
        !isRedirect ? yup.string().nullable().trim().required('common.validation.required') : schema
      )),
      redirectUrl: yup.string().nullable().when('redirect', ([isRedirect], schema) => (
        isRedirect ? yup.string().nullable().trim().required('common.validation.required') : schema
      )),
    }),
  });
}

export default getVmFormSchema;
