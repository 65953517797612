import ROUTES from '@constants/routes';
import { Box, Flex } from '@chakra-ui/react';
import AuthDescription from '@modules/Auth/components/AuthDescription';
import LoginHeader from '@modules/Auth/components/LoginHeader';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SignUpDescription from '@modules/Auth/components/SignUpDescription';
import SignUpForm from '@modules/Auth/forms/SignUpForm';
import { EXTERNAL_LINKS } from '@constants/external-links';

function SignUp() {
  const { t } = useTranslation();
  return (
    <Flex
      minH="100vh"
      w="full"
      py="100px"
      align="center"
      justify="center"
      gap="145px"
      bg="vmGray.700"
      px="40px"
      direction={{
        base: 'column-reverse',
        mxl: 'row',
      }}
    >
      <Box w="615px" maxW="full"><AuthDescription /></Box>
      <Box>
        <Box
          bg="vmWhite"
          borderRadius="10px"
          py="40px"
          px="80px"
          boxShadow="0px 2px 50px rgba(0, 112, 201, 0.08);"
          w={{
            base: '615px',
            wxga: '560px',
          }}
          maxW="full"
        >
          <LoginHeader />
          <SignUpDescription />
          <SignUpForm />
          <Flex
            color="vmBlack.50"
            fontSize="16px"
            lineHeight="24px"
            fontWeight={400}
            textAlign="center"
            align="center"
            justify="center"
            gap="6px"
            mt="20px"
          >
            {t('sign_up.already_using')}
            <Link to={ROUTES.AUTH.LOGIN}>
              <Box>{t('sign_up.login')}</Box>
            </Link>
          </Flex>
        </Box>
        <Flex
          mt="40px"
          align="center"
          justify="center"
          w={{
            base: '615px',
            mxl: '560px',
          }}
          maxW="full"
          fontWeight={400}
          lineHeight="22.5px"
          color="vmGray.50"
          fontSize="15px"
          sx={{
            '& a': {
              color: 'vmGray.50',
              mx: '2px',
              textDecoration: 'underline',
            },
          }}
          dangerouslySetInnerHTML={{ __html: t('sign_up.term_service', { termServiceLink: EXTERNAL_LINKS.TERMS_AND_CONDITIONS, privacyLink: EXTERNAL_LINKS.EXTERNAL_PRIVACY_POLICY }) }}
        />
      </Box>
    </Flex>
  );
}

export default SignUp;
