import { memo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import VMVideoPlayer from '@components/VMVideoPlayer';
import useRecorderRef from '@components/VmVideoRecorder/hooks/useRecorderRef';
import useRecorderPlayingState from '@hooks/useRecorderPlayingState';
import PlayIcon from '@components/TrustRecorder/icons/Play';

interface Props {
  videoToken: string;
}

function QuestionVideoView({
  videoToken,
}: Props) {
  const { recorder, setRecorderRef } = useRecorderRef();
  const { isPlaying, isReadyToPlay } = useRecorderPlayingState(recorder);
  return (
    <Box maxW="full" w="498px">
      <Box
        sx={{
          display: videoToken ? 'block' : 'none',
          '& .ba-videorecorder-container': {
            borderRadius: '10px',
          },
          '& .ba-videoplayer-container': {
            borderRadius: '10px',
          },
          '& .css-19w1vec .ba-videorecorder-chooser-button-container': {
            w: '90%',
          },
          '& .ba-videorecorder-chooser-container': {
            bg: '#15334B',
          },
          '& .ba-videoplayer-rerecord-button-container': {
            display: 'none',
          },
          '& .ba-player-rerecord-frontbar': {
            display: 'none',
          },
          '& .ba-videoplayer-rerecord-bar': {
            display: 'none',
          },
          '& .ba-videoplayer-playbutton-container': {
            display: 'none',
          },
          '& .ba-player-dashboard': {
            display: 'none',
          },
        }}
      >
        <Box>
          <Box pos="relative" maxW="498px">
            <VMVideoPlayer
              source={null}
              videoToken={videoToken}
              setOuterRef={setRecorderRef}
            />
            {!isPlaying && isReadyToPlay && (
              <Flex
                align="center"
                justify="center"
                className="player-f"
                w="44px"
                h="44px"
                bg="rgba(255, 255, 255, 0.25)"
                borderRadius="50%"
                cursor="pointer"
                mb={0}
                onClick={() => {
                  recorder.play();
                }}
                pos="absolute"
                bottom={0}
                top={0}
                left={0}
                right={0}
                m="auto"
                zIndex={90}
              >
                <PlayIcon fontSize="22px" />
              </Flex>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default memo(QuestionVideoView);
