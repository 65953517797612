import { cloneElement, useCallback } from 'react';
import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import TickIcon from '@components/icons/Tick';
import WarningIcon from '@components/icons/alerts/Warning';
import { useTranslation } from 'react-i18next';

interface ConfirmPopupContent {
  children: JSX.Element;
  description?: string;
}

interface ConfirmPopupOpenState {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}

interface Props extends ConfirmPopupContent, ConfirmPopupOpenState {
  active: boolean;
  isLoading: boolean;
  onProceed: () => void;
}

function ConfirmPopup({
  active,
  children,
  isLoading,
  isOpen,
  onClose,
  onOpen,
  onProceed,
  description,
}: Props) {
  const { t } = useTranslation();

  const handleOpen = useCallback((event: Event) => {
    event.stopPropagation();
    onOpen();
  }, [onOpen]);

  if (!active) {
    return cloneElement(children, { onClick: handleOpen });
  }

  return (
    <Popover trigger="click" placement="top" isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        {cloneElement(children, { onClick: handleOpen })}
      </PopoverTrigger>
      <Portal>
        <PopoverContent zIndex={9999} _focus={{ boxShadow: 'none' }} _focusVisible={{ outline: 'none' }} minW="350px" color="vmWhite">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Box fontSize="22px" textAlign="center">
              <WarningIcon />
            </Box>
            <Box textAlign="center" pt="10px">
              <Box color="vmBlack.50" whiteSpace="initial">{description ?? t('common.texts.delete_confirm')}</Box>
            </Box>
          </PopoverBody>
          <PopoverFooter display="flex" justifyContent="center">
            <Button
              variant="iconOnly"
              size="iconOnly"
              type="button"
              isLoading={isLoading}
              isDisabled={isLoading}
              onClick={onProceed}
              bg="vmGray.200"
            >
              <TickIcon stroke="vmGray.600" />
            </Button>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

ConfirmPopup.defaultProps = {
  description: null,
};

export default ConfirmPopup;
