import {
  FC,
  memo,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Grid } from '@chakra-ui/react';
import {
  AutoSizer as _AutoSizer,
  AutoSizerProps,
  Grid as _Grid,
  GridProps,
  GridCellProps,
  CellMeasurerCache,
  CellMeasurer as _CellMeasurer,
  CellMeasurerProps,
} from 'react-virtualized';
import VideoLibraryLoadingItem from '@modules/VideoLibrary/components/VideoLibraryLoadingItem';
import VideoLibraryItem from '@modules/VideoLibrary/components/VideoLibraryItem';
import { IVideoLibraryItem } from '@modules/VideoLibrary/types/video-library.types';

const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;
const GridList = _Grid as unknown as FC<GridProps>;
const CellMeasurer = _CellMeasurer as unknown as FC<CellMeasurerProps>;

const cache = new CellMeasurerCache({
  minWidth: 75,
  fixedHeight: true,
  fixedWidth: true,
});

interface Props {
  items: IVideoLibraryItem[];
  isLoading: boolean;
  itemPerRow: number;
}

function VideoLibraryList({
  items,
  isLoading,
  itemPerRow,
}: Props) {
  const { t } = useTranslation();

  const renderCell = useCallback(({
    columnIndex,
    rowIndex,
    key,
    style,
    parent,
  }: GridCellProps) => {
    const video = items[rowIndex * itemPerRow + columnIndex];
    if (!video) return null;
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        {({ registerChild, measure }) => (
          <Box ref={registerChild as any} style={style} key={key} px="10px">
            <VideoLibraryItem
              key={video.id}
              video={video}
              measure={measure}
            />
          </Box>
        )}
      </CellMeasurer>
    );
  }, [itemPerRow, items]);

  if (isLoading) {
    return (
      <Grid gap="20px" gridTemplateColumns="auto auto auto auto">
        <VideoLibraryLoadingItem />
        <VideoLibraryLoadingItem />
        <VideoLibraryLoadingItem />
        <VideoLibraryLoadingItem />
        <VideoLibraryLoadingItem />
        <VideoLibraryLoadingItem />
        <VideoLibraryLoadingItem />
        <VideoLibraryLoadingItem />
      </Grid>
    );
  }

  if (items.length === 0) {
    return (
      <Flex p="20px" align="center" justify="center">
        {t('ml.message.no_data')}
      </Flex>
    );
  }

  return (
    <AutoSizer>
      {({ width, height }) => (
        <GridList
          cellRenderer={renderCell}
          columnWidth={width / itemPerRow}
          columnCount={itemPerRow}
          height={height}
            // noContentRenderer={this._noContentRenderer}
          overscanColumnCount={0}
          overscanRowCount={6}
          rowHeight={212}
          rowCount={Math.ceil(items.length / itemPerRow)}
          width={width}
          deferredMeasurementCache={cache}
        />
      )}
    </AutoSizer>
  );
}

export default memo(VideoLibraryList);
