import { Flex, Spinner } from '@chakra-ui/react';
import usePaddle from '@hooks/usePaddle';
import PlanContainer from './PlanContainer';

function Plan() {
  const { isReady } = usePaddle();

  if (!isReady) {
    return (
      <Flex align="center" justify="center" w="full" h="full" minH="100vh">
        <Spinner />
      </Flex>
    );
  }

  return <PlanContainer />;
}

export default Plan;
