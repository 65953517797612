import { Icon, IconProps } from '@chakra-ui/react';

function ErrorIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" stroke="none" fill="vmRed.50" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.67 -0.000793457H14.34C17.73 -0.000793457 20 2.37921 20 5.91921V14.0902C20 17.6202 17.73 19.9992 14.34 19.9992H5.67C2.28 19.9992 0 17.6202 0 14.0902V5.91921C0 2.37921 2.28 -0.000793457 5.67 -0.000793457ZM13.01 12.9992C13.35 12.6602 13.35 12.1102 13.01 11.7702L11.23 9.99021L13.01 8.20921C13.35 7.87021 13.35 7.31021 13.01 6.97021C12.67 6.62921 12.12 6.62921 11.77 6.97021L10 8.74921L8.22 6.97021C7.87 6.62921 7.32 6.62921 6.98 6.97021C6.64 7.31021 6.64 7.87021 6.98 8.20921L8.76 9.99021L6.98 11.7602C6.64 12.1102 6.64 12.6602 6.98 12.9992C7.15 13.1692 7.38 13.2602 7.6 13.2602C7.83 13.2602 8.05 13.1692 8.22 12.9992L10 11.2302L11.78 12.9992C11.95 13.1802 12.17 13.2602 12.39 13.2602C12.62 13.2602 12.84 13.1692 13.01 12.9992Z"
      />
    </Icon>
  );
}

export default ErrorIcon;
