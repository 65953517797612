import { Menu } from '../types/menu.types';

const menus: Menu[] = [
  {
    title: 'Forms',
    route: '/forms',
    key: 'menu.forms',
  },
  {
    title: 'Questions',
    route: '/questions',
    key: 'menu.questions',
  },
  {
    title: 'Contacts',
    route: '/contacts',
    key: 'menu.contacts',
  },
  {
    title: 'Video Library',
    route: '/video-library',
    key: 'menu.video-library',
  },
];

export default menus;
