import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDisclosure } from '@chakra-ui/react';
import { getPublicAnswer } from '@apis/pubic-form';
import { IVmAnswer } from '@typed/answer.types';

interface UsePublicAnswer {
  isLoadingAnswer: boolean
  answer: IVmAnswer | null;
}

function usePublicAnswer(answerId: string): UsePublicAnswer {
  const [answer, setAnswer] = useState<IVmAnswer | null>(null);
  const {
    isOpen: isLoadingAnswer,
    onOpen: startLoadingAnswer,
    onClose: finishLoadingAnswer,
  } = useDisclosure({ defaultIsOpen: true });

  const getAnswer = useCallback(async () => {
    try {
      startLoadingAnswer();
      const result = await getPublicAnswer(answerId);
      setAnswer(result);
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishLoadingAnswer();
  }, [answerId, finishLoadingAnswer, startLoadingAnswer]);

  useEffect(() => {
    getAnswer();
  }, [getAnswer]);

  return {
    isLoadingAnswer,
    answer,
  };
}

export default usePublicAnswer;
