import {
  // Box,
  Button,
  chakra,
  Flex,
  Grid,
  // Spinner,
  // useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getVideoStatus } from '@modules/VideoEditor/utils/rendered-video.util';
import useRenderedVideos from '@modules/VideoEditor/hooks/useRenderedVideos';
import ErrorNotify from '@components/ErrorNotify';
import { emptyFunction } from '@utils/func.util';
import BackIcon from '@components/icons/Back';
import LabelHeader from '@components/LabelHeader';
import { VideoIcon } from '@components/icons/ve';
import BottomBarDeleteSelected from '@shared/MediaLibrary/components/BottomBarDeleteSelected';
// import CloseIcon from '@components/icons/Close';
// import PublishIcon from '@components/icons/ve/Publish';
import VideoRenderedItem from './VideoRenderedItem';
import VideoRenderedStatus from './VideoRenderedStatus';

const VIDEO_INDEXES = [0];

const RenderedVideoStatusWrapper = chakra(Flex, {
  baseStyle: {
    alignItems: 'center',
    justifyContent: 'space-between',
    p: '20px',
    bg: 'vmWhite',
    borderRadius: '10px',
  },
});

interface Props {
  defaultVideoToken: string | null;
  answerId: string;
  havingSubtitleVideo: boolean;
}

function VideoRendered({
  defaultVideoToken,
  answerId,
  havingSubtitleVideo,
}: Props) {
  console.log({ havingSubtitleVideo });
  const { t } = useTranslation();
  // const { notify } = useToastMessage();
  const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null);
  const [testimonialVideoToken] = useState<string | null>(defaultVideoToken);
  // const {
  //   isOpen: isUpdatingTestimonialVideo,
  //   onOpen: startUpdatingTestimonialVideo,
  //   onClose: finishUpdatingTestimonialVideo,
  // } = useDisclosure();
  // const {
  //   isOpen: isShowingVideoWithSubtitleExist,
  //   onOpen: showVideoWithSubtitleExist,
  //   onClose: closeVideoWithSubtitleExist,
  // } = useDisclosure();
  const {
    isDeletingRenderedVideo,
    isLoadingRenderedVideo,
    renderedVideos,
    updatedStatusVideos,
    handleDeleteVideoOk,
    handleDeleteRenderedVideo,
    handleReProcessOk,
    handleRemoveStatusOfOutQueue,
  } = useRenderedVideos(answerId, false);

  const handleChooseVideo = useCallback((videoId: string, isChecking: boolean) => {
    setSelectedVideoId(() => (isChecking ? null : videoId));
  }, []);

  const handleDeleteVideo = useCallback(() => {
    handleDeleteRenderedVideo(selectedVideoId ?? '');
  }, [handleDeleteRenderedVideo, selectedVideoId]);

  // const handleMaskAsTestimonialVideo = useCallback(async () => {
  //   const selectedVideo = renderedVideos.find((item) => item.id === selectedVideoId);
  //   if (!selectedVideo || !selectedVideo.videoToken) return;
  //   try {
  //     startUpdatingTestimonialVideo();
  //     await setRenderedVideoAsTestimonialVideo(answerId, selectedVideo.id);
  //     setTVideoToken(selectedVideo.videoToken);
  //     notify(
  //       'success',
  //       t(
  //         'video_editor.messages.set_video_as_testimonial_video_ok',
  //         { videoName: selectedVideo.name },
  //       ),
  //     );
  //   } catch (error: any) {
  //     toast.error(error?.message);
  //   }
  //   finishUpdatingTestimonialVideo();
  // }, [
  //   renderedVideos,
  //   finishUpdatingTestimonialVideo,
  //   selectedVideoId,
  //   startUpdatingTestimonialVideo,
  //   answerId,
  //   notify,
  //   t,
  // ]);

  // const handleRemoveAsTestimonialVideo = useCallback(async () => {
  //   try {
  //     startUpdatingTestimonialVideo();
  //     await removeRenderedVideoAsTestimonialVideo(answerId);
  //     setTVideoToken(null);
  //     notify('success', t('video_editor.messages.remove_video_as_testimonial_video_ok'));
  //   } catch (error: any) {
  //     toast.error(error?.message);
  //   }
  //   finishUpdatingTestimonialVideo();
  // }, [
  //   finishUpdatingTestimonialVideo,
  //   startUpdatingTestimonialVideo,
  //   answerId,
  //   notify,
  //   t,
  // ]);

  const selectRenderedVideo = useMemo(() => {
    if (selectedVideoId === null) return null;
    return renderedVideos.find((item) => item.id === selectedVideoId) ?? null;
  }, [renderedVideos, selectedVideoId]);

  const isAllSet = renderedVideos.every((item) => item.messageClosed);
  // const canSetAsTestimonial = renderedVideos.some((item) => (
  //   selectedVideoId !== null && item.id === selectedVideoId && item.shotstackDone
  // ));
  const isSelectingVideoSameWithTVideo = Boolean(
    testimonialVideoToken !== null
    && selectRenderedVideo
    && selectRenderedVideo.videoToken === testimonialVideoToken,
  );

  return (
    <Grid templateRows="76px fit-content(100%) auto" gridGap="20px" px="20px" pb="20px" borderRadius="10px" minH="100vh">
      <Flex align="center" gap="20px">
        <Link to={`/video-editor/${answerId}/f/scenes`}>
          <Button variant="back" size="iconOnly" type="button">
            <BackIcon fontSize="17px" stroke="vmPrimary.50" />
          </Button>
        </Link>
        <LabelHeader pl={0} pr={0}>{t('video_editor.labels.rendered_videos')}</LabelHeader>
        <Flex align="center" gap="12px">
          <VideoIcon fontSize="20px" />
        </Flex>
      </Flex>
      {(isAllSet || updatedStatusVideos.length === 0) ? (
        <RenderedVideoStatusWrapper>
          <VideoRenderedStatus rvId="" name="" status="none" createdDate="" errorMessage={null} onDismissOk={emptyFunction} />
        </RenderedVideoStatusWrapper>
      ) : (
        <Flex direction="column" gap="20px">
          {updatedStatusVideos.map((item) => (
            <RenderedVideoStatusWrapper key={`video-rendered-status-${item.id}`}>
              <VideoRenderedStatus
                name={item.name}
                status={getVideoStatus(item)}
                showDate
                showCloseButton
                rvId={item.id}
                onDismissOk={handleRemoveStatusOfOutQueue}
                createdDate={item.shotstackInit?.toString() ?? ''}
                errorMessage={item.shotstackError}
              />
            </RenderedVideoStatusWrapper>
          ))}
        </Flex>
      )}
      <Grid templateRows="auto 54px">
        <Grid py="1.5px" gridTemplateColumns="minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr)" templateRows="305px" gridGap="20px" bg="vmWhite" p="20px" borderTopLeftRadius="10px" borderTopRightRadius="10px">
          {VIDEO_INDEXES.map((index) => (
            <VideoRenderedItem
              video={renderedVideos[index] ?? null}
              key={renderedVideos[index]?.id ?? index}
              isChecked={(
                selectedVideoId !== null && selectedVideoId === renderedVideos[index]?.id
              )}
              isTestimonialVideo={(
                testimonialVideoToken !== null
                && testimonialVideoToken === renderedVideos[index]?.videoToken
              )}
              isLoading={isLoadingRenderedVideo}
              onChooseVideo={handleChooseVideo}
              onReProcess={handleReProcessOk}
              onDelete={handleDeleteVideoOk}
            />
          ))}
        </Grid>
        <Flex align="center" justify="center" mt="2px" gap="20px" bg="vmWhite" p="20px" pos="relative" borderBottomLeftRadius="10px" borderBottomRightRadius="10px">
          <Flex align="center" gap="41px">
            <BottomBarDeleteSelected
              isDeleting={isDeletingRenderedVideo}
              isDisabled={(
                isDeletingRenderedVideo
                || !selectedVideoId
                || isSelectingVideoSameWithTVideo)}
              onDeleteVideo={handleDeleteVideo}
              buttonText={t('ml.labels.delete_video')}
              confirmMessage={t('ml.message.delete_confirm')}
            />
            {/* <Box>
              <Box w="2px" h="full" bg="#F3F9FF" pos="absolute" top={0} />
            </Box>
            <Flex
              align="center"
              cursor="pointer"
              gap="10px"
              opacity={
                (isDeletingRenderedVideo || !canSetAsTestimonial || isUpdatingTestimonialVideo)
                  ? 0.6 : 1
              }
              onClick={() => {
                if (!selectRenderedVideo) return;
                if (havingSubtitleVideo) {
                  showVideoWithSubtitleExist();
                  return;
                }
                if (selectRenderedVideo.videoToken !== testimonialVideoToken) {
                  handleMaskAsTestimonialVideo();
                }
                if (selectRenderedVideo.videoToken === testimonialVideoToken) {
                  handleRemoveAsTestimonialVideo();
                }
              }}
            >
              {isUpdatingTestimonialVideo && (
                <Spinner w="15px" h="15px" />
              )}
              {!isUpdatingTestimonialVideo && (
                selectRenderedVideo
                && selectRenderedVideo.videoToken !== null
                && selectRenderedVideo.videoToken === testimonialVideoToken
                  ? (
                    <CloseIcon fontSize="15px" stroke="vmRed.50" />
                  ) : (
                    <PublishIcon stroke="vmPrimary.50" fontSize="17px" />
                  )
              )}
              <Box
                as="span"
                fontSize="trNormal"
                fontWeight={600}
                lineHeight="24px"
                color="vmBlack.100"
                textTransform="uppercase"
                whiteSpace="nowrap"
              >
                {selectRenderedVideo
                  && selectRenderedVideo.videoToken !== null
                  && selectRenderedVideo.videoToken === testimonialVideoToken
                  ? t('video_editor.labels.remove_from_video_testimonial')
                  : t('video_editor.labels.set_as_testimonial_video')}
              </Box>
            </Flex> */}
          </Flex>
        </Flex>
      </Grid>
      <ErrorNotify
        isLoading={false}
        title={t('testimonial.interactive_form.labels.remove_video_with_subtitle_first')}
        message={t('testimonial.interactive_form.message.remove_video_with_subtitle_first')}
        isOpen={false} // isShowingVideoWithSubtitleExist
        onClose={emptyFunction} // closeVideoWithSubtitleExist
      />
    </Grid>
  );
}

export default VideoRendered;
