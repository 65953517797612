import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function useHistoryRoute(defaultRoute: string) {
  const location = useLocation();
  const [backRoute, setBackRoute] = useState<string | null>(null);
  useEffect(() => {
    setBackRoute((location.state as any)?.backRoute ?? null);
  }, [location]);

  return {
    backRoute: backRoute ?? defaultRoute,
  };
}

export default useHistoryRoute;
