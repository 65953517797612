import { useEffect, useState } from 'react';

function useRecorderPlayingState(recorder: any, previousToken: string | null = null) {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isReadyToPlay, setIsReadyToPlay] = useState<boolean>(false);

  useEffect(() => {
    if (recorder) {
      recorder.on('playing', () => {
        setIsPlaying(true);
      });
      recorder.on('ended', () => {
        setIsPlaying(false);
      });
      recorder.on('paused', () => {
        setIsPlaying(false);
      });
      recorder.on('ready_to_play', () => {
        setIsReadyToPlay(true);
      });
    }
  }, [recorder]);

  useEffect(() => {
    setIsPlaying(false);
    setIsReadyToPlay(false);
  }, [previousToken]);

  return {
    isPlaying,
    isReadyToPlay,
  };
}

export default useRecorderPlayingState;
