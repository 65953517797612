import axios, { AxiosError } from 'axios';
import { getItem } from '@utils/localstorage.util';
import LOCAL_STORAGE_KEY from '@constants/localstorage';
import { getApiRootUrl, getApiVersion } from '@root/config';

// eslint-disable-next-line import/prefer-default-export
export const initAxios = () => {
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.baseURL = `${getApiRootUrl()}/${getApiVersion()}`;

  axios.interceptors.request.use(
    (config) => {
      const tokenObject = getItem(LOCAL_STORAGE_KEY.AUTH0_KEY);
      if (tokenObject) {
        const auth = JSON.parse(tokenObject);
        if (config.headers && auth.body.access_token) {
          config.headers.Authorization = `Bearer ${auth.body.access_token}`;
        }
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  axios.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error?.response?.request) {
        if (error.response.status === 401) {
          // removeItem(LOCAL_STORAGE_KEY.AUTH0_KEY);
          // window.location.href = '/login';
        }
      }
      if (axios.isCancel(error)) {
        throw new axios.Cancel('Operation canceled by the user');
      }
      const errorResponse = (error as AxiosError<any>)?.response;
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        message: errorResponse?.data?.error ?? '',
        code: errorResponse?.status,
      });
    },
  );
};
