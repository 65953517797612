import { Icon, IconProps } from '@chakra-ui/react';

function MicrophoneSelectorIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 24" fill="vmWhite" stroke="none" {...props}>
      <path
        d="M8.0007 17.1429C9.28085 17.1429 10.5086 16.601 11.4138 15.6365C12.319 14.6721 12.8275 13.364 12.8275 12V5.14286C12.8275 3.77889 12.319 2.47078 11.4138 1.50631C10.5086 0.541835 9.28085 0 8.0007 0C6.72055 0 5.49283 0.541835 4.58763 1.50631C3.68242 2.47078 3.17388 3.77889 3.17388 5.14286V12C3.17388 13.364 3.68242 14.6721 4.58763 15.6365C5.49283 16.601 6.72055 17.1429 8.0007 17.1429Z"
      />
      <path
        d="M15.2804 12.0107C15.07 11.9866 14.8591 12.0517 14.6935 12.1921C14.5278 12.3325 14.4208 12.5368 14.3955 12.7607C14.2189 14.4363 13.469 15.9837 12.2893 17.1075C11.1095 18.2312 9.58247 18.8524 8 18.8524C6.41753 18.8524 4.89053 18.2312 3.71075 17.1075C2.53097 15.9837 1.78112 14.4363 1.60447 12.7607C1.57923 12.5368 1.47219 12.3325 1.30654 12.1921C1.14089 12.0517 0.929994 11.9866 0.719552 12.0107C0.614076 12.0214 0.511664 12.0544 0.41834 12.1078C0.325017 12.1612 0.242669 12.234 0.176142 12.3219C0.109616 12.4097 0.0602546 12.5109 0.0309637 12.6194C0.0016729 12.7279 -0.00695589 12.8415 0.00558548 12.9536C0.21133 14.8983 1.0378 16.7088 2.34665 18.0819C3.6555 19.455 5.36731 20.3074 7.19553 20.4964V23.1429C7.19553 23.3702 7.28029 23.5882 7.43115 23.7489C7.58202 23.9097 7.78664 24 8 24C8.21336 24 8.41798 23.9097 8.56885 23.7489C8.71971 23.5882 8.80447 23.3702 8.80447 23.1429V20.4964C10.6327 20.3074 12.3445 19.455 13.6534 18.0819C14.9622 16.7088 15.7887 14.8983 15.9944 12.9536C16.007 12.8415 15.9983 12.7279 15.969 12.6194C15.9397 12.5109 15.8904 12.4097 15.8239 12.3219C15.7573 12.234 15.675 12.1612 15.5817 12.1078C15.4883 12.0544 15.3859 12.0214 15.2804 12.0107Z"
      />
    </Icon>
  );
}

export default MicrophoneSelectorIcon;
