import { FormProvider, useForm } from 'react-hook-form';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import VideoScenes from '@modules/VideoEditor/containers/VideoScenes/VideoScenes';
import {
  VideoEditor,
  VideoEditorIntroOutro,
  VideoEditorScene,
} from '@modules/VideoEditor/types/video-editor.types';
import VideoEditorAddScene from '@modules/VideoEditor/containers/VideoEditorAddScene/VideoEditorAddScene';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { generateGuid } from '@utils/guid.util';
import NotFound from '@components/NotFound';
import { getVideoDurations } from '@apis/rendered-videos.api';
import useEffectOnlyInitial from '@hooks/useEffectOnlyInitial';
import VideoEditorEditImageScene from './containers/VideoEditorEditImageScene/VideoEditorEditImageScene';
import VideoEditorHeader from './components/VideoEditorHeader/VideoEditorHeader';

interface Props {
  customerId: string;
  brandId: string;
  videoEditorConfig: VideoEditor;
  answerId: string | null;
  questionId: string;
}

interface VideoEditorSceneFormModel {
  intro: VideoEditorIntroOutro;
  outro: VideoEditorIntroOutro;
  scenes: VideoEditorScene[];
  answerId: string | null;
}

function VideoEditorContainer({
  customerId,
  videoEditorConfig,
  brandId,
  answerId,
  questionId,
}: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const methods = useForm<VideoEditorSceneFormModel>({
    defaultValues: {
      intro: {
        ...videoEditorConfig.intro,
        id: generateGuid(),
      },
      outro: {
        ...videoEditorConfig.outro,
        id: generateGuid(),
      },
      scenes: videoEditorConfig.scenes,
      answerId,
    },
  });
  const intro = methods.watch('intro');
  const outro = methods.watch('outro');

  const handleAddIntro = useCallback((introData: VideoEditorIntroOutro) => {
    methods.setValue('intro', introData, { shouldDirty: true });
    navigate('./scenes/intro');
  }, [methods, navigate]);

  const handleAddOutro = useCallback((outroData: VideoEditorIntroOutro) => {
    methods.setValue('outro', outroData, { shouldDirty: true });
    navigate('./scenes/outro');
  }, [methods, navigate]);

  const handleCreateScene = useCallback((newScene: VideoEditorIntroOutro) => {
    methods.setValue('scenes', [
      ...methods.getValues('scenes'),
      newScene,
    ], { shouldDirty: true });
    navigate(`./scenes/${newScene.id}`);
  }, [methods, navigate]);

  const handleZeroDuration = useCallback(async () => {
    const listZeroDurationScenes = videoEditorConfig.scenes.map((ve) => (
      ve.videoToken && ve.videoVideoUrl && !ve.videoDuration ? ve : null
    ));
    if (listZeroDurationScenes.filter((item) => item !== null).length > 0) {
      const result = await Promise.allSettled(listZeroDurationScenes.map((ve) => (
        ve === null
          ? Promise.resolve(null)
          : getVideoDurations(customerId!, ve.videoVideoUrl!)
      )));
      result.forEach((item, index) => {
        if (item.status === 'fulfilled' && item.value !== null) {
          methods.setValue(`scenes.${index}.videoDuration`, item.value, { shouldDirty: true });
        }
      });
    }
  }, [customerId, methods, videoEditorConfig.scenes]);

  useEffectOnlyInitial(() => {
    handleZeroDuration();
  }, [handleZeroDuration]);

  return (
    <FormProvider {...methods}>
      <form>
        <VideoEditorHeader config={videoEditorConfig} questionId={questionId} />
        <Box>
          <Routes>
            <Route index element={<Navigate to="scenes" />} />
            <Route
              path="scenes/*"
              element={(
                <VideoScenes
                  videoEditorConfig={videoEditorConfig}
                  onCreateScene={handleCreateScene}
                />
              )}
            />
            <Route
              path="image/:id"
              element={(
                <VideoEditorEditImageScene brandId={videoEditorConfig.brandId} />
              )}
            />
            <Route
              path="intro"
              element={(
                <VideoEditorAddScene
                  defaultScene={intro}
                  brandId={brandId}
                  onCreateScene={handleAddIntro}
                  title={t('video_editor.labels.adding_intro')}
                  description={t('video_editor.messages.upload_as_intro')}
                />
              )}
            />
            <Route
              path="outro"
              element={(
                <VideoEditorAddScene
                  defaultScene={outro}
                  brandId={brandId}
                  onCreateScene={handleAddOutro}
                  title={t('video_editor.labels.adding_outro')}
                  description={t('video_editor.messages.upload_as_outro')}
                />
              )}
            />
            <Route
              path="new"
              element={(
                <VideoEditorAddScene
                  defaultScene={null}
                  brandId={brandId}
                  onCreateScene={handleCreateScene}
                  title={t('video_editor.labels.adding_scene')}
                  description={t('video_editor.messages.upload_as_scene')}
                />
              )}
            />
            <Route path="*" element={<Box pt="64px"><NotFound /></Box>} />
          </Routes>
        </Box>
      </form>
    </FormProvider>
  );
}

export default VideoEditorContainer;
