import {
  Box, Button,
  FormControl,
  FormLabel,
  Input, useDisclosure,
} from '@chakra-ui/react';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ErrorMessage from '@components/ErrorMessage';
import { SignUpFormValues } from '@modules/Auth/types/sign-up.types';
import signUpSchema from '@modules/Auth/schemas/sign-up.schema';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { registerUser } from '@apis/auth.api';
import { useNavigate } from 'react-router-dom';

function SignUpForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    register,
  } = useForm<SignUpFormValues>({
    defaultValues: {
      email: '',
      password: '',
      plan: null,
      firstname: '',
      lastname: '',
    },
    resolver: yupResolver(signUpSchema),
  });
  const { errors } = useFormState<SignUpFormValues>({ control });
  const {
    isOpen: isRegistering,
    onOpen: startRegistering,
    onClose: finishRegistering,
  } = useDisclosure();

  const onSubmit = useCallback(async (values: SignUpFormValues) => {
    try {
      startRegistering();
      await registerUser(values);
      navigate(`/verify?${new URLSearchParams({ email: values.email }).toString()}`);
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishRegistering();
  }, [finishRegistering, navigate, startRegistering]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <FormControl isInvalid={!!errors.firstname}>
          <FormLabel>{t('common.labels.first_name')}</FormLabel>
          <Input {...register('firstname')} type="text" placeholder={t('common.labels.first_name')} variant="default" />
          <ErrorMessage error={errors.firstname?.message} />
        </FormControl>
        <FormControl isInvalid={!!errors.lastname}>
          <FormLabel>{t('common.labels.last_name')}</FormLabel>
          <Input {...register('lastname')} type="text" placeholder={t('common.labels.last_name')} />
          <ErrorMessage error={errors.lastname?.message} />
        </FormControl>
        <FormControl isInvalid={!!errors.email}>
          <FormLabel>{t('common.labels.email')}</FormLabel>
          <Input {...register('email')} name="email" type="text" placeholder={t('common.labels.email')} />
          <ErrorMessage error={errors.email?.message} />
        </FormControl>
        <FormControl isInvalid={!!errors.password}>
          <FormLabel>{t('common.labels.password')}</FormLabel>
          <Input {...register('password')} type="password" placeholder={t('common.labels.password')} />
          <ErrorMessage error={errors.password?.message} />
        </FormControl>
      </Box>
      <Box mt="30px" textAlign="center">
        <Button
          w="full"
          isLoading={isRegistering}
          disabled={isRegistering}
          type="submit"
          textTransform="uppercase"
        >
          {t('common.button.texts.sign_up')}
        </Button>
      </Box>
    </form>
  );
}

export default SignUpForm;
