import { create } from 'zustand';
import createBrandSlice from '@store/slices/brand/slice';
import createCustomerSlice from './slices/customer/slice';
import { AppState } from './types';
import createFolderSlice from './slices/folder/slice';

const useVmStore = create<AppState>((...params) => ({
  ...createCustomerSlice(...params),
  ...createBrandSlice(...params),
  ...createFolderSlice(...params),
}));

export {
  // eslint-disable-next-line import/prefer-default-export
  useVmStore,
};
