import { useCallback, useEffect, useState } from 'react';
import { IVmContact } from '@typed/contact.types';
import { toast } from 'react-toastify';
import { useDisclosure } from '@chakra-ui/react';
import { getContacts } from '@apis/contact.api';
import { useVmStore } from '@store/index';
import { selectedBrandSelector } from '@store/slices/brand/selectors';

interface UseContacts {
  isLoadingContacts: boolean;
  contacts: IVmContact[];
}

function useContacts(): UseContacts {
  const brandId = useVmStore(selectedBrandSelector);
  const [contacts, setContacts] = useState<IVmContact[]>([]);
  const {
    isOpen: isLoadingContacts,
    onOpen: startLoadingContacts,
    onClose: finishLoadingContacts,
  } = useDisclosure({ defaultIsOpen: true });

  const loadContacts = useCallback(async () => {
    try {
      startLoadingContacts();
      const result = await getContacts(brandId!);
      setContacts(result);
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishLoadingContacts();
  }, [brandId, finishLoadingContacts, startLoadingContacts]);

  useEffect(() => {
    loadContacts();
  }, [loadContacts]);

  return {
    isLoadingContacts,
    contacts,
  };
}

export default useContacts;
