import { useClipboard } from '@chakra-ui/react';
import { useEffect } from 'react';

interface UseVmClipboard {
  hasCopied: boolean;
  onCopy: () => void;
}

function useVmClipboard(value: string): UseVmClipboard {
  const { hasCopied, onCopy, setValue } = useClipboard(value);

  useEffect(() => {
    setValue(value);
  }, [setValue, value]);

  return {
    onCopy,
    hasCopied,
  };
}

export default useVmClipboard;
