import { Box } from '@chakra-ui/react';
import { useVmStore } from '@store/index';
import { isAdminCustomer } from '@store/slices/customer/selectors';
import { useTranslation } from 'react-i18next';
import SidebarAccountMenu from '../../components/SidebarAccountMenu';
import SidebarMenu from '../../components/SidebarMenu';
import SidebarSupportMenu from '../../components/SidebarSupportMenu';

function ConfigurationMenu() {
  const { t } = useTranslation();
  const isAdmin = useVmStore(isAdminCustomer);

  return (
    <Box>
      {isAdmin && (
        <SidebarMenu title={t('sidebar.menu.title.admin')} />
      )}
      <Box>
        <SidebarSupportMenu />
      </Box>
      <Box textAlign="center" mt="20px">
        <SidebarAccountMenu />
      </Box>
    </Box>
  );
}

export default ConfigurationMenu;
