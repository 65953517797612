import { Box, chakra } from '@chakra-ui/react';

const SettingBoxLabelStyled = chakra(Box, {
  baseStyle: {
    fontSize: '16px',
    lineHeight: '24px',
    color: 'vmGray.400',
    fontWeight: 500,
  },
});

export default SettingBoxLabelStyled;
