import { Icon, IconProps } from '@chakra-ui/react';

function ArrowBackIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 8 14" fill="none" stroke="vmGray.600" {...props}>
      <path
        d="M7 1L0.999999 7L7 13"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default ArrowBackIcon;
