import { Box, Flex } from '@chakra-ui/react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import VideoEditorSubmitButton from '@modules/VideoEditor/components/VideoEditorSubmitButton/VideoEditorSubmitButton';
import { VideoEditor } from '@modules/VideoEditor/types/video-editor.types';
import { Link } from 'react-router-dom';
import ArrowLeftIcon from '@components/icons/ArrowLeft';

interface Props {
  config: VideoEditor;
  questionId: string;
}

function VideoEditorHeader({
  config,
  questionId,
}: Props) {
  const { t } = useTranslation();

  return (
    <Flex
      pos="fixed"
      justify="space-between"
      align="center"
      w="full"
      py="12px"
      px="20px"
      bg="vmBlack.50"
      zIndex={18}
    >
      <Link to={`/questions/view/${questionId}`}>
        <Flex align="center" gap="20px">
          <Flex role="link" align="center" gap="10px" cursor="pointer">
            <ArrowLeftIcon fontSize="12px" />
            <Box color="vmGray.950" fontSize="16px" lineHeight="24px" fontWeight={500}>Question</Box>
          </Flex>
        </Flex>
      </Link>
      {/* <RenderedVideos
        testimonialId={config.testimonialId}
      /> */}
      <Box>
        <Box as="h3" fontWeight={600} fontSize="20px" color="vmWhite">{t('video_editor.title')}</Box>
      </Box>
      <Flex align="center" justify="space-between" gap="14px">
        <VideoEditorSubmitButton
          brandId={config.brandId}
          answerId={config.answerId}
          questionId={questionId}
        />
      </Flex>
    </Flex>
  );
}

export default memo(VideoEditorHeader);
