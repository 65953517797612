import {
  chakra,
  Flex,
  FlexProps,
} from '@chakra-ui/react';
import { memo } from 'react';
import AlertMessageContent from './AlertMessageContent';
import { AlertMessageProps } from './type';

const AlertMessageWrapper = chakra(Flex, {
  baseStyle: {
    alignItems: 'center',
    gap: '12px',
    py: '15.5px',
    px: '20px',
    borderRadius: '10px',
    bg: 'vmGray.1030',
  },
});

function AlertMessage({
  type,
  message,
  ...flexProps
}: AlertMessageProps & FlexProps) {
  return (
    <AlertMessageWrapper {...flexProps}>
      <AlertMessageContent type={type} message={message} />
    </AlertMessageWrapper>
  );
}

export default memo(AlertMessage);
