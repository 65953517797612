import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { deleteRenderedVideo, getListRenderedVideos } from '@apis/rendered-videos.api';
import { toast } from 'react-toastify';
import { useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { VideoEditorRenderedVideo } from '../types/video-editor.types';

interface UseRenderedVideos {
  isDeletingRenderedVideo: boolean;
  isLoadingRenderedVideo: boolean;
  handleDeleteRenderedVideo: (selectedVideoId: string) => Promise<void>;
  handleDeleteVideoOk: (selectedVideoId: string) => void;
  handleLoadRenderedVideo: () => Promise<void>;
  handleReProcessOk: (videoId: string) => void;
  handleRemoveStatusOfOutQueue: (videoId: string) => void;
  renderedVideos: VideoEditorRenderedVideo[];
  updatedStatusVideos: VideoEditorRenderedVideo[];
}

function compareStatusVideos(
  renderedVideos: VideoEditorRenderedVideo[],
  newRenderedVideos: VideoEditorRenderedVideo[],
  needCompareStatusAfterPolling: boolean,
) {
  if (needCompareStatusAfterPolling) {
    return newRenderedVideos.filter((video) => {
      const oldVideo = renderedVideos.find((item) => item.id === video.id);
      return oldVideo && (
        (oldVideo.shotstackDone !== video.shotstackDone)
        || (oldVideo.shotstackError !== video.shotstackError)
      );
    });
  }
  return newRenderedVideos.filter((rv) => !rv.messageClosed);
}

function useRenderedVideos(
  answerId: string,
  needCompareStatusAfterPolling: boolean = false,
): UseRenderedVideos {
  const { t } = useTranslation();
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const oldRenderedVideos = useRef<VideoEditorRenderedVideo[]>([]);
  const [renderedVideos, setRenderedVideos] = useState<VideoEditorRenderedVideo[]>([]);
  const [updatedStatusVideos, setUpdatedStatusVideos] = useState<VideoEditorRenderedVideo[]>([]);
  const {
    isOpen: isLoadingRenderedVideo,
    onClose: finishLoadingRenderedVideo,
  } = useDisclosure({ defaultIsOpen: true });
  const {
    isOpen: isDeletingRenderedVideo,
    onOpen: startDeletingRenderedVideo,
    onClose: finishDeletingRenderedVideo,
  } = useDisclosure();

  const handleRemoveStatusOfOutQueue = useCallback((videoId: string) => {
    setUpdatedStatusVideos((currentUpdatedStatusVideos) => (
      currentUpdatedStatusVideos.filter((video) => video.id !== videoId)
    ));
  }, []);

  const handleDeleteVideoOk = useCallback((selectedVideoId: string) => {
    setRenderedVideos((currentRenderedVideos) => (
      currentRenderedVideos.filter((video) => video.id !== selectedVideoId)
    ));
  }, []);

  const handleDeleteRenderedVideo = useCallback(async (selectedVideoId: string) => {
    if (selectedVideoId === null) return;
    try {
      startDeletingRenderedVideo();
      await deleteRenderedVideo(selectedVideoId);
      handleDeleteVideoOk(selectedVideoId);
      toast.success(t('video_editor.messages.delete_rendered_video_ok'));
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishDeletingRenderedVideo();
  }, [startDeletingRenderedVideo, finishDeletingRenderedVideo, t, handleDeleteVideoOk]);

  const handleReProcessOk = useCallback((videoId: string) => {
    const currentRenderedVideos = [...renderedVideos];
    const currentVideoIndex = currentRenderedVideos.findIndex((item) => item.id === videoId);
    if (currentVideoIndex > -1) {
      currentRenderedVideos[currentVideoIndex] = {
        ...currentRenderedVideos[currentVideoIndex],
        shotstackDone: false,
        shotstackError: null,
        messageClosed: false,
      };
    }
    setRenderedVideos(currentRenderedVideos);
  }, [renderedVideos]);

  const handleLoadRenderedVideo = useCallback(async () => {
    try {
      const result = await getListRenderedVideos(answerId);
      setRenderedVideos(result);
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishLoadingRenderedVideo();
  }, [answerId, finishLoadingRenderedVideo]);

  const handleLongPollingRenderedVideo = useCallback(async () => {
    if (renderedVideos.length === 0) return;
    const needToBePoll = renderedVideos.some((video) => (
      !video.shotstackDone && !video.shotstackError));
    if (needToBePoll) {
      timerRef.current = setTimeout(() => {
        handleLoadRenderedVideo();
      }, 20000);
    }
  }, [renderedVideos, handleLoadRenderedVideo]);

  useEffect(() => {
    setUpdatedStatusVideos(
      compareStatusVideos(oldRenderedVideos.current, renderedVideos, needCompareStatusAfterPolling),
    );
    oldRenderedVideos.current = renderedVideos;
  }, [renderedVideos, needCompareStatusAfterPolling]);

  useEffect(() => {
    handleLongPollingRenderedVideo();
  }, [handleLongPollingRenderedVideo]);

  useEffect(() => {
    handleLoadRenderedVideo();
  }, [handleLoadRenderedVideo]);

  useEffect(() => () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  return {
    isDeletingRenderedVideo,
    isLoadingRenderedVideo,
    handleDeleteVideoOk,
    handleDeleteRenderedVideo,
    handleLoadRenderedVideo,
    handleReProcessOk,
    handleRemoveStatusOfOutQueue,
    renderedVideos,
    updatedStatusVideos,
  };
}

export default useRenderedVideos;
