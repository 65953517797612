function generateGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = Math.random() * 16 | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function makeCustomerCharAvatar(customerName: string, maxLength: number = Infinity): string {
  if (maxLength === 0) return '';
  const shortName = customerName.split(' ').map((item) => item.charAt(0).toUpperCase()).join('');
  if (shortName.length > maxLength) {
    return shortName.slice(0, maxLength);
  }
  return shortName;
}

function removeCharacterFromString(str: string, character: string): string {
  if (!character) return str;
  return str.replace(character, '');
}

export {
  makeCustomerCharAvatar,
  removeCharacterFromString,
  generateGuid,
};
