import { Box } from '@chakra-ui/react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Guards
import GuestGuard from '@root/guards/GuestGuard';
import AuthGuard from '@root/guards/AuthGuard';
// Layouts
import Layout from '@core/layouts/Layout';

// Modules
import Login from '@modules/Auth/containers/Login';
import SignUp from '@modules/Auth/containers/SignUp';
import VerifyRequired from '@modules/Auth/containers/VerifyRequired';
import Callback from '@modules/Auth/containers/Callback/Callback';
import Account from '@modules/Account';
import Plan from '@modules/Plan';
import Contact from '@modules/Contact';
import Branding from '@modules/Branding';
import Forms from '@modules/Forms';
import { useMemo } from 'react';
import PublicQuestion from '@modules/PublicQuestion';
import PublicQuestionEmailConfirm from '@modules/PublicQuestionEmailConfirm';
import Question from '@modules/Question';
import NotFound from '@components/NotFound';
import ComingSoon from '@components/ComingSoon';
import PublicAnswer from '@modules/PublicAnswer';
import MediaLibrary from '@modules/MediaLibrary/MediaLibrary';
import VideoEditor from '@modules/VideoEditor/VideoEditor';
import VideoLibrary from '@modules/VideoLibrary';

function App() {
  const location = useLocation();
  const hasSidebar = useMemo(() => !['/forms/add', '/forms/update', '/forms/clone', '/video-editor'].some((path) => location.pathname.startsWith(path)), [location]);

  return (
    <Box id="wrapper">
      <Routes>
        <Route path="login" element={<GuestGuard><Login /></GuestGuard>} />
        <Route path="signup" element={<GuestGuard><SignUp /></GuestGuard>} />
        <Route path="verify" element={<GuestGuard><VerifyRequired /></GuestGuard>} />
        <Route path="callback" element={<GuestGuard><Callback /></GuestGuard>} />
        <Route path="question/:formId/*" element={<PublicQuestion />} />
        <Route path="answer/:answerId/*" element={<PublicAnswer />} />
        <Route path="confirm-email/:questionId" element={<PublicQuestionEmailConfirm />} />
        <Route path="/" element={<AuthGuard><Layout hasSidebar={hasSidebar} /></AuthGuard>}>
          <Route index element={<Navigate to="/forms" />} />
          <Route path="account" element={<Account />} />
          <Route path="plan" element={<Plan />} />
          <Route path="contacts/*" element={<Contact />} />
          <Route path="brands/*" element={<Branding />} />
          <Route path="forms/*" element={<Forms />} />
          <Route path="questions/*" element={<Question />} />
          <Route path="questions-f/:formId/*" element={<Question />} />
          <Route path="questions-c/:contactId/*" element={<Question />} />
          <Route path="media-library" element={<MediaLibrary />} />
          <Route path="settings" element={<ComingSoon />} />
          <Route path="video-editor/:answerId/*" element={<VideoEditor />} />
          <Route path="video-library/*" element={<VideoLibrary />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer />
    </Box>
  );
}

export default App;
