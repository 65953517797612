import { StateCreator } from 'zustand';
import { AppState } from '@store/types';
import { BrandState } from '@store/slices/brand/types';
import {
  createBrand,
  getAlLBrands,
  removeBrand,
  updateBrandData,
} from '@apis/brand.api';
import { getItem, setItem } from '@utils/localstorage.util';
import LOCAL_STORAGE_KEY from '@constants/localstorage';

const createBrandSlice: StateCreator<AppState, [], [], BrandState> = (set, get) => ({
  brands: [],
  selectedBrandId: getItem(LOCAL_STORAGE_KEY.BRAND_KEY),
  getBrands: async () => {
    const { customerId } = get().customer!;
    return getAlLBrands(customerId).then((brands) => {
      set(() => ({ brands }));
      return brands;
    });
  },
  addBrand: async (brand) => {
    const { customerId } = get().customer!;
    return createBrand(customerId, brand).then((brands) => {
      set(() => ({ brands }));
      return brands;
    });
  },
  deleteBrand: async (brandId) => removeBrand(brandId).then(() => {
    set((state) => {
      const newBrands = state.brands.filter((brand) => brand.brandId !== brandId);
      if (newBrands.length > 0) {
        return ({ brands: newBrands, selectedBrandId: newBrands[0].brandId });
      }
      return ({ brands: newBrands, selectedBrandId: null });
    });
  }),
  updateBrand: async (brandId, brandData) => updateBrandData(brandId, brandData).then(() => {
    set((state) => {
      const newBrands = [...state.brands];
      const brandIndex = state.brands.findIndex((item) => item.brandId === brandId);
      if (brandIndex !== -1) {
        newBrands[brandIndex] = {
          ...newBrands[brandIndex],
          ...brandData,
        };
        return ({ brands: newBrands });
      }
      return state;
    });
  }),
  changeSelectedBrand: (brandId) => {
    setItem(LOCAL_STORAGE_KEY.BRAND_KEY, brandId);
    set(() => ({ selectedBrandId: brandId }));
  },
});

export default createBrandSlice;
