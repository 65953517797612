import {
  Box,
  Button,
  Flex,
} from '@chakra-ui/react';
import VideoProcessIcon from '@components/icons/ve/VideoProcess';
import StepProcessHeader from '@components/StepProcessHeader';
import WarningText from '@components/WarningText';
import {
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import VideoRenderInfo from './VideoRenderInfo';

interface Props {
  defaultName: string
  onClose: (redirect?: boolean) => void;
  onRenderVideo: (name: string) => Promise<void>;
}

function VideoRenderProgress({
  defaultName,
  onClose,
  onRenderVideo,
}: Props) {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState<number>(0);

  const onGoToNextStep = useCallback(() => {
    setActiveStep((currentStep) => currentStep + 1);
  }, []);

  const steps = useMemo(() => ([
    {
      title: t('video_editor.labels.ready_to_render'),
      render: () => (
        <Box mx="auto">
          <Box
            fontSize="16px"
            lineHeight="24px"
            fontWeight={400}
            textAlign="center"
          >
            {t('video_editor.labels.ready_to_render_desc')}
          </Box>
          <VideoRenderInfo
            onGoNext={onGoToNextStep}
            onRenderVideo={onRenderVideo}
            onClose={onClose}
            defaultName={defaultName}
          />
        </Box>
      ),
    },
    {
      title: t('video_editor.labels.video_is_processing'),
      render: () => (
        <Box mx="auto">
          <Box
            fontSize="16px"
            lineHeight="24px"
            fontWeight={400}
            textAlign="center"
          >
            {t('video_editor.messages.process_video_description')}
          </Box>
          <WarningText mt="30px" py="15px" px="20px" bg="trYellow.100" color="trYellow.150" textAlign="center">
            {t('video_editor.messages.process_video_alert')}
          </WarningText>
          <Flex align="center" justify="center" mt="40px">
            <Button type="button" onClick={() => onClose(true)}>{t('common.button.texts.close')}</Button>
          </Flex>
        </Box>
      ),
      hasIcon: true,
    },
  ]), [onClose, onGoToNextStep, onRenderVideo, defaultName, t]);

  return (
    <Box>
      <StepProcessHeader
        title={steps[activeStep].title}
        currentStep={activeStep}
        totalStep={steps.length}
      >
        {steps[activeStep].hasIcon ? (
          <Box p="10px" bg="vmPrimary.50" borderRadius="10px">
            <VideoProcessIcon fill="vmWhite" fontSize="24px" />
          </Box>
        ) : undefined }
      </StepProcessHeader>
      <Box mt="30px">
        {steps[activeStep].render()}
      </Box>
    </Box>
  );
}

export default memo(VideoRenderProgress);
