import { IVideoLibraryItem } from '@modules/VideoLibrary/types/video-library.types';
import { ApiResponse } from '@typed/http.types';
import axios, { AxiosResponse } from 'axios';

async function getVideos(brandId: string): Promise<IVideoLibraryItem[]> {
  return axios.get(`/video-library/${brandId}/all`)
    .then((response: AxiosResponse<ApiResponse<IVideoLibraryItem[]>>) => response.data.result);
}

async function getVideoDetail(videoId: string): Promise<IVideoLibraryItem> {
  return axios.get(`/video-library/${videoId}`)
    .then((response: AxiosResponse<ApiResponse<IVideoLibraryItem>>) => (
      response.data.result
    ));
}

async function deleteVideoItem(videoId: string) {
  return axios.delete(`/video-library/${videoId}`);
}

export {
  getVideos,
  getVideoDetail,
  deleteVideoItem,
};
