import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import ConfirmPopup from '@components/ConfirmPopup';
import EditIcon from '@components/icons/Edit';
import FolderIcon from '@components/icons/Folder';
import TrashIcon from '@components/icons/Trash';
import VmFolderControl from '@modules/Forms/forms/VmFolderControl';
import { IFolderFormValue } from '@modules/Forms/types/folder.types';
import { useVmStore } from '@store/index';
import { IFolder } from '@typed/folder.types';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface Props {
  folder: IFolder
}

function VmFolderItem({
  folder,
}: Props) {
  const navigate = useNavigate();
  const removeFolder = useVmStore((state) => state.removeFolder);
  const updateFolder = useVmStore((state) => state.updateFolder);
  const defaultValues = useMemo<Pick<IFolderFormValue, 'name'>>(() => ({ name: folder.name }), [folder]);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: isOpenModal, onClose: onCloseModal, onOpen: openModal } = useDisclosure();
  const {
    isOpen: isDeletingFolder,
    onOpen: startDeletingFolder,
    onClose: finishDeletingFolder,
  } = useDisclosure();
  const {
    isOpen: isUpdatingFolder,
    onOpen: startUpdatingFolder,
    onClose: finishUpdatingFolder,
  } = useDisclosure();

  const onUpdate = useCallback(async (values: IFolderFormValue) => {
    try {
      startUpdatingFolder();
      await updateFolder(folder.folderId, values.name);
      toast.success('The folder has been updated successfully');
      onCloseModal();
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishUpdatingFolder();
  }, [finishUpdatingFolder, folder.folderId, onCloseModal, startUpdatingFolder, updateFolder]);

  const onRemoveFolder = useCallback(async () => {
    try {
      startDeletingFolder();
      await removeFolder(folder.folderId);
      toast.success('A folder has been remove successfully');
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishDeletingFolder();
  }, [finishDeletingFolder, folder.folderId, removeFolder, startDeletingFolder]);

  return (
    <>
      <Flex
        role="button"
        borderWidth="1px"
        borderColor="vmGray.900"
        borderRadius="10px"
        py="14px"
        px="18px"
        pr="58px"
        bg="vmWhite"
        minW="192px"
        pos="relative"
        gap="12px"
        align="center"
        onClick={() => navigate(`/forms/f/${folder.folderId}`)}
      >
        <FolderIcon fontSize="20px" />
        <Box>{folder.name}</Box>
        <Flex pos="absolute" top="50%" transform="translateY(-50%)" right="10px" align="center" gap="10px">
          <Box role="button" onClick={(event) => { event.stopPropagation(); openModal(); }}><EditIcon /></Box>
          <ConfirmPopup
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            active
            isLoading={isDeletingFolder}
            onProceed={onRemoveFolder}
            description="This will delete folder"
          >
            <Box role="button"><TrashIcon /></Box>
          </ConfirmPopup>
        </Flex>
      </Flex>
      <VmFolderControl
        isLoading={isUpdatingFolder}
        onClose={onCloseModal}
        isOpen={isOpenModal}
        onSubmit={onUpdate}
        defaultValues={defaultValues}
      />
    </>
  );
}

export default VmFolderItem;
