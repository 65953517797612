import axios, { AxiosResponse } from 'axios';
import { ApiResponse } from '@typed/http.types';
import {
  VideoEditor,
  VideoEditorIntroOutro,
  VideoEditorScene,
  VideoEditorSettings,
} from '@modules/VideoEditor/types/video-editor.types';

async function getVideoEditorConfig(brandId: string, answerId: string): Promise<VideoEditor> {
  return axios.get(`/video-editor/${brandId}/${answerId}`)
    .then((response: AxiosResponse<ApiResponse<VideoEditor>>) => response.data.result);
}

async function updateVideoEditorSettings(
  brandId: string,
  answerId: string,
  data: VideoEditorSettings,
): Promise<VideoEditorSettings> {
  return axios.put(`/video-editor/${brandId}/settings/${answerId}`, data)
    .then((response: AxiosResponse<ApiResponse<VideoEditorSettings>>) => response.data.result);
}

async function updateVideoEditorScenes(
  brandId: string,
  answerId: string,
  data: { intro: VideoEditorIntroOutro, outro: VideoEditorIntroOutro, scenes: VideoEditorScene[]},
) {
  return axios.put(`/video-editor/${brandId}/${answerId}`, data);
}

export {
  getVideoEditorConfig,
  updateVideoEditorSettings,
  updateVideoEditorScenes,
};
