import { Icon, IconProps } from '@chakra-ui/react';

function ShareRightIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 12" fill="vmPrimary.50" {...props}>
      <path
        d="M9.33333 3.00131V0.450142C9.33333 0.269545 9.454 0.106347 9.63867 0.0355475C9.82267 -0.0346516 10.038 0.000747862 10.1813 0.127346L15.848 5.07729C15.9453 5.16188 16 5.27828 16 5.40008C16 5.52188 15.9453 5.63828 15.848 5.72288L10.1813 10.6728C10.0367 10.7988 9.822 10.8342 9.63867 10.7646C9.454 10.6938 9.33333 10.5306 9.33333 10.35V7.80005H8.388C5.29733 7.80005 2.448 9.31203 0.952667 11.7432L0.938667 11.766C0.849334 11.9124 0.68 12 0.5 12C0.458666 12 0.417332 11.9958 0.375999 11.9862C0.154666 11.9352 -4.76837e-07 11.7558 -4.76837e-07 11.55C-4.76837e-07 6.88566 4.17133 3.08171 9.33333 3.00131V3.00131Z"
      />
    </Icon>
  );
}

export default ShareRightIcon;
