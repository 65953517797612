import { Flex, Grid } from '@chakra-ui/react';
import { useVmStore } from '@store/index';
import dayjs from 'dayjs';
import { customerEmailSelector, customerNameSelector, customerRegisteredSelector } from '@store/slices/customer/selectors';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import { EXTERNAL_LINKS } from '@constants/external-links';
import PasswordUpdate from '@modules/Account/components/PassswordUpdate';
import NameUpdate from '@modules/Account/components/NameUpdate';
import EmailUpdate from '@modules/Account/components/EmailUpdate';
import AccountDelete from '@modules/Account/components/AccountDelete';
import SettingBoxLabelStyled from '@shared/Setting/styled/SettingBoxLabelStyled';
import SettingBoxTextStyled from '@shared/Setting/styled/SettingBoxTextStyled';

function Account() {
  const { t, i18n } = useTranslation();
  const customerName = useVmStore(customerNameSelector);
  const customerEmail = useVmStore(customerEmailSelector);
  const customerRegistered = useVmStore(customerRegisteredSelector);

  const documentLink = useMemo(() => {
    switch (i18n.language) {
      case 'en': {
        return EXTERNAL_LINKS.GDPR_DOCUMENT.GDPR_DOCUMENT_EN;
      }
      case 'de': {
        return EXTERNAL_LINKS.GDPR_DOCUMENT.GDPR_DOCUMENT_DE;
      }
      default: {
        return EXTERNAL_LINKS.GDPR_DOCUMENT.GDPR_DOCUMENT_EN;
      }
    }
  }, [i18n.language]);

  return (
    <Flex direction="column" gap="20px" py="80px" px="40px">
      <Flex direction="column" gap="20px" p="20px" bg="vmGray.150" borderRadius="8px">
        <Grid alignItems="baseline" templateColumns="293px auto" gridGap="20px">
          <SettingBoxLabelStyled as="label">{t('account.labels.user_name')}</SettingBoxLabelStyled>
          <Flex align="center" justify="space-between" gap="20px">
            <SettingBoxTextStyled as="span">{customerName}</SettingBoxTextStyled>
            <NameUpdate />
          </Flex>
        </Grid>
        <Grid alignItems="baseline" templateColumns="293px auto" gridGap="20px">
          <SettingBoxLabelStyled as="label">{t('account.labels.email_address')}</SettingBoxLabelStyled>
          <Flex align="center" justify="space-between" gap="20px">
            <SettingBoxTextStyled as="span">{customerEmail}</SettingBoxTextStyled>
            <EmailUpdate />
          </Flex>
        </Grid>
        <Grid alignItems="baseline" templateColumns="293px auto" gridGap="20px">
          <SettingBoxLabelStyled as="label">{t('account.labels.registerd')}</SettingBoxLabelStyled>
          <Flex align="center" justify="space-between" gap="20px">
            <SettingBoxTextStyled as="span">{customerRegistered ? dayjs(customerRegistered).format('MM/DD/YYYY HH:mm A') : ''}</SettingBoxTextStyled>
          </Flex>
        </Grid>
      </Flex>
      <Flex direction="column" gap="20px" p="20px" bg="vmGray.150" borderRadius="8px">
        <Grid alignItems="baseline" templateColumns="293px auto" gridGap="20px">
          <SettingBoxLabelStyled as="label">{t('account.labels.gdpr_processing')}</SettingBoxLabelStyled>
          <Flex align="center" justify="space-between" gap="20px">
            <Flex align="center" gap="20px">
              <Link to={documentLink}>
                <SettingBoxTextStyled as="span" color="vmPrimary.50">{t('account.message.english_version')}</SettingBoxTextStyled>
              </Link>
              <SettingBoxTextStyled as="span" color="vmPrimary.50">{t('account.message.english_version_facility')}</SettingBoxTextStyled>
            </Flex>
          </Flex>
        </Grid>
        <Grid alignItems="baseline" templateColumns="293px auto" gridGap="20px">
          <SettingBoxLabelStyled as="label">{t('account.labels.gdpr_subject')}</SettingBoxLabelStyled>
          <Flex align="center" justify="space-between" gap="20px">
            <Link to="https://app.usetrust.app/get-user-data" target="_blank">
              <SettingBoxTextStyled as="span" color="vmPrimary.50">https://app.usetrust.app/get-user-data</SettingBoxTextStyled>
            </Link>
          </Flex>
        </Grid>
      </Flex>
      <Flex direction="column" gap="20px" p="20px" bg="vmGray.150" borderRadius="8px">
        <Grid alignItems="baseline" templateColumns="293px auto" gridGap="20px">
          <SettingBoxLabelStyled as="label">{t('common.labels.password')}</SettingBoxLabelStyled>
          <Flex align="center" justify="space-between" gap="20px">
            <SettingBoxTextStyled as="span">***********</SettingBoxTextStyled>
            <PasswordUpdate />
          </Flex>
        </Grid>
        <Grid alignItems="baseline" templateColumns="293px auto" gridGap="20px">
          <SettingBoxLabelStyled as="label">{t('account.labels.delete_account')}</SettingBoxLabelStyled>
          <Flex align="center" justify="space-between" gap="20px">
            <SettingBoxTextStyled as="span">{t('account.message.cancel_subscription_first')}</SettingBoxTextStyled>
            <AccountDelete />
          </Flex>
        </Grid>
      </Flex>
    </Flex>
  );
}

export default Account;
