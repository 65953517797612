import { memo, useCallback } from 'react';
import {
  Box,
  Button,
  chakra,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { VideoEditorRenderedVideo } from '@modules/VideoEditor/types/video-editor.types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { deleteRenderedVideo, reRenderVideo } from '@apis/rendered-videos.api';
import { BrokenVideoIcon, PlayIcon } from '@components/icons/ve';
import ReloadIcon from '@components/icons/Reload';
import DeleteIcon from '@components/icons/ml/Delete';
import CloseIcon from '@components/icons/Close';
import VMVideoPlayer from '@components/VMVideoPlayer';

const VideoRenderedItemWrapper = chakra(Flex, {
  baseStyle: {
    p: '22px',
    borderWidth: '2px',
    borderStyle: 'dashed',
    borderColor: 'vmGray.850',
    borderRadius: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
});

interface Props {
  video: VideoEditorRenderedVideo | null;
  isChecked: boolean;
  isLoading: boolean;
  isTestimonialVideo: boolean;
  onChooseVideo: (videoId: string, isChecking: boolean) => void;
  onReProcess: (videoId: string) => void;
  onDelete: (videoId: string) => void;
}

function VideoRenderedItem({
  video,
  isChecked,
  isLoading,
  isTestimonialVideo,
  onChooseVideo,
  onReProcess,
  onDelete,
}: Props) {
  const { t } = useTranslation();
  const {
    isOpen,
    onOpen,
    onClose,
  } = useDisclosure();
  const {
    isOpen: isStartingReProcess,
    onOpen: startReProcessing,
    onClose: finishProcessing,
  } = useDisclosure();
  const {
    isOpen: isDeletingVideo,
    onOpen: startDeletingVideo,
    onClose: finishDeletingVideo,
  } = useDisclosure();

  const handleVideoReProcess = useCallback(async () => {
    try {
      startReProcessing();
      await reRenderVideo(video!.id);
      onReProcess(video!.id);
      toast.success(t('video_editor.messages.video_reprocess_ok'));
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishProcessing();
  }, [finishProcessing, startReProcessing, video, onReProcess, t]);

  const handleDeleteVideo = useCallback(async () => {
    try {
      startDeletingVideo();
      await deleteRenderedVideo(video!.id);
      onDelete(video!.id);
      toast.success(t('video_editor.messages.delete_rendered_video_ok'));
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishDeletingVideo();
  }, [finishDeletingVideo, startDeletingVideo, video, onDelete, t]);

  if (isLoading) {
    return (
      <VideoRenderedItemWrapper gap="10px">
        <Skeleton w="full" h="226px" />
        <Flex gap="5px" align="center" w="full">
          <Skeleton w="20px" h="20px" />
          <Skeleton w="120px" maxW="full" h="20px" />
        </Flex>
      </VideoRenderedItemWrapper>
    );
  }

  if (video === null) {
    return (
      <VideoRenderedItemWrapper>
        <Flex align="center" justify="center" w="full" h="226px" fontSize="16px" lineHeight="24px" fontWeight={400} color="vmGray.50">{t('video_editor.labels.rendered_videos.no_video')}</Flex>
      </VideoRenderedItemWrapper>
    );
  }

  if (video.shotstackError) {
    return (
      <VideoRenderedItemWrapper borderColor="vmRed.50" gap="30px">
        <BrokenVideoIcon fontSize="32px" />
        <Box fontSize="16px" lineHeight="24px" fontWeight={400}>{t('video_editor.labels.video_render_fail')}</Box>
        <Flex align="center" justify="center" gap="10px">
          <Button bg="vmGray.100" type="button" variant="iconOnly" size="iconOnly" isLoading={isStartingReProcess} isDisabled={isStartingReProcess || isStartingReProcess} onClick={handleVideoReProcess}>
            <ReloadIcon
              fontSize="16px"
              stroke="vmPrimary.50"
            />
          </Button>
          <Button bg="vmGray.100" type="button" variant="iconOnly" size="iconOnly" isLoading={isDeletingVideo} isDisabled={isDeletingVideo || isStartingReProcess} onClick={handleDeleteVideo}>
            <DeleteIcon fontSize="16px" stroke="vmRed.50" />
          </Button>
        </Flex>
      </VideoRenderedItemWrapper>
    );
  }

  return (
    <VideoRenderedItemWrapper borderStyle={isTestimonialVideo ? 'solid' : 'dashed'} borderColor={isTestimonialVideo ? 'vmGreen.50' : 'vmGray.850'} pos="relative">
      {isTestimonialVideo && (
        <Box zIndex={90} top="-18px" pos="absolute" left="20px" color="vmPrimary.50" fontWeight={400} fontSize="15px" lineHeight="22.5px" py="4px" px="10px" borderWidth="1px" borderColor="vmGray.350" borderRadius="100px" bg="vmWhite">
          {t('video_editor.labels.video_testimonial')}
        </Box>
      )}
      {video.videoToken === null ? (
        <Flex mb="10px" align="center" justify="center" w="full" h="226px" fontSize="16px" lineHeight="24px" fontWeight={400} color="vmGray.50">{t('video_editor.labels.rendered_videos.rendering_video')}</Flex>
      ) : (
        <Box w="full" borderRadius="4px" overflow="hidden" cursor="pointer" pos="relative" mb="10px" onClick={onOpen}>
          <Box
            w="full"
            h="226px"
            bgImage={`//${video.videoImageUrl!}`}
            bgSize="cover"
            bgPos="center center"
            bgRepeat="no-repeat"
          />
          <Box
            pos="absolute"
            left={0}
            right={0}
            top={0}
            bottom={0}
            bg="vmWhite"
            m="auto"
            opacity={0.5}
            borderRadius="100px"
            w="44px"
            h="44px"
          />
          <PlayIcon
            pos="absolute"
            left={0}
            right={0}
            top={0}
            bottom={0}
            m="auto"
            fontSize="22px"
          />
        </Box>
      )}
      <Flex align="start" gap="10px" w="full">
        <Checkbox
          w="20px"
          h="20px"
          pt="3px"
          isChecked={isChecked}
          onChange={(event) => {
            onChooseVideo(video.id, !event.target.checked);
          }}
          _focus={{ boxShadow: 'none' }}
          sx={{
            '& .chakra-checkbox__control': {
              w: '20px',
              h: '20px',
              _focus: {
                boxShadow: 'none',
              },
            },
          }}
        />
        <Flex direction="column" gap="5px" w="full">
          <Tooltip label={video.name} placement="bottom-start">
            <Flex
              fontSize="16px"
              lineHeight="22.4px"
              fontWeight={400}
              color="vmBlack.50"
              minW={0}
              w="full"
              alignSelf="flex-start"
            >
              <Box
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                minW={0}
                maxW="calc(100% - 60px)"
              >
                {video.name}
              </Box>
            </Flex>
          </Tooltip>
          {/* {video. && (
            <Box
              fontWeight={400}
              fontSize="13px"
              lineHeight="19.5px"
              color="vmGray.50"
            >
              {size}
            </Box>
          )} */}
        </Flex>
      </Flex>
      <Modal
        closeOnOverlayClick
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent w="auto" maxW="100%" bg="transparent">
          <ModalBody pos="relative" p={0}>
            <Box
              pos="absolute"
              cursor="pointer"
              onClick={onClose}
              zIndex={99999}
              top="-28px"
              right="-21px"
            >
              <CloseIcon color="vmGray.400" />
            </Box>
            <Box borderRadius="10px" overflow="hidden">
              <Box h="650px">
                <VMVideoPlayer
                  source={null}
                  videoToken={video.videoToken}
                />
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VideoRenderedItemWrapper>
  );
}

export default memo(VideoRenderedItem);
