import {
  FC,
  memo,
  ReactNode,
  useMemo,
} from 'react';
import { Media } from '@shared/MediaLibrary/types/media';
import { useTranslation } from 'react-i18next';
import { Flex, Grid } from '@chakra-ui/react';
import { generateNumbersWithNumLength } from '@utils/array.util';
import {
  AutoSizer as _AutoSizer,
  AutoSizerProps,
  List as _List,
  ListProps,
} from 'react-virtualized';
import MediaLoadingItem from './MediaLoadingItem';
import MediaItem from './MediaItem';

const List = _List as unknown as FC<ListProps>;
const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;

interface Props {
  medias: Media[][];
  isLoading: boolean;
  itemPerRow: number;
  selectedFiles: {[key: string]: boolean};
  onSelectFile: (checkedState: {[key: string]: boolean}) => void;
}

function MediaLibraryList({
  medias,
  isLoading,
  itemPerRow,
  selectedFiles,
  onSelectFile,
}: Props) {
  const { t } = useTranslation();
  const gridTemplateColumns = useMemo(() => generateNumbersWithNumLength(itemPerRow).map(() => 'minmax(100px, 1fr)').join(' '), [itemPerRow]);
  if (isLoading) {
    return (
      <Grid gap="20px" gridTemplateColumns="auto auto auto auto">
        <MediaLoadingItem />
        <MediaLoadingItem />
        <MediaLoadingItem />
        <MediaLoadingItem />
        <MediaLoadingItem />
        <MediaLoadingItem />
        <MediaLoadingItem />
        <MediaLoadingItem />
        <MediaLoadingItem />
        <MediaLoadingItem />
      </Grid>
    );
  }

  if (medias.length === 0) {
    return (
      <Flex p="20px" align="center" justify="center">
        {t('ml.message.no_data')}
      </Flex>
    );
  }

  return (
    <AutoSizer>
      {({ width, height }) => (
        <List
          height={height}
          overscanRowCount={5}
          rowCount={medias.length}
          rowHeight={281}
          rowRenderer={({ index, style }) => {
            const media = medias[index];
            return (
              <Grid key={index} py="1.5px" gridColumnGap="30px" gridTemplateColumns={gridTemplateColumns} style={style}>
                {media.map((item) => (
                  <MediaItem
                    key={item.id}
                    media={item}
                    onSelectFile={onSelectFile}
                    isSelected={!!selectedFiles[item.id]}
                  />
                ))}
              </Grid>
            ) as ReactNode;
          }}
          width={width}
        />
      )}
    </AutoSizer>
  );
}

export default memo(MediaLibraryList);
