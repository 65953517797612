import { Box, chakra } from '@chakra-ui/react';

const LabelHeader = chakra(Box, {
  baseStyle: {
    fontSize: '20px',
    lineHeight: '26px',
    fontWeight: 600,
    color: 'vmBlack.50',
  },
});

export default LabelHeader;
