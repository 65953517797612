import axios, { AxiosResponse } from 'axios';
import { IVmQuestion, IVmQuestionListItem } from '@typed/question.types';
import { ApiResponse } from '@typed/http.types';

async function getAllQuestions(brandId: string): Promise<IVmQuestionListItem[]> {
  return axios.get(`/questions/${brandId}/all`)
    .then((response: AxiosResponse<ApiResponse<IVmQuestionListItem[]>>) => response.data.result);
}

async function getQuestion(questionId: string): Promise<IVmQuestion> {
  return axios.get(`/questions/${questionId}`)
    .then((response: AxiosResponse<ApiResponse<IVmQuestion>>) => response.data.result);
}

async function getAllQuestionsOfForm(formId: string): Promise<IVmQuestionListItem[]> {
  return axios.get(`/questions/form/${formId}`)
    .then((response: AxiosResponse<ApiResponse<IVmQuestionListItem[]>>) => response.data.result);
}

async function deleteQuestion(questionId: string) {
  return axios.delete(`/questions/${questionId}`);
}

async function getQuestionsOfContact(contactId: string): Promise<IVmQuestionListItem[]> {
  return axios.get(`/questions/contact/${contactId}`)
    .then((response: AxiosResponse<ApiResponse<IVmQuestionListItem[]>>) => response.data.result);
}

export {
  getAllQuestions,
  getQuestion,
  deleteQuestion,
  getAllQuestionsOfForm,
  getQuestionsOfContact,
};
