import { memo } from 'react';
import { Box } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { VideoEditorScene } from '@modules/VideoEditor/types/video-editor.types';
import NotFound from '@components/NotFound';
import VideoEditorEditImageSceneContainer from './VideoEditorEditImageSceneContainer';

interface Props {
  brandId: string;
}

function VideoEditorEditImageScene({
  brandId,
}: Props) {
  const { id } = useParams<'id'>();
  const { getValues } = useFormContext();
  const scenes: VideoEditorScene[] = getValues('scenes');
  const sceneIndex = scenes.findIndex((scene) => scene.id === id);

  if (sceneIndex === -1) {
    return (
      <Box pt="64px">
        <NotFound />
      </Box>
    );
  }

  return (
    <VideoEditorEditImageSceneContainer
      brandId={brandId}
      sceneIndex={sceneIndex}
    />
  );
}

export default memo(VideoEditorEditImageScene);
