import { Box, Flex, Image } from '@chakra-ui/react';
import { IForm } from '@modules/Forms/types/form.types';

interface Props {
  form: IForm;
}

function FormThankYou({
  form,
}: Props) {
  return (
    <Flex align="center" w="full" minH="100vh" bg="vmWhite">
      <Flex w="540px" borderRadius="10px" py="40px" minH="100vh" mx="auto">
        <Box>
          <Box px="60px" mb="20px">
            <Flex align="center" pb="5px" borderBottomWidth="2px" borderColor="vmPrimary.50" w="full">
              <Image maxWidth="75px" src="/static-data/images/logo-green.png" alt="Logo" mx="auto" />
            </Flex>
          </Box>
          <Box
            textAlign="center"
            as="h2"
            fontSize="21px"
            color="#071e57"
            mb="25px"
            fontWeight={700}
          >
            {form.thankYou.title}
          </Box>
          <Box
            as="p"
            color="#000"
            fontSize="1rem"
            mb="25px"
            textAlign="center"
            dangerouslySetInnerHTML={{ __html: form.thankYou.text }}
          />
        </Box>
      </Flex>
    </Flex>
  );
}

export default FormThankYou;
