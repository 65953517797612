import { useCallback, useState } from 'react';

function useRecorderRef() {
  const [recorder, setRecorder] = useState<any>(null);

  const setRecorderRef = useCallback((newRecorderInstance: any) => {
    setRecorder(newRecorderInstance);
  }, []);

  return {
    recorder,
    setRecorderRef,
  };
}

export default useRecorderRef;
