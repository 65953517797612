import {
  Box,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import CloneIcon from '@components/Clone';
import ContextItem from '@components/ContextItem';
import CopyIcon from '@components/icons/Copy';
import CameraIcon from '@components/icons/forms/Camera';
import CommentIcon from '@components/icons/forms/Comment';
import MoveRightForward from '@components/icons/MoveRightForward';
import ShareIcon from '@components/icons/Share';
import ThreeDotsIcon from '@components/icons/ThreeDots';
import TrashIcon from '@components/icons/Trash';
import { useListTypeContext } from '@root/contexts/ListType/context';
import { ListType } from '@root/contexts/ListType/types';
import dayjs from 'dayjs';
import useVmClipboard from '@hooks/useVmClipboard';
import TickIcon from '@components/icons/Tick';
import { IFromListItem } from '@modules/Forms/types/form-list.types';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { deleteForm } from '@apis/form.api';
import NavLink from '@components/NavLink';
import EditIcon from '@components/icons/Edit';
import { Link } from 'react-router-dom';

interface Props {
  form: IFromListItem;
  onRemoveSuccess: (folderId: string) => void;
  onChooseForm: (formId: string) => void;
}

function VmFormItem({
  form,
  onRemoveSuccess,
  onChooseForm,
}: Props) {
  const { hasCopied, onCopy } = useVmClipboard(`${window.location.origin}/question/${form.formId}`);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { type } = useListTypeContext();
  const isGridType = type === ListType.GRID;
  const { isOpen: isDeleting, onOpen: startDeleting, onClose: finishDeleting } = useDisclosure();

  const onDeleteForm = useCallback(async () => {
    try {
      onClose();
      startDeleting();
      await deleteForm(form.formId);
      onRemoveSuccess(form.formId);
      toast.success('A form has been removed successfully');
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishDeleting();
  }, [finishDeleting, form.formId, onClose, onRemoveSuccess, startDeleting]);

  return (
    <Flex
      align="center"
      justify={isGridType ? 'center' : 'space-between'}
      bg="vmGray.150"
      py={isGridType ? '20px' : '20px'}
      px={isGridType ? '20px' : '15px'}
      borderRadius="10px"
      pos="relative"
      w="full"
    >
      <Flex align="center" gap={isGridType ? '4px' : '56px'} direction={isGridType ? 'column' : 'row'} w="full" justify="space-between">
        <Flex gap="15px" direction={isGridType ? 'column' : 'row'} align="center">
          {form.enableTextQuestion && !form.enableVideoQuestion ? (
            <CommentIcon fontSize="20px" />
          ) : (
            <CameraIcon fontSize="22px" />
          )}
          <Box as="h5" fontSize="16px" lineHeight="24px" fontWeight={400} color="vmBlack.50">{form.name}</Box>
        </Flex>
        <Box fontSize="16px" fontWeight={400} lineHeight="24px">
          created
          {' '}
          {dayjs(form.created).format('MM.DD.YYYY')}
        </Box>
      </Flex>
      <Flex
        w="full"
        align="center"
        gap="35px"
        pos={isGridType ? 'absolute' : 'relative'}
        top={isGridType ? '20px' : undefined}
        width={isGridType ? 'calc(100% - 40px)' : undefined}
        mx={isGridType ? 'auto' : undefined}
        justifyContent={isGridType ? 'space-between' : 'end'}
      >
        <Flex as={Link} to={`/questions-f/${form.formId}`} align="center" gap="9px" cursor="pointer">
          <ShareIcon fontSize="16px" />
          <Box as="span" fontSize="16px" fontWeight={400} lineHeight="24px" _hover={{ color: 'vmPrimary.50' }}>
            {form.amountQuestions}
            {' '}
            <Box as="span" display={isGridType ? 'none' : 'inline-block'}>
              question
              {form.amountQuestions > 1 ? 's' : ''}
            </Box>
          </Box>
        </Flex>
        <Flex align="center" gap="10px">
          <Popover placement="right-start" isOpen={isOpen} onClose={onClose} isLazy>
            <PopoverTrigger>
              {isDeleting ? (
                <Spinner w="20px" h="20px" />
              ) : (
                <Box cursor="pointer" onClick={onOpen}>
                  <ThreeDotsIcon fontSize="20px" />
                </Box>
              )}
            </PopoverTrigger>
            <Portal>
              <PopoverContent ml="28px" mt="-20px" _focusVisible={{ outline: 'none' }} borderRadius="10px" boxShadow="0px 2px 12px rgba(36, 133, 204, 0.15)" overflow="hidden">
                <PopoverBody>
                  <Box w="320px" bg="vmWhite">
                    <NavLink link={`/forms/update/${form.formId}`}>
                      <ContextItem onClick={onCopy} borderBottomWidth="2px !important">
                        <EditIcon fill="vmGray.50" fontSize="16px" />
                        <Box color="vmGray.400" fontSize="vmNormal" fontWeight={600} lineHeight="24px">Edit</Box>
                      </ContextItem>
                    </NavLink>
                    <ContextItem onClick={onCopy}>
                      {hasCopied ? <TickIcon stroke="vmGreen.100" fontSize="16px" /> : <CopyIcon stroke="vmGray.50" fontSize="16px" />}
                      <Box color="vmGray.400" fontSize="vmNormal" fontWeight={600} lineHeight="24px">Copy link</Box>
                    </ContextItem>
                    <NavLink link={`/forms/clone/${form.formId}`}>
                      <ContextItem borderBottomWidth="2px !important">
                        <CloneIcon stroke="vmGray.50" fontSize="16px" />
                        <Box color="vmGray.400" fontSize="vmNormal" fontWeight={600} lineHeight="24px">Clone</Box>
                      </ContextItem>
                    </NavLink>
                    <ContextItem onClick={() => onChooseForm(form.formId)}>
                      <MoveRightForward stroke="vmGray.50" fontSize="16px" />
                      <Box color="vmGray.400" fontSize="vmNormal" fontWeight={600} lineHeight="24px">Move to folder</Box>
                    </ContextItem>
                    <ContextItem onClick={onDeleteForm}>
                      {isDeleting ? <Spinner w="16px" h="16px" /> : <TrashIcon fontSize="16px" />}
                      <Box color="vmGray.400" fontSize="vmNormal" fontWeight={600} lineHeight="24px">Delete</Box>
                    </ContextItem>
                  </Box>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default VmFormItem;
