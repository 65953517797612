import { IForm } from '@modules/Forms/types/form.types';
import { PublicQuestion } from '@modules/PublicQuestion/types/public-question';
import { IVmAnswer } from '@typed/answer.types';
import { ApiResponse } from '@typed/http.types';
import axios, { AxiosResponse } from 'axios';

async function getPublicForm(formId: string): Promise<{ form: IForm }> {
  return axios.get(`/public/${formId}`)
    .then((response: AxiosResponse<ApiResponse<{ form: IForm }>>) => response.data.result);
}

async function sendPublicQuestion(formId: string, data: PublicQuestion) {
  return axios.post(`/public/${formId}`, data);
}

async function confirmPublicQuestionEmail(questionId: string) {
  return axios.put(`/public/${questionId}/email-confirmed`);
}

async function getPublicAnswer(answerId: string): Promise<IVmAnswer> {
  return axios.get(`/public/answer/${answerId}`)
    .then((response: AxiosResponse<ApiResponse<IVmAnswer>>) => response.data.result);
}

export {
  getPublicForm,
  sendPublicQuestion,
  confirmPublicQuestionEmail,
  getPublicAnswer,
};
