import { Icon, IconProps } from '@chakra-ui/react';

function ClockIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" fill="none" stroke="vmGray.600" {...props}>
      <path
        d="M10 5V10L13 13M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default ClockIcon;
