import { Box, chakra } from '@chakra-ui/react';

const TrustRecorderWrapper = chakra(Box, {
  baseStyle: {
    top: '0',
    width: '100%',
    m: 0,
    left: 0,
  },
});

export default TrustRecorderWrapper;
