import axios from 'axios';

async function downloadFile(
  fileUrl: string,
  fileName: string,
): Promise<void> {
  const result = await axios({
    url: fileUrl,
    method: 'GET',
    responseType: 'blob',
  });
  const url = window.URL.createObjectURL(new Blob([result.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}

export {
  // eslint-disable-next-line import/prefer-default-export
  downloadFile,
};
