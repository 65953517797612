import { AppState } from '@store/types';
import { StateCreator } from 'zustand';
import { getCustomer, updateCustomerEmail, updateCustomerName } from '@apis/customer.api';
import { CustomerState } from './types';

const createCustomerSlice: StateCreator<AppState, [], [], CustomerState> = (set, get) => ({
  customer: null,
  loadCustomer: async (email: string) => getCustomer(email).then((result) => {
    set(() => ({ customer: result }));
    return result;
  }),
  updateName: async (firstName: string, lastName: string) => {
    const { customerId } = get().customer!;
    return updateCustomerName(customerId, { firstName, lastName })
      .then((newCustomer) => set(() => ({ customer: newCustomer })));
  },
  customerUpdateEmail: async (email: string) => {
    const { customerId } = get().customer!;
    return updateCustomerEmail(customerId, email).then((newCustomer) => {
      set(() => ({ customer: newCustomer }));
    });
  },
});

export default createCustomerSlice;
