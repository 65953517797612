export const isLocalDevelopment: boolean = window.location.hostname === 'localhost';
export const isProduction = window.location.hostname === 'app.usetrust.app';
const isTest = window.location.hostname === 'test.usetrust.app';

function getApiRootUrl(): string {
  const { port, origin } = window.location;

  if (isProduction) {
    return 'https://test.videomentor.io';
  }
  if (isTest) {
    return 'https://test.videomentor.io';
  }
  if (isLocalDevelopment && port === '3000') {
    return 'https://test.videomentor.io';
    // return 'https://localhost:7006';
  }
  if (isLocalDevelopment && port === '44338') {
    return 'origin';
  }

  return origin;
}

function getApiVersion() {
  return 'api/v1';
}

export {
  getApiRootUrl,
  getApiVersion,
};
