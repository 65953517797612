import { Icon, IconProps } from '@chakra-ui/react';

function SuccessIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" stroke="none" fill="vmPrimary.50" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.67 -0.00012207H14.34C17.73 -0.00012207 20 2.37988 20 5.91988V14.0909C20 17.6199 17.73 19.9999 14.34 19.9999H5.67C2.28 19.9999 0 17.6199 0 14.0909V5.91988C0 2.37988 2.28 -0.00012207 5.67 -0.00012207ZM9.43 12.9899L14.18 8.23988C14.52 7.89988 14.52 7.34988 14.18 6.99988C13.84 6.65988 13.28 6.65988 12.94 6.99988L8.81 11.1299L7.06 9.37988C6.72 9.03988 6.16 9.03988 5.82 9.37988C5.48 9.71988 5.48 10.2699 5.82 10.6199L8.2 12.9899C8.37 13.1599 8.59 13.2399 8.81 13.2399C9.04 13.2399 9.26 13.1599 9.43 12.9899Z"
      />
    </Icon>
  );
}

export default SuccessIcon;
