import { memo, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { PlanBenefit as IPlanBenefit } from '@modules/Plan/types/plan.types';
import CheckIcon from '@components/icons/Check';
import NotAllowedIcon from '@components/icons/NotAllowed';

interface Props {
  isFull: boolean;
  benefits: Array<IPlanBenefit | null>;
}

function PlanBenefit({ isFull, benefits }: Props) {
  const mid = Math.ceil(benefits.length / 2);

  const leftBenefits = useMemo<Array<IPlanBenefit | null>>(() => (
    benefits.slice(0, mid)
  ), [mid, benefits]);
  const rightBenefits = useMemo<Array<IPlanBenefit | null>>(() => (
    benefits.slice(mid, benefits.length)
  ), [mid, benefits]);

  return (
    <Flex align="baseline" gap={isFull ? '10px' : '30px'} direction={isFull ? 'column' : 'row'}>
      <Flex direction="column" w={isFull ? '100%' : '57%'} gap="5px">
        {leftBenefits.map((benefit) => {
          if (benefit === null) return null;
          return (
            <Flex key={benefit.title} gap="5px" align="flex-start">
              <Box w="16px" h="16px" pt="1px" lineHeight={1}>
                {benefit.included ? (
                  <CheckIcon d="block" color="vmGreen.50" />
                ) : (
                  <NotAllowedIcon d="block" color="vmGray.50" />
                )}
              </Box>
              <Box fontSize="14px" fontWeight="600">{benefit.title}</Box>
            </Flex>
          );
        })}
      </Flex>
      {rightBenefits.length > 0 && (
        <Flex direction="column" w={isFull ? '100%' : '43%'} gap="5px">
          {rightBenefits.map((benefit) => {
            if (benefit === null) return null;
            return (
              <Flex key={benefit.title} gap="5px" align="flex-start">
                <Box w="16px" h="16px" pt="1px" lineHeight={1}>
                  {benefit.included ? (
                    <CheckIcon d="block" color="vmGreen.50" />
                  ) : (
                    <NotAllowedIcon d="block" color="vmGray.50" />
                  )}
                </Box>
                <Box fontSize="14px" fontWeight="600">{benefit.title}</Box>
              </Flex>
            );
          })}
        </Flex>
      )}
    </Flex>
  );
}

export default memo(PlanBenefit);
