import { Icon, IconProps } from '@chakra-ui/react';

function CheckIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 14 14" fill="vmGreen.50" {...props}>
      <g>
        <polygon points="5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039" />
      </g>
    </Icon>
  );
}

export default CheckIcon;
