import { memo } from 'react';
import { Button, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import MoveRightForward from '@components/icons/MoveRightForward';

interface Props {
  onLogin: () => void;
}

function LoginAction({ onLogin }: Props) {
  const { t } = useTranslation();

  return (
    <Box mt="20px" textAlign="center">
      <Button
        w="200px"
        rightIcon={<MoveRightForward />}
        textTransform="uppercase"
        onClick={onLogin}
      >
        {t('common.button.texts.sign_in')}
      </Button>
    </Box>
  );
}

export default memo(LoginAction);
