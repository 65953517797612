import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@chakra-ui/react';
import { Plan } from '@modules/Plan/types/plan.types';
import StarterIcon from '@components/icons/plan/Starter';
import GroundIcon from '@components/icons/plan/Ground';
import BusinessIcon from '@components/icons/plan/Business';
import { useVmStore } from '@store/index';
import { customerSubscriptionSelector } from '@store/slices/customer/selectors';
import PlanBenefit from './PlanBenefit';

function formatPrice(unit: 'EUR' | 'USD', price: number): string {
  const priceUnit = unit === 'EUR' ? '€' : '$';
  return `${priceUnit}${price}`;
}

function showTimeUnit(isBillOnce: boolean, timeType: 'monthly' | 'yearly'): string {
  if (isBillOnce) return '';
  return timeType === 'yearly' ? 'yr' : 'mo';
}

const ICONS: {[key: string]: () => JSX.Element} = {
  starter: () => <StarterIcon />,
  ground: () => <GroundIcon />,
  business: () => <BusinessIcon />,
};

interface Props {
  plan: Plan;
  type: 'monthly' | 'yearly';
  unit: 'EUR' | 'USD';
  onPurchase: (plan: Plan) => void;
  isCurrentPlan: boolean;
}

function PlanItem({
  plan,
  type,
  unit,
  onPurchase,
  isCurrentPlan,
}: Props) {
  const { t } = useTranslation();
  const customerSubscription = useVmStore(customerSubscriptionSelector);
  const fee = plan[type] ?? null;
  const isDisablePlanButton = Boolean(
    isCurrentPlan
    && customerSubscription?.active
    && !customerSubscription.trial
    && !customerSubscription.trialEnded,
  );

  const handlePurchasePlan = useCallback(() => {
    if (isCurrentPlan) return;
    onPurchase(plan);
  }, [onPurchase, plan, isCurrentPlan]);

  return (
    <Box
      p="20px"
      borderWidth="1px"
      borderColor="vmGray.450"
      borderRadius="10px"
      pos="relative"
      overflow="hidden"
    >
      {fee.month_for_free > 0 && (
        <Box
          pos="absolute"
          right="-47px"
          transform="rotate(45deg)"
          color="vmWhite"
          bg="vmGreen.50"
          userSelect="none"
          px="40px"
          textAlign="center"
          top="39px"
        >
          <Box fontSize="14px">
            {fee.month_for_free}
            {' '}
            {t('plan.purchase.labels.months_for_free')}
          </Box>
        </Box>
      )}
      <Box
        textAlign="center"
        color="vmBlack.50"
        fontWeight="bold"
        fontSize="26px"
      >
        {plan.title}
      </Box>
      {fee !== null && (
        <Box mt="10px" fontSize="45px" fontWeight="bold" textAlign="center" color="vmPrimary.50">
          {fee.price === 0 ? (
            <Box>
              {formatPrice(unit, fee.price)}
              {' '}
              {t('plan.purchase.labels.forever')}
            </Box>
          ) : (
            <Box>
              {formatPrice(unit, fee.price)}
              {' '}
              {plan.isBillOnce ? '' : '/'}
              {' '}
              {showTimeUnit(plan.isBillOnce ?? false, type)}
            </Box>
          )}
        </Box>
      )}
      {fee !== null && !plan.isBillOnce && (
        <Box fontSize="vmNormal" fontWeight="bold" color="vmGray.500" textAlign="center">
          {type === 'yearly' ? t('plan.purchase.labels.if_billed_yearly') : t('plan.purchase.labels.if_billed_monthly')}
        </Box>
      )}
      {plan.isBillOnce && (
        <Box fontSize="vmNormal" fontWeight="bold" color="vmGray.500" textAlign="center">
          {t('plan.purchase.labels.billed_once')}
        </Box>
      )}
      <Box mt="20px" textAlign="center" fontSize="60px" minH="90px">
        {ICONS[plan.icon] && ICONS[plan.icon]()}
      </Box>
      <Box mt="20px">
        <Button type="button" w="full" onClick={handlePurchasePlan} disabled={isDisablePlanButton}>
          {isCurrentPlan ? t('plan.purchase.labels.your_current_plan') : (plan.buttonText ?? t('plan.purchase.labels.purchase_plan'))}
        </Button>
      </Box>
      <Box mt="20px">
        <PlanBenefit isFull={plan?.isFull ?? false} benefits={plan.benefits} />
      </Box>
    </Box>
  );
}

export default memo(PlanItem);
