import { Icon, IconProps } from '@chakra-ui/react';

function ListTypeIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 44 44" fill="none">
      <rect x="8" y="10" width="28" height="4" fill="#B5B5B5" />
      <rect x="8" y="20" width="28" height="4" fill="#B5B5B5" />
      <rect x="8" y="30" width="28" height="4" fill="#B5B5B5" />
    </Icon>
  );
}

export default ListTypeIcon;
