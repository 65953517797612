import { IVmAnswer, IVmNewAnswerModel } from '@typed/answer.types';
import { ApiResponse } from '@typed/http.types';
import axios, { AxiosResponse } from 'axios';

async function addAnswer(data: IVmNewAnswerModel) {
  return axios.post('/answers', data);
}

async function getAnswersOfQuestion(questionId: string): Promise<IVmAnswer> {
  return axios.get(`/answers/question/${questionId}`)
    .then((response: AxiosResponse<ApiResponse<IVmAnswer>>) => response.data.result);
}

async function deleteAnswer(answerId: string) {
  return axios.delete(`/answers/${answerId}`);
}

async function getAnswerDetail(answerId: string): Promise<IVmAnswer> {
  return axios.get(`answers/${answerId}`)
    .then((response: AxiosResponse<ApiResponse<IVmAnswer>>) => response.data.result);
}

export {
  addAnswer,
  getAnswersOfQuestion,
  deleteAnswer,
  getAnswerDetail,
};
