import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { getAllMedias } from '@apis/media.api';
import { toast } from 'react-toastify';
import Fuse from 'fuse.js';
import useFilterSearch from '@hooks/useFilterSearch';
import { MediaFilterType } from '../types/media-filter';
import { Media } from '../types/media';

interface UseMediaLibrary {
  filterType: MediaFilterType;
  isLoadingMedia: boolean;
  medias: Media[][];
  flattenMedias: Media[];
  total: number;
  itemCountPerRow: number;
  handleKeywordChange: (newKeyword: string) => void;
  setFilterType: Dispatch<SetStateAction<MediaFilterType>>;
  handleUploadSuccess: (file: Media) => Promise<void>;
  handleRefresh: () => void;
  handleDeleteSuccess: (ids: string[]) => void;
}

function getItemsPerRowBasedOnWidth(containerWidth: number): number {
  if (containerWidth <= 500) return 1;
  if (containerWidth <= 800) return 2;
  if (containerWidth <= 1000) return 3;
  if (containerWidth <= 1200) return 4;
  if (containerWidth <= 1400) return 4;
  return 5;
}

function getContainerSizeName(containerWidth: number): string {
  if (containerWidth <= 500) return 's-less-500';
  if (containerWidth <= 800) return 's-less-800';
  if (containerWidth <= 1200) return 's-less-1200';
  if (containerWidth <= 1400) return 's-less-1400';
  return 's-more-1400';
}

function useMediaLibrary(brandId: string, container: HTMLDivElement | null, type: 'image' | 'video' | 'both' = 'both'): UseMediaLibrary {
  const containerSizeNameRef = useRef<string | null>(null);
  const [currentMediaFilterType, setCurrentMediaFilterType] = useState<MediaFilterType>('all');
  const [flattenMedias, setFlattenMedias] = useState<Media[]>([]);
  const [medias, setMedias] = useState<Media[][]>([]);
  const [itemCountPerRow, setItemCountPerRow] = useState<number>(5);
  const {
    keyword,
    onKeywordChange,
  } = useFilterSearch();
  const {
    isOpen: isLoadingMedia,
    onOpen: startLoadingMedia,
    onClose: finishLoadingMedia,
  } = useDisclosure({ defaultIsOpen: true });

  const fuse = useMemo<Fuse<Media>>(() => new Fuse(flattenMedias, { keys: ['name'] }), [flattenMedias]);

  function calculateItemPerRow(flattenMediasList: Media[], itemsPerRow: number) {
    const listMedias = [...flattenMediasList];
    if (listMedias.length === 0) return [];
    const totalRow = Math.ceil(listMedias.length / itemsPerRow);
    if (totalRow > 1) {
      const result = [];
      for (let i = 0; i < totalRow; i += 1) {
        result.push(listMedias.slice(i * itemsPerRow, (i + 1) * itemsPerRow));
      }
      return result;
    }
    return [listMedias];
  }

  const loadMedias = useCallback(async () => {
    if (!container) return;
    try {
      startLoadingMedia();
      const result = await getAllMedias(brandId);
      if (type === 'both') {
        setFlattenMedias(result);
      }
      if (type === 'image') {
        setFlattenMedias(result.filter((media) => !!media.imageUrl));
      }
      if (type === 'video') {
        setFlattenMedias(result.filter((media) => !!media.videoToken));
      }
    } catch (error: any) {
      toast.error(error?.message);
      finishLoadingMedia();
    }
  }, [container, startLoadingMedia, brandId, type, finishLoadingMedia]);

  const handleCalculateData = useCallback(async () => {
    if (!container || !flattenMedias.length) {
      setMedias([]);
      finishLoadingMedia();
      return;
    }
    startLoadingMedia();
    const itemsPerRow = getItemsPerRowBasedOnWidth(container.offsetWidth);
    let listMedias = currentMediaFilterType === 'all' ? flattenMedias : flattenMedias.filter((media) => media.type === currentMediaFilterType);
    if (keyword) {
      listMedias = fuse.search(keyword).map((item) => item.item);
    }
    const data = calculateItemPerRow(listMedias, itemsPerRow);
    setItemCountPerRow(itemsPerRow);
    setMedias(data);
    finishLoadingMedia();
  }, [
    container,
    flattenMedias,
    startLoadingMedia,
    currentMediaFilterType,
    keyword,
    finishLoadingMedia,
    fuse,
  ]);

  const handleResize = useCallback(() => {
    if (!container) return;
    const containerSizeName = getContainerSizeName(container.offsetWidth);
    if (containerSizeName !== containerSizeNameRef.current) {
      handleCalculateData();
      containerSizeNameRef.current = containerSizeName;
    }
  }, [container, handleCalculateData]);

  const handleUploadSuccess = useCallback(async (file: Media) => {
    setFlattenMedias((currentFlattenMedias) => ([
      file,
      ...currentFlattenMedias,
    ]));
  }, []);

  const handleDeleteSuccess = useCallback((ids: string[]) => {
    setFlattenMedias((currentFlattenMedias) => (
      currentFlattenMedias.filter((media) => !ids.includes(media.id))
    ));
  }, []);

  const totalMedia = useMemo(() => flattenMedias.length, [flattenMedias]);

  useEffect(() => {
    handleCalculateData();
  }, [handleCalculateData]);

  useEffect(() => {
    loadMedias();
  }, [loadMedias]);

  useEffect(() => {
    if (containerSizeNameRef.current === null && container?.offsetWidth) {
      containerSizeNameRef.current = getContainerSizeName(container.offsetWidth);
    }
  }, [containerSizeNameRef, container]);

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
    return () => {
      window.removeEventListener('resize', handleResize, false);
    };
  }, [handleResize]);

  return {
    itemCountPerRow,
    filterType: currentMediaFilterType,
    isLoadingMedia,
    setFilterType: setCurrentMediaFilterType,
    medias,
    flattenMedias,
    total: totalMedia,
    handleKeywordChange: onKeywordChange,
    handleUploadSuccess,
    handleRefresh: loadMedias,
    handleDeleteSuccess,
  };
}

export default useMediaLibrary;
