import { useConst, useDisclosure } from '@chakra-ui/react';
import { useVmStore } from '@store/index';
import { customerIdSelector } from '@store/slices/customer/selectors';
import VMFormControl from '@modules/Forms/forms/VMFormControl';
import { IFormValue } from '@modules/Forms/forms/VMFormControl/types/form-value';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { createForm } from '@apis/form.api';
import { useNavigate, useParams } from 'react-router-dom';
import useHistoryRoute from '@hooks/useHistoryRoute';

function FormAdding() {
  const { folderId } = useParams<'folderId'>();
  const navigate = useNavigate();
  const { backRoute } = useHistoryRoute('/forms');
  const brandId = useVmStore((state) => state.selectedBrandId);
  const customerId = useVmStore(customerIdSelector);
  const {
    isOpen: isAddingForm,
    onOpen: startAddingForm,
    onClose: finishUpdatingForm,
  } = useDisclosure();
  const defaultValues = useConst<IFormValue>(() => ({
    brandId: brandId!,
    customerId,
    name: 'New form',
    folderId: folderId ?? null,
    intro: {
      title: 'Ask a mentor!',
      text: "Do you have unanswered questions? Don't hesitate and ask your mentor via personalized video or text your question :)",
      enableVideoQuestion: true,
      enableTextQuestion: false,
      showVideoMessage: false,
      videoMessageText: 'asdasdasdasd',
      videoMessageToken: null,
    },
    question: {
      titleVideoQuestion: 'Record a video question',
      textVideoQuestion: 'Make sure your mic is working Choose good lighting ',
      titleTextQuestion: 'Ask your question using text form',
      textTextQuestion: 'What are the things you are not confident about Ask whatever you are curious about',
    },
    personalData: {
      title: 'Your name',
      placeholder: 'Please type in the name',
      email: 'Email',
      emailPlaceholder: 'johnwave@personal.com',
      requireConsent: false,
      consentText: 'I give consent to share my testimonial publicly and use it for any marketing purposes.',
    },
    thankYou: {
      title: 'Thank you!',
      text: 'Thank you so much for you question! We will get you an answer as quick as we can. Stay tuned and check your email :)',
      redirect: false,
      redirectUrl: null,
    },
    advanced: {
      validationMessages: {
        requiredMessage: 'This field is required',
        emailMessage: 'Email is invalid',
      },
    },
  }));

  const onSubmit = useCallback(async (values: IFormValue) => {
    try {
      startAddingForm();
      await createForm(values);
      finishUpdatingForm();
      navigate(backRoute);
    } catch (error: any) {
      toast.error(error?.message);
      finishUpdatingForm();
    }
  }, [backRoute, finishUpdatingForm, navigate, startAddingForm]);

  return (
    <VMFormControl defaultValues={defaultValues} onSubmitForm={onSubmit} isLoading={isAddingForm} />
  );
}

export default FormAdding;
