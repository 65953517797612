import QuestionAskResponseType from '@modules/Question/components/QuestionAskResponseType';
import { IVmQuestion } from '@typed/question.types';
import { useState } from 'react';
import AnswerRecording from './parts/AnswerRecording';

type AnswerType = 'recording' | 'screensharing';

interface Props {
  question: IVmQuestion;
  onRefetch: () => Promise<void>;
}

function Answer({
  question,
  onRefetch,
}: Props) {
  const [activeAnswerType, setActiveAnswerType] = useState<AnswerType | null>();

  if (activeAnswerType === 'recording') {
    return (
      <AnswerRecording
        questionId={question.questionId}
        customerId={question.customerId}
        brandId={question.brandId}
        formId={question.formId}
        onRefetch={onRefetch}
      />
    );
  }

  return (
    <QuestionAskResponseType onSwitchToRecord={() => setActiveAnswerType('recording')} />
  );
}

export default Answer;
