import { Box, Flex } from '@chakra-ui/react';
import TabLink from '@components/TabLink';
import { useTranslation } from 'react-i18next';

function VMControlSidebar() {
  const { t } = useTranslation();
  return (
    <Flex py="40px" direction="column" px="20px" gap="40px" borderRightWidth="1px" borderColor="vmGray.1000">
      <Box>
        <Box fontSize="16px" lineHeight="24px" fontWeight={500} color="vmGray.950" mb="10px" textTransform="uppercase">{t('forms.labels.steps')}</Box>
        <Flex align="center" direction="column" gap="10px">
          <TabLink to="" title={t('forms.labels.introductory_page')} />
          <TabLink to="question" title={t('forms.labels.questioner_page')} />
          <TabLink to="personal" title={t('forms.labels.personal_data')} />
          <TabLink to="thank-you" title={t('forms.labels.thank_you')} />
        </Flex>
      </Box>
      <Box>
        <Box fontSize="16px" lineHeight="24px" fontWeight={500} color="vmGray.950" mb="10px" textTransform="uppercase">{t('forms.labels.settings')}</Box>
        <Flex align="center" direction="column" gap="10px">
          {/* <TabLink to="logo-and-colors" title={t('forms.labels.logo_and_colors')} /> */}
          <TabLink to="advanced" title={t('forms.labels.advanced')} />
        </Flex>
      </Box>
    </Flex>
  );
}

export default VMControlSidebar;
