import {
  Box,
  Flex,
  useConst,
  useDisclosure,
} from '@chakra-ui/react';
import AddIcon from '@components/icons/Add';
import { IFolderFormValue } from '@modules/Forms/types/folder.types';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useVmStore } from '@store/index';
import VmFolderControl from '@modules/Forms/forms/VmFolderControl';

function VmFolderAdding() {
  const { t } = useTranslation();
  const createFolder = useVmStore((state) => state.addFolder);
  const loadFolder = useVmStore((state) => state.loadFolders);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const defaultValues = useConst<Pick<IFolderFormValue, 'name'>>(() => ({ name: '' }));
  const {
    isOpen: isLoading,
    onOpen: startLoading,
    onClose: finishLoading,
  } = useDisclosure();
  const onSubmit = useCallback(async (values: IFolderFormValue) => {
    try {
      startLoading();
      await createFolder(values.name);
      await loadFolder();
      toast.success('A folder has been created successfully');
      onClose();
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishLoading();
  }, [createFolder, finishLoading, loadFolder, onClose, startLoading]);

  return (
    <>
      <Flex role="button" align="center" justify="center" minW="192px" borderWidth="1px" borderColor="vmGray.900" borderRadius="10px" py="14px" px="23px" bg="vmWhite" gap="10px" onClick={onOpen}>
        <AddIcon stroke="vmPrimary.50" />
        <Box fontSize="16px" lineHeight="24px" color="vmPrimary.50" fontWeight={600}>{t('common.texts.create_folder')}</Box>
      </Flex>
      <VmFolderControl
        isLoading={isLoading}
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
      />
    </>
  );
}

export default VmFolderAdding;
