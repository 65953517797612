import { Icon, IconProps } from '@chakra-ui/react';

function FilterIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" fill="none" stroke="vmBlack.100" strokeWidth="2" {...props}>
      <path
        d="M1.94898 1.5H18.0552C18.2007 1.50005 18.343 1.5424 18.4649 1.62191C18.5867 1.70141 18.6828 1.81463 18.7414 1.94777C18.8 2.08092 18.8187 2.22824 18.795 2.37179C18.7714 2.51533 18.7066 2.64891 18.6084 2.75625L12.449 9.53437C12.3208 9.67159 12.2503 9.85286 12.2521 10.0406V15.3469C12.2533 15.4715 12.2229 15.5944 12.1639 15.7042C12.1049 15.814 12.0192 15.9072 11.9146 15.975L8.91461 17.9719C8.80207 18.046 8.67165 18.0884 8.53704 18.0947C8.40244 18.101 8.26863 18.0709 8.14968 18.0076C8.03073 17.9443 7.93103 17.8501 7.86107 17.7349C7.79111 17.6198 7.75347 17.4879 7.75211 17.3531V10.0406C7.75391 9.85286 7.68342 9.67159 7.55523 9.53437L1.39586 2.75625C1.29766 2.64891 1.2328 2.51533 1.20917 2.37179C1.18555 2.22824 1.20419 2.08092 1.26281 1.94777C1.32143 1.81463 1.41751 1.70141 1.53934 1.62191C1.66118 1.5424 1.8035 1.50005 1.94898 1.5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default FilterIcon;
