import { memo, useCallback } from 'react';
import {
  Box,
  Flex,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import DeleteIcon from '@components/icons/ml/Delete';
import { useTranslation } from 'react-i18next';
import WarningConfirm from '@components/WarningConfirm';

interface Props {
  isDeleting: boolean;
  onDeleteVideo: () => void;
  isDisabled?: boolean;
  buttonText?: string;
  confirmMessage?: string;
}

function BottomBarDeleteSelected({
  isDeleting,
  isDisabled,
  onDeleteVideo,
  buttonText,
  confirmMessage,
}: Props) {
  const { t } = useTranslation();
  const {
    isOpen,
    onClose,
    onOpen,
  } = useDisclosure();

  const handleDeleteVideo = useCallback(() => {
    onClose();
    onDeleteVideo();
  }, [onDeleteVideo, onClose]);

  const handleOpenConfirmModal = useCallback(() => {
    if (isDisabled) return;
    onOpen();
  }, [isDisabled, onOpen]);

  return (
    <>
      {isDeleting ? (
        <Box><Spinner fontSize="17px" /></Box>
      ) : (
        <Flex align="center" cursor="pointer" gap="10px" onClick={handleOpenConfirmModal} opacity={isDisabled ? 0.6 : 1}>
          <DeleteIcon stroke="vmRed.50" />
          <Box
            as="span"
            fontSize="vmNormal"
            fontWeight={600}
            lineHeight="24px"
            color="vmRed.50"
            textTransform="uppercase"
            whiteSpace="nowrap"
          >
            {t(buttonText!)}
          </Box>
        </Flex>
      )}
      <WarningConfirm
        isOpen={isOpen}
        onConfirm={handleDeleteVideo}
        onClose={onClose}
        confirmTitle={t('common.texts.caution')}
        confirmDescription={t(confirmMessage!)}
      />
    </>
  );
}

BottomBarDeleteSelected.defaultProps = {
  isDisabled: false,
  buttonText: 'ml.labels.delete_video',
  confirmMessage: 'ml.message.delete_confirm',
};

export default memo(BottomBarDeleteSelected);
