import {
  Box,
  Button,
  Flex,
} from '@chakra-ui/react';
import { memo, useRef } from 'react';
import { VideoEditorScene } from '@modules/VideoEditor/types/video-editor.types';
import { generateTimeByMilisecond } from '@utils/timer.util';
import { useNavigate } from 'react-router-dom';
import { isNil } from '@utils/compare.util';
import { useDrag, useDrop } from 'react-dnd';
import ImageIcon from '@components/icons/ve/Image';
import EditIcon from '@components/icons/Edit';
import TrashIcon from '@components/icons/Trash';
import DragIcon from '@components/icons/ve/Drag';
import CameraSelectorIcon from '@components/TrustRecorder/icons/CameraSelector';
import SceneDurationWarning from './SceneDurationWarning';

interface Props {
  isActive: boolean;
  scene: VideoEditorScene;
  sceneIndex: number;
  allowDrag: boolean;
  onChangeScenePosition: (sceneIndex: number, targetIndex: number) => void;
  onDelete: (sceneId: string) => void;
  isIntro?: boolean;
  isOutro?: boolean;
}

interface DragItem {
  index: number;
}

function Scene({
  isActive,
  scene,
  sceneIndex,
  allowDrag,
  onChangeScenePosition,
  onDelete,
  isIntro,
  isOutro,
}: Props) {
  const navigate = useNavigate();
  const isImage = !!scene.imageUrl;
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: string | symbol | null }
  >({
    accept: 'video-editor-scene',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = sceneIndex;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as { x: number; y: number }).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      onChangeScenePosition(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  }, [sceneIndex]);

  const [{ isDragging }, drag] = useDrag({
    type: 'video-editor-scene',
    item: () => ({ index: sceneIndex }),
    canDrag: allowDrag,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  }, [sceneIndex]);

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Flex
      ref={ref}
      align="center"
      justify="space-between"
      opacity={opacity}
      data-handler-id={handlerId}
      _hover={{
        bg: 'trWhite',
        borderColor: 'vmGreen.50',
        '& button': {
          opacity: 1,
        },
        '& .video-editor-camera-icon': {
          fill: 'vmPrimary.50',
        },
      }}
      py="18px"
      pl="10px"
      pr="20px"
      bg={isActive ? 'trWhite' : 'vmGray.1050'}
      borderRadius="10px"
      borderColor={isActive ? 'vmGreen.50' : 'vmGray.700'}
      borderWidth="2px"
      cursor="pointer"
      onClick={() => {
        if (isIntro) {
          navigate('./intro');
          return;
        }
        if (isOutro) {
          navigate('./outro');
          return;
        }
        navigate(`./${scene.id}`);
      }}
    >
      <Flex align="center" gap="20px">
        <DragIcon />
        <Box
          w="110px"
          h="64px"
          borderRadius="4px"
          bgImage={isImage ? scene.imageUrl! : `https://${scene.videoImageUrl}`}
          bgSize="cover"
          bgPos="center center"
        />
        <Flex direction="column" gap="21px">
          <Flex align="center" gap="10px">
            {isImage ? (
              <ImageIcon className="video-editor-camera-icon" fill={isActive ? 'vmPrimary.50' : 'vmGray.950'} fontSize="22px" />
            ) : (
              <CameraSelectorIcon className="video-editor-camera-icon" fill={isActive ? 'vmPrimary.50' : 'vmGray.950'} fontSize="22px" />
            )}
            {(isIntro || isOutro) && (
              <Box px="8px" py="1px" borderRadius="4px" color="trWhite" bg="vmPrimary.50" fontSize="15px" fontWeight={400} lineHeight="22.5px">
                {isIntro ? 'Intro' : 'Outro'}
              </Box>
            )}
          </Flex>
          {!isImage && !isNil<number | null>(scene.videoDuration) && scene.videoDuration !== 0 ? (
            <Box
              fontSize="15px"
              fontWeight={400}
              lineHeight="22.5px"
              color="vmBlack.50"
            >
              {generateTimeByMilisecond((scene.videoDuration ?? 0) * 1000)}
            </Box>
          ) : (
            <SceneDurationWarning scene={scene} />
          )}
        </Flex>
      </Flex>
      <Flex align="center" gap="10px">
        {(isIntro || isOutro || isImage) && (
          <Button
            type="button"
            variant="iconOnly"
            size="iconOnly"
            opacity={Number(isActive)}
            onClick={(event) => {
              event.stopPropagation();
              if (isOutro) {
                navigate('../outro');
                return;
              }
              if (isIntro) {
                navigate('../intro');
                return;
              }

              if (isImage) {
                navigate(`../image/${scene.id}`);
              }
            }}
          >
            <EditIcon />
          </Button>
        )}
        <Button
          type="button"
          variant="iconOnly"
          size="iconOnly"
          opacity={Number(isActive)}
          onClick={(event) => {
            event.stopPropagation();
            onDelete(scene.id);
          }}
        >
          <TrashIcon stroke="vmRed.50" />
        </Button>
      </Flex>
    </Flex>
  );
}

Scene.defaultProps = {
  isIntro: false,
  isOutro: false,
};

export default memo(Scene);
