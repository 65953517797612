import i18n from 'i18next';
import backend from 'i18next-xhr-backend';

import translationEn from '../locales/en.json';

const resources = {
  en: {
    translation: translationEn,
  },
};

i18n.use(backend)
  .init({
    resources,
    lng: 'en',
    returnNull: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
