import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import throttle from 'lodash.throttle';
import { generateTimeByMilisecond } from '@utils/timer.util';

interface UseVideoEditorPlayProgress {
  handleUpdatePosition: (currentPlayerPosition: number) => void;
  handleCalculateStartPlayPoint: () => void;
}

function useVideoEditorPlayProgress(
  progressElement: HTMLDivElement | null,
  progressWrapper: HTMLDivElement | null,
  playedCountElement: HTMLDivElement | null,
  timeLeftElement: HTMLDivElement | null,
  player: any,
  initPosition: number,
  isPlaying: boolean,
  startTime: number,
  endTime: number,
  collapseMode: boolean,
): UseVideoEditorPlayProgress {
  const frameRef = useRef<number>();
  const duration = (endTime || player.get('duration')) - startTime;

  const handleCalculateStartPlayPoint = useCallback(() => {
    if (!progressElement || !progressWrapper) return;
    const totalDuration = player.get('duration');
    const widthPerSecond = progressWrapper.clientWidth / totalDuration;
    progressElement.style.left = `${widthPerSecond * startTime}px`;
    progressElement.style.width = '0px';
  }, [progressElement, progressWrapper, player, startTime]);

  const handleUpdatePosition = useCallback((currentPosition: number) => {
    if (!progressWrapper) return;
    const position = Number.isNaN(currentPosition) ? 0 : currentPosition;
    const wrapperWidth = progressWrapper.clientWidth;
    const widthPerSecond = wrapperWidth / (collapseMode ? duration : player.get('duration'));
    let percent = Number((widthPerSecond * position).toFixed(2));
    if (!collapseMode && progressWrapper) {
      const progressRangeWidth = widthPerSecond * duration;
      percent = Number(((position / duration) * progressRangeWidth).toFixed(2));
    }
    if (progressElement) {
      progressElement.style.width = `${Number(percent > wrapperWidth ? wrapperWidth : percent)}px`;
    }
    if (playedCountElement) {
      playedCountElement.textContent = `${generateTimeByMilisecond(Math.ceil(position * 1000))}`;
    }
    if (timeLeftElement) {
      const left = duration - position;
      timeLeftElement.textContent = `- ${generateTimeByMilisecond(Math.ceil((left >= 0 ? left : 0) * 1000))}`;
    }
  }, [
    progressWrapper,
    collapseMode,
    duration,
    player,
    progressElement,
    playedCountElement,
    timeLeftElement,
  ]);

  const throttled = useMemo(() => throttle(() => {
    const currentPosition = player.getCurrentPosition();
    if (currentPosition > (duration + startTime)) {
      player.stop();
      window.cancelAnimationFrame(frameRef.current as number);
      throttled.cancel();
      return;
    }
    if (currentPosition === (duration + startTime)) {
      window.cancelAnimationFrame(frameRef.current as number);
      throttled.cancel();
    }
    try {
      handleUpdatePosition(currentPosition - startTime);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error when handling the requestAnimationFrame');
    }
    frameRef.current = window.requestAnimationFrame(throttled);
  }, 10), [handleUpdatePosition, duration, player, startTime]);

  useEffect(() => {
    if (player && isPlaying) {
      const currentPosition = player.getCurrentPosition();
      const isPlayingInLeftPosition = currentPosition <= startTime;
      if (isPlayingInLeftPosition) {
        player.seek(startTime);
      }
      const currentInitPosition = currentPosition - startTime;
      handleUpdatePosition(currentInitPosition >= 0 ? currentInitPosition : 0);
      frameRef.current = window.requestAnimationFrame(() => throttled());
      return () => {
        window.cancelAnimationFrame(frameRef.current as number);
        throttled.cancel();
      };
    }

    return () => {
      throttled.cancel();
    };
  }, [throttled, initPosition, isPlaying, handleUpdatePosition, endTime, startTime, player]);

  useEffect(() => {
    if (player) {
      player.on('ended', () => {
        handleUpdatePosition(duration);
      });
    }
  }, [player, handleUpdatePosition, duration]);

  return {
    handleUpdatePosition,
    handleCalculateStartPlayPoint,
  };
}

export default useVideoEditorPlayProgress;
