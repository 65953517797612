import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Plan } from '../types/plan.types';

function usePlan(): Plan[] {
  const { t } = useTranslation();

  const plans = useMemo<Plan[]>(() => [
    {
      key: 'starter',
      title: t('plan.list.starter'),
      name: 'Starter',
      icon: 'starter',
      isFree: false,
      yearly: {
        price: 16,
        month_for_free: 2,
        productId: 749913,
      },
      monthly: {
        price: 19,
        month_for_free: 0,
        productId: 749912,
      },
      benefits: [
        { title: t('plan.benefits.website', { countValue: 1, last: '' }), included: true },
        { title: t('plan.benefits.visitor', { countValue: 5000 }), included: true },
        { title: t('plan.benefits.video_hd'), included: false },
        { title: t('plan.benefits.branding_removal'), included: false },
        { title: t('plan.benefits.video_editor'), included: false },
        { title: t('plan.benefits.videp_subtitles'), included: false },
        { title: t('plan.benefits.brands', { countValue: 1, last: '' }), included: true },
      ],
    },
    {
      key: 'ground',
      title: t('plan.list.off_the_ground'),
      name: 'Off the Ground',
      icon: 'ground',
      isFree: false,
      yearly: {
        price: 41,
        month_for_free: 2,
        productId: 576405,
      },
      monthly: {
        price: 49,
        month_for_free: 0,
        productId: 576404,
      },
      benefits: [
        { title: t('plan.benefits.website', { countValue: 5, last: 's' }), included: true },
        { title: t('plan.benefits.visitor', { countValue: 10000 }), included: true },
        { title: t('plan.benefits.video_hd'), included: true },
        { title: t('plan.benefits.branding_removal'), included: true },
        { title: t('plan.benefits.video_editor'), included: false },
        { title: t('plan.benefits.videp_subtitles'), included: false },
        { title: t('plan.benefits.brands', { countValue: 5, last: 's' }), included: true },
      ],
    },
    {
      key: 'business',
      title: t('plan.list.business'),
      name: 'Business',
      icon: 'business',
      isFree: false,
      yearly: {
        price: 83,
        month_for_free: 2,
        productId: 576407,
      },
      monthly: {
        price: 99,
        month_for_free: 0,
        productId: 576406,
      },
      benefits: [
        { title: t('plan.benefits.website', { countValue: 10, last: 's' }), included: true },
        { title: t('plan.benefits.visitor', { countValue: 100000 }), included: true },
        { title: t('plan.benefits.video_hd'), included: true },
        { title: t('plan.benefits.branding_removal'), included: true },
        { title: t('plan.benefits.video_editor'), included: true },
        { title: t('plan.benefits.videp_subtitles'), included: true },
        { title: t('plan.benefits.brands', { countValue: '∞', last: 's' }), included: true },
      ],
    },
  ], [t]);

  return plans;
}

export default usePlan;
