import { memo, useCallback } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import useToastMessage from '@hooks/useToastMessage';
import ErrorMessage from '@components/ErrorMessage';

interface FormValues {
  name: string;
}

interface Props {
  onRenderVideo: (name: string) => Promise<void>;
  onGoNext: () => void;
  onClose: () => void;
  defaultName: string;
}

function VideoRenderInfo({
  onRenderVideo,
  onGoNext,
  onClose,
  defaultName,
}: Props) {
  const { t } = useTranslation();
  const { notify } = useToastMessage();
  const {
    isOpen: isRenderingVideo,
    onClose: finishRenderingVideo,
    onOpen: startRenderingVideo,
  } = useDisclosure();
  const {
    control,
    handleSubmit,
    register,
  } = useForm<FormValues>({
    defaultValues: {
      name: t('video_editor.labels.new_video_default', { videoNum: defaultName }),
    },
    resolver: yupResolver(yup.object({
      name: yup.string().required(t('common.validation.required')),
    })),
  });
  const { errors } = useFormState({ control });

  const onSubmit = useCallback(async (values: FormValues) => {
    try {
      startRenderingVideo();
      await onRenderVideo(values.name);
      onGoNext();
      notify('success', t('video_editor.messages.video_start_render_ok'));
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishRenderingVideo();
  }, [finishRenderingVideo, startRenderingVideo, onRenderVideo, onGoNext, notify, t]);

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)} w="400px" maxW="full" mx="auto">
      <FormControl isInvalid={!!errors?.name?.message}>
        <FormLabel>
          {t('video_editor.labels.video_title')}
          <Box as="span" color="vmPrimary.50" ml="2px">*</Box>
        </FormLabel>
        <Input type="text" placeholder={t('video_editor.placeholders.video_title')} {...register('name')} />
        <ErrorMessage error={errors?.name?.message} />
      </FormControl>
      <Flex align="center" justify="center" gap="20px" mt="20px">
        <Button type="button" variant="solidPrimary" isDisabled={isRenderingVideo} onClick={() => onClose()}>{t('common.button.texts.cancel')}</Button>
        <Button type="submit" isLoading={isRenderingVideo} isDisabled={isRenderingVideo}>{t('video_editor.labels.send_video')}</Button>
      </Flex>
    </Box>
  );
}

export default memo(VideoRenderInfo);
