import {
  RefObject,
  SyntheticEvent,
  useCallback,
  useRef,
} from 'react';
import { useDisclosure } from '@chakra-ui/react';
import debounce from 'lodash.debounce';

interface UseListAction {
  ref: RefObject<HTMLInputElement>,
  isOpen: boolean,
  onSearchChange: (event: SyntheticEvent<HTMLInputElement>) => void,
  handleReload: () => void,
  handleOpenSearchBox: () => void,
  handleCloseSearchBox: (emit?: boolean) => void,
}

function useListAction(
  defaultIsOpenSearch?: boolean,
  onKeywordChange?: (v: string) => void,
  onReload?: () => void,
): UseListAction {
  const ref = useRef<HTMLInputElement>(null);
  const {
    isOpen,
    onToggle,
    onClose,
  } = useDisclosure({ defaultIsOpen: Boolean(defaultIsOpenSearch ?? false) });

  const onSearchChange = useCallback(debounce((event: SyntheticEvent<HTMLInputElement>) => {
    event.persist();
    if (onKeywordChange && event.target instanceof HTMLInputElement) {
      onKeywordChange(event.target.value.trim());
    }
  }, 1000), [onKeywordChange]);

  const handleReload = useCallback(() => {
    if (onReload) {
      onReload();
    }
  }, [onReload]);

  const handleOpenSearchBox = useCallback(() => {
    onToggle();
    ref.current?.focus();
  }, [onToggle]);

  const handleCloseSearchBox = useCallback((emitChange = true) => {
    if (onKeywordChange && emitChange) {
      onKeywordChange('');
    }
    if (ref.current) {
      ref.current.value = '';
    }
    onClose();
  }, [onClose, onKeywordChange]);

  return {
    ref,
    isOpen,
    onSearchChange,
    handleReload,
    handleOpenSearchBox,
    handleCloseSearchBox,
  };
}

export default useListAction;
