import { getQuestion } from '@apis/question.api';
import { useDisclosure } from '@chakra-ui/react';
import { IVmQuestion } from '@typed/question.types';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

function useQuestionDetail(questionId: string) {
  const [question, setQuestion] = useState<IVmQuestion | null>(null);
  const {
    isOpen: isLoadingQuestion,
    onOpen: startLoadingQuestion,
    onClose: finishLoadingQuestion,
  } = useDisclosure({ defaultIsOpen: true });

  const getQuestionDetail = useCallback(async () => {
    try {
      startLoadingQuestion();
      const result = await getQuestion(questionId);
      setQuestion(result);
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishLoadingQuestion();
  }, [finishLoadingQuestion, questionId, startLoadingQuestion]);

  useEffect(() => {
    getQuestionDetail();
  }, [getQuestionDetail]);

  return {
    question,
    isLoadingQuestion,
  };
}

export default useQuestionDetail;
