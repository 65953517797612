import { Icon, IconProps } from '@chakra-ui/react';

function BackIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 10 16" stroke="vmGray.50" fill="none" {...props}>
      <path
        d="M8.5 1L1.5 8L8.5 15"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default BackIcon;
