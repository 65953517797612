import axios, { AxiosResponse } from 'axios';
import { ApiResponse } from '@typed/http.types';
import { Brand } from '@modules/Branding/types/brand.types';
import { BrandFormValues } from '@modules/Branding/types/brand-form.types';

async function createBrand(customerId: string, brand: BrandFormValues): Promise<Brand[]> {
  return axios.post(`/brands/${customerId}`, brand)
    .then((response: AxiosResponse<ApiResponse<Brand[]>>) => response.data.result);
}

async function getAlLBrands(customerId: string): Promise<Brand[]> {
  return axios.get(`/brands/${customerId}`)
    .then((response: AxiosResponse<ApiResponse<Brand[]>>) => response.data.result);
}

async function removeBrand(brandId: string) {
  return axios.delete(`/brands/${brandId}`);
}

async function updateBrandData(brandId: string, brand: BrandFormValues) {
  return axios.put(`/brands/${brandId}`, brand);
}

export {
  createBrand,
  getAlLBrands,
  removeBrand,
  updateBrandData,
};
