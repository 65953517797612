import { Box } from '@chakra-ui/react';

interface Props {
  title: string;
}

function SidebarMenu({ title }: Props) {
  return (
    <Box
      py="8px"
      px="6px"
      w="96px"
      h="64px"
      cursor="pointer"
      borderWidth="2px"
      borderColor="transparent"
      textAlign="center"
      _hover={{
        borderColor: 'vmGray.250',
      }}
    >
      <Box w="24px" h="24px" bg="vmGray.300" borderRadius="100%" mx="auto" />
      <Box mt="5px" fontSize="13px" fontWeight={400} lineHeight="19.5px" color="vmBlack.100">
        {title}
      </Box>
    </Box>
  );
}

export default SidebarMenu;
