import {
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Button,
  Flex,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import { getListRenderedVideos, renderVideo } from '@apis/rendered-videos.api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { makeNumberWithZeroAsPrefix } from '@utils/number.util';
import { ExclaimationIcon } from '@components/icons/ve';
import VideoRenderProgress from '../VideoRenderProgress/VideoRenderProgress';

interface Props {
  answerId: string;
  onClose: (redirect?: boolean) => void;
  onRenderVideoPreProcess: () => Promise<void>;
  onSendSuccess: () => Promise<void>;
}

function VideoEditorSendProgress({
  answerId,
  onClose,
  onRenderVideoPreProcess,
  onSendSuccess,
}: Props) {
  const { t } = useTranslation();
  const [needToDeleteRenderedVideo, setNeedToDeleteRenderedVideo] = useState<boolean>(false);
  const [totalRenderedVideo, setTotalRenderedVideo] = useState<number>(0);
  const {
    isOpen: isLoadingRenderedVideo,
    onClose: finishLoadingRenderedVideo,
  } = useDisclosure({ defaultIsOpen: true });

  const handleRenderVideo = useCallback(async (name: string) => (
    onRenderVideoPreProcess()
      .then(() => renderVideo(answerId, name))
      .then(onSendSuccess)
  ), [onRenderVideoPreProcess, onSendSuccess, answerId]);

  const handleLoadRenderedVideo = useCallback(async () => {
    try {
      const result = await getListRenderedVideos(answerId);
      setTotalRenderedVideo(result.length);
      setNeedToDeleteRenderedVideo(result.length >= 1);
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishLoadingRenderedVideo();
  }, [finishLoadingRenderedVideo, setNeedToDeleteRenderedVideo, answerId]);

  useEffect(() => {
    handleLoadRenderedVideo();
  }, [handleLoadRenderedVideo]);

  if (isLoadingRenderedVideo) {
    return (
      <Flex align="center" justify="center">
        <Spinner />
      </Flex>
    );
  }

  if (needToDeleteRenderedVideo) {
    return (
      <Box>
        <Box textAlign="center" mb="20px">
          <Flex m="0 auto" w="44px" h="44px" bg="trOrange.50" borderRadius="10px" align="center" justify="center">
            <ExclaimationIcon fontSize="24px" />
          </Flex>
        </Box>
        <Box
          as="h2"
          fontWeight="600"
          fontSize="24px"
          lineHeight="31px"
          color="trOrange.50"
          textAlign="center"
          mb="20px"
        >
          {t('common.texts.caution')}
        </Box>
        <Box mb="40px" fontSize="trNormal" color="vmBlack.50" textAlign="center">{t('video_editor.messages.have_max_video_already')}</Box>
        <Flex gap="20px" justify="center" align="center">
          <Button variant="solidPrimary" type="button" onClick={() => onClose()}>{t('common.button.texts.close')}</Button>
        </Flex>
      </Box>
    );
  }

  return (
    <VideoRenderProgress
      onClose={onClose}
      onRenderVideo={handleRenderVideo}
      defaultName={makeNumberWithZeroAsPrefix(totalRenderedVideo + 1)}
    />
  );
}

export default memo(VideoEditorSendProgress);
