import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

interface UseFilterSearch {
  keyword: string;
  onKeywordChange: (keyword: string) => void;
}

function useFilterSearch(): UseFilterSearch {
  const [searchParams, setSearchParams] = useSearchParams();

  const setKeyword = useCallback((value: string) => {
    const newSerchParams = new URLSearchParams(searchParams);
    newSerchParams.set('keyword', value);
    if (value === '') {
      newSerchParams.delete('source');
    }
    setSearchParams(newSerchParams);
  }, [setSearchParams, searchParams]);

  const keyword = searchParams.get('keyword') || '';

  return {
    keyword,
    onKeywordChange: setKeyword,
  };
}

export default useFilterSearch;
