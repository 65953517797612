import { Icon, IconProps } from '@chakra-ui/react';

function ArrowDropIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 14 8" fill="none" stroke="vmRed.100" strokeWidth="1.5" {...props}>
      <path
        d="M1 1L7 7L13 1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default ArrowDropIcon;
