import { Box } from '@chakra-ui/react';
import { IVmQuestion } from '@typed/question.types';

interface Props {
  question: IVmQuestion;
}

function QuestionTextView({
  question,
}: Props) {
  return (
    <Box fontWeight={400} fontSize="16px" lineHeight="24px" color="vmBlack.50">
      {question.text}
    </Box>
  );
}

export default QuestionTextView;
