import { memo, useCallback, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import useMediaLibrary from '@shared/MediaLibrary/hooks/useMediaLibrary';
import useSelectedMediaFiles from '@shared/MediaLibrary/hooks/useSelectedMediaFiles';
import MediaLibrary from '@shared/MediaLibrary/MediaLibrary';
import useElementRef from '@hooks/useElementRef';
import { useTranslation } from 'react-i18next';
import useDeleteMedia from '@shared/MediaLibrary/hooks/useDeleteMedia';
import { Media } from '@shared/MediaLibrary/types/media';
import BottomBarDeleteSelected from '@shared/MediaLibrary/components/BottomBarDeleteSelected';
import CheckIcon from '@components/icons/Check';

interface Props {
  brandId: string;
  onClose: () => void;
  onSelectFile: (file: Media) => void;
  selectedId: string | null;
  specificType?: 'image' | 'video' | 'both';
}

function MediaLibrarySelector({
  brandId,
  onClose,
  onSelectFile,
  selectedId,
  specificType,
}: Props) {
  const { t } = useTranslation();
  const [containerRef, containerElement] = useElementRef<HTMLDivElement>();
  const {
    medias,
    flattenMedias,
    handleKeywordChange,
    filterType,
    isLoadingMedia,
    itemCountPerRow,
    setFilterType,
    handleUploadSuccess,
    handleRefresh,
    handleDeleteSuccess,
  } = useMediaLibrary(brandId, containerElement, specificType!);
  const defaultSelectedIds = useMemo(() => (selectedId ? [selectedId] : []), [selectedId]);
  const {
    handleFileSelected,
    havingSelectedFile,
    selectedFiles,
  } = useSelectedMediaFiles(false, defaultSelectedIds);
  const handleDeteleMediaSucess = useCallback((ids: string[]) => {
    handleDeleteSuccess(ids);
    handleFileSelected(ids.reduce((hashMap, id) => ({
      ...hashMap,
      [id]: false,
    }), {}));
  }, [handleDeleteSuccess, handleFileSelected]);
  const {
    isDeleteingMedia,
    handleDeleteMedia,
  } = useDeleteMedia(selectedFiles, flattenMedias, handleDeteleMediaSucess);

  const handleUseImage = useCallback(() => {
    if (!havingSelectedFile) return;
    const fileId = Object.keys(selectedFiles).filter((item) => selectedFiles[item])[0];
    const file = flattenMedias.find((item) => item.id === fileId);
    if (file) {
      onSelectFile(file);
    }
  }, [havingSelectedFile, selectedFiles, flattenMedias, onSelectFile]);

  return (
    <Box w="full" h="100vh" pos="fixed" zIndex={9998} bg="vmGray.150">
      <Box
        px="20px"
        h="full"
        sx={{
          '& .media-library-list-wrapper': {
            h: 'calc(100% - 150px)',
            overflow: 'auto',
          },
        }}
      >
        <MediaLibrary
          hasBackButton
          onGoBack={onClose}
          ref={containerRef}
          onFileSelected={handleFileSelected}
          medias={medias}
          handleKeywordChange={handleKeywordChange}
          filterType={filterType}
          isLoadingMedia={isLoadingMedia}
          itemCountPerRow={itemCountPerRow}
          selectedFiles={selectedFiles}
          setFilterType={setFilterType}
          handleUploadSuccess={handleUploadSuccess}
          handleRefresh={handleRefresh}
        />
        <Flex
          align="center"
          justify="center"
          mt="2px"
          gap="20px"
          bg="vmWhite"
          p="20px"
          pos="relative"
          borderBottomLeftRadius="10px"
          borderBottomRightRadius="10px"
        >
          <Flex align="center" gap="41px">
            <BottomBarDeleteSelected
              isDeleting={isDeleteingMedia}
              isDisabled={!havingSelectedFile || isDeleteingMedia}
              onDeleteVideo={handleDeleteMedia}
              buttonText={t('ml.labels.delete_video')}
              confirmMessage={t('ml.message.delete_confirm')}
            />
            <Box>
              <Box w="2px" h="full" bg="#F3F9FF" pos="absolute" top={0} />
            </Box>
            <Flex
              cursor="pointer"
              gap="10px"
              align="center"
              w="full"
              opacity={(!havingSelectedFile || isDeleteingMedia) ? 0.6 : 1}
            >
              <CheckIcon />
              <Box
                as="span"
                fontSize="trNormal"
                fontWeight={600}
                lineHeight="24px"
                color="vmBlack.100"
                // onClick={handleDownload}
                onClick={handleUseImage}
                textTransform="uppercase"
              >
                {t('video_editor.labels.select_and_use')}
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}

MediaLibrarySelector.defaultProps = {
  specificType: 'both',
};

export default memo(MediaLibrarySelector);
