import {
  Box,
  Flex,
  Grid,
  Skeleton,
  Spinner,
} from '@chakra-ui/react';
import ListAction from '@components/ListAction';
import QuestionListItem from '@modules/Question/components/QuestionListItem';
import useQuestions from '@modules/Question/hooks/useQuestions';
import { useEffect, useMemo } from 'react';
import Fuse from 'fuse.js';
import { IVmQuestionListItem } from '@typed/question.types';
import useFilterSearch from '@hooks/useFilterSearch';
import {
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';
import QuestionDetail from './containers/QuestionDetail';

function Question() {
  const navigate = useNavigate();
  const { formId, contactId } = useParams<{formId?: string; contactId?: string}>();
  const {
    isLoadingQuestions,
    questions,
    getQuestions,
    onRemoveQuestion,
  } = useQuestions(formId, contactId);
  const { keyword, onKeywordChange } = useFilterSearch();
  const fuse = useMemo<Fuse<IVmQuestionListItem>>(() => new Fuse(questions, { keys: ['firstname', 'lastname'], threshold: 0.1 }), [questions]);

  const listQuestions = useMemo<IVmQuestionListItem[]>(() => {
    if (!keyword) return questions;
    return fuse.search(keyword).map((item) => item.item);
  }, [keyword, questions, fuse]);

  useEffect(() => {
    if (formId) {
      if (questions.length > 0 && window.location.pathname.split('/').length === 3) {
        navigate(`${window.location.pathname}/view/${questions[0].questionId}`);
      }
    } else if (contactId) {
      if (questions.length > 0 && window.location.pathname.split('/').length === 3) {
        navigate(`${window.location.pathname}/view/${questions[0].questionId}`);
      }
    } else if (questions.length > 0 && window.location.pathname.split('/').pop() === 'questions') {
      navigate(`/questions/view/${questions[0].questionId}`);
    }
  }, [formId, questions, navigate, contactId]);

  return (
    <Grid templateColumns="457px auto">
      <Box bg="vmWhite" minH="100vh">
        <Box h="full" borderRightWidth="1px" borderColor="vmGray.650" pl="15px" pr="23px" py="20px">
          <Flex align="center" justify="space-between" py="8px" px="20px" borderRadius="10px" gap="10px" bg="vmGray.150">
            <Box fontSize="20px" fontWeight={600} lineHeight="26px" color="vmBlack.50">
              Questions&nbsp;
              <Box as="span">{isLoadingQuestions ? <Spinner w="13px" h="13px" /> : `(${listQuestions.length})`}</Box>
            </Box>
            <ListAction
              isLoading={false}
              havingReload
              havingSearch
              onReload={getQuestions}
              onKeywordChange={onKeywordChange}
            />
          </Flex>
          {isLoadingQuestions ? (
            <Flex mt="25px" direction="column" gap="20px">
              <Skeleton borderRadius="10px" w="full" h="78px" />
              <Skeleton borderRadius="10px" w="full" h="78px" />
              <Skeleton borderRadius="10px" w="full" h="78px" />
              <Skeleton borderRadius="10px" w="full" h="78px" />
            </Flex>
          ) : (
            <Flex mt={listQuestions.length > 0 ? '25px' : 0} direction="column" gap="20px">
              {listQuestions.map((question) => (
                <QuestionListItem
                  key={question.questionId}
                  question={question}
                  onRemove={onRemoveQuestion}
                  formId={formId ?? null}
                  contactId={contactId ?? null}
                />
              ))}
            </Flex>
          )}
        </Box>
      </Box>
      <Flex minH="100vh" align="center" justify="center">
        {isLoadingQuestions ? (
          <Spinner />
        ) : (
          <Routes>
            <Route index element={<Box />} />
            <Route path="view/:questionId" element={<QuestionDetail />} />
          </Routes>
        )}
      </Flex>
    </Grid>
  );
}

export default Question;
