import * as yup from 'yup';
import { IForm } from '@modules/Forms/types/form.types';
import { PublicQuestion } from '@modules/PublicQuestion/types/public-question';

interface FormContext {
  schema: any;
  value: PublicQuestion;
}

interface RootFormContext {
  from: FormContext[];
}

type ValidationContext = yup.TestContext & RootFormContext & FormContext;

function getPublicQuestionSchema(form: IForm) {
  return yup.object({
    firstname: yup.string().trim().required(form.advanced.validationMessages.requiredMessage || form.advanced.validationMessages.requiredMessage || 'common.validation.required'),
    lastname: yup.string().trim().required(form.advanced.validationMessages.requiredMessage || 'common.validation.required'),
    email: yup.string().trim().required(form.advanced.validationMessages.requiredMessage || 'common.validation.required').email(form.advanced.validationMessages.emailMessage || 'common.validation.email'),
    text: yup.string().nullable().test({
      name: 'textAndVideoTokenRequired',
      message: form.advanced.validationMessages.requiredMessage || 'common.validation.required',
      test() {
        const { from } = this as ValidationContext;
        const questionSchemaValue = from[from.length - 1].value as PublicQuestion;
        if (!questionSchemaValue.text && !questionSchemaValue.videoToken) {
          return false;
        }
        return true;
      },
    }),
    videoToken: yup.string().nullable().test({
      name: 'textAndVideoTokenRequired',
      message: form.advanced.validationMessages.requiredMessage || 'common.validation.required',
      test() {
        const { from } = this as ValidationContext;
        const questionSchemaValue = from[from.length - 1].value as PublicQuestion;
        if (!form.intro.enableVideoQuestion) return true;
        if (!questionSchemaValue.videoToken && !questionSchemaValue.text) {
          return false;
        }
        return true;
      },
    }),
    gaveConsent: form.personalData.requireConsent ? (
      yup.boolean()
        .required('common.validation.confirm_required')
        .oneOf([true], 'common.validation.confirm_required')
    ) : yup.boolean(),
  });
}

export default getPublicQuestionSchema;
