import { memo } from 'react';
import { Box, Flex, Grid } from '@chakra-ui/react';
import RecordIcon from '@components/icons/Record';
import TextIcon from '@components/icons/Text';
import RecordChooserItem from '@components/VmVideoRecorder/styled/RecordChooserItem.styled';

interface Props {
  label: string;
  onSwitchToRecorderView: () => void;
  onSwitchToTextView: () => void;
  allowText: boolean;
  allowVideo: boolean;
}

function VmVideoRecorderChooser({
  label,
  onSwitchToRecorderView,
  onSwitchToTextView,
  allowText,
  allowVideo,
}: Props) {
  return (
    <Box>
      <Flex
        align="center"
        justify="center"
        borderRadius="10px"
        direction="column"
        py="40px"
        gap="25px"
        bg="vmWhite"
      >
        {label && (
          <Box
            fontWeight={400}
            fontSize="16px"
            lineHeight="24px"
            textAlign="center"
            color="vmBlack.50"
          >
            {label}
          </Box>
        )}
        <Grid templateColumns="80px 80px" justifyContent="center" gridGap="20px" onClick={(event) => event.stopPropagation()} w="full">
          {allowText && (
            <RecordChooserItem onClick={onSwitchToTextView}>
              <TextIcon fontSize="30px" />
            </RecordChooserItem>
          )}
          {allowVideo && (
            <RecordChooserItem onClick={onSwitchToRecorderView}>
              <RecordIcon fill="vmGreen.100" fontSize="30px" />
            </RecordChooserItem>
          )}
        </Grid>
      </Flex>
    </Box>
  );
}

export default memo(VmVideoRecorderChooser);
