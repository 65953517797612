import { updateVideoEditorScenes } from '@apis/video-editor.api';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import { memo, useCallback, useEffect } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { VideoEditorIntroOutro, VideoEditorScene } from '@modules/VideoEditor/types/video-editor.types';
import VideoEditorSendVideo from '@modules/VideoEditor/components/VideoEditorSendVideo/VideoEditorSendVideo';
import { Link, useNavigate } from 'react-router-dom';
import useRenderedVideos from '@modules/VideoEditor/hooks/useRenderedVideos';
import { getVideoStatus, getVideoStatusMesssage } from '@modules/VideoEditor/utils/rendered-video.util';
import useToastMessage from '@hooks/useToastMessage';
import CloseIcon from '@components/icons/Close';
import ChevronRightIcon from '@components/icons/ve/ChevronRight';
import VideoMessageIcon from '@components/icons/ve/VideoMessage';
import { LinkIcon } from '@components/icons/ve';
import CheckIcon from '@components/icons/Check';

interface Props {
  brandId: string;
  answerId: string;
  questionId: string;
}

function VideoEditorSubmitButton({
  brandId,
  answerId,
  questionId,
}: Props) {
  const navigate = useNavigate();
  const { notify } = useToastMessage();
  const { t } = useTranslation();
  const { control, getValues, reset } = useFormContext<{
    intro: VideoEditorIntroOutro;
    outro: VideoEditorIntroOutro;
    scenes: VideoEditorScene[];
    testimonialVideoToken: string | null;
  }>();
  const { isDirty } = useFormState({ control });
  const {
    isOpen,
    onClose,
    onToggle,
  } = useDisclosure();
  const {
    isOpen: isConfirmSavingConfig,
    onOpen: openConfirmSavingConfig,
    onClose: closeConfirmSavingConfig,
  } = useDisclosure();
  const {
    isOpen: isRenderingVideo,
    onOpen: startRenderingVideo,
    onClose: finishRenderingVideo,
  } = useDisclosure();
  const {
    isOpen: isSavingConfig,
    onOpen: startSavingConfig,
    onClose: finishSavingConfig,
  } = useDisclosure();
  const {
    isLoadingRenderedVideo,
    renderedVideos,
    updatedStatusVideos,
    handleLoadRenderedVideo,
  } = useRenderedVideos(answerId, true);
  const havingRenderingVideo = renderedVideos.some((video) => Boolean(
    video.shotstackInit && !video.shotstackDone && !video.shotstackError,
  ));

  const handleCloseVE = useCallback((redirect: boolean = false) => {
    finishRenderingVideo();
    if (redirect) {
      navigate('../rendered-videos');
    }
  }, [finishRenderingVideo, navigate]);

  const handleSavingConfig = useCallback(() => {
    const values = getValues();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { testimonialVideoToken, ...rest } = values;
    return updateVideoEditorScenes(brandId, answerId, rest).then(() => {
      reset(values);
      toast.success(t('video_editor.messages.video_saved_successfully'));
    });
  }, [getValues, brandId, answerId, reset, t]);

  const handleSaveVideoEditor = useCallback(async () => {
    try {
      closeConfirmSavingConfig();
      startSavingConfig();
      await handleSavingConfig();
      navigate(`/questions/view/${questionId}`);
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishSavingConfig();
  }, [
    finishSavingConfig,
    closeConfirmSavingConfig,
    startSavingConfig,
    handleSavingConfig,
    navigate,
    questionId,
  ]);

  const handlePreProcessRenderVideo = useCallback(async () => {
    if (!isDirty) return undefined;
    return handleSavingConfig();
  }, [isDirty, handleSavingConfig]);

  useEffect(() => {
    updatedStatusVideos.forEach((video) => {
      const status = getVideoStatus(video);
      notify(status, getVideoStatusMesssage(status, t, video.name));
    });
  }, [updatedStatusVideos, notify, t]);

  return (
    <>
      <Link to={`/video-editor/${answerId}/rendered-videos`}>
        <Flex align="center" py="8px" gap="9px" justifyContent="flex-end">
          {isLoadingRenderedVideo && (
            <Spinner w="12px" h="12px" />
          )}
          {havingRenderingVideo && (
            <Box w="8px" h="8px" bg="vmGray.600" borderRadius="50%" />
          )}
          <Box fontSize="15px" lineHeight="24px" fontWeight={700} textTransform="uppercase" color="vmWhite">
            {t('video_editor.labels.rendered_videos')}
          </Box>
          <LinkIcon fontSize="16px" mt="-2px" />
        </Flex>
      </Link>
      <Box>
        <Popover isLazy placement="bottom-start" isOpen={isOpen} onClose={onClose}>
          <PopoverTrigger>
            <Box pl="14px" borderLeftWidth="2px" borderColor="vmGray.1000">
              <Button
                type="button"
                h="40px"
                textTransform="uppercase"
                onClick={onToggle}
                isDisabled={isSavingConfig}
                isLoading={isSavingConfig}
                fontSize="15px"
                lineHeight="24px"
                fontWeight={700}
                color="vmBlack.50"
                bg="vmRed.100"
                borderColor="vmRed.100"
                _hover={{
                  bg: 'vmRed.50',
                  borderColor: 'vmRed.50',
                }}
              >
                <Flex align="center" gap="10px">
                  <Box mt="1px">
                    {t('common.button.texts.save')}
                  </Box>
                  <Box transform={isOpen ? 'rotate(90deg)' : 'none'} transition=".2s ease-in-out" lineHeight="7px">
                    <ChevronRightIcon fontSize="10px" stroke="vmBlack.50" />
                  </Box>
                </Flex>
              </Button>
            </Box>
          </PopoverTrigger>
          <PopoverContent
            w="auto"
            outline="none"
            borderRadius="10px"
            borderWidth={0}
            zIndex={1999999}
            _focus={{ boxShadow: '0px 2px 12px rgba(36, 133, 204, 0.15)' }}
            boxShadow="0px 2px 12px rgba(36, 133, 204, 0.15)"
          >
            <PopoverArrow
              boxShadow="-1px -1px 1px 0 rgba(36, 133, 204, 0.15) !important"
            />
            <Flex p={0} direction="column" minW="320px" overflow="hidden" borderRadius="10px">
              <Flex
                align="center"
                gap="15px"
                px="20px"
                py="14px"
                borderBottomWidth="2px"
                borderColor="vmGray.450"
                bg="vmWhite"
                _hover={{
                  bg: 'vmGray.250',
                }}
                cursor="pointer"
                onClick={() => {
                  onClose();
                  openConfirmSavingConfig();
                }}
              >
                <CheckIcon fontSize="18px" />
                <Box
                  fontSize="16px"
                  lineHeight="24px"
                  fontWeight="600"
                  color="vmBlack.100"
                >
                  {t('video_editor.labels.save_video_config')}
                </Box>
              </Flex>
              <Flex
                align="center"
                gap="15px"
                px="20px"
                py="14px"
                bg="vmWhite"
                _hover={{
                  bg: 'vmGray.250',
                }}
                cursor="pointer"
                onClick={() => {
                  onClose();
                  startRenderingVideo();
                }}
              >
                <VideoMessageIcon fontSize="18px" stroke="vmGreen.50" />
                <Box
                  fontSize="16px"
                  lineHeight="24px"
                  fontWeight="600"
                  color="vmBlack.100"
                >
                  {t('video_editor.labels.render_the_video')}
                </Box>
              </Flex>
            </Flex>
          </PopoverContent>
        </Popover>
        <Modal
          closeOnOverlayClick
          isCentered
          isOpen={isConfirmSavingConfig}
          onClose={closeConfirmSavingConfig}
        >
          <ModalOverlay />
          <ModalContent w="720px" maxW="720px">
            <ModalBody p="40px" pos="relative">
              <Box pos="absolute" right="16px" top="10px" cursor="pointer" onClick={closeConfirmSavingConfig}>
                <CloseIcon />
              </Box>
              <Box
                as="h3"
                fontSize="28px"
                fontWeight={600}
                lineHeight="36.4px"
                textAlign="center"
                color="vmBlack.100"
              >
                {t('video_editor.labels.save_config_settings')}
              </Box>
              <Box
                mt="20px"
                fontSize="16px"
                lineHeight="24px"
                fontWeight={400}
                textAlign="center"
              >
                {t('video_editor.messages.save_config_settings')}
              </Box>
              <Flex gap="20px" justify="center" align="center" mt="40px">
                <Button variant="solidPrimary" isDisabled={isSavingConfig} type="button" onClick={closeConfirmSavingConfig}>{t('common.button.texts.cancel')}</Button>
                <Button
                  type="button"
                  isDisabled={isSavingConfig}
                  isLoading={isSavingConfig}
                  color="vmWhite"
                  onClick={handleSaveVideoEditor}
                >
                  {t('common.button.texts.save')}
                </Button>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
        {isRenderingVideo && (
          <VideoEditorSendVideo
            isOpen={isRenderingVideo}
            onClose={handleCloseVE}
            answerId={answerId}
            onRenderVideoPreProcess={handlePreProcessRenderVideo}
            onRenderVideoSuccess={handleLoadRenderedVideo}
          />
        )}
      </Box>
    </>
  );
}

export default memo(VideoEditorSubmitButton);
