import { memo, useCallback } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Switch,
  useConst,
  useDisclosure,
} from '@chakra-ui/react';
import { VideoEditorSettings, VideoEditorTransition } from '@modules/VideoEditor/types/video-editor.types';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TabLink from '@components/TabLink';
import { Controller, useForm, useFormState } from 'react-hook-form';
import videoEditorSettingsSchema from '@modules/VideoEditor/schemas/video-editor-settings.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import ErrorMessage from '@components/ErrorMessage';
import Select from '@components/Select';
import InputColor from '@components/InputColor';
import { toast } from 'react-toastify';
import { updateVideoEditorSettings } from '@apis/video-editor.api';
import useToastMessage from '@hooks/useToastMessage';
import BackIcon from '@components/icons/Back';
import LabelHeader from '@components/LabelHeader';
import useHistoryRoute from '@hooks/useHistoryRoute';

interface Props {
  answerId: string;
  settings: VideoEditorSettings | null;
  brandId: string;
  onUpdateSettings: (settings: VideoEditorSettings) => void;
}

const TRANSITION_TYPES = [
  { key: 'fade', title: 'Fade' },
  { key: 'dissolve', title: 'Dissolve' },
];

function getKey(item: {key: string, title: string}) {
  return item.key;
}

function getLabel(item: {key: string, title: string}) {
  return (
    <Box>{item.title}</Box>
  );
}

function VideoEditorSettingsContainer({
  answerId,
  settings,
  brandId,
  onUpdateSettings,
}: Props) {
  const { t } = useTranslation();
  const { notify } = useToastMessage();
  const navigate = useNavigate();
  const { backRoute } = useHistoryRoute(`/video-editor/${answerId}/f/scenes`);
  const SPEEDS = useConst(() => ([
    { key: 'regular', title: t('video_editor.labels.second', { secondNumber: 1 }) },
    { key: 'fast', title: t('video_editor.labels.second', { secondNumber: 0.5 }) },
    { key: 'slow', title: t('video_editor.labels.second', { secondNumber: 2 }) },
  ]));
  const {
    control,
    handleSubmit,
    register,
    watch,
  } = useForm<VideoEditorTransition>({
    defaultValues: (settings === null || settings.transition === null) ? ({
      transistion: false,
      type: 'fade',
      speed: 'regular',
      backgroundColor: '#ffffff',
    }) : settings.transition,
    resolver: yupResolver(videoEditorSettingsSchema),
  });
  const { errors } = useFormState({ control });
  const isTransitionEnabled = watch('transistion');
  const {
    isOpen: isSubmitting,
    onOpen: startSubmitting,
    onClose: finishSubmitting,
  } = useDisclosure();

  const onSubmit = useCallback(async (values: VideoEditorTransition) => {
    try {
      startSubmitting();
      const result = await updateVideoEditorSettings(
        brandId,
        answerId,
        { transition: values },
      );
      onUpdateSettings(result);
      notify('success', t('video_editor.messages.save_settings_ok'));
      navigate(backRoute);
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishSubmitting();
  }, [
    finishSubmitting,
    startSubmitting,
    brandId,
    answerId,
    onUpdateSettings,
    notify,
    t,
    navigate,
    backRoute,
  ]);

  return (
    <Grid as="form" onSubmit={handleSubmit(onSubmit)} templateRows="65px auto" minH="100vh">
      <Box px="20px" pt="10px" borderRadius="10px">
        <Flex align="center" gap="20px">
          <Link to={`/video-editor/${answerId}/f/scenes`}>
            <Button variant="back" size="iconOnly" type="button">
              <BackIcon fontSize="17px" stroke="vmPrimary.50" />
            </Button>
          </Link>
          <LabelHeader pl={0}>{t('video_editor.labels.video_scenes_settings')}</LabelHeader>
        </Flex>
      </Box>
      <Grid templateRows="fit-content(100%) auto 84px" gridGap="20px" pb="20px" px="20px">
        <Flex p="20px" bg="vmWhite" borderRadius="10px" align="center" gap="20px">
          <TabLink to="" title={t('video_editor.labels.transition')} />
          <TabLink to="watermark" title={t('video_editor.labels.watermark')} isDisabled />
        </Flex>
        <Box p="20px" bg="vmWhite" borderRadius="10px">
          <Box w="440px" mx="auto">
            <FormControl isInvalid={!!errors?.transistion?.message}>
              <Flex align="center" justify="space-between">
                <FormLabel mb={0}>{t('video_editor.labels.enable_transition')}</FormLabel>
                <Switch {...register('transistion')} />
              </Flex>
              <ErrorMessage error={errors?.transistion?.message} />
            </FormControl>
            {isTransitionEnabled && (
              <>
                <FormControl isInvalid={!!errors?.type?.message}>
                  <FormLabel>{t('video_editor.labels.transition_type')}</FormLabel>
                  <Controller
                    control={control}
                    name="type"
                    render={({ field }) => (
                      <Select data={TRANSITION_TYPES} getKey={getKey} getLabel={getLabel} placeholder="" {...field} />
                    )}
                  />
                  <ErrorMessage error={errors?.type?.message} />
                </FormControl>
                <FormControl isInvalid={!!errors?.speed?.message}>
                  <FormLabel>{t('video_editor.labels.transition_speed')}</FormLabel>
                  <Controller
                    control={control}
                    name="speed"
                    render={({ field }) => (
                      <Select data={SPEEDS} getKey={getKey} getLabel={getLabel} placeholder="" {...field} />
                    )}
                  />
                  <ErrorMessage error={errors?.speed?.message} />
                </FormControl>
                <FormControl isInvalid={!!errors?.backgroundColor?.message}>
                  <FormLabel>{t('common.labels.background_color')}</FormLabel>
                  <Controller
                    name="backgroundColor"
                    control={control}
                    render={({ field }) => (
                      <InputColor {...field} />
                    )}
                  />
                  <ErrorMessage error={errors?.backgroundColor?.message} />
                </FormControl>
              </>
            )}
          </Box>
        </Box>
        <Flex p="20px" bg="vmWhite" borderRadius="10px" align="center" gap="20px">
          <Button
            variant="solidPrimary"
            type="button"
            isDisabled={isSubmitting}
            onClick={() => {
              navigate(backRoute);
            }}
          >
            {t('common.button.texts.cancel')}
          </Button>
          <Button type="submit" isLoading={isSubmitting} isDisabled={isSubmitting}>{t('common.button.texts.save')}</Button>
        </Flex>
      </Grid>
    </Grid>
  );
}

export default memo(VideoEditorSettingsContainer);
