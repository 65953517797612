import { Icon, IconProps } from '@chakra-ui/react';

function ArrowLeftIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 8 14" fill="none" stroke="vmRed.100" strokeWidth={1.5} {...props}>
      <path d="M7 1L0.999999 7L7 13" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  );
}

export default ArrowLeftIcon;
