import { useDisclosure } from '@chakra-ui/react';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { getBrowserName } from '../utils/browser.util';
import { enumerateDevices, getUserMedia } from '../utils/device.util';

interface UseTrustRecorder {
  isCameraExist: boolean;
  isGettingDeviceState: boolean;
  isPermissionDenied: boolean;
  isMicrophoneExist: boolean;
  needToAskForCameraPermission: boolean;
  audioDevices: MediaDeviceInfo[];
  cameraDevices: MediaDeviceInfo[];
  selectedMicro: MediaDeviceInfo | null;
  selectedCamera: MediaDeviceInfo | null;
  isVerified: boolean;
  changeSelectedCamera: (device: MediaDeviceInfo) => void;
  changeSelectedMicro: (device: MediaDeviceInfo) => void;
  requestDevicePermission: () => Promise<void>;
}

function useTrustRecorder(): UseTrustRecorder {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedMicro, setSelectedMicro] = useState<MediaDeviceInfo | null>(null);
  const [selectedCamera, setSelectedCamera] = useState<MediaDeviceInfo | null>(null);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const {
    isOpen: isGettingDeviceState,
    onOpen: startGettingDeviceState,
    onClose: finishGettingDeviceState,
  } = useDisclosure();
  const [isPermissionDenied, setIsPermissionDenied] = useState<boolean>(false);
  const devicesData = useMemo<Omit<
    UseTrustRecorder,
    'requestDevicePermission'
    | 'selectedMicro'
    | 'selectedCamera'
    | 'changeSelectedCamera'
    | 'changeSelectedMicro'
    | 'isVerified'
    | 'isGettingDeviceState'
    | 'isPermissionDenied'>
  >(() => {
    const audioDevices = devices.filter((device) => device.kind === 'audioinput');
    const cameraDevices = devices.filter((device) => device.kind === 'videoinput');
    const firstCamera = cameraDevices[0] ?? null;
    const firstMicro = audioDevices[0] ?? null;

    return {
      isCameraExist: cameraDevices.length > 0,
      isMicrophoneExist: audioDevices.length > 0,
      needToAskForCameraPermission: !firstCamera?.label || !firstMicro?.label,
      audioDevices,
      cameraDevices,
    };
  }, [devices]);

  useEffect(() => {
    setSelectedCamera(devicesData.cameraDevices[0] ?? null);
    setSelectedMicro(devicesData.audioDevices[0] ?? null);
  }, [devicesData]);

  const getDevices = useCallback(async () => {
    const mediaDevices: MediaDeviceInfo[] = await enumerateDevices();
    setDevices(mediaDevices);
  }, []);

  const detechDevicePermission = useCallback(async () => {
    try {
      const [
        cameraPermissionState,
        microPermissionState,
      ]: [PermissionStatus, PermissionStatus] = await Promise.all([
        navigator.permissions.query({ name: 'camera' as any }),
        navigator.permissions.query({ name: 'microphone' as any }),
      ]);
      if (cameraPermissionState.state === 'granted' && microPermissionState.state === 'granted') {
        await getDevices();
        finishGettingDeviceState();
        return;
      }
      if (cameraPermissionState.state === 'denied' || microPermissionState.state === 'denied') {
        setIsPermissionDenied(true);
        finishGettingDeviceState();
        return;
      }
    } catch (e: any) {
      toast.error(e);
    }
    finishGettingDeviceState();
  }, [finishGettingDeviceState, getDevices]);

  const handleDetectDeviceFirefox = useCallback(async () => {
    await getUserMedia().catch((error) => {
      if (error.name === 'NotAllowedError' && error.result === 2152923169) {
        setIsPermissionDenied(true);
        finishGettingDeviceState();
      }
    }).then(() => getDevices()).then(() => finishGettingDeviceState());
  }, []);

  const requestDevicePermission = useCallback(async () => {
    startGettingDeviceState();
    await getUserMedia().catch(() => {});
    const browser = getBrowserName();
    if (['Firefox', 'Safari'].includes(browser ?? '')) {
      await handleDetectDeviceFirefox();
    } else {
      await detechDevicePermission();
    }
  }, [detechDevicePermission, handleDetectDeviceFirefox, startGettingDeviceState]);

  const handleInitial = useCallback(async () => {
    const browser = getBrowserName();
    if (!['Firefox', 'Safari'].includes(browser ?? '')) {
      await detechDevicePermission();
    } else {
      await getDevices();
    }
    setIsVerified(true);
  }, [detechDevicePermission, getDevices]);

  useEffect(() => {
    handleInitial();
  }, [handleInitial]);

  return {
    ...devicesData,
    isGettingDeviceState,
    isPermissionDenied,
    isVerified,
    requestDevicePermission,
    selectedMicro,
    selectedCamera,
    changeSelectedCamera: setSelectedCamera,
    changeSelectedMicro: setSelectedMicro,
  };
}

export default useTrustRecorder;
