import { useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  ScaleFade,
  Tooltip,
} from '@chakra-ui/react';
import SearchIcon from '@components/icons/Search';
import CloseIcon from '@components/icons/Close';
import ReloadIcon from '@components/icons/Reload';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import useListAction from './useListAction';

interface Props {
  isLoading: boolean;
  havingSearch?: boolean;
  havingReload?: boolean;
  onKeywordChange?: (v: string) => void;
  onReload?: () => void;
}

function ListAction({
  isLoading,
  havingSearch,
  havingReload,
  onKeywordChange,
  onReload,
}: Props) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const {
    ref,
    isOpen,
    onSearchChange,
    handleReload,
    handleOpenSearchBox,
    handleCloseSearchBox,
  } = useListAction(
    !!searchParams.get('keyword'),
    onKeywordChange,
    onReload,
  );

  const search = searchParams.get('keyword');
  useEffect(() => {
    if (search === null && ref.current) {
      ref.current.value = '';
      handleCloseSearchBox(false);
    }
  }, [search, ref, handleCloseSearchBox]);

  return (
    <Flex align="center" gap="10px">
      <ScaleFade initialScale={0.9} in={isOpen} style={{ position: 'relative' }}>
        <Input
          ref={ref}
          type="text"
          isDisabled={isLoading}
          defaultValue={searchParams.get('keyword') ?? ''}
          placeholder={t('common.placeholders.search')}
          onChange={onSearchChange}
          position="absolute"
          right={0}
          width={{
            base: '180px',
            wxgb: '280px',
          }}
          top={0}
          transform="translateY(-50%)"
        />
      </ScaleFade>
      {havingSearch && (
        <Box>
          {!isOpen ? (
            <Tooltip label={t('common.texts.search')} hasArrow placement="top">
              <Button isDisabled={isLoading} type="button" size="iconOnly" variant="iconOnly" bg="vmWhite" borderRadius="6px" onClick={handleOpenSearchBox}>
                <SearchIcon fontSize="16px" />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label={t('common.texts.close')} hasArrow placement="top">
              <Button isDisabled={isLoading} type="button" size="iconOnly" variant="iconOnly" bg="vmWhite" borderRadius="6px" onClick={() => handleCloseSearchBox()}>
                <CloseIcon stroke="vmGray.600" fontSize="16px" />
              </Button>
            </Tooltip>
          )}
        </Box>
      )}
      {havingReload && (
        <Tooltip label={t('common.texts.refresh')} hasArrow placement="top">
          <Button isDisabled={isLoading} type="button" size="iconOnly" variant="iconOnly" bg="vmWhite" borderRadius="6px" onClick={handleReload}>
            <ReloadIcon stroke="vmGray.600" fontSize="16px" />
          </Button>
        </Tooltip>
      )}
    </Flex>
  );
}

ListAction.defaultProps = {
  havingSearch: false,
  havingReload: false,
  onKeywordChange: () => {},
  onReload: () => {},
};

export default ListAction;
