import { Icon, IconProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

function QuestionIcon(props: IconProps, ref: any) {
  return (
    <Icon
      ref={ref}
      viewBox="0 0 20 20"
      stroke="vmGray.950"
      fill="none"
      _hover={{
        '& > #rect-2': {
          display: 'none',
        },
        '& > #path-2': {
          display: 'none',
        },
        '& > #path-1': {
          display: 'block',
        },
        '& > #rect-1': {
          display: 'block',
        },
      }}
      {...props}
    >
      <rect id="rect-1" width="20" height="20" rx="10" fill="#B6CDE8" display="none" />
      <path
        id="path-1"
        d="M7 7.18572C7.24196 6.45696 7.71954 5.84244 8.34815 5.45101C8.97676 5.05957 9.71584 4.91649 10.4345 5.04709C11.1531 5.1777 11.805 5.57356 12.2745 6.16457C12.7441 6.75559 13.0011 7.5036 13 8.27614C13 10.457 10.0051 11.5455 9.91252 11.5474M9.99485 15H10.0051"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        display="none"
      />
      <path
        id="path-2"
        d="M7 7.18572C7.24196 6.45696 7.71954 5.84244 8.34815 5.45101C8.97676 5.05957 9.71584 4.91649 10.4345 5.04709C11.1531 5.1777 11.805 5.57356 12.2745 6.16457C12.7441 6.75559 13.0011 7.5036 13 8.27614C13 10.457 10.0051 11.5455 9.91252 11.5474M9.99485 15H10.0051"
        stroke="#B6CDE8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect id="rect-2" x="1" y="1" width="18" height="18" rx="9" stroke="#B6CDE8" strokeWidth="2" />
    </Icon>
  );
}

export default forwardRef(QuestionIcon);
