import ListTypeContextProvider from '@root/contexts/ListType/context';
import ContactListContainer from './container';

function ContactList() {
  return (
    <ListTypeContextProvider keyMap="vm-contact-grid-type">
      <ContactListContainer />
    </ListTypeContextProvider>
  );
}

export default ContactList;
