import {
  Box,
  Button,
  Flex,
  Skeleton,
  useDisclosure,
} from '@chakra-ui/react';
import BackIcon from '@components/icons/Back';
import CopyIcon from '@components/icons/Copy';
import TickIcon from '@components/icons/Tick';
import NotFound from '@components/NotFound';
import PlayIcon from '@components/TrustRecorder/icons/Play';
import VMVideoPlayer from '@components/VMVideoPlayer';
import useRecorderRef from '@components/VmVideoRecorder/hooks/useRecorderRef';
import useRecorderPlayingState from '@hooks/useRecorderPlayingState';
import useVmClipboard from '@hooks/useVmClipboard';
import useVLVideoDetail from '@modules/VideoLibrary/hooks/useVLVideoDetail';
import { downloadFile } from '@apis/file.api';
import { useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteVideoItem } from '@apis/video-library.api';

function VLDetail() {
  const navigate = useNavigate();
  const { videoId } = useParams<'videoId'>();
  const { isLoadingVideoDetail, video } = useVLVideoDetail(videoId!);
  const { hasCopied, onCopy } = useVmClipboard(`${window.location.origin}/video-library/view/${video?.id}`);
  const { recorder, setRecorderRef } = useRecorderRef();
  const { isPlaying, isReadyToPlay } = useRecorderPlayingState(recorder);
  const {
    isOpen: isDownloading,
    onOpen: startDownloading,
    onClose: finishDownloading,
  } = useDisclosure();
  const {
    isOpen: isDeleting,
    onOpen: startDeleting,
    onClose: finishDeleting,
  } = useDisclosure();

  const handleDownloadVideo = useCallback(async () => {
    startDownloading();
    const videoName = video?.name ?? 'video.mp4';
    await downloadFile(`https://${video!.videoVideoUrl}.mp4`, videoName.endsWith('.mp4') ? videoName : `${videoName}.mp4`);
    finishDownloading();
  }, [finishDownloading, startDownloading, video]);

  const handleDeleteVideo = useCallback(async () => {
    try {
      startDeleting();
      await deleteVideoItem(video!.id);
      toast.success('A video has been removed successfully');
      navigate('/video-library');
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishDeleting();
  }, [finishDeleting, navigate, startDeleting, video]);

  if (isLoadingVideoDetail) {
    return (
      <Box w="1200px" mx="auto" px="20px" maxW="full" pt="86px" pb="80px">
        <Skeleton w="158px" h="44px" mb="25px" />
        <Skeleton w="full" h="500px" />
        <Flex align="center" justify="center" mt="35px">
          <Skeleton w="122px" h="44px" />
          <Skeleton w="122px" h="44px" />
          <Skeleton w="122px" h="44px" />
        </Flex>
      </Box>
    );
  }

  if (!video) {
    return (
      <NotFound />
    );
  }

  return (
    <Box w="1200px" mx="auto" px="20px" maxW="full" pt="86px" pb="80px">
      <Flex align="center" gap="10px" mb="25px">
        <Link to="/video-library">
          <Button bg="vmGray.150" _hover={{ bg: 'vmGray.100' }} type="button" variant="iconOnly" size="iconOnly">
            <BackIcon />
          </Button>
        </Link>
        <Box
          fontSize="13px"
          lineHeight="19.5px"
          fontWeight={400}
          color="vmBlack.100"
        >
          Back to All Videos
        </Box>
      </Flex>
      <Box w="813px" maxW="full">
        <Box
          sx={{
            display: video.videoToken ? 'block' : 'none',
            '& .ba-videorecorder-container': {
              borderRadius: '10px',
            },
            '& .ba-videoplayer-container': {
              borderRadius: '10px',
            },
            '& .css-19w1vec .ba-videorecorder-chooser-button-container': {
              w: '90%',
            },
            '& .ba-videorecorder-chooser-container': {
              bg: '#15334B',
            },
            '& .ba-videoplayer-rerecord-button-container': {
              display: 'none',
            },
            '& .ba-player-rerecord-frontbar': {
              display: 'none',
            },
            '& .ba-videoplayer-rerecord-bar': {
              display: 'none',
            },
            '& .ba-videoplayer-playbutton-container': {
              display: 'none',
            },
            '& .ba-player-dashboard': {
              display: 'none',
            },
          }}
        >
          <Box>
            <Box pos="relative" maxW="full">
              <VMVideoPlayer
                source={null}
                videoToken={video.videoToken}
                setOuterRef={setRecorderRef}
              />
              {!isPlaying && isReadyToPlay && (
                <Flex
                  align="center"
                  justify="center"
                  className="player-f"
                  w="44px"
                  h="44px"
                  bg="rgba(255, 255, 255, 0.25)"
                  borderRadius="50%"
                  cursor="pointer"
                  mb={0}
                  onClick={() => {
                    recorder.play();
                  }}
                  pos="absolute"
                  bottom={0}
                  top={0}
                  left={0}
                  right={0}
                  m="auto"
                  zIndex={90}
                >
                  <PlayIcon fontSize="22px" />
                </Flex>
              )}
            </Box>
          </Box>
        </Box>
        <Flex align="center" justify="center" gap="20px" mt="35px">
          <Button type="button" isDisabled={isDownloading || isDeleting} isLoading={isDeleting} onClick={handleDeleteVideo}>Delete</Button>
          <Button type="button" isDisabled={isDownloading || isDeleting} isLoading={isDownloading} onClick={handleDownloadVideo}>Download</Button>
          <Button type="button" variant="solidPrimary" onClick={onCopy}>
            <Flex align="center" gap="10px" textTransform="capitalize">
              {hasCopied ? <TickIcon stroke="vmGreen.50" fontSize="17px" /> : <CopyIcon strokeWidth={2} fontSize="17px" />}
              <Box as="span" color="vmGray.400">{hasCopied ? 'Copied' : 'Copy'}</Box>
            </Flex>
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}

export default VLDetail;
