import axios, { AxiosResponse } from 'axios';
import { Customer } from '@typed/customer.types';
import { ApiResponse } from '@typed/http.types';

async function getCustomer(
  email: string,
  isShadowCustomer: boolean = false,
): Promise<Customer> {
  const query = new URLSearchParams({ email, shadowUser: Number(isShadowCustomer).toString() });
  return axios.get(`/login?${query.toString()}`).then((response: AxiosResponse<ApiResponse<Customer>>) => response.data.result);
}

async function updateCustomerName(
  customerId: string,
  data: Pick<Customer, 'firstName' | 'lastName'>,
): Promise<Customer> {
  return axios.put(`/customer/${customerId}/name`, data).then((response: AxiosResponse<ApiResponse<Customer>>) => response.data.result);
}

async function updateCustomerEmail(
  customerId: string,
  email: string,
): Promise<Customer> {
  const query = new URLSearchParams({ email }).toString();
  return axios.put(`/customer/${customerId}/email?${query}`).then((response: AxiosResponse<ApiResponse<Customer>>) => response.data.result);
}

async function resetCustomerPassword(
  customerId: string,
): Promise<void> {
  return axios.put(`/customer/${customerId}/reset-password`).then(() => {});
}

async function deleteCustomerAccount(
  customerId: string,
): Promise<void> {
  return axios.delete(`/customer/${customerId}`);
}

export {
  getCustomer,
  updateCustomerName,
  updateCustomerEmail,
  resetCustomerPassword,
  deleteCustomerAccount,
};
