async function getUserMedia() {
  return window.navigator.mediaDevices.getUserMedia({
    audio: true,
    video: true,
  });
}

async function enumerateDevices() {
  return window.navigator.mediaDevices.enumerateDevices();
}

export {
  getUserMedia,
  enumerateDevices,
};
