import axios from 'axios';
import { SignUpFormValues } from '@modules/Auth/types/sign-up.types';

async function registerUser(data: SignUpFormValues) {
  return axios.post('/signup', data);
}

async function resendEmailConfirm(email: string) {
  const query = new URLSearchParams({ email });
  return axios.put(`/resend-confirmation?${query.toString()}`);
}

export {
  registerUser,
  resendEmailConfirm,
};
