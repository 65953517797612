import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import LoginHeader from '@modules/Auth/components/LoginHeader';
import ROUTES from '@constants/routes';
import LoginDescription from '@modules/Auth/components/LoginDescription';
import AuthDescription from '@modules/Auth/components/AuthDescription';
import LoginAction from '@modules/Auth/components/LoginAction';
import usePageTitle from '@hooks/usePageTitle';

function Login() {
  const { t } = useTranslation();
  const { loginWithRedirect } = useAuth0();
  usePageTitle(t('page_titles.login'));

  return (
    <Flex
      minH="100vh"
      w="full"
      py="100px"
      align="center"
      justify="center"
      gap="145px"
      bg="#fbfcfe"
      px="40px"
      direction={{
        base: 'column-reverse',
        mxl: 'row',
      }}
    >
      <Box w="615px" maxW="full"><AuthDescription /></Box>
      <Box
        bg="vmWhite"
        borderRadius="10px"
        py="40px"
        boxShadow="0px 2px 50px rgba(0, 112, 201, 0.08);"
        w={{
          base: '615px',
          wxga: '560px',
        }}
        maxW="full"
      >
        <Box px="80px">
          <LoginHeader />
        </Box>
        <LoginDescription />
        <LoginAction onLogin={loginWithRedirect} />
        <Flex
          color="vmBlack.50"
          fontSize="16px"
          lineHeight="24px"
          fontWeight={400}
          textAlign="center"
          align="center"
          justify="center"
          gap="6px"
          mt="20px"
        >
          {t('login.account_required_question')}
          <Link to={ROUTES.AUTH.SIGNUP}>
            <Box>{t('common.button.texts.sign_up')}</Box>
          </Link>
        </Flex>
      </Box>
    </Flex>
  );
}

export default Login;
