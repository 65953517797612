import { Box, Spinner, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@components/icons/Edit';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { resetCustomerPassword } from '@apis/customer.api';
import { useVmStore } from '@root/store';
import { customerIdSelector } from '@store/slices/customer/selectors';
import WarningConfirm from '@components/WarningConfirm';

function PasswordUpdate() {
  const { t } = useTranslation();
  const customerId = useVmStore(customerIdSelector);
  const {
    isOpen,
    onOpen,
    onClose,
  } = useDisclosure();
  const {
    isOpen: isResettingPassword,
    onOpen: startResettingPassword,
    onClose: finishResettingPassword,
  } = useDisclosure();

  const handleResetPassword = useCallback(async () => {
    try {
      onClose();
      startResettingPassword();
      await resetCustomerPassword(customerId);
      toast.success(t('account.message.reset_password_ok'));
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishResettingPassword();
  }, [customerId, finishResettingPassword, onClose, startResettingPassword, t]);

  return (
    <>
      {isResettingPassword ? (
        <Box><Spinner width="16px" height="16px" /></Box>
      ) : (
        <Box cursor="pointer" onClick={onOpen}>
          <EditIcon fontSize="16px" />
        </Box>
      )}
      <WarningConfirm
        isOpen={isOpen}
        onClose={onClose}
        confirmTitle={t('account.message.reset_password_title')}
        confirmDescription={t('account.message.reset_password_des')}
        onConfirm={handleResetPassword}
      />
    </>
  );
}

export default PasswordUpdate;
