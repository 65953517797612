import { Box, Flex, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import SidebarMenu from './components/SidebarMenu';
import menus from './constants/menu';
import ConfigurationMenu from './parts/ConfigurationMenu';

function Sidebar() {
  return (
    <Flex direction="column" bg="vmGray.150" px="7px" pb="20px" position="fixed" w="100px" minH="100vh" overflow="overlay">
      <Box>
        <Box mb="20px">
          <Link to="/">
            <Image maxWidth="60px" src="/static-data/images/logo-green.png" alt="Logo" mx="auto" />
          </Link>
        </Box>
        <Flex direction="column" align="center">
          {menus.map((menu) => (
            <Link to={menu.route} key={menu.key}>
              <SidebarMenu title={menu.title} />
            </Link>
          ))}
        </Flex>
      </Box>
      <Flex align="center" direction="column" mt="auto" pt="30px">
        <ConfigurationMenu />
      </Flex>
    </Flex>
  );
}

export default Sidebar;
