import { memo } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useParams,
} from 'react-router-dom';
import {
  Box, Flex, Spinner,
} from '@chakra-ui/react';
import useVideoEditor from '@modules/VideoEditor/hooks/useVideoEditor';
import VideoEditorSettings from '@modules/VideoEditor/containers/VideoEditorSettings/VideoEditorSettings';
import { useVmStore } from '@store/index';
import useAnswerDetail from '@shared/Answer/hooks/useAnswerDetail';
import { selectedBrandSelector } from '@store/slices/brand/selectors';
import { customerIdSelector } from '@store/slices/customer/selectors';
import NotFound from '@components/NotFound';
import VideoEditorLoading from './components/VideoEditorLoading/VideoEditorLoading';
import VideoEditorContainer from './VideoEditorContainer';
import VideoRendered from './containers/VideoRendered/VideoRendered';

function VideoEditor() {
  const customerId = useVmStore(customerIdSelector);
  const brandId = useVmStore(selectedBrandSelector);
  const { answerId } = useParams<{answerId: string}>();
  const {
    videoEditorConfig,
    isGettingVideoEditorConfig,
    onUpdateSettings,
  } = useVideoEditor(brandId!, answerId!);
  const { answer, isLoadingAnswer } = useAnswerDetail(answerId!);

  // const handleUpdateQanda = useCallback(async (durationMap: {[key: string]: number}) => {
  //   if (!testimonial) return;
  //   const currentQAs = testimonial!.questionsAndAnswers;
  //   if (!currentQAs) return;
  //   const haveQAItemInDurationMap = currentQAs.some((qaItem) => Boolean(
  //     durationMap[qaItem.questionVideoToken ?? ''] || durationMap[qaItem.answerVideoToken ?? ''],
  //   ));
  //   if (!haveQAItemInDurationMap) return;
  //   const newQA = currentQAs.reduce<TestimonialQuestionsAndAnswer[]>((newQAList, qaItem) => ([
  //     ...newQAList,
  //     {
  //       ...qaItem,
  //       questionVideoDuration: (
  //         qaItem.questionVideoToken === null ? 0 : (durationMap[qaItem.questionVideoToken] ?? 0)
  //       ),
  //       answerVideoDuration: (
  //         qaItem.answerVideoToken === null ? 0 : (durationMap[qaItem.answerVideoToken] ?? 0)
  //       ),
  //     },
  //   ]), [] as TestimonialQuestionsAndAnswer[]);
  //   await updateTestimonialQanda(testimonial.id, newQA);
  // }, [testimonial]);

  if (isGettingVideoEditorConfig || isLoadingAnswer) {
    const lastPath = window.location.pathname.split('/').pop();
    if (lastPath && ['settings', 'rendered-videos'].includes(lastPath)) {
      return (
        <Flex align="center" justify="center" w="full" h="100vh">
          <Spinner />
        </Flex>
      );
    }
    return (
      <VideoEditorLoading />
    );
  }

  if (!answer || !videoEditorConfig) {
    return <Navigate to="/404" />;
  }

  return (
    <Box bg="vmGray.150">
      <Routes>
        <Route index element={<Navigate to="f/scenes" />} />
        <Route
          path="f/*"
          element={(
            <VideoEditorContainer
              brandId={brandId!}
              answerId={answerId!}
              customerId={customerId}
              questionId={answer.questionId}
              videoEditorConfig={videoEditorConfig}
              // onUpdateQanda={handleUpdateQanda}
            />
          )}
        />
        <Route
          path="settings"
          element={(
            <VideoEditorSettings
              brandId={brandId!}
              answerId={answerId!}
              settings={videoEditorConfig.settings}
              onUpdateSettings={onUpdateSettings}
            />
          )}
        />
        <Route
          path="rendered-videos"
          element={(
            <VideoRendered
              answerId={answerId!}
              defaultVideoToken={answer.videoToken ?? null}
              havingSubtitleVideo={false}
            />
          )}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Box>
  );
}

export default memo(VideoEditor);
