import { Box, chakra } from '@chakra-ui/react';

const SettingBoxTextStyled = chakra(Box, {
  baseStyle: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: 'vmBlack.50',
  },
});

export default SettingBoxTextStyled;
