import * as yup from 'yup';

function getBrandSchema() {
  return yup.object({
    name: yup.string().trim().required('common.validation.required'),
    languageCode: yup.string().trim().required('common.validation.required'),
    language: yup.string().trim().required('common.validation.required'),
  });
}

export default getBrandSchema;
