import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import * as yup from 'yup';
import CloseIcon from '@components/Close';
import { IFolderFormValue } from '@modules/Forms/types/folder.types';
import { memo } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '@components/ErrorMessage';

const schema = yup.object({
  name: yup.string().trim().required('common.validation.required'),
});

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: IFolderFormValue) => Promise<void>;
  isLoading: boolean;
  defaultValues: Pick<IFolderFormValue, 'name'>;
}

function VmFolderControl({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  defaultValues,
}: Props) {
  const { t } = useTranslation();
  const { control, handleSubmit, register } = useForm<IFolderFormValue>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { errors } = useFormState({ control });

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent maxWidth="580px">
        <ModalBody pos="relative">
          <Box pos="absolute" right="16px" top="10px" cursor="pointer" onClick={onClose}>
            <CloseIcon />
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box fontSize="20px" lineHeight="28px" fontWeight={600}>Create Folder</Box>
            <FormControl isInvalid={!!errors?.name?.message}>
              <FormLabel>{t('common.labels.name')}</FormLabel>
              <Input type="text" placeholder={t('common.placeholders.name')} {...register('name')} />
              <ErrorMessage error={errors?.name?.message} />
            </FormControl>
            <Button isLoading={isLoading} isDisabled={isLoading} mt="20px" type="submit">{t('common.button.texts.save')}</Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default memo(VmFolderControl);
