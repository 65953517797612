import { resendEmailConfirm } from '@apis/auth.api';
import {
  Box,
  Button,
  Flex,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

function VerifyRequired() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    isOpen: isResendingEmail,
    onOpen: startResendingEmail,
    onClose: finishResendingEmail,
  } = useDisclosure();
  const email = searchParams.get('email') ?? '';

  useEffect(() => {
    if (!email) {
      navigate('/login');
    }
  }, [email, navigate]);

  const handleResendEmail = useCallback(async () => {
    if (!email) return;
    try {
      startResendingEmail();
      await resendEmailConfirm(email);
      toast.success('We just send to you a new email. Please check the inbox or spam folder');
      finishResendingEmail();
      navigate('/login');
    } catch (error: any) {
      toast.error(error?.message);
      finishResendingEmail();
    }
  }, [email, finishResendingEmail, navigate, startResendingEmail]);

  return (
    <Flex align="center" justify="center" w="full" minH="100vh">
      <Box bg="#F2F2F2" py="40px" px="60px" w="600px" maxW="full" textAlign="center" borderRadius="10px">
        <Heading as="h2" color="#262637" fontSize="22px" mb="10px" lineHeight="28px">Please verify your email</Heading>
        <Box as="p" color="#808092" fontSize="16px">
          We have sent an email to
          {' '}
          <Box as="span" color="#262637">tuananhit.oct@gmail.com</Box>
          . Check your inbox to activate your account
        </Box>
        <Box as="p" color="#808092" fontSize="16px" mt="20px">
          If it is not in your inbox, please check
          {' '}
          <Box fontSize="16px" as="span" color="#262637" fontWeight={600}>SPAM</Box>
          {' '}
          folder
        </Box>
        <Box textAlign="center" mt="30px">
          <Button
            type="button"
            textTransform="capitalize"
            onClick={handleResendEmail}
            isLoading={isResendingEmail}
            isDisabled={isResendingEmail}
          >
            Resend email
          </Button>
        </Box>
      </Box>
    </Flex>
  );
}

export default VerifyRequired;
