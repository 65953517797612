import { Box, chakra } from '@chakra-ui/react';

const WarningText = chakra(Box, {
  baseStyle: {
    fontSize: 'vmRegular',
    lineHeight: '24px',
    px: '15px',
    py: '10px',
    bg: 'vmGray.650',
    color: 'vmYellow.50',
    borderRadius: '10px',
  },
});

export default WarningText;
