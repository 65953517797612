import {
  Navigate,
  useLocation,
} from 'react-router-dom';
import { getItem } from '@utils/localstorage.util';
import ROUTES from '@constants/routes';
import LOCAL_STORAGE_KEY from '@constants/localstorage';

function AuthGuard({ children }: { children: JSX.Element }): JSX.Element {
  const location = useLocation();
  const auth0Token = getItem(LOCAL_STORAGE_KEY.AUTH0_KEY);

  if (!auth0Token) {
    return (
      <Navigate to={ROUTES.AUTH.LOGIN} state={{ from: location }} replace />
    );
  }

  return children;
}

export default AuthGuard;
