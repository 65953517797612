import { Box } from '@chakra-ui/react';
import { useListTypeContext } from '@root/contexts/ListType/context';
import { ListType } from '@root/contexts/ListType/types';
import { IVmContact } from '@typed/contact.types';
import { useEffect } from 'react';
import ContactItem from './ContactItem';

interface Props {
  title: string;
  contacts: IVmContact[];
  onMeasure: () => void;
  onRemoveContact: (contactId: string) => void;
  hiddingContacts: {[k: string]: boolean};
}

function ContactGroup({
  title,
  contacts,
  onMeasure,
  onRemoveContact,
  hiddingContacts,
}: Props) {
  const { type } = useListTypeContext();
  useEffect(() => {
    onMeasure();
  }, [onMeasure, type]);
  return (
    <Box>
      <Box as="label" display="block" fontSize="16px" lineHeight="24px" fontWeight={600} mb="20px" pos="relative">
        <Box as="span" pr="20px" bg="vmWhite">{title}</Box>
        <Box pos="absolute" zIndex={-1} top="50%" transform="translateY(-50%)" left={0} mt="auto" mb="auto" h="1px" bg="vmGray.200" w="full" />
      </Box>
      <Box
        sx={{
          display: type === ListType.GRID ? 'grid' : 'flex',
          alignItems: 'center',
          flexDirection: type === ListType.GRID ? undefined : 'column',
          gap: '20px',
          gridTemplateColumns: type === ListType.GRID ? '1fr 1fr 1fr' : undefined,
        }}
      >
        {contacts.map((contact) => (
          <ContactItem
            key={contact.contactId}
            contact={contact}
            onRemoveContact={onRemoveContact}
            isHidden={!!hiddingContacts[contact.contactId]}
          />
        ))}
      </Box>
    </Box>
  );
}

export default ContactGroup;
