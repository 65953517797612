import { Flex, Spinner } from '@chakra-ui/react';
import NotFound from '@components/NotFound';
import { useParams } from 'react-router-dom';
import PublicQuestionContainer from './container';
import useQuestionForm from './hooks/useQuestionForm';

function PublicQuestion() {
  const { formId } = useParams<'formId'>();
  const { isLoadingFormConfig, form } = useQuestionForm(formId!);

  if (isLoadingFormConfig) {
    return (
      <Flex align="center" justify="center" minH="100vh">
        <Spinner />
      </Flex>
    );
  }

  if (!form) {
    return <NotFound />;
  }

  return (
    <PublicQuestionContainer form={form} />
  );
}

export default PublicQuestion;
