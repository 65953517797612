import {
  Box, Flex, Grid, Skeleton, Spinner, useDisclosure,
} from '@chakra-ui/react';
import AddIcon from '@components/icons/Add';
import ListAction from '@components/ListAction';
import { Link, Route, Routes } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import Fuse from 'fuse.js';
import useFilterSearch from '@hooks/useFilterSearch';
import { useVmStore } from '@root/store';
import BrandAdding from './containers/BrandAdding';
import BrandItem from './components/BrandItem';
import BrandUpdating from './containers/BrandUpdating';
import { Brand } from './types/brand.types';

function Branding() {
  const getAllBrands = useVmStore((state) => state.getBrands);
  const brands = useVmStore((state) => state.brands);
  const {
    isOpen: isLoadingBrand,
    onOpen: startLoadingBrand,
    onClose: finishLoadingBrand,
  } = useDisclosure({ defaultIsOpen: true });
  const { keyword, onKeywordChange } = useFilterSearch();
  const fuse = useMemo<Fuse<Brand>>(() => new Fuse(brands, { keys: ['name'], threshold: 0.1 }), [brands]);

  const listForms = useMemo<Brand[]>(() => {
    if (!keyword) return brands;
    return fuse.search(keyword).map((item) => item.item);
  }, [keyword, brands, fuse]);

  const handleGetBrands = useCallback(async () => {
    try {
      startLoadingBrand();
      await getAllBrands();
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishLoadingBrand();
  }, [finishLoadingBrand, getAllBrands, startLoadingBrand]);

  useEffect(() => {
    handleGetBrands();
  }, [handleGetBrands]);

  return (
    <Grid templateColumns="457px auto">
      <Box bg="vmWhite" minH="100vh">
        <Box h="full" borderRightWidth="1px" borderColor="vmGray.650" pl="15px" pr="23px" py="20px">
          <Flex align="center" justify="space-between" py="8px" px="20px" borderRadius="10px" gap="10px" bg="vmGray.150">
            <Box fontSize="20px" fontWeight={600} lineHeight="26px" color="vmBlack.50">
              Brands&nbsp;
              <Box as="span">{isLoadingBrand ? <Spinner w="13px" h="13px" /> : `(${listForms.length})`}</Box>
            </Box>
            <ListAction
              isLoading={false}
              havingReload
              havingSearch
              onReload={handleGetBrands}
              onKeywordChange={onKeywordChange}
            />
          </Flex>
          {isLoadingBrand ? (
            <Flex mt="25px" direction="column" gap="20px">
              <Skeleton borderRadius="10px" w="full" h="54px" />
              <Skeleton borderRadius="10px" w="full" h="54px" />
              <Skeleton borderRadius="10px" w="full" h="54px" />
              <Skeleton borderRadius="10px" w="full" h="54px" />
            </Flex>
          ) : (
            <Flex mt={listForms.length > 0 ? '25px' : 0} direction="column" gap="20px">
              {listForms.map((brand) => (
                <BrandItem key={brand.brandId} brand={brand} />
              ))}
            </Flex>
          )}
          <Link to="/brands/add">
            <Flex align="center" justify="center" height="85px" mt="20px" borderWidth="2px" borderStyle="dashed" borderColor="vmGray.150" borderRadius="8px" cursor="pointer">
              <AddIcon stroke="vmPrimary.50" strokeWidth={2} />
            </Flex>
          </Link>
        </Box>
      </Box>
      {isLoadingBrand ? (
        <Flex minH="100vh" align="center" justify="center">
          <Spinner />
        </Flex>
      ) : (
        <Routes>
          <Route index element={<Box />} />
          <Route path="add" element={<BrandAdding />} />
          <Route path="/view/:brandId" element={<BrandUpdating />} />
        </Routes>
      )}
    </Grid>
  );
}

export default Branding;
