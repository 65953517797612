import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDisclosure } from '@chakra-ui/react';
import { getAnswersOfQuestion } from '@apis/answer.api';
import { IVmAnswer } from '@typed/answer.types';

interface UseQuestionAnswer {
  answer: IVmAnswer | null;
  isLoadingAnswers: boolean;
  refetch: () => Promise<void>;
}

function useQuestionAnswer(questionId: string): UseQuestionAnswer {
  const [answer, setAnswer] = useState<IVmAnswer | null>(null);
  const {
    isOpen: isLoadingAnswers,
    onOpen: startLoadingAnswers,
    onClose: finishLoadingAnswers,
  } = useDisclosure({ defaultIsOpen: true });

  const getAnswers = useCallback(async () => {
    try {
      startLoadingAnswers();
      const result = await getAnswersOfQuestion(questionId);
      setAnswer(result);
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishLoadingAnswers();
  }, [finishLoadingAnswers, questionId, startLoadingAnswers]);

  useEffect(() => {
    getAnswers();
  }, [getAnswers]);

  return {
    answer,
    isLoadingAnswers,
    refetch: getAnswers,
  };
}

export default useQuestionAnswer;
