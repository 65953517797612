import { Icon, IconProps } from '@chakra-ui/react';

function TickIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 14 12" fill="none" stroke="vmWhite" strokeWidth="2" {...props}>
      <path d="M1.16663 6.83337L4.49996 10.1667L12.8333 1.83337" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  );
}

export default TickIcon;
