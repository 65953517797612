import { chakra, Flex } from '@chakra-ui/react';

const RecordChooserItem = chakra(Flex, {
  baseStyle: {
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    w: '80px',
    h: '80px',
    bg: 'vmWhite',
    borderRadius: '10px',
    borderColor: 'vmGray.50',
    display: 'flex',
    _hover: {
      borderColor: 'vmPrimary.50',
    },
    borderWidth: '2px',
  },
});

export default RecordChooserItem;
