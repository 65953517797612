export default {
  baseStyle: {
    color: '#071E57',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
    mr: '5px',
    mb: '10px',
  },
};
