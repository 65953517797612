export default {
  baseStyle: {
    width: 'full',
    minW: 0,
    outline: 'none',
    pos: 'relative',
    appearance: 'none',
    py: '8px',
    minH: '80px',
    lineHeight: 1.375,
    verticalAlign: 'top',
    fontSize: '16px',
    px: '10px',
    borderWidth: '2px',
  },
  variants: {
    default: {
      bg: 'vmWhite',
      borderRadius: '10px',
      borderColor: 'vmGray.750',
      height: '40px',
      _focus: {
        borderColor: 'vmPrimary.50',
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
};
