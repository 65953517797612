import { Box, Button, Flex } from '@chakra-ui/react';
import BackIcon from '@components/icons/Back';
import CameraSelectorIcon from '@components/TrustRecorder/icons/CameraSelector';
import VideoRecorder from '@components/VideoRecorder';

interface Props {
  simulate: boolean;
  isGettingDevice: boolean;
  onChange: (event: { target: { value: string | null } }) => void;
  onGoBack: () => void;
  oldVideoToken: string | null;
  onRequest: () => Promise<void>;
  showArrowBack: boolean;
}

function TrustRecorderDevicePermissionRequest({
  simulate,
  isGettingDevice,
  onChange,
  onGoBack,
  oldVideoToken,
  onRequest,
  showArrowBack,
}: Props) {
  return (
    <Flex
      direction="column"
      align="center"
      py="40px"
      px={{
        base: '40px',
        md: '125px',
      }}
      gap="35px"
      bg="vmGray.350"
      borderRadius="10px"
      pos="relative"
      h="full"
    >
      <Flex direction="column" align="center" justify="center" pos="relative" gap="30px">
        <Box pos="relative" w="full" textAlign="center" px={{ base: '30px', md: 0 }}>
          <Box
            fontSize="20px"
            lineHeight="26px"
            fontWeight={600}
            color="vmBlack.100"
            bgImage="linear-gradient(180deg, transparent 89%, #48DC6B 0)"
            bgRepeat="no-repeat"
            bgSize="92% 100%"
            bgPos="147px 0"
            textDecoration="none"
            textAlign="center"
            pos="relative"
            display="inline"
          >
            To record video, your browser will need to request access to your camera & microphone
          </Box>
        </Box>
        <Flex align="center" justify="center" h="250px" w="400px" borderRadius="10px" bg="vmGray.1010" mx="auto" pos="relative">
          <CameraSelectorIcon fontSize="40px" />
          <Box w="60px" h="3px" bg="vmGray.600" pos="absolute" top="50%" left="50%" transform="translateX(-50%) rotate(145deg)" />
        </Flex>
        <Button
          type="button"
          onClick={onRequest}
          isDisabled={isGettingDevice}
          isLoading={isGettingDevice}
        >
          Request Permission
        </Button>
      </Flex>
      {showArrowBack && (
        <Flex
          align="center"
          justify="center"
          pos="absolute"
          w="44px"
          h="44px"
          borderRadius="50%"
          bg="vmWhite"
          bottom="40px"
          left={{
            base: '5px',
            ssm: '10px',
            gsm: '15px',
            md: '40px',
          }}
          cursor="pointer"
          onClick={() => onGoBack()}
        >
          <BackIcon fontSize="20px" color="vmPrimary.50" />
        </Flex>
      )}
      <Box display="none">
        <VideoRecorder
          allowUpload
          allowRecord={false}
          localplayback
          proceedInVerfiedProgress
          videoToken={oldVideoToken}
          onChange={(data) => {
            onChange({ target: { value: data.video } });
          }}
          simulate={simulate}
        />
      </Box>
    </Flex>
  );
}

export default TrustRecorderDevicePermissionRequest;
