import {
  Box,
  Button,
  FormControl,
  Textarea,
} from '@chakra-ui/react';
import BackIcon from '@components/icons/Back';
import {
  Controller,
  useFormContext,
  useFormState,
  FieldError,
} from 'react-hook-form';

interface Props {
  name: string;
  onGoBack: () => void;
  title: string;
  text: string;
  allowVideo: boolean;
}

function VmVideoRecorderText({
  name,
  onGoBack,
  title,
  text,
  allowVideo,
}: Props) {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const fieldError = (errors[name] as FieldError) ?? undefined;

  return (
    <Box pl={allowVideo ? '60px' : 0} borderRadius="10px" pos="relative">
      <Box
        fontSize="16px"
        lineHeight="24px"
        fontWeight="600"
        color="vmGray.400"
        mb="15px"
      >
        {title || 'Your Question'}
      </Box>
      {allowVideo && (
        <Box pos="absolute" left="0" top={0}>
          <Button type="button" variant="iconOnly" bg="vmGray.1050" size="iconOnly" onClick={onGoBack}>
            <BackIcon fontSize="14px" stroke="vmPrimary.50" />
          </Button>
        </Box>
      )}
      <FormControl
        isInvalid={!!fieldError?.message}
        p={0}
        borderBottom={0}
        pos="relative"
      >
        <Controller
          render={({ field }) => (
            <Textarea
              rows={5}
              placeholder={text || 'Enter your question'}
              {...field}
            />
          )}
          name={name}
        />
      </FormControl>
    </Box>
  );
}

export default VmVideoRecorderText;
