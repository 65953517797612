import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

// configs
import i18n from '@configs/i18n';
import AUTH_CONFIG from '@configs/auth.config';
import { initAxios } from '@configs/axios';
// themes
import theme from '@root/themes/default/theme';
import { ChakraProvider } from '@chakra-ui/react';

// main application
import App from './App';

// web performance monitor
import reportWebVitals from './reportWebVitals';

initAxios();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <StrictMode>
    <ChakraProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <Auth0Provider
          domain={AUTH_CONFIG.domain}
          clientId={AUTH_CONFIG.clientID}
          cacheLocation="localstorage"
          authorizationParams={AUTH_CONFIG.authorizationParams}
        >
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Auth0Provider>
      </I18nextProvider>
    </ChakraProvider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
