import { useEffect, useMemo, useState } from 'react';

interface UseMaxSizeNotify {
  isMatch: boolean;
}

function useMaxSizeNotify(
  boxElement: HTMLElement | null,
  expectedSize: number = 100,
): UseMaxSizeNotify {
  const [isMatch, setIsMatch] = useState<boolean>((boxElement?.clientWidth ?? 0) < expectedSize);
  const resizeObserver = useMemo(() => (
    new ResizeObserver((entries) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          const firstEntry = entry.contentBoxSize[0];
          if (firstEntry.inlineSize < expectedSize) {
            setIsMatch(true);
          } else {
            setIsMatch(false);
          }
        }
      }
    })
  ), [expectedSize]);

  useEffect(() => {
    if (boxElement) {
      resizeObserver.observe(boxElement);
    }

    return () => {
      if (boxElement) {
        resizeObserver.unobserve(boxElement);
        resizeObserver.disconnect();
      }
    };
  }, [resizeObserver, boxElement]);

  return {
    isMatch,
  };
}

export default useMaxSizeNotify;
