import { Icon, IconProps } from '@chakra-ui/react';

function EditIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 18 18" stroke="none" fill="vmBlack.100" {...props}>
      <path
        d="M1 13.2353L0.46967 12.705L0.25 12.9246V13.2353H1ZM13.2353 1L13.7656 0.46967C13.4727 0.176777 12.9979 0.176777 12.705 0.46967L13.2353 1ZM17 4.76471L17.5303 5.29504C17.8232 5.00214 17.8232 4.52727 17.5303 4.23438L17 4.76471ZM4.76471 17V17.75H5.07537L5.29504 17.5303L4.76471 17ZM1 17H0.25C0.25 17.4142 0.585786 17.75 1 17.75L1 17ZM1.53033 13.7656L13.7656 1.53033L12.705 0.46967L0.46967 12.705L1.53033 13.7656ZM12.705 1.53033L16.4697 5.29504L17.5303 4.23438L13.7656 0.46967L12.705 1.53033ZM16.4697 4.23438L4.23438 16.4697L5.29504 17.5303L17.5303 5.29504L16.4697 4.23438ZM4.76471 16.25H1V17.75H4.76471V16.25ZM1.75 17V13.2353H0.25V17H1.75ZM9.88143 4.35386L13.6461 8.11857L14.7068 7.05791L10.9421 3.2932L9.88143 4.35386Z"
      />
    </Icon>
  );
}

export default EditIcon;
