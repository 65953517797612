import { useCallback } from 'react';
import { toast } from 'react-toastify';
import AlertMessageContent from '@components/AlertMessage/AlertMessageContent';
import { AlertType } from '@components/AlertMessage/type';

function useToastMessage() {
  const notify = useCallback((type: AlertType, message: string) => {
    toast.success(
      <AlertMessageContent
        type={type}
        message={message}
      />,
      {
        icon: false,
        hideProgressBar: true,
        containerId: 've-notify',
        style: {
          padding: '10px 15px',
          minHeight: '44px',
        },
        bodyStyle: {
          padding: 0,
        },
      },
    );
  }, []);

  return {
    notify,
  };
}

export default useToastMessage;
