import {
  Box,
  Button,
  Flex,
  Input,
  Spinner,
} from '@chakra-ui/react';
import UploadIcon from '@components/icons/Upload';
import RemoveVideoButton from '@components/RemoveVideoButton';
import PlayIcon from '@components/TrustRecorder/icons/Play';
import VideoRecorder from '@components/VideoRecorder';
import VMVideoPlayer from '@components/VMVideoPlayer';
import useRecorderRef from '@components/VmVideoRecorder/hooks/useRecorderRef';
import useRecorderUpload from '@components/VmVideoRecorder/hooks/useRecorderUpload';
import RecordChooserItem from '@components/VmVideoRecorder/styled/RecordChooserItem.styled';
import useRecorderPlayingState from '@hooks/useRecorderPlayingState';
import { ZiggeoProgressType } from '@typed/ziggeo-recorder';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  onStartUpload: () => void;
  onDeleteUploadFile: () => void;
}

function VmVideoUploader({ name, onStartUpload, onDeleteUploadFile }: Props) {
  const { recorder, setRecorderRef } = useRecorderRef();
  const { isPlaying, isReadyToPlay } = useRecorderPlayingState(recorder);
  const { control, setValue, watch } = useFormContext();
  const {
    id,
    inputRef,
    isFileExist,
    setFileExist,
    // isUploadMode,
    handleSelectFile,
    handleResetVideo,
  } = useRecorderUpload(recorder, onStartUpload, onDeleteUploadFile);
  const videoToken = watch(name);

  const handleDeleteVideo = useCallback(() => {
    setValue(name, null);
    handleResetVideo();
  }, [handleResetVideo, name, setValue]);

  return (
    <Flex align="center" justify="center" direction="column" gap="30px" w="full">
      {!videoToken && (
        <RecordChooserItem as="label" htmlFor={isFileExist ? '' : `ziggei$-${id}`}>
          {isFileExist ? (
            <Spinner w="30px" h="30px" />
          ) : (
            <Box cursor="pointer">
              <UploadIcon fill="vmPrimary.50" fontSize="30px" />
            </Box>
          )}
        </RecordChooserItem>
      )}
      <Box pos="relative" width="full" h={videoToken ? 'full' : 0}>
        <Input ref={inputRef} id={`ziggei$-${id}`} type="file" accept="video/mp4,video/x-m4v,video/*" hidden onChange={handleSelectFile} />
        <Box
          boxShadow="none !important"
          sx={{
            display: videoToken ? 'block' : 'none',
            '& .ba-videorecorder-container': {
              borderRadius: '10px',
            },
            '& .ba-videoplayer-container': {
              borderRadius: '10px',
            },
            '& .css-19w1vec .ba-videorecorder-chooser-button-container': {
              w: '90%',
            },
            '& .ba-videorecorder-chooser-container': {
              bg: '#15334B',
            },
            '& .ba-videoplayer-rerecord-button-container': {
              display: 'none',
            },
            '& .ba-player-rerecord-frontbar': {
              display: 'none',
            },
            '& .ba-videoplayer-rerecord-bar': {
              display: 'none',
            },
            '& .ba-videoplayer-playbutton-container': {
              display: 'none',
            },
            '& .ba-player-dashboard': {
              display: 'none',
            },
          }}
        >
          <Box>
            <Box pos="relative" h="full" w="full">
              {videoToken ? (
                <VMVideoPlayer
                  source={null}
                  videoToken={videoToken}
                  setOuterRef={setRecorderRef}
                />
              ) : (
                <Controller
                  control={control}
                  name={name}
                  render={({ field }) => (
                    <VideoRecorder
                      allowUpload
                      allowRecord
                      proceedInVerfiedProgress
                      localplayback
                      videoToken={videoToken || null}
                      onChange={(data) => {
                        field.onChange(data.video);
                      }}
                      setOuterRef={setRecorderRef}
                      onProgressAction={(action: ZiggeoProgressType) => {
                        if (action === ZiggeoProgressType.VERIFIED) {
                          setFileExist(false);
                        }
                      }}
                    />
                  )}
                />
              )}
              {!isPlaying && isReadyToPlay && (
                <Flex
                  align="center"
                  justify="center"
                  className="player-f"
                  w="44px"
                  h="44px"
                  bg="rgba(255, 255, 255, 0.25)"
                  borderRadius="50%"
                  cursor="pointer"
                  mb={0}
                  onClick={() => {
                    recorder.play();
                  }}
                  pos="absolute"
                  bottom={0}
                  top={0}
                  left={0}
                  right={0}
                  m="auto"
                  zIndex={90}
                >
                  <PlayIcon fontSize="22px" />
                </Flex>
              )}
            </Box>
            {videoToken && (
              <Flex mt="20px" align="center" justify="center" gap="20px">
                <RemoveVideoButton
                  shouldCallProvider={false}
                  videoToken={videoToken ?? null}
                  onDeleteSuccess={handleDeleteVideo}
                >
                  <Button
                    type="button"
                    variant="solidPrimary"
                  >
                    Delete Video
                  </Button>
                </RemoveVideoButton>
              </Flex>
            )}
          </Box>
        </Box>
      </Box>
    </Flex>
  );
}

export default VmVideoUploader;
