export default {
  parts: ['dialogContainer', 'overlay', 'dialog', 'closeButton', 'body'],
  baseStyle: {
    px: '25px',
    overlay: {
      bg: 'blackAlpha.600',
      zIndex: 'modal',
    },
    dialogContainer: {
      display: 'flex',
      zIndex: 'modal',
      justifyContent: 'center',
      overscrollBehaviorY: 'none',
    },
    dialog: {
      borderRadius: 'md',
      bg: 'vmWhite',
      color: 'inherit',
      my: '16',
      zIndex: 'modal',
      boxShadow: 'lg',
    },
    closeButton: {
      position: 'absolute',
      top: '2',
      insetEnd: '3',
    },
    body: {
      p: '40px',
      flex: '1',
      overflow: 'auto',
    },
  },
};
