import {
  Box,
  Button,
  Fade,
  Flex,
  Grid,
  useDisclosure,
} from '@chakra-ui/react';
import {
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import update from 'immutability-helper';
import Intro from '@modules/VideoEditor/components/Intro/Intro';
import Scene from '@modules/VideoEditor/components/Scene/Scene';
import AddIcon from '@components/icons/Add';
import { VideoEditor, VideoEditorScene } from '@modules/VideoEditor/types/video-editor.types';
import { generateGuid } from '@utils/guid.util';
import MediaLibrarySelector from '@modules/VideoEditor/components/MediaLibrarySelector/MediaLibrarySelector';
import { Media } from '@shared/MediaLibrary/types/media';
import SettingIcon from '@components/icons/Setting';
import MediaIcon from '@components/icons/ml/Media';
import VideoSceneDetail from './VideoSceneDetail';

interface Props {
  videoEditorConfig: VideoEditor;
  onCreateScene: (scene: VideoEditorScene) => void;
}

function VideoScenes({
  videoEditorConfig,
  onCreateScene,
}: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { getValues, setValue, watch } = useFormContext();
  const scenes: VideoEditorScene[] = watch('scenes');
  const [activeSceneId, setActiveSceneId] = useState<string | null>(null);
  const {
    isOpen: isLibraryOpen,
    onClose: closeLibrary,
    onOpen: openLibrary,
  } = useDisclosure();

  useEffect(() => {
    const pathName = location.pathname;
    const sceneId = pathName.split('/').pop();
    if (sceneId === 'scenes') {
      setActiveSceneId(null);
    } else {
      setActiveSceneId(sceneId ?? null);
    }
  }, [location.pathname]);

  const handleCreateScene = useCallback((file: Media) => {
    const newScene: VideoEditorScene = file.type === 'image' ? ({
      id: generateGuid(),
      imageUrl: file.imageUrl,
      videoVideoUrl: '',
      videoToken: null,
      videoUrl: null,
      videoImageUrl: null,
      width: null,
      height: null,
      codedWidth: null,
      codedHeight: null,
      mediaId: file.id,
    }) : ({
      id: generateGuid(),
      imageUrl: '',
      imageDuration: null,
      videoVideoUrl: file.videoVideoUrl,
      videoDuration: file.videoDuration,
      trimmingStart: null,
      trimmingEnd: 0,
      videoToken: file.videoToken,
      videoUrl: file.videoUrl,
      videoImageUrl: file.videoImageUrl,
      width: file.videoWidth ?? null,
      height: file.videoHeight ?? null,
      codedWidth: null,
      codedHeight: null,
      mediaId: file.id,
    });
    onCreateScene(newScene);
    closeLibrary();
  }, [closeLibrary, onCreateScene]);

  const handleChangeScenePosition = useCallback((itemIndex: number, targetIndex: number) => {
    const preScenes: VideoEditorScene[] = getValues('scenes') ?? [];
    setValue('scenes', [...update(preScenes, {
      $splice: [
        [itemIndex, 1],
        [targetIndex, 0, preScenes[itemIndex] as VideoEditorScene],
      ],
    })], { shouldDirty: true });
  }, [getValues, setValue]);

  const handleDeleteScene = useCallback((sceneId: string) => {
    const preScenes: VideoEditorScene[] = getValues('scenes') ?? [];
    setValue('scenes', preScenes.filter((sceneItem) => sceneItem.id !== sceneId), { shouldDirty: true });
    if (activeSceneId === sceneId) {
      setActiveSceneId(null);
      navigate('../');
    }
  }, [getValues, setValue, activeSceneId, navigate]);

  useEffect(() => {
    const lastPath = window.location.pathname.split('/').pop();
    if (lastPath !== 'scenes') {
      navigate('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {isLibraryOpen ? (
        <Fade in={isLibraryOpen}>
          <MediaLibrarySelector
            brandId={videoEditorConfig.brandId}
            onClose={closeLibrary}
            onSelectFile={handleCreateScene}
            selectedId={null}
          />
        </Fade>
      ) : (
        <Box pt="64px">
          <Grid w="full" templateColumns="480px auto" minH="calc(100vh - 64px)" pt="20px" px="20px" pb="20px">
            <Flex direction="column" gap="2px" bg="vmGray.150" borderRadius="10px" overflow="hidden" h="calc(100vh - 64px - 40px)">
              <Flex align="center" justify="center" pos="relative" bg="vmWhite">
                <Box
                  fontSize="24px"
                  lineHeight="32.4px"
                  fontWeight={600}
                  px="20px"
                  py="27px"
                >
                  {t('video_editor.labels.video_scenes')}
                </Box>
                <Box pos="absolute" m="auto" right="20px" top="auto" bottom="auto">
                  <Link to="./../../settings">
                    <Button variant="iconOnly" size="iconOnly" borderColor="vmGray.250" bg="vmGray.700">
                      <SettingIcon stroke="vmPrimary.50" />
                    </Button>
                  </Link>
                </Box>
              </Flex>
              <DndProvider backend={HTML5Backend}>
                <Flex direction="column" gap="20px" bg="vmWhite" py="20px" px="20px" h="calc(100vh - 87px - 54px - 104px - 20px - 4px)" overflowX="hidden" overflowY="auto">
                  <Intro isOutro={false} isActive={activeSceneId === 'intro'} title={t('video_editor.labels.add_intro')} />
                  {scenes.map((scene, index: number) => (
                    <Scene
                      key={scene.id}
                      allowDrag
                      isActive={activeSceneId === scene.id}
                      sceneIndex={index}
                      scene={scene}
                      onChangeScenePosition={handleChangeScenePosition}
                      onDelete={handleDeleteScene}
                    />
                  ))}
                  <Intro isOutro isActive={activeSceneId === 'outro'} title={t('video_editor.labels.add_outro')} />
                </Flex>
              </DndProvider>
              <Flex align="center" gap="2px" mt="auto">
                <Flex align="center" justify="center" gap="12px" bg="vmWhite" p="15px" w="full" cursor="pointer" onClick={openLibrary}>
                  <MediaIcon stroke="vmPrimary.50" />
                  <Box fontSize="16px" lineHeight="24px" fontWeight={600} color="vmBlack.100">
                    {t('ml.title')}
                  </Box>
                </Flex>
                <Flex as={Link} to="./../new" align="center" justify="center" gap="12px" bg="vmWhite" p="15px" w="full" cursor="pointer">
                  <AddIcon stroke="vmPrimary.50" />
                  <Box fontSize="16px" lineHeight="24px" fontWeight={600} color="vmBlack.100">
                    {t('video_editor.labels.add_image')}
                  </Box>
                </Flex>
              </Flex>
            </Flex>
            <Flex align="center" justify="center">
              <Routes>
                <Route index element={<Box />} />
                <Route path=":id" element={<VideoSceneDetail />} />
              </Routes>
            </Flex>
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default memo(VideoScenes);
