import { sendPublicQuestion } from '@apis/pubic-form';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  useConst,
  useDisclosure,
} from '@chakra-ui/react';
import ErrorMessage from '@components/ErrorMessage';
import VmVideoRecorder from '@components/VmVideoRecorder';
import { yupResolver } from '@hookform/resolvers/yup';
import { IForm } from '@modules/Forms/types/form.types';
import WelcomeVideo from '@modules/PublicQuestion/parts/WelcomeVideo';
import getPublicQuestionSchema from '@modules/PublicQuestion/schemas/public-question.schema';
import { PublicQuestion } from '@modules/PublicQuestion/types/public-question';
import { useCallback, useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface Props {
  form: IForm;
}

function FormQuestion({
  form,
}: Props) {
  const navigate = useNavigate();
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const defaultValues = useConst(() => ({
    customerId: form.customerId,
    brandId: form.brandId,
    firstname: '',
    lastname: '',
    company: '',
    email: '',
    text: '',
    videoToken: null,
    gaveConsent: false,
  }));
  const methods = useForm<PublicQuestion>({
    defaultValues,
    resolver: yupResolver(getPublicQuestionSchema(form)),
    mode: 'onSubmit',
  });
  const { errors } = useFormState({ control: methods.control });
  const gaveConsent = methods.watch('gaveConsent');
  const {
    isOpen: isSendingQuestion,
    onOpen: startSendingQuestion,
    onClose: finishSendingQuestion,
  } = useDisclosure();

  const handleReset = useCallback(() => {
    methods.reset(defaultValues, { keepDefaultValues: true, keepDirty: false, keepErrors: false });
  }, [defaultValues, methods]);

  const onSubmit = useCallback(async (values: PublicQuestion) => {
    try {
      startSendingQuestion();
      await sendPublicQuestion(form.formId, values);
      toast.success('The question has been sent successfully');
      handleReset();
      if (form.thankYou.redirect) {
        window.location.href = form.thankYou.redirectUrl!;
        return;
      }
      navigate('thank-you');
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishSendingQuestion();
  }, [
    finishSendingQuestion,
    form.formId,
    form.thankYou.redirect,
    form.thankYou.redirectUrl,
    handleReset,
    navigate,
    startSendingQuestion,
  ]);

  const shouldDisable = (
    (form.personalData.requireConsent && !gaveConsent)
    || isSendingQuestion
    || isRecording
  );

  return (
    <>
      <FormProvider {...methods}>
        <Flex align="center" w="full" minH="100vh" bg="vmWhite">
          <Flex w="540px" borderRadius="10px" py="40px" minH="100vh" mx="auto">
            <Box w="full" as="form" onSubmit={methods.handleSubmit(onSubmit)}>
              <Box px="60px" mb="20px">
                <Flex align="center" pb="5px" borderBottomWidth="2px" borderColor="vmPrimary.50" w="full">
                  <Image maxWidth="75px" src="/static-data/images/logo-green.png" alt="Logo" mx="auto" />
                </Flex>
                <Box fontSize="22px" lineHeight="33px" fontWeight={600} my="20px" textAlign="center">
                  {form.intro.title}
                </Box>
                <Box fontSize="16px" lineHeight="24px" fontWeight={400} textAlign="center">
                  {form.intro.text}
                </Box>
              </Box>
              <Box px="60px">
                <FormControl isInvalid={!!errors?.firstname?.message}>
                  <FormLabel>First name</FormLabel>
                  <Input type="text" placeholder="First name" {...methods.register('firstname')} />
                  <ErrorMessage error={errors?.firstname?.message} />
                </FormControl>
                <FormControl isInvalid={!!errors?.lastname?.message}>
                  <FormLabel>Last name</FormLabel>
                  <Input type="text" placeholder="Last name" {...methods.register('lastname')} />
                  <ErrorMessage error={errors?.lastname?.message} />
                </FormControl>
                <FormControl isInvalid={!!errors?.email?.message}>
                  <FormLabel>{form.personalData.email}</FormLabel>
                  <Input type="text" placeholder={form.personalData.emailPlaceholder} {...methods.register('email')} />
                  <ErrorMessage error={errors?.email?.message} />
                </FormControl>
                <Box mt="30px">
                  <VmVideoRecorder
                    label=""
                    name="videoToken"
                    allowText={form.intro.enableTextQuestion}
                    allowVideo={form.intro.enableVideoQuestion}
                    textName="text"
                    textTitle={form.question.titleTextQuestion}
                    textPlaceholder={form.question.textTextQuestion}
                    videoTitle={form.question.titleVideoQuestion}
                    videoText={form.question.textVideoQuestion}
                    onStartVideoProgress={() => setIsRecording(true)}
                    onFinishVideoProgress={() => setIsRecording(false)}
                  />
                  <FormControl p={0} isInvalid={!!errors?.videoToken?.message}>
                    <ErrorMessage error={errors?.videoToken?.message} />
                  </FormControl>
                </Box>
                <Box
                  mt="30px"
                  pt="30px"
                  borderTopWidth="2px"
                  borderColor="vmGray.450"
                >
                  {form.personalData.requireConsent && (
                    <Flex align="center" justify="center" mb="30px">
                      <Checkbox
                        size="lg"
                        iconSize="35px"
                        {...methods.register('gaveConsent')}
                        autoFocus
                        sx={{
                          '& > .chakra-checkbox__control': {
                            boxShadow: 'none',
                          },
                          '& > .chakra-checkbox__control[data-focus]': {
                            boxShadow: 'none',
                          },
                        }}
                      >
                        <Box
                          as="p"
                          fontSize="14px"
                          sx={{
                            '& a': {
                              color: 'vmPrimary.50',
                            },
                          }}
                          dangerouslySetInnerHTML={{ __html: form.personalData.consentText }}
                        />
                      </Checkbox>
                    </Flex>
                  )}
                  <Flex
                    align="center"
                    justify="center"
                    gap="20px"
                  >
                    <Button
                      type="button"
                      variant="solidPrimary"
                      onClick={handleReset}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      isLoading={isSendingQuestion}
                      isDisabled={shouldDisable}
                    >
                      Send
                    </Button>
                  </Flex>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Flex>
      </FormProvider>
      <WelcomeVideo
        showVideoMessage={form.intro.showVideoMessage}
        videoMessageToken={form.intro.videoMessageToken}
      />
    </>
  );
}

export default FormQuestion;
