import { Icon, IconProps } from '@chakra-ui/react';

function CheckRounded(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" {...props} fill="vmBlack.100">
      <path
        d="M9.75 0.25C7.82164 0.25 5.93657 0.821828 4.33319 1.89317C2.72982 2.96451 1.48013 4.48726 0.742179 6.26884C0.00422452 8.05042 -0.188858 10.0108 0.187348 11.9021C0.563554 13.7934 1.49215 15.5307 2.85571 16.8943C4.21928 18.2579 5.95656 19.1865 7.84787 19.5627C9.73919 19.9389 11.6996 19.7458 13.4812 19.0078C15.2627 18.2699 16.7855 17.0202 17.8568 15.4168C18.9282 13.8134 19.5 11.9284 19.5 10C19.495 7.41566 18.4662 4.93859 16.6388 3.11118C14.8114 1.28378 12.3343 0.254956 9.75 0.25ZM14.3906 8.29375L8.89688 13.5438C8.75485 13.6774 8.56687 13.7512 8.37188 13.75C8.27657 13.7514 8.18194 13.7338 8.09344 13.6984C8.00494 13.663 7.92433 13.6105 7.85625 13.5438L5.10938 10.9188C5.03319 10.8523 4.97123 10.7711 4.92722 10.6801C4.88321 10.589 4.85806 10.49 4.85328 10.389C4.8485 10.2881 4.86419 10.1871 4.89941 10.0924C4.93463 9.99758 4.98865 9.9109 5.05822 9.83754C5.1278 9.76417 5.21149 9.70563 5.30426 9.66543C5.39703 9.62523 5.49698 9.6042 5.59809 9.60362C5.69919 9.60303 5.79938 9.62289 5.89261 9.66201C5.98585 9.70113 6.07021 9.7587 6.14063 9.83125L8.37188 11.9594L13.3594 7.20625C13.5052 7.07902 13.6946 7.01309 13.8879 7.02223C14.0812 7.03138 14.2635 7.1149 14.3967 7.25533C14.5298 7.39576 14.6036 7.58222 14.6024 7.77575C14.6013 7.96928 14.5254 8.15488 14.3906 8.29375Z"
      />
    </Icon>
  );
}

export default CheckRounded;
