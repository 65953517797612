import { memo, useCallback } from 'react';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import useElementRef from '@hooks/useElementRef';
import { toast } from 'react-toastify';
import { downloadImageMedia } from '@apis/media.api';
import { downloadFile } from '@apis/file.api';
import MediaLibrary from '@shared/MediaLibrary/MediaLibrary';
import useMediaLibrary from '@shared/MediaLibrary/hooks/useMediaLibrary';
import { Media } from '@shared/MediaLibrary/types/media';
import useDeleteMedia from '@shared/MediaLibrary/hooks/useDeleteMedia';
import useSelectedMediaFiles from '@shared/MediaLibrary/hooks/useSelectedMediaFiles';
import BottomBarDeleteSelected from '@shared/MediaLibrary/components/BottomBarDeleteSelected';
import BottomBarDownloader from '@shared/MediaLibrary/components/BottomBarDownloader';
import { EMPTY_ARRAY, findItemsInArray } from '@utils/array.util';
import { downloadFile as downloadOriginalFile } from '@utils/file.util';
import { useVmStore } from '@store/index';
import { selectedBrandSelector } from '@store/slices/brand/selectors';

function MediaLibraryContainer() {
  const { t } = useTranslation();
  const [containerRef, containerElement] = useElementRef<HTMLDivElement>();
  const brandId = useVmStore(selectedBrandSelector);
  const {
    medias,
    flattenMedias,
    handleKeywordChange,
    filterType,
    isLoadingMedia,
    itemCountPerRow,
    setFilterType,
    handleUploadSuccess,
    handleRefresh,
    handleDeleteSuccess,
  } = useMediaLibrary(brandId!, containerElement);
  const {
    selectedFiles,
    handleFileSelected,
    havingSelectedFile,
  } = useSelectedMediaFiles(true, EMPTY_ARRAY);
  const handleDeteleMediaSucess = useCallback((ids: string[]) => {
    handleDeleteSuccess(ids);
    handleFileSelected(ids.reduce((hashMap, id) => ({
      ...hashMap,
      [id]: false,
    }), {}));
  }, [handleDeleteSuccess, handleFileSelected]);
  const {
    isDeleteingMedia,
    handleDeleteMedia,
  } = useDeleteMedia(selectedFiles, flattenMedias, handleDeteleMediaSucess);
  const {
    isOpen: isDownloadingMedia,
    onClose: finishDownloadingMedia,
    onOpen: startDownloadingMedia,
  } = useDisclosure();

  const handleDownloadMedia = useCallback(async () => {
    try {
      startDownloadingMedia();
      const listIds = Object.keys(selectedFiles).filter((id) => selectedFiles[id]);
      if (listIds.length === 0) return;
      const imageMedias = findItemsInArray<Media>(flattenMedias, listIds);
      const imageFiles = imageMedias.filter((item) => item.type === 'image');
      const videoFiles = imageMedias.filter((item) => item.type === 'video');
      if (imageFiles.length > 0) {
        await Promise.allSettled(imageFiles.map((imageFile) => (
          downloadImageMedia(imageFile.id).then((data) => {
            downloadOriginalFile(data, imageFile.name);
          }).catch((error) => {
            toast.error(error?.message);
          })
        )));
      }
      if (videoFiles.length > 0) {
        await Promise.allSettled(videoFiles.map((videoFile) => downloadFile(`https://${videoFile.videoVideoUrl}.mp4`, videoFile.name)));
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishDownloadingMedia();
  }, [finishDownloadingMedia, startDownloadingMedia, selectedFiles, flattenMedias]);

  return (
    <Box>
      <Box
        borderRadius="10px"
        p="20px"
        sx={{
          '& .media-library-list-wrapper': {
            h: 'calc(100vh - 88px - 37px - 20px - 66px - 40px - 107px)',
          },
        }}
      >
        <MediaLibrary
          ref={containerRef}
          onFileSelected={handleFileSelected}
          medias={medias}
          handleKeywordChange={handleKeywordChange}
          filterType={filterType}
          isLoadingMedia={isLoadingMedia}
          itemCountPerRow={itemCountPerRow}
          setFilterType={setFilterType}
          handleUploadSuccess={handleUploadSuccess}
          handleRefresh={handleRefresh}
          selectedFiles={selectedFiles}
        />
        <Box bg="vmGray.150" borderRadius="10px">
          <Flex align="center" justify="center" mt="2px" gap="20px" p="20px" pos="relative" borderBottomLeftRadius="10px" borderBottomRightRadius="10px">
            <Flex align="center" gap="41px">
              <BottomBarDeleteSelected
                isDeleting={isDeleteingMedia}
                isDisabled={isDownloadingMedia || !havingSelectedFile || isDeleteingMedia}
                onDeleteVideo={handleDeleteMedia}
                buttonText={t('ml.labels.delete_video')}
                confirmMessage={t('ml.message.delete_confirm')}
              />
              <Box>
                <Box w="2px" h="full" bg="vmWhite" pos="absolute" top={0} />
              </Box>
              <BottomBarDownloader
                isDisabled={isDownloadingMedia || !havingSelectedFile || isDeleteingMedia}
                isDownloading={isDownloadingMedia}
                onDownloadVideo={handleDownloadMedia}
                title={t('ml.labels.download')}
              />
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
}

export default memo(MediaLibraryContainer);
