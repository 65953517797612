import { getVideoEditorConfig } from '@apis/video-editor.api';
import { useDisclosure } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { VideoEditor, VideoEditorSettings } from '@modules/VideoEditor/types/video-editor.types';

interface UseVideoEditor {
  videoEditorConfig: VideoEditor | null;
  isGettingVideoEditorConfig: boolean;
  onUpdateSettings: (settings: VideoEditorSettings) => void;
}

function useVideoEditor(brandId: string, answerId: string): UseVideoEditor {
  const [videoEditorConfig, setVideoEditorConfig] = useState<VideoEditor | null>(null);
  const {
    isOpen: isGettingVideoEditorConfig,
    onOpen: startGettingVideoEditorConfig,
    onClose: finishGettingVideoEditorConfig,
  } = useDisclosure({ defaultIsOpen: true });

  const handleLoadVideoEditorConfig = useCallback(async () => {
    try {
      startGettingVideoEditorConfig();
      const result = await getVideoEditorConfig(brandId, answerId);
      setVideoEditorConfig(result);
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishGettingVideoEditorConfig();
  }, [brandId, finishGettingVideoEditorConfig, startGettingVideoEditorConfig, answerId]);

  const handleUpdateVideoEditorSettings = useCallback((settings: VideoEditorSettings) => {
    setVideoEditorConfig((currentVideoEditorConfig) => ({
      ...currentVideoEditorConfig!,
      settings,
    }));
  }, []);

  useEffect(() => {
    handleLoadVideoEditorConfig();
  }, [handleLoadVideoEditorConfig]);

  return {
    videoEditorConfig,
    isGettingVideoEditorConfig,
    onUpdateSettings: handleUpdateVideoEditorSettings,
  };
}

export default useVideoEditor;
