import { Box } from '@chakra-ui/react';
import MediaLibraryContainer from './MediaLibraryContainer';

function MediaLibrary() {
  return (
    <Box w="1440px" mx="auto" px="20px" maxW="full" pt="108px" pb="80px">
      <MediaLibraryContainer />
    </Box>
  );
}

export default MediaLibrary;
