import { Box, Flex, Skeleton } from '@chakra-ui/react';
import { memo } from 'react';

function VideoLibraryLoadingItem() {
  return (
    <Flex direction="column" w="full" gap="10px">
      <Box w="full" borderRadius="4px">
        <Skeleton w="full" h="192px" />
      </Box>
      <Box />
    </Flex>
  );
}

export default memo(VideoLibraryLoadingItem);
