import { Icon, IconProps } from '@chakra-ui/react';

function VideoIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 22 22" fill="none" stroke="vmGray.600" {...props}>
      <path
        d="M20.9999 16V4C20.9999 2.34315 19.6567 1 17.9999 1H5.99989M6.49989 16.04L12.2499 12.77L6.49989 9.5V16.04ZM4.00003 21H13.9999C15.6567 21 16.9999 19.6569 16.9999 18V8C16.9999 6.34315 15.6567 5 13.9999 5L4 5C2.34314 5 0.999994 6.34315 1 8.00001L1.00003 18C1.00004 19.6569 2.34318 21 4.00003 21Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default VideoIcon;
