import { memo } from 'react';
import { IVmContactUpdateModel } from '@typed/contact.types';
import { useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '@components/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object({
  firstname: yup.string().trim().required('common.validation.required'),
  lastname: yup.string().trim().required('common.validation.required'),
  email: yup.string().trim().required('common.validation.required').email('common.validation.email'),
});

interface Props {
  defaultValues: IVmContactUpdateModel;
  isLoading: boolean;
  onSubmit: (values: IVmContactUpdateModel) => Promise<void>;
}

function ContactControl({
  defaultValues,
  isLoading,
  onSubmit,
}: Props) {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    register,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = useFormState({ control });

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={!!errors?.firstname?.message}>
        <FormLabel>{t('common.labels.first_name')}</FormLabel>
        <Input
          type="text"
          placeholder={t('common.placeholders.first_name')}
          {...register('firstname')}
        />
        <ErrorMessage error={errors?.firstname?.message} />
      </FormControl>
      <FormControl isInvalid={!!errors?.lastname?.message}>
        <FormLabel>{t('common.labels.last_name')}</FormLabel>
        <Input
          type="text"
          placeholder={t('common.placeholders.last_name')}
          {...register('lastname')}
        />
        <ErrorMessage error={errors?.lastname?.message} />
      </FormControl>
      <FormControl isInvalid={!!errors?.email?.message}>
        <FormLabel>{t('common.labels.email')}</FormLabel>
        <Input
          type="text"
          placeholder={t('common.placeholders.email')}
          {...register('email')}
        />
        <ErrorMessage error={errors?.email?.message} />
      </FormControl>
      <FormControl isInvalid={!!errors?.company?.message}>
        <FormLabel>{t('common.labels.company')}</FormLabel>
        <Input
          type="text"
          placeholder={t('common.placeholders.company')}
          {...register('company')}
        />
        <ErrorMessage error={errors?.company?.message} />
      </FormControl>
      <Box mt="20px">
        <Button isLoading={isLoading} isDisabled={isLoading} type="submit">{t('common.button.texts.save')}</Button>
      </Box>
    </Box>
  );
}

export default memo(ContactControl);
