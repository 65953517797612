import { Icon, IconProps } from '@chakra-ui/react';

function TextIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" fill="vmPrimary.50" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33333 0C1.49236 0 0 1.49241 0 3.33333V16.6667C0 18.5076 1.49236 20 3.33333 20H16.6667C18.5076 20 20 18.5076 20 16.6667V3.33333C20 1.49241 18.5076 0 16.6667 0H3.33333ZM6.66667 6.92312V5.83333H9.16667V14.1667H8.10608C7.64582 14.1667 7.27275 14.5398 7.27275 15C7.27275 15.4602 7.64582 15.8333 8.10608 15.8333H11.8939C12.3541 15.8333 12.7273 15.4602 12.7273 15C12.7273 14.5398 12.3541 14.1667 11.8939 14.1667H10.8333V5.83333H13.3333V6.92312C13.3333 7.38332 13.7065 7.75645 14.1667 7.75645C14.6269 7.75645 15 7.38332 15 6.92312V5.76925C15 5.34831 14.8354 4.94202 14.5385 4.6406C14.2411 4.33868 13.8351 4.16667 13.4091 4.16667H6.59093C6.16486 4.16667 5.75882 4.33868 5.46148 4.6406C5.16459 4.94202 5 5.34831 5 5.76925V6.92312C5 7.38332 5.37308 7.75645 5.83333 7.75645C6.29359 7.75645 6.66667 7.38332 6.66667 6.92312Z"
      />
    </Icon>
  );
}

export default TextIcon;
