import { Box, Flex, Button } from '@chakra-ui/react';
import LabelHeader from '@components/LabelHeader';
import ListAction from '@components/ListAction';
import SortDropdown from '@components/SortDropdown';
import useFilterSearch from '@hooks/useFilterSearch';
import useVLStatus from '@modules/VideoLibrary/hooks/useVLStatus';
import useVLVideos from '@modules/VideoLibrary/hooks/useVLVideos';
import VideoLibraryList from '@modules/VideoLibrary/parts/VideoLiraryList';
import { emptyFunction } from '@utils/func.util';
import { useTranslation } from 'react-i18next';

function VLList() {
  const { t } = useTranslation();
  const { onKeywordChange } = useFilterSearch();
  const { status, onStatusChange } = useVLStatus();
  const { videos, isLoadingVideos } = useVLVideos();

  return (
    <Box w="1200px" mx="auto" px="20px" maxW="full" pt="86px" pb="80px">
      <Box px="20px" bg="vmGray.150" py="8px" borderRadius="10px">
        <Flex
          align="center"
          justify="space-between"
          gap={{
            base: '20px',
            lxl: 0,
          }}
          direction={{
            base: 'column',
            nxl: 'row',
          }}
          sx={{
            '& .input-search-jp': {
              w: { base: '180px !important', hxl: '300px !important' },
            },
          }}
        >
          <Flex align="center" gap="20px">
            <LabelHeader pl={0}>{t('vl.title')}</LabelHeader>
          </Flex>
          <Flex align="center" gap="20px">
            <ListAction
              isLoading={false}
              havingSearch
              havingReload
              onKeywordChange={onKeywordChange}
              onReload={emptyFunction}
            />
          </Flex>
        </Flex>
      </Box>
      <Flex mt="20px" gap="20px">
        <SortDropdown
          onSortChange={emptyFunction}
        />
        <Button
          px="10px"
          py="5.5px"
          bg="vmGray.450"
          borderColor={status === 'reply' ? 'vmBlack.150' : 'vmBlack.100'}
          borderWidth="1px"
          borderRadius="100px"
          onClick={() => onStatusChange('reply')}
          textTransform="unset"
        >
          <Box fontSize="14px" lineHeight="21px" color="vmBlack.100" fontWeight={400}>reply</Box>
        </Button>
        <Button
          px="10px"
          py="5.5px"
          bg="vmGray.450"
          borderColor={status === 'edited' ? 'vmBlack.150' : 'vmBlack.100'}
          borderWidth="1px"
          borderRadius="100px"
          onClick={() => onStatusChange('edited')}
          textTransform="unset"
        >
          <Box fontSize="14px" lineHeight="21px" color="vmBlack.100" fontWeight={400}>edited</Box>
        </Button>
      </Flex>
      <Box mt="20px" h="100vh">
        <VideoLibraryList
          items={videos}
          isLoading={isLoadingVideos}
          itemPerRow={4}
        />
      </Box>
    </Box>
  );
}

export default VLList;
