export default {
  parts: ['content', 'body'],
  baseStyle: {
    content: {
      w: 'auto',
      _focusVisible: {
        outline: 'none',
      },
    },
    body: {
      p: 0,
    },
  },
};
