import { generateTimeByMilisecond } from '@utils/timer.util';
import {
  useCallback,
  useEffect,
  MouseEventHandler,
  MouseEvent as MouseEventWrapper,
} from 'react';

interface UsePreviewProgressBarTimer {
  handleChangeTime: MouseEventHandler<HTMLDivElement>;
}

function usePreviewProgressBarTimer(
  element: HTMLElement | null,
  handleUpdateElement: (currentPosition: number, duration: number) => void,
  player: any,
  startTime: number,
  endTime: number,
): UsePreviewProgressBarTimer {
  const duration = player.get('duration');

  const handleTimer = useCallback((event: MouseEvent) => {
    const { clientX } = event;
    const progressPreviewElement = document.getElementById('progress-preview');
    if (element && progressPreviewElement) {
      const elementOffsetLeft = element.offsetLeft;
      const progressBarWidth = element.clientWidth;
      const distanceMoved = clientX - elementOffsetLeft;
      const percentageMoved = (distanceMoved / progressBarWidth) * 100;
      const timeMoved = (percentageMoved * duration) / 100;
      progressPreviewElement.style.left = `${clientX - elementOffsetLeft}px`;
      progressPreviewElement.style.display = 'block';
      progressPreviewElement.textContent = `${generateTimeByMilisecond((timeMoved >= 0 ? timeMoved : 0) * 1000, 2)}`;
    }
  }, [element, duration]);

  const handleMouseLeave = useCallback(() => {
    const progressPreviewElement = document.getElementById('progress-preview');
    if (progressPreviewElement) {
      progressPreviewElement.style.display = 'none';
    }
  }, []);

  const handleChangeTime = useCallback((
    event: MouseEventWrapper<HTMLDivElement>,
  ) => {
    event.stopPropagation();
    if (!player.getPlaybackCount()) {
      player.play();
      handleUpdateElement(player.getCurrentPosition(), endTime - startTime);
      return;
    }
    const { clientX } = event;
    if (element && player && handleUpdateElement) {
      const elementOffsetLeft = element.offsetLeft;
      const progressBarWidth = element.clientWidth;
      const distanceMoved = clientX - elementOffsetLeft;
      const percentageMoved = (distanceMoved / progressBarWidth) * 100;
      const timeMoved = (percentageMoved * duration) / 100;
      player.seek(Number(timeMoved.toFixed(2)));
      handleUpdateElement(player.getCurrentPosition(), endTime);
    }
  }, [player, element, handleUpdateElement, endTime, startTime, duration]);

  useEffect(() => {
    if (element) {
      element.addEventListener('mousemove', handleTimer, true);
      element.addEventListener('mouseout', handleMouseLeave, true);
    }
    return () => {
      if (element) {
        element.removeEventListener('mousemove', handleTimer, true);
        element.removeEventListener('mouseout', handleMouseLeave, true);
      }
    };
  }, [element, handleMouseLeave, handleTimer]);

  return {
    handleChangeTime,
  };
}

export default usePreviewProgressBarTimer;
