import { Icon, IconProps } from '@chakra-ui/react';

function GroundIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 45 80" fill="none" {...props}>
      <g transform="matrix(0.941356,0,0,0.941356,1.42109e-14,-0.0152689)">
        <ellipse cx="26.5" cy="10" rx="9.5" ry="10" fill="rgb(0, 112, 201)" />
        <path d="M45.844,47.964L45.844,47.876C45.833,47.733 45.812,47.59 45.792,47.448C45.573,45.549 45.209,43.584 45.209,43.584L43.232,30.225L42.993,28.589C42.753,27.053 42.16,25.647 41.089,24.33C38.602,21.29 31.901,20.982 31.62,20.971L31.61,20.971C31.61,20.971 28.135,33.759 28.093,33.902L28.093,33.913L28.093,33.902C28.052,32.881 26.938,23.726 26.938,23.726C26.938,23.726 26.949,23.715 26.959,23.682L28.52,20.982L22.963,20.982L24.524,23.682C24.535,23.704 24.545,23.726 24.545,23.726C24.545,23.726 23.432,32.87 23.39,33.902L23.39,33.913L23.39,33.902C23.349,33.759 19.832,20.828 19.811,20.763L10.488,20.763C11.123,19.325 13.006,13.188 14.14,9.95C15.108,7.184 12.996,3.946 10.207,4.001C7.7,4.045 6.472,5.999 5.712,7.996C3.891,12.771 2.268,17.634 0.624,22.497C0.437,23.035 0.26,23.584 0.073,24.122C0.021,24.418 0,24.725 0,25.033C0,26.855 0.884,28.446 2.227,29.38C2.997,29.917 3.923,30.225 4.911,30.225L14.171,30.225L14.171,32.914C14.171,39.434 14.171,45.944 14.182,52.464L14.192,52.453L14.192,52.53C14.192,60.796 14.161,69.05 14.192,77.316C14.192,77.601 14.213,78.48 14.213,78.633C14.213,82.135 16.897,84.978 20.206,85C22.693,84.978 24.816,83.375 25.721,81.092L25.814,81.092C26.72,83.364 28.842,84.978 31.329,85C34.648,84.978 37.322,82.135 37.322,78.633C37.322,78.48 37.343,77.601 37.343,77.316C37.374,69.05 37.343,60.796 37.343,52.53L37.343,52.453L37.354,52.464C38.165,53.88 39.643,54.835 41.339,54.835C43.909,54.835 46,52.64 46,49.918L46,49.841C45.948,49.325 45.906,48.655 45.844,47.964Z" fill="rgb(0, 112, 201)" />
        <path d="M47,61.573C47,59.604 45.663,58 44,58C42.346,58 41,59.593 41,61.573L41,76.427C41,78.396 42.337,80 44,80C45.654,80 47,78.407 47,76.427L47,76.351L47,61.573Z" fill="rgb(50, 197, 85)" />
      </g>
    </Icon>
  );
}

export default GroundIcon;
