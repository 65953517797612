import { useNavigate, useParams } from 'react-router-dom';
import useFormDetail from '@modules/Forms/hooks/useFormDetail';
import { Flex, Spinner, useDisclosure } from '@chakra-ui/react';
import NotFound from '@components/NotFound';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { updateForm } from '@apis/form.api';
import { IFormValue } from '@modules/Forms/forms/VMFormControl/types/form-value';
import VMFormControl from '@modules/Forms/forms/VMFormControl';
import useHistoryRoute from '@hooks/useHistoryRoute';

function FormUpdating() {
  const navigate = useNavigate();
  const { formId } = useParams<'formId'>();
  const { backRoute } = useHistoryRoute('/forms');
  const {
    isLoadingForm,
    form,
  } = useFormDetail(formId!);
  const {
    isOpen: isUpdatingForm,
    onOpen: startUpdatingForm,
    onClose: finishUpdatingForm,
  } = useDisclosure();

  const onSubmit = useCallback(async (data: IFormValue) => {
    try {
      startUpdatingForm();
      await updateForm(formId!, data);
      navigate(backRoute);
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishUpdatingForm();
  }, [backRoute, finishUpdatingForm, formId, navigate, startUpdatingForm]);

  if (isLoadingForm) {
    return (
      <Flex align="center" justify="center" minH="100vh">
        <Spinner />
      </Flex>
    );
  }

  if (!form) {
    return (
      <NotFound />
    );
  }

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formId: formIdL,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    created,
    ...formValue
  } = form;

  return (
    <VMFormControl isLoading={isUpdatingForm} onSubmitForm={onSubmit} defaultValues={formValue} />
  );
}

export default FormUpdating;
