import { useCallback, useEffect } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useVmStore } from '@store/index';
import { IFolder } from '@typed/folder.types';
import { toast } from 'react-toastify';
import { getFoldersSelector } from '@store/slices/folder/selector';

interface UseFolders {
  isLoadingFolders: boolean;
  folders: IFolder[];
}

function useFolders(): UseFolders {
  const loadFolder = useVmStore((state) => state.loadFolders);
  const folders = useVmStore(getFoldersSelector);
  const {
    isOpen: isLoadingFolders,
    onOpen: startLoadingFolders,
    onClose: finishLoadingFolders,
  } = useDisclosure({ defaultIsOpen: true });

  const getFolders = useCallback(async () => {
    try {
      startLoadingFolders();
      await loadFolder();
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishLoadingFolders();
  }, [finishLoadingFolders, loadFolder, startLoadingFolders]);

  useEffect(() => {
    getFolders();
  }, [getFolders]);

  return {
    folders,
    isLoadingFolders,
  };
}

export default useFolders;
