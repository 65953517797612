import { memo } from 'react';
import { Box, chakra } from '@chakra-ui/react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

const LinkStyled = chakra(Box, {
  baseStyle: {
    textAlign: 'center',
    px: '10.5px',
    py: '10px',
    fontSize: '16px',
    lineHeight: '24px',
    borderRadius: '10px',
    borderWidth: '2px',
    fontWeight: 400,
    color: 'vmBlack.50',
    '&.active': {
      borderColor: 'vmRed.100',
    },
    '&.disabled': {
      opacity: 0.5,
      cursor: 'not-allow',
    },
  },
});

interface Props {
  to: string;
  pathMatch?: string;
  isDisabled?: boolean;
  title: string
}

function TabLink({
  to,
  pathMatch,
  isDisabled,
  title,
}: Props) {
  const resolved = useResolvedPath(pathMatch ?? to);
  const match = useMatch({ path: resolved.pathname, end: true });

  if (isDisabled) {
    return (
      <LinkStyled
        className={match ? 'disabled active' : 'disabled'}
      >
        {title}
      </LinkStyled>
    );
  }

  return (
    <Box as={Link} to={to} alignSelf="stretch">
      <LinkStyled
        className={match ? 'active' : ''}
      >
        {title}
      </LinkStyled>
    </Box>
  );
}

TabLink.defaultProps = {
  pathMatch: null,
  isDisabled: false,
};

export default memo(TabLink);
