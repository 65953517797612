import { useConst } from '@chakra-ui/react';
import { generateGuid } from '@utils/string.util';
import {
  ChangeEvent,
  useCallback,
  useRef,
  useState,
} from 'react';

function useRecorderUpload(
  recorder: any,
  onStartUpload: () => void,
  onDeleteUploadFile: () => void,
) {
  const inputRef = useRef<HTMLInputElement>(null);
  const id = useConst(() => generateGuid());
  const [isFileExist, setFileExist] = useState<boolean>(false);
  const [isUploadMode, setIsUploadMode] = useState<boolean>(false);

  const handleSelectFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ?? [];
    const file = files[0];
    if (file) {
      onStartUpload();
      setIsUploadMode(true);
      if (inputRef.current) inputRef.current.value = '';
      setFileExist(true);
      recorder.upload_video(file);
    }
  }, [onStartUpload, recorder]);

  const handleResetVideo = useCallback(() => {
    onDeleteUploadFile();
    setIsUploadMode(false);
    if (recorder.reset) {
      recorder.reset();
    }
  }, [onDeleteUploadFile, recorder]);

  return {
    id,
    inputRef,
    isFileExist,
    setFileExist,
    isUploadMode,
    handleSelectFile,
    handleResetVideo,
  };
}

export default useRecorderUpload;
