import {
  Box,
  chakra,
  Flex,
  FlexProps,
} from '@chakra-ui/react';
import {
  AlertIcon,
  ErrorIcon,
  MoreIcon,
  PendingIcon,
  SettingIcon,
  SuccessIcon,
  WarningIcon,
} from '@components/icons/alerts';
import { AlertMessageProps } from './type';

const AlertMessageWrapper = chakra(Flex, {
  baseStyle: {
    alignItems: 'flex-start',
    gap: '12px',
    sx: {
      '& > svg': {
        mt: '1px',
      },
    },
  },
});

const AlertMessageText = chakra(Box, {
  baseStyle: {
    color: 'vmBlack.50',
    fontSize: '15px',
    lineHeight: '22.5px',
    fontWeight: 400,
  },
});

function AlertMessageContent({
  type,
  message,
  ...flexProps
}: AlertMessageProps & FlexProps) {
  switch (type) {
    case 'error': {
      return (
        <AlertMessageWrapper {...flexProps}>
          <ErrorIcon fontSize="20px" />
          <AlertMessageText color="vmRed.400" dangerouslySetInnerHTML={{ __html: message }} />
        </AlertMessageWrapper>
      );
    }
    case 'warning': {
      return (
        <AlertMessageWrapper {...flexProps}>
          <WarningIcon fontSize="20px" />
          <AlertMessageText color="trYellow.150" dangerouslySetInnerHTML={{ __html: message }} />
        </AlertMessageWrapper>
      );
    }
    case 'success': {
      return (
        <AlertMessageWrapper {...flexProps}>
          <SuccessIcon fontSize="20px" />
          <AlertMessageText color="vmBlack.100" dangerouslySetInnerHTML={{ __html: message }} />
        </AlertMessageWrapper>
      );
    }
    case 'pending': {
      return (
        <AlertMessageWrapper {...flexProps}>
          <PendingIcon fontSize="20px" />
          <AlertMessageText dangerouslySetInnerHTML={{ __html: message }} />
        </AlertMessageWrapper>
      );
    }
    case 'alert': {
      return (
        <AlertMessageWrapper {...flexProps}>
          <AlertIcon fontSize="20px" />
          <AlertMessageText dangerouslySetInnerHTML={{ __html: message }} />
        </AlertMessageWrapper>
      );
    }
    case 'setting': {
      return (
        <AlertMessageWrapper {...flexProps}>
          <SettingIcon fontSize="20px" />
          <AlertMessageText dangerouslySetInnerHTML={{ __html: message }} />
        </AlertMessageWrapper>
      );
    }
    case 'more': {
      return (
        <AlertMessageWrapper {...flexProps}>
          <MoreIcon fontSize="20px" />
          <AlertMessageText dangerouslySetInnerHTML={{ __html: message }} />
        </AlertMessageWrapper>
      );
    }
    case 'none': {
      return (
        <AlertMessageWrapper {...flexProps}>
          <AlertMessageText dangerouslySetInnerHTML={{ __html: message }} />
        </AlertMessageWrapper>
      );
    }
    default: {
      return null;
    }
  }
}

export default AlertMessageContent;
