import { AlertType } from '@components/AlertMessage/type';
import { VideoEditorRenderedVideo } from '../types/video-editor.types';

function getVideoStatus(item: VideoEditorRenderedVideo): AlertType {
  if (item.shotstackError) {
    return 'error';
  }
  if (item.shotstackDone) {
    return 'success';
  }
  return 'pending';
}

function getVideoStatusMesssage(status: AlertType, t: any, videoName: string = '', errorMessage: string = ''): string {
  switch (status) {
    case 'none': {
      return t('video_editor.statuses.none');
    }
    case 'pending': {
      return t('video_editor.statuses.rendering', { videoName });
    }
    case 'success': {
      return t('video_editor.statuses.sucess', { videoName });
    }
    case 'error': {
      return t('video_editor.statuses.error', { videoName, errorMessage });
    }
    default: {
      return '';
    }
  }
}

export {
  getVideoStatus,
  getVideoStatusMesssage,
};
