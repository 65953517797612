import {
  Box,
  Button,
  Flex,
  Switch,
} from '@chakra-ui/react';
import LockIcon from '@components/icons/Lock';
import ArrowBackIcon from '@components/icons/question/ArrowBack';
import CameraSelectorIcon from '@components/TrustRecorder/icons/CameraSelector';
import MicrophoneSelectorIcon from '@components/TrustRecorder/icons/MicrophoneSelector';
import { useCallback, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onUpload: (file: File) => void;
  isUploading: boolean;
  onGoBack: () => void;
  showArrowBack: boolean;
}

function TrustRecorderPermissionChecking({
  onUpload,
  isUploading,
  onGoBack,
  showArrowBack,
}: Props) {
  const { t } = useTranslation();

  const handleVideoChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const file = (event.target.files ?? [])[0];
    if (file) {
      onUpload(file);
    }
  }, [onUpload]);

  return (
    <Flex
      direction="column"
      align="center"
      py="40px"
      px={{
        base: '40px',
        md: '125px',
      }}
      gap="35px"
      bg="vmGray.350"
      borderRadius="10px"
      pos="relative"
      h="full"
    >
      <Flex direction="column" align="center" justify="center" gap="20px">
        <Box w="full" pos="relative" textAlign="center">
          <Box
            fontSize="20px"
            lineHeight="26px"
            fontWeight={600}
            color="vmBlack.100"
            bgImage="linear-gradient(180deg, transparent 89%, #48DC6B 0)"
            bgRepeat="no-repeat"
            bgSize="61% 100%"
            bgPos="67px 0"
            textDecoration="none"
            textAlign="center"
            pos="relative"
            display="inline"
          >
            {t('testimonial.interactive_form.labels.enable_camera_permission')}
          </Box>
        </Box>
        <Box
          as="p"
          my={0}
          fontSize="16px"
          lineHeight="24px"
          fontWeight={400}
          color="vmBlack.50"
          textAlign="center"
        >
          {t('testimonial.interactive_form.labels.enable_camera_permission_message')}
        </Box>
      </Flex>
      <Flex direction="column" align="center" justify="center" mb="30px">
        <Flex ml="8px" pl="34px" align="center" justify="space-between" pos="relative" borderRadius="100px" bg="vmWhite" gap="55px" pr="10px" h="24px">
          <Flex align="center" justify="center" w="28px" h="24px" borderWidth="2px" borderColor="vmGreen.50" borderRadius="100px" pos="absolute" left={0} top={0}>
            <LockIcon />
          </Flex>
          <Box
            fontSize="12px"
            lineHeight="16.5px"
            fontWeight={400}
            color="vmGray.600"
          >
            https://app.usetrust.app
          </Box>
          <Flex align="center" justify="center" gap="5px">
            <Box w="10px" h="10px" borderRadius="50%" bg="vmGray.1010" />
            <Box w="10px" h="10px" borderRadius="50%" bg="vmGray.1010" />
          </Flex>
        </Flex>
        <Flex p="15px" gap="10px" direction="column" bg="vmWhite" borderRadius="4px" mt="4px" w="full">
          <Flex align="center" justify="space-between" gap="42px">
            <Flex align="center" gap="6px">
              <CameraSelectorIcon fill="vmGray.600" fontSize="18px" />
              <Box color="vmBlack.50" fontSize="13px" fontWeight={400} lineHeight="20px">{t('testimonial.interactive_form.labels.camera')}</Box>
            </Flex>
            <Switch isChecked={false} onChange={() => {}} />
          </Flex>
          <Flex align="center" justify="space-between" gap="42px">
            <Flex align="center" gap="6px">
              <MicrophoneSelectorIcon fill="vmPrimary.50" fontSize="18px" />
              <Box color="vmBlack.50" fontSize="13px" fontWeight={400} lineHeight="20px">{t('testimonial.interactive_form.labels.micro')}</Box>
            </Flex>
            <Switch isChecked onChange={() => {}} />
          </Flex>
        </Flex>
      </Flex>
      <Flex direction="column" align="center" justify="center" gap="20px" mt="auto">
        <Box color="vmBlack.50" fontSize="16px" fontWeight={400} lineHeight="24px">
          <Box color="vmPrimary.50" as="a" href="mailto:support@usetrust.help" target="_blank">{t('testimonial.interactive_form.labels.contact_support')}</Box>
          {' '}
          {t('testimonial.interactive_form.labels.or')}
        </Box>
        <input hidden type="file" accept="video/*,image/*" id="upload-media-when-ignore-device" onChange={handleVideoChange} />
        <Button
          type="button"
          cursor="pointer"
          textTransform="uppercase"
          isLoading={isUploading}
          isDisabled={isUploading}
          px={isUploading ? '28px' : 0}
          py={isUploading ? '8px' : 0}
          w="200px"
        >
          <Box as="label" htmlFor="upload-media-when-ignore-device" px="28px" py="8px">
            {t('testimonial.interactive_form.labels.upload_a_video')}
          </Box>
        </Button>
      </Flex>
      {!isUploading && showArrowBack && (
        <Flex align="center" justify="center" pos="absolute" w="44px" h="44px" borderRadius="50%" bg="vmWhite" bottom="40px" left="40px" cursor="pointer" onClick={onGoBack}>
          <ArrowBackIcon fontSize="20px" color="vmPrimary.50" />
        </Flex>
      )}
    </Flex>
  );
}

export default TrustRecorderPermissionChecking;
