import { Icon, IconProps } from '@chakra-ui/react';

function LinkIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 18 18" fill="none" stroke="vmGreen.50" {...props}>
      <path
        d="M17 1L6.99997 11M17 1L17 7M17 1L11 0.999989M7 1H4C2.34315 1 1 2.34315 1 4V14C1 15.6569 2.34315 17 4 17H14C15.6569 17 17 15.6569 17 14V11"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default LinkIcon;
