import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import ErrorMessage from '@components/ErrorMessage';
import EditIcon from '@components/icons/Edit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useVmStore } from '@store/index';
import { AppState } from '@store/types';
import { Customer } from '@typed/customer.types';
import { useCallback } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as yup from 'yup';

type PersonalData = Pick<Customer, 'firstName' | 'lastName'>;

const schema = yup.object({
  firstName: yup.string().trim().required('common.validation.required'),
  lastName: yup.string().trim().required('common.validation.required'),
});

function NameUpdate() {
  const { t } = useTranslation();
  const customerFirstName = useVmStore((state: AppState) => state.customer?.firstName ?? '');
  const customerLastName = useVmStore((state: AppState) => state.customer?.lastName ?? '');
  const updateCustomerName = useVmStore((state: AppState) => state.updateName);
  const {
    isOpen,
    onClose,
    onOpen,
  } = useDisclosure();
  const {
    isOpen: isUpdating,
    onClose: finishUpdating,
    onOpen: startUpdating,
  } = useDisclosure();
  const {
    control,
    handleSubmit,
    register,
  } = useForm<PersonalData>({
    defaultValues: { firstName: customerFirstName, lastName: customerLastName },
    resolver: yupResolver(schema),
  });
  const { errors } = useFormState({ control });

  const onSubmit = useCallback(async (values: PersonalData) => {
    try {
      startUpdating();
      await updateCustomerName(values.firstName, values.lastName);
      finishUpdating();
      onClose();
    } catch (error: any) {
      toast.error(error?.message);
      finishUpdating();
    }
  }, [finishUpdating, onClose, startUpdating, updateCustomerName]);

  return (
    <>
      <Box cursor="pointer" onClick={onOpen}>
        <EditIcon fontSize="16px" />
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent w="650px" maxW="full">
          <ModalCloseButton />
          <ModalBody>
            <Box mb="25px" as="h4" textAlign="center" fontSize="28px" lineHeight="37px">{t('account.labels.enter_personal_info')}</Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl pt={0} isInvalid={!!errors?.firstName?.message}>
                <FormLabel>{t('common.labels.first_name')}</FormLabel>
                <Input type="text" {...register('firstName')} placeholder={t('common.placeholders.first_name')} />
                <ErrorMessage error={errors?.firstName?.message} />
              </FormControl>
              <FormControl isInvalid={!!errors?.lastName?.message}>
                <FormLabel>{t('common.labels.last_name')}</FormLabel>
                <Input type="text" {...register('lastName')} placeholder={t('common.placeholders.last_name')} />
                <ErrorMessage error={errors?.lastName?.message} />
              </FormControl>
              <Box textAlign="center" mt="25px">
                <Button isLoading={isUpdating} isDisabled={isUpdating} type="submit">{t('common.button.texts.save')}</Button>
              </Box>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default NameUpdate;
