import {
  Box,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal, Spinner, useDisclosure,
} from '@chakra-ui/react';
import ContextItem from '@components/ContextItem';
import ThreeDotsIcon from '@components/icons/ThreeDots';
import TrashIcon from '@components/icons/Trash';
import { useTranslation } from 'react-i18next';
import { Brand } from '@modules/Branding/types/brand.types';
import { useVmStore } from '@root/store';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import EditIcon from '@components/icons/Edit';
import { setItem } from '@utils/localstorage.util';
import LOCAL_STORAGE_KEY from '@constants/localstorage';
import CheckIcon from '@components/icons/Check';
import TickIcon from '@components/icons/Tick';

interface Props {
  brand: Brand;
}

function BrandItem({
  brand,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const deleteBrand = useVmStore((state) => state.deleteBrand);
  const changeSelectedBrand = useVmStore((state) => state.changeSelectedBrand);
  const brandId = useVmStore((state) => state.selectedBrandId);
  const {
    isOpen: isDeleting,
    onOpen: startDeleting,
    onClose: finishDeleting,
  } = useDisclosure();
  const {
    isOpen,
    onClose,
    onOpen,
  } = useDisclosure();

  const handleDeleteBrand = useCallback(async () => {
    if (isDeleting) return;
    try {
      startDeleting();
      await deleteBrand(brand.brandId);
      navigate('/brands');
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishDeleting();
  }, [brand.brandId, deleteBrand, finishDeleting, isDeleting, navigate, startDeleting]);

  const handleChangeSelectedBrand = useCallback(async () => {
    try {
      onClose();
      setItem(LOCAL_STORAGE_KEY.BRAND_KEY, brand.brandId);
      changeSelectedBrand(brand.brandId);
    } catch (error: any) {
      toast.error(error?.message);
    }
  }, [brand.brandId, changeSelectedBrand, onClose]);

  return (
    <Box bg="vmGray.150" borderRadius="8px" p="15px">
      <Flex align="center" justify="space-between">
        <Flex align="center" gap="20px">
          <Box w="8px" h="8px" bg="vmGray.300" borderRadius="full" />
          <Box>
            <Box fontSize="16px" lineHeight="24px" fontWeight={600} color="vmBlack.50">{brand.name}</Box>
          </Box>
        </Flex>
        <Flex align="center" gap="20px">
          <Box>{brand.brandId === brandId ? <CheckIcon /> : null}</Box>
          {isDeleting ? (
            <Spinner w="20px" h="20px" />
          ) : (
            <Popover placement="top-start" isLazy closeOnBlur isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
              <PopoverTrigger>
                <Box cursor="pointer" lineHeight={1}>
                  <ThreeDotsIcon fontSize="20px" />
                </Box>
              </PopoverTrigger>
              <Portal>
                <PopoverContent border={0} w="auto" _focusVisible={{ outline: 'none' }}>
                  <PopoverBody p={0} border={0}>
                    <Box w="360px" borderWidth="1px" borderColor="vmGray.150" borderRadius="8px" overflow="hidden">
                      <Flex direction="column">
                        <ContextItem as={Link} borderBottomWidth="2px !important" to={`/brands/view/${brand.brandId}`} onClick={onClose}>
                          <EditIcon fontSize="16px" />
                          <Box color="vmGray.400" fontSize="vmNormal" fontWeight={600} lineHeight="24px">{t('common.texts.edit')}</Box>
                        </ContextItem>
                        {brand.brandId !== brandId && (
                          <ContextItem onClick={handleChangeSelectedBrand}>
                            <TickIcon stroke="vmBlack.100" fontSize="16px" />
                            <Box color="vmGray.400" fontSize="vmNormal" fontWeight={600} lineHeight="24px">Select this brand</Box>
                          </ContextItem>
                        )}
                        <ContextItem onClick={handleDeleteBrand}>
                          <TrashIcon fontSize="16px" />
                          <Box color="vmGray.400" fontSize="vmNormal" fontWeight={600} lineHeight="24px">{t('common.texts.delete')}</Box>
                        </ContextItem>
                      </Flex>
                    </Box>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}

export default BrandItem;
