import { extendTheme } from '@chakra-ui/react';
import Button from '@root/themes/default/components/Button';
import Input from './components/Input';
import Form from './components/Form';
import FormLabel from './components/FormLabel';
import Spinner from './components/Spinner';
import Modal from './components/Modal';
import Stat from './components/Stat';
import Switch from './components/Switch';
// import Tooltip from './components/Tooltip';
import Skeleton from './components/Skeleton';
import Textarea from './components/Textarea';
import Popover from './components/Popover';

const breakpoints = {
  wxga: '1280px',
  wxgb: '945px',
};

const theme = extendTheme({
  config: {
    cssVarPrefix: 'vm',
  },
  breakpoints,
  components: {
    Button,
    Input,
    Form,
    FormLabel,
    Spinner,
    Modal,
    Stat,
    Switch,
    // Tooltip,
    Skeleton,
    Textarea,
    Popover,
  },
  colors: {
    vmWhite: '#ffffff',
    vmPrimary: {
      50: '#2485CC',
    },
    vmGray: {
      50: '#92abca',
      100: '#D9D9D9',
      150: '#f2f2f2',
      200: '#f3f9ff',
      250: '#858585',
      300: '#B5B5B5',
      350: '#DDDDDD',
      400: '#071E57',
      450: '#C4C4C4',
      500: '#515151',
      550: '#898989',
      600: '#6E8AAC',
      650: '#E8E8E8',
      700: '#B5C1CE',
      750: '#E4EEFA',
      800: '#929292',
      850: '#B6CDE8',
      900: '#AEAEAE',
      950: '#A4A4A4',
      1000: '#C1C1C1',
      1050: '#f5f9fd',
      1010: '#d8e2ee',
      1020: '#EFEFEF',
      1030: '#e7eef6',
    },
    vmGreen: {
      50: '#48DC6B',
      100: '#22a498',
    },
    vmBlack: {
      50: '#282E3D',
      100: '#000000',
      150: '#333333',
    },
    vmRed: {
      50: '#dc5858',
      100: '#DD3D36',
    },
    vmYellow: {
      50: '#573A08',
    },
  },
  fontSizes: {
    vmSmall: '10px',
    vmRegular: '15px',
    vmNormal: '16px',
  },
  styles: {
    global: {
      html: {
        height: '100%',
      },
      body: {
        fontSize: 'vmNormal',
        fontWeight: '400',
        minHeight: '100vh',
        height: '100%',
        fontFamily: "'Albert Sans', sans-serif",
        overflow: 'overlay',
      },
      '*::-webkit-scrollbar': {
        width: '5px',
      },
      '*::-webkit-scrollbar-track': {
        width: '7px',
      },
      '*::-webkit-scrollbar-thumb': {
        background: 'vmGray.100',
        borderRadius: '24px',
      },
      'input[type="color"]::-webkit-color-swatch-wrapper': {
        padding: 0,
      },
      'input[type="color"]::-webkit-color-swatch': {
        border: 'none',
      },
      a: {
        color: 'vmPrimary.50',
      },
      h1: {
        fontSize: '2em',
      },
      h2: {
        fontSize: '1.5em',
      },
      h3: {
        fontSize: '1.17em',
      },
    },
  },
});

export default theme;
