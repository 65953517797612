import { getAllRootForms } from '@apis/form.api';
import {
  Box,
  Button,
  Flex,
  Spinner,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
// import GridViewType from '@components/GridViewType';
import AddIcon from '@components/icons/Add';
import FormIcon from '@components/icons/Form';
import ListAction from '@components/ListAction';
import VmFolderList from '@modules/Forms/components/VmFolderList';
import VmFormList from '@modules/Forms/components/VmFormList';
// import { useListTypeContext } from '@root/contexts/ListType/context';
import { useVmStore } from '@root/store';
import { selectedBrandSelector } from '@store/slices/brand/selectors';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Fuse from 'fuse.js';
import { IFromListItem } from '@modules/Forms/types/form-list.types';
import useFilterSearch from '@hooks/useFilterSearch';
import BackIcon from '@components/icons/Back';
import NavLink from '@components/NavLink';

function FormListContainer() {
  const { t } = useTranslation();
  const { folderId } = useParams<'folderId'>();
  const brandId = useVmStore(selectedBrandSelector);
  const [forms, setForms] = useState<IFromListItem[]>([]);
  const { keyword, onKeywordChange } = useFilterSearch();
  const fuse = useMemo<Fuse<IFromListItem>>(() => new Fuse(forms, { keys: ['name'], threshold: 0.1 }), [forms]);
  // const {
  //   type,
  //   onTypeChange,
  // } = useListTypeContext();
  const {
    isOpen: isLoadingForms,
    onOpen: startLoadingForms,
    onClose: finishLoadingForms,
  } = useDisclosure({ defaultIsOpen: true });

  const handleGetForms = useCallback(async () => {
    try {
      startLoadingForms();
      const result = await getAllRootForms(brandId!);
      setForms(result.filter((form) => form.folderId === (folderId ?? null)));
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishLoadingForms();
  }, [finishLoadingForms, startLoadingForms, brandId, folderId]);

  const handleRemoveFormSuccess = useCallback((formId: string) => {
    setForms((currentForms) => currentForms.filter((item) => item.formId !== formId));
  }, []);

  const handleMoveFormSuccess = useCallback((formId: string) => {
    setForms((currentForms) => currentForms.filter((item) => item.formId !== formId));
  }, []);

  const listForms = useMemo<IFromListItem[]>(() => {
    if (!keyword) return forms;
    return fuse.search(keyword).map((item) => item.item);
  }, [keyword, forms, fuse]);

  useEffect(() => {
    handleGetForms();
  }, [handleGetForms]);

  return (
    <Box w="960px" px="20px" mx="auto" maxW="full" pt="108px" pb="80px">
      <Flex py="8px" px="20px" align="center" justify="space-between" bg="vmGray.150" borderRadius="10px">
        <Flex align="center" gap="20px">
          {folderId && (
            <Tooltip label="Back to root" placement="top" hasArrow>
              <Link to="/forms">
                <Button type="button" variant="iconOnly" size="iconOnly"><BackIcon stroke="vmWhite" /></Button>
              </Link>
            </Tooltip>
          )}
          <Box color="vmBlack.50" fontWeight={600} fontSize="20px" lineHeight="26px">{t('forms.labels.forms')}</Box>
          <Flex align="center" gap="8px">
            <FormIcon fontSize="18px" />
            {isLoadingForms ? (
              <Spinner w="10px" h="10px" />
            ) : (
              <Box as="span" fontWeight={400} fontSize="16px" lineHeight="24px">{listForms.length}</Box>
            )}
          </Flex>
        </Flex>
        <Flex align="center" gap="20px">
          <ListAction
            isLoading={false}
            havingSearch
            onKeywordChange={onKeywordChange}
          />
          {/* <GridViewType activeType={type} onChangeType={onTypeChange} /> */}
          <NavLink link={folderId ? `/forms/add-f/${folderId}` : '/forms/add'}>
            <Button type="button" bg="vmGray.300" borderColor="vmGray.300">
              <Flex align="center" gap="10px">
                <AddIcon fontSize="14px" />
                <Box as="span" fontSize="16px" fontWeight={600} lineHeight="24px" textTransform="capitalize">{t('forms.labels.add_form')}</Box>
              </Flex>
            </Button>
          </NavLink>
        </Flex>
      </Flex>
      {!folderId && (
        <Box my="20px">
          <VmFolderList />
        </Box>
      )}
      <VmFormList
        forms={listForms}
        isLoading={isLoadingForms}
        onRemoveSuccess={handleRemoveFormSuccess}
        onMoveSuccess={handleMoveFormSuccess}
      />
    </Box>
  );
}

export default FormListContainer;
