import { Box, Flex } from '@chakra-ui/react';
import GridTypeIcon from '@components/icons/contact/GridType';
import ListTypeIcon from '@components/icons/contact/ListType';
import { ListType } from '@root/contexts/ListType/types';

interface Props {
  activeType: ListType;
  onChangeType: (t: ListType) => void;
}

function GridViewType({
  activeType,
  onChangeType,
}: Props) {
  return (
    <Flex align="center" gap="5px">
      <Box
        cursor="pointer"
        borderWidth="1px"
        borderColor={activeType === ListType.GRID ? 'vmBlack.100' : 'transparent'}
        onClick={() => onChangeType(ListType.GRID)}
      >
        <GridTypeIcon fontSize="44px" />
      </Box>
      <Box
        cursor="pointer"
        borderWidth="1px"
        borderColor={activeType === ListType.LIST ? 'vmBlack.100' : 'transparent'}
        onClick={() => onChangeType(ListType.LIST)}
      >
        <ListTypeIcon fontSize="44px" />
      </Box>
    </Flex>
  );
}

export default GridViewType;
