import {
  Box,
  Flex,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertType } from '@components/AlertMessage/type';
import AlertMessageContent from '@components/AlertMessage/AlertMessageContent';
import { toast } from 'react-toastify';
import { dismissRenderedVideoMessage } from '@apis/rendered-videos.api';
import { ClockIcon } from '@components/icons/ve';
import CloseIcon from '@components/icons/Close';

interface Props {
  name: string;
  status: AlertType;
  createdDate: string;
  errorMessage: string | null;
  showDate?: boolean;
  customMessage?: string;
  showCloseButton?: boolean;
  onDismissOk: (rvId: string) => void;
  rvId: string;
}

const customStyles = {
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 400,
  color: 'vmBlack.50',
  borderRadius: '10px',
  '& > b': {
    color: 'vmBlack.100',
    fontWeight: 600,
  },
};

function VideoRenderedStatusMessage({
  name,
  status,
  errorMessage,
  createdDate,
  showDate,
  customMessage,
  showCloseButton,
  onDismissOk,
  rvId,
}: Props) {
  const { t } = useTranslation();
  const {
    isOpen: isDimissingMessage,
    onClose: finishDimissingMessage,
    onOpen: startDimissingMessage,
  } = useDisclosure();

  const handleDismissMessage = useCallback(async () => {
    try {
      startDimissingMessage();
      await dismissRenderedVideoMessage(rvId);
      onDismissOk(rvId);
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishDimissingMessage();
  }, [finishDimissingMessage, startDimissingMessage, rvId, onDismissOk]);

  if (status === 'none') {
    return (
      <AlertMessageContent
        message={customMessage ?? t('video_editor.statuses.none')}
        type={status}
        sx={customStyles}
      />
    );
  }

  if (status === 'pending') {
    return (
      <>
        <AlertMessageContent
          message={customMessage ?? t('video_editor.statuses.rendering', { videoName: name })}
          type={status}
          sx={customStyles}
        />
        {showDate && (
          <Flex align="center" gap="8px" flexWrap="nowrap">
            <ClockIcon fontSize="18px" />
            <Box color="vmBlack.50" fontSize="15px" lineHeight="22.5px" fontWeight={400}>{dayjs(createdDate).format('MM/DD/YYYY HH:mm A')}</Box>
          </Flex>
        )}
      </>
    );
  }

  if (status === 'success') {
    return (
      <>
        <AlertMessageContent
          message={customMessage ?? t('video_editor.statuses.sucess', { videoName: name })}
          type={status}
          sx={customStyles}
        />
        {showCloseButton && (
          <Flex alignItems="center" justify="center" cursor="pointer" onClick={handleDismissMessage}>
            {isDimissingMessage ? (
              <Spinner w="12px" h="12px" />
            ) : (
              <CloseIcon fontSize="10px" />
            )}
          </Flex>
        )}
      </>
    );
  }

  if (status === 'error') {
    return (
      <>
        <AlertMessageContent
          message={customMessage ?? t('video_editor.statuses.error', { videoName: name, errorMessage })}
          type={status}
          sx={customStyles}
        />
        {showCloseButton && (
          <Flex alignItems="center" justify="center" onClick={handleDismissMessage} cursor="pointer">
            {isDimissingMessage ? (
              <Spinner w="12px" h="12px" />
            ) : (
              <CloseIcon fontSize="10px" />
            )}
          </Flex>
        )}
      </>
    );
  }

  return null;
}

VideoRenderedStatusMessage.defaultProps = {
  showDate: false,
  customMessage: null,
  showCloseButton: false,
};

export default memo(VideoRenderedStatusMessage);
