import { Icon, IconProps } from '@chakra-ui/react';

function VideoEditorIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 22 24" fill="none" stroke="vmGray.600" {...props}>
      <path
        d="M2.14092 11.3959V20.6792C2.14092 21.2947 2.38928 21.885 2.83138 22.3202C3.27347 22.7555 3.87308 23 4.4983 23H18.6426C19.2678 23 19.8674 22.7555 20.3095 22.3202C20.7516 21.885 21 21.2947 21 20.6792V11.3959H2.14092ZM2.14092 11.3959L1.10367 8.06552C1.01273 7.77371 0.981167 7.4671 1.01077 7.16326C1.04038 6.85942 1.13057 6.56431 1.27619 6.29484C1.42181 6.02536 1.61999 5.78681 1.85939 5.59285C2.09879 5.39889 2.3747 5.25333 2.67133 5.1645L16.2027 1.10306C16.7998 0.921789 17.4456 0.981298 17.9981 1.26851C18.5506 1.55573 18.9648 2.04715 19.1495 2.6348L20.1749 5.96518L2.14092 11.4075V11.3959ZM5.20552 4.42183L9.1895 9.29556M11.4054 2.55357L15.3894 7.4273M9.5 14V20L14.5 17L9.5 14Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default VideoEditorIcon;
