import { IVmContact, IVmContactUpdateModel } from '@typed/contact.types';
import { ApiResponse } from '@typed/http.types';
import axios, { AxiosResponse } from 'axios';

async function getContacts(brandId: string): Promise<IVmContact[]> {
  return axios.get(`/contacts/${brandId}/all`)
    .then((response: AxiosResponse<ApiResponse<IVmContact[]>>) => response.data.result);
}

async function getContact(contactId: string): Promise<IVmContact> {
  return axios.get(`/contacts/${contactId}`)
    .then((response: AxiosResponse<ApiResponse<IVmContact>>) => response.data.result);
}

async function updateContact(contactId: string, data: IVmContactUpdateModel) {
  return axios.put(`/contacts/${contactId}`, data);
}

async function deleteContact(contactId: string) {
  return axios.delete(`/contacts/${contactId}`);
}

export {
  getContacts,
  getContact,
  updateContact,
  deleteContact,
};
