import {
  forwardRef,
  memo,
  Ref,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ZiggeoRecorder } from 'react-ziggeo';
import useElementRef from '@hooks/useElementRef';
import { Flex } from '@chakra-ui/react';
import { ZiggeoProgressType } from '@typed/ziggeo-recorder';

interface Props {
  onChange: (videoToken: any) => void;
  allowRecord: boolean;
  allowUpload: boolean;
  onProgressAction?: (action: ZiggeoProgressType) => void;
  proceedInVerfiedProgress?: boolean;
  setOuterRef?: (playerRef: any) => void;
  skipinitial?: boolean;
  simulate?: boolean;
  localplayback?: boolean;
  videoToken?: string | null;
  forceOverwrite?: boolean;
  deleteOldStreams?: boolean;
}

const VideoRecorder = forwardRef(({
  onChange,
  allowRecord,
  allowUpload,
  onProgressAction,
  setOuterRef,
  proceedInVerfiedProgress,
  skipinitial,
  simulate,
  localplayback,
  videoToken,
  forceOverwrite,
  deleteOldStreams,
}: Props, ref: Ref<HTMLDivElement>) => {
  const [updateInstance, setUpdateInstance] = useState(false);
  const [recorderRef, recorder] = useElementRef();

  useEffect(() => {
    if (recorder) {
      setUpdateInstance(false);
    }
  }, [recorder]);

  useEffect(() => {
    setUpdateInstance(true);
  }, []);

  const handleProcessed = useCallback((videoAttributes: unknown) => {
    if (proceedInVerfiedProgress) return;
    onChange(videoAttributes);
    onProgressAction!(ZiggeoProgressType.PROCESSED);
  }, [onChange, onProgressAction, proceedInVerfiedProgress]);

  const handleVideoVerified = useCallback((videoAttributes: unknown) => {
    onProgressAction!(ZiggeoProgressType.VERIFIED);
    if (!proceedInVerfiedProgress) return;
    onChange(videoAttributes);
  }, [onChange, onProgressAction, proceedInVerfiedProgress]);

  const collectRef = useCallback((e: any) => {
    recorderRef(e);
    setOuterRef!(e);
  }, [recorderRef, setOuterRef]);

  return (
    <Flex
      ref={ref}
      maxW="100%"
      h="full"
      w="full"
      align="center"
      justify="center"
      sx={{
        '.ba-videorecorder-chooser-button-container': {
          width: '80%',
        },
        '.ba-videorecorder-chooser-button-0': {
          whiteSpace: 'unset',
        },
        '.ba-videorecorder-chooser-icon-container': {
          display: 'none',
        },
        '& > div': {
          maxW: '100%',
          minW: '0!important',
          minH: '0!important',
        },
        '& > div > div': {
          maxW: '100%',
          minW: '0!important',
          minH: '0!important',
        },
        '& .ba-recorder-overlay': {
          w: 'full',
          h: 'full',
        },
      }}
    >
      <ZiggeoRecorder
        skipinitial={skipinitial}
        updateInstance={updateInstance}
        apiKey={process.env.REACT_APP_ZIGGEO_API_KEY}
        height="100%"
        width="100%"
        allowcancel
        webrtc_streaming_if_necessary
        theme="default"
        themecolor="default"
        allowrecord={allowRecord}
        allowupload={allowUpload}
        timelimit={250}
        faceoutline={false}
        skipinitialonrerecord={false}
        picksnapshots={false}
        countdown={3}
        force-overwrite={forceOverwrite}
        delete-old-streams={deleteOldStreams}
        video={videoToken}
        onRef={collectRef}
        onProcessed={handleProcessed}
        recordingwidth={960}
        recordingheight={720}
        onVerified={handleVideoVerified}
        onUploadSelected={() => {
          onProgressAction!(ZiggeoProgressType.UPLOADING);
        }}
        onUploading={() => {
          onProgressAction!(ZiggeoProgressType.UPLOADING);
        }}
        onRecording={() => {
          onProgressAction!(ZiggeoProgressType.RECORDING);
        }}
        onProcessing={() => {
          onProgressAction!(ZiggeoProgressType.PROCESSING);
        }}
        simulate={simulate}
        localplayback={localplayback}
        rerecordable
      />
    </Flex>
  );
});
VideoRecorder.defaultProps = {
  onProgressAction: () => {},
  setOuterRef: () => {},
  skipinitial: false,
  simulate: false,
  localplayback: false,
  proceedInVerfiedProgress: false,
  videoToken: null,
  forceOverwrite: false,
  deleteOldStreams: false,
};

export default memo(VideoRecorder);
