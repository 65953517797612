export default {
  baseStyle: {
    boxSizing: 'border-box',
    border: '2px solid',
    fontFamily: "'Nunito', sans-serif",
    cursor: 'pointer',
    boxShadow: 'none',
    _focus: {
      outline: 'none',
      outlineOffset: 0,
      boxShadow: 'none',
    },
  },
  sizes: {
    regular: {
      py: '8px',
      px: '28px',
      fontSize: 'vmRegular',
      lineHeight: '24px',
      fontWeight: 700,
    },
    iconOnly: {
      w: '44px',
      h: '44px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: 0,
    },
    tableIconOnly: {
      w: {
        sm: '35px',
        hxl: '44px',
      },
      h: {
        sm: '35px',
        hxl: '44px',
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: 0,
      sx: {
        '& > svg': {
          fontSize: { sm: 'vmRegular', hxl: '17px' },
        },
      },
    },
  },
  variants: {
    primary: {
      backgroundColor: 'vmGray.500',
      borderColor: 'vmGray.500',
      color: 'vmWhite',
      borderRadius: '10px',
      textTransform: 'uppercase',
      _hover: {
        backgroundColor: 'vmGray.450',
        borderColor: 'vmGray.450',
        _disabled: {
          boxShadow: 'none',
          bg: 'vmGray.300',
        },
      },
    },
    iconOnly: {
      bg: 'vmGray.250',
      color: 'vmGray.50',
      borderRadius: '4px',
      _hover: {
        bg: 'vmGray.50',
        sx: {
          '& > *': {
            color: 'vmWhite',
          },
        },
      },
    },
    solidPrimary: {
      borderColor: 'vmGray.450',
      background: 'vmWhite',
      color: 'vmBlack.100',
      borderRadius: '10px',
      textTransform: 'uppercase',
      _hover: {
        color: 'vmPrimary.100',
        borderColor: 'vmGray.400',
      },
    },
  },
  defaultProps: {
    variant: 'primary',
    size: 'regular',
  },
};
