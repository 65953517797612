import { Box } from '@chakra-ui/react';
import CheckRounded from '@components/icons/question/CheckRounded';
import { IVmQuestionListItem } from '@typed/question.types';

interface Props {
  question: IVmQuestionListItem;
}

function QuestionListItemStatus({
  question,
}: Props) {
  if (!question.viewed) {
    return (
      <Box w="8px" h="8px" bg="vmGray.300" borderRadius="full" />
    );
  }

  if (!question.answered) {
    return (
      <Box w="8px" h="8px" bg="vmGray.150" borderRadius="full" />
    );
  }

  if (question.answered) {
    return (
      <CheckRounded fontSize="19.5px" />
    );
  }

  return <Box />;
}

export default QuestionListItemStatus;
