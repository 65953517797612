import { Icon, IconProps } from '@chakra-ui/react';

function ArrowDownIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 10 6" strokeWidth="2" stroke="vmPrimary.50" fill="none" {...props}>
      <path
        d="M1 1L5 5L9 1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default ArrowDownIcon;
