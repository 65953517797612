import { Icon, IconProps } from '@chakra-ui/react';

function RecordIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 21 12" fill="vmWhite" {...props}>
      <path
        d="M12.1736 0H2.15972C1.24306 0 0.5 0.66875 0.5 1.49375V10.5063C0.5 11.3313 1.24306 12 2.15972 12H12.1736C13.0903 12 13.8333 11.3313 13.8333 10.5063V1.49375C13.8333 0.66875 13.0903 0 12.1736 0ZM18.75 1.17812L14.9444 3.54063V8.45938L18.75 10.8188C19.4861 11.275 20.5 10.8094 20.5 10.0125V1.98438C20.5 1.19063 19.4896 0.721875 18.75 1.17812Z"
      />
    </Icon>
  );
}

export default RecordIcon;
