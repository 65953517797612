import { memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { VideoEditorScene, VideoEditorIntroOutro } from '@modules/VideoEditor/types/video-editor.types';
import VideoEditorScenePreview from '@modules/VideoEditor/components/VideoEditorScenePreview/VideoEditorScenePreview';
import NotFound from '@components/NotFound';

function VideoSceneDetail() {
  const { id } = useParams<'id'>();
  const { getValues, watch } = useFormContext();
  const scenes: VideoEditorScene[] = watch('scenes');
  const intro: VideoEditorIntroOutro | null = getValues('intro') ?? null;
  const outro: VideoEditorIntroOutro | null = getValues('outro') ?? null;
  const sceneIndex = scenes.findIndex((scene) => scene.id === id);
  const isIntro = id === 'intro' && intro;
  const isOutro = id === 'outro' && outro;

  const scene = useMemo(() => {
    if (isIntro) {
      return intro;
    }
    if (isOutro) {
      return outro;
    }
    if (sceneIndex === -1) return null;
    return (scenes ?? [])[sceneIndex];
  }, [isIntro, isOutro, sceneIndex, scenes, intro, outro]);

  const updateKey = useMemo(() => {
    if (isIntro) {
      return 'intro';
    }
    if (isOutro) {
      return 'outro';
    }
    return `scenes.${sceneIndex}`;
  }, [isIntro, isOutro, sceneIndex]);

  if (scene !== null) {
    return (
      <VideoEditorScenePreview
        scene={scene}
        updateKey={updateKey}
        isDisableTrimMode={Boolean(isIntro || isOutro)}
      />
    );
  }
  return (<NotFound />);
}

export default memo(VideoSceneDetail);
