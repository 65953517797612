import {
  Box,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import { IVmQuestionListItem } from '@typed/question.types';
import QuestionListItemStatus from '@modules/Question/components/QuestionListItemStatus';
import ContextItem from '@components/ContextItem';
import ContactIcon from '@components/icons/contact/Contact';
import NavLink from '@components/NavLink';
import ShareIcon from '@components/icons/Share';
import ThreeDotsIcon from '@components/icons/ThreeDots';
import {
  Link,
  useMatch,
  useNavigate,
  useResolvedPath,
} from 'react-router-dom';
import TrashIcon from '@components/icons/Trash';
import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { deleteQuestion } from '@apis/question.api';

interface Props {
  question: IVmQuestionListItem;
  onRemove: (questionId: string) => void;
  formId: string | null;
  contactId: string | null;
}

function QuestionListItem({
  question,
  onRemove,
  formId,
  contactId,
}: Props) {
  const navigate = useNavigate();
  const name = `${question.firstname} ${question.lastname}`;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: isDeleting, onClose: finishDeleting, onOpen: startDeleting } = useDisclosure();
  const resolved = useResolvedPath(`view/${question.questionId}`);
  const match = useMatch({ path: resolved.pathname, end: true });

  const handleDeleteQuestion = useCallback(async (event: Event) => {
    event.stopPropagation();
    try {
      startDeleting();
      await deleteQuestion(question.questionId);
      toast.success('A question has been deleted successfully');
      onRemove(question.questionId);
      finishDeleting();
      if (match) {
        navigate('/questions');
        return;
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishDeleting();
  }, [finishDeleting, match, navigate, onRemove, question.questionId, startDeleting]);
  const questionLink = useMemo(() => {
    if (formId) return `/questions-f/${formId}`;
    if (contactId) return `/questions-c/${formId}`;
    return '/questions';
  }, [formId, contactId]);

  return (
    <Flex
      onClick={() => navigate({ pathname: `${questionLink}/view/${question.questionId}`, search: window.location.search })}
      align="center"
      justify="space-between"
      py="11px"
      px="19px"
      bg={match ? 'vmWhite' : 'vmGray.150'}
      borderRadius="8px"
      borderWidth="1px"
      borderColor={match ? 'vmBlack.100' : 'vmGray.150'}
      cursor="pointer"
    >
      <Flex align="center" gap="15px">
        <Box minW="19.5px" textAlign="center">
          <QuestionListItemStatus question={question} />
        </Box>
        <Box>
          <Box as="h4" fontSize="16px" lineHeight="24px" fontWeight={600} color="vmBlack.50">{name}</Box>
          <Box fontSize="16px" lineHeight="24px" fontWeight={400} color="vmBlack.50">
            asks via
            <Link to={`/forms/update/${question.formId}`} onClick={(event) => event.stopPropagation()}>
              <Box ml="5px" as="span" color="vmPrimary.50" cursor="pointer">{question.formName}</Box>
            </Link>
          </Box>
        </Box>
      </Flex>
      <Flex align="center" gap="22px">
        <Box fontSize="13px" lineHeight="19.5px" fontWeight={400} color="vmBlack.100">1 hour ago</Box>
        <Popover placement="right-start" isOpen={isOpen} onClose={onClose} isLazy>
          <PopoverTrigger>
            {isDeleting ? (
              <Spinner w="20px" h="20px" />
            ) : (
              <Box cursor="pointer" onClick={(event) => { event.stopPropagation(); onOpen(); }}>
                <ThreeDotsIcon fontSize="20px" />
              </Box>
            )}
          </PopoverTrigger>
          <Portal>
            <PopoverContent ml="28px" mt="-20px" _focusVisible={{ outline: 'none' }} borderRadius="10px" boxShadow="0px 2px 12px rgba(36, 133, 204, 0.15)" overflow="hidden">
              <PopoverBody>
                <Box w="320px" bg="vmWhite">
                  <NavLink link="/answers">
                    <ContextItem borderBottomWidth="2px !important">
                      <ShareIcon fill="vmGray.50" fontSize="16px" />
                      <Box color="vmGray.400" fontSize="vmNormal" fontWeight={600} lineHeight="24px">Answers</Box>
                    </ContextItem>
                  </NavLink>
                  <NavLink link="/contacts">
                    <ContextItem borderBottomWidth="2px !important">
                      <ContactIcon stroke="vmGray.50" fontSize="16px" />
                      <Box color="vmGray.400" fontSize="vmNormal" fontWeight={600} lineHeight="24px">Contact</Box>
                    </ContextItem>
                  </NavLink>
                  <ContextItem onClick={handleDeleteQuestion}>
                    <TrashIcon fontSize="16px" />
                    <Box color="vmGray.400" fontSize="vmNormal" fontWeight={600} lineHeight="24px">Delete</Box>
                  </ContextItem>
                </Box>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </Flex>
    </Flex>
  );
}

export default QuestionListItem;
