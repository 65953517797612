import { Icon, IconProps } from '@chakra-ui/react';

function ContactIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 22" fill="none" stroke="vmGray.50" strokeWidth="1.5" {...props}>
      <path
        d="M14 1V3M6 1V3M15 17.5C13.6 16.5 11.9 16 10 16C8.1 16 6.4 16.6 5 17.5M17 21H3C1.9 21 1 20.1 1 19V5C1 3.9 1.9 3 3 3H17C18.1 3 19 3.9 19 5V19C19 20.1 18.1 21 17 21ZM13 10C13 11.6569 11.6569 13 10 13C8.34315 13 7 11.6569 7 10C7 8.34315 8.34315 7 10 7C11.6569 7 13 8.34315 13 10Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default ContactIcon;
