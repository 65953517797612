import { Icon, IconProps } from '@chakra-ui/react';

function DeviceSettingIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 10 10" fill="vmWhite" stroke="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.42229 5.79C9.61054 5.885 9.75577 6.035 9.85796 6.185C10.057 6.495 10.0408 6.875 9.84721 7.21L9.4707 7.81C9.27168 8.13 8.90055 8.33 8.51866 8.33C8.33041 8.33 8.12064 8.28 7.94852 8.18C7.80867 8.095 7.64731 8.065 7.47519 8.065C6.9427 8.065 6.49626 8.48 6.48012 8.975C6.48012 9.55 5.98528 10 5.36673 10H4.63522C4.01129 10 3.51645 9.55 3.51645 8.975C3.50569 8.48 3.05926 8.065 2.52676 8.065C2.34927 8.065 2.1879 8.095 2.05344 8.18C1.88132 8.28 1.66617 8.33 1.48329 8.33C1.09602 8.33 0.724892 8.13 0.525879 7.81L0.154747 7.21C-0.0442655 6.885 -0.0550229 6.495 0.14399 6.185C0.230049 6.035 0.391411 5.885 0.574288 5.79C0.724892 5.72 0.821709 5.605 0.913147 5.47C1.18208 5.04 1.02072 4.475 0.56353 4.22C0.0310366 3.935 -0.141083 3.3 0.165505 2.805L0.525879 2.215C0.837845 1.72 1.50481 1.545 2.04268 1.835C2.51063 2.075 3.11842 1.915 3.39274 1.49C3.4788 1.35 3.52721 1.2 3.51645 1.05C3.50569 0.855 3.56486 0.67 3.66705 0.52C3.86607 0.21 4.22644 0.01 4.61909 0H5.37749C5.77551 0 6.13589 0.21 6.3349 0.52C6.43172 0.67 6.49626 0.855 6.48012 1.05C6.46937 1.2 6.51778 1.35 6.60384 1.49C6.87815 1.915 7.48595 2.075 7.95927 1.835C8.49177 1.545 9.16411 1.72 9.4707 2.215L9.83107 2.805C10.143 3.3 9.97092 3.935 9.43304 4.22C8.97585 4.475 8.81449 5.04 9.08881 5.47C9.17487 5.605 9.27168 5.72 9.42229 5.79ZM3.4788 5.005C3.4788 5.79 4.1619 6.415 5.00636 6.415C5.85081 6.415 6.51778 5.79 6.51778 5.005C6.51778 4.22 5.85081 3.59 5.00636 3.59C4.1619 3.59 3.4788 4.22 3.4788 5.005Z"
      />
    </Icon>
  );
}

export default DeviceSettingIcon;
