import { Box, Flex } from '@chakra-ui/react';
import { EXTERNAL_LINKS } from '@constants/external-links';
import SettingBoxLabelStyled from '@shared/Setting/styled/SettingBoxLabelStyled';
import SettingBoxTextStyled from '@shared/Setting/styled/SettingBoxTextStyled';
import { useVmStore } from '@store/index';
import { customerRegisteredSelector, customerSubscriptionSelector } from '@store/slices/customer/selectors';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow';
import ExtraPlan from './parts/ExtraPlan';
import ListPlan from './parts/ListPlan';
import PaymentHandler from './parts/PaymentHandler';
import SubscriptionTools from './parts/SubscriptionTools';

function PlanContainer() {
  const { t } = useTranslation();
  const customerSubscription = useVmStore(customerSubscriptionSelector, shallow);
  const customerRegistered = useVmStore(customerRegisteredSelector);

  useEffect(() => {
    window.Paddle.Setup({ vendor: 28889 });
  }, []);

  return (
    <Flex direction="column" gap="20px" py="80px" px="40px">
      <Flex direction="column" gap="20px" p="20px" bg="vmGray.150" borderRadius="8px">
        <Flex align="center" justify="space-between" gap="20px">
          <SettingBoxLabelStyled>{t('plan.labels.registered')}</SettingBoxLabelStyled>
          <SettingBoxTextStyled>{dayjs(customerRegistered).format('MM/DD/YYYY HH:mm A')}</SettingBoxTextStyled>
        </Flex>
        <Flex align="center" justify="space-between" gap="20px">
          <SettingBoxLabelStyled>{t('plan.labels.subscription')}</SettingBoxLabelStyled>
          <SettingBoxTextStyled>
            {customerSubscription?.subscription ?? t('common.texts.unknown')}
            {' '}
            {(customerSubscription?.trial) && (
            <Box as="span">
              (
              {customerSubscription?.trialEnded ? 'Trial Ended' : 'Trial'}
              )
            </Box>
            )}
          </SettingBoxTextStyled>
        </Flex>
      </Flex>
      <Box p="20px" bg="vmGray.150" borderRadius="8px">
        <SubscriptionTools />
      </Box>
      {customerSubscription?.active && (
        <PaymentHandler
          paymentCancelUrl={customerSubscription.cancelUrl}
          paymentUpdateUrl={customerSubscription.updateUrl}
        />
      )}
      <Box p="20px" bg="vmGray.150" borderRadius="8px">
        <ListPlan />
      </Box>
      <Box p="20px" bg="vmGray.150" borderRadius="8px">
        <Box w="calc(50% - 15px)" mx="auto">
          <ExtraPlan />
        </Box>
      </Box>
      <Box mt="20px" p="20px" bg="vmGray.150" borderRadius="8px">
        <Box
          fontSize="vmRegular"
          lineHeight="22px"
          px="15px"
          py="10px"
          color="vmYellow.50"
          borderRadius="10px"
          p="20px"
        >
          <Box>{t('plan.text.exclusive_vendor')}</Box>
          <Box mt="10px">{t('plan.text.service_offer')}</Box>
          <Box mt="10px">{t('plan.text.addition')}</Box>
          <Flex align="center" w="full" justify="space-between" mt="20px">
            <Box w="full" textAlign="center" as="a" href={EXTERNAL_LINKS.TERMS_AND_CONDITIONS} target="_blank" color="vmPrimary.50">
              {t('plan.text.labels.terms_and_conditions')}
            </Box>
            <Box w="full" textAlign="center" as="a" href={EXTERNAL_LINKS.DATA_CONFIDENTIALITY_STATEMENT} target="_blank" color="vmPrimary.50">
              {t('plan.text.labels.data_protection_statement')}
            </Box>
            <Box w="full" textAlign="center" as="a" href={EXTERNAL_LINKS.IMPRINT} target="_blank" color="vmPrimary.50">
              {t('plan.text.labels.imprint')}
            </Box>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
}

export default PlanContainer;
