import { Icon, IconProps } from '@chakra-ui/react';

function TrimIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 18" fill="none" stroke="vmPrimary.50" {...props}>
      <path
        d="M3.5 6C2.11929 6 1 4.88071 1 3.5C1 2.11929 2.11929 0.999999 3.5 0.999999C4.88071 0.999999 6 2.11929 6 3.5C6 4.88071 4.88071 6 3.5 6ZM3.5 6L19 14M3.5 12C2.11929 12 0.999999 13.1193 0.999999 14.5C0.999999 15.8807 2.11929 17 3.5 17C4.88071 17 6 15.8807 6 14.5C6 13.1193 4.88071 12 3.5 12ZM3.5 12L19 4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default TrimIcon;
