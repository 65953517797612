import { memo } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

function VideoMessageIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 18 14" stroke="vmPrimary.50" fill="none" {...props}>
      <path
        d="M12.5556 4.23077L17 2.38462V11.6154L12.5556 9.76923M1.88889 13H11.6667C12.1576 13 12.5556 12.5867 12.5556 12.0769V1.92308C12.5556 1.41328 12.1576 1 11.6667 1H1.88889C1.39797 1 1 1.41328 1 1.92308V12.0769C1 12.5867 1.39797 13 1.88889 13Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default memo(VideoMessageIcon);
