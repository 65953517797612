import { emptyFunction } from '@utils/func.util';
import { useCallback, useState } from 'react';
import { RecorderContainerContextProvider } from './contexts/RecorderContainerContext';
import VmVideoRecorderText from './parts/VmVideoRecoderText';
import VmVideoRecorderChooser from './parts/VmVideoRecorderChooser';
import VmVideoRecorderTrigger from './parts/VmVideoRecorderTrigger';

enum VmVideoRecorderType {
  RECORD = 'record',
  TEXT = 'text',
}

interface TextProps {
  allowText?: boolean;
  textName?: string;
  textTitle?: string;
  textPlaceholder?: string;
}

interface VideoProps {
  allowVideo: boolean;
  videoTitle?: string;
  videoText?: string;
  showArrowBack?: boolean;
  allowUpload?: boolean;
  onStartVideoProgress?: () => void;
  onFinishVideoProgress?: () => void;
  shouldCallApi?: boolean;
  children?: JSX.Element;
}

interface Props {
  label: string;
  name: string;
}

function getDefaultView(isVideoEnable: boolean, isTextEnable: boolean): VmVideoRecorderType | null {
  if (isTextEnable && isVideoEnable) return null;
  if (isTextEnable) return VmVideoRecorderType.TEXT;
  if (isVideoEnable) return VmVideoRecorderType.RECORD;
  return null;
}

function VmVideoRecorder({
  label,
  name,
  allowText,
  allowVideo,
  textName,
  showArrowBack,
  allowUpload,
  textTitle,
  textPlaceholder,
  videoTitle,
  videoText,
  shouldCallApi,
  children,
  onStartVideoProgress,
  onFinishVideoProgress,
}: Props & TextProps & VideoProps) {
  const [activeType, setActiveType] = useState<VmVideoRecorderType | null>(
    getDefaultView(allowVideo, allowText!),
  );

  const handleSwitchToRecordView = useCallback(() => {
    setActiveType(VmVideoRecorderType.RECORD);
  }, []);

  const handleSwitchToTextView = useCallback(() => {
    setActiveType(VmVideoRecorderType.TEXT);
  }, []);

  const handleBackToChooserView = useCallback(() => {
    setActiveType(null);
  }, []);

  if (activeType === VmVideoRecorderType.TEXT && allowText && textName) {
    return (
      <VmVideoRecorderText
        name={textName}
        onGoBack={handleBackToChooserView}
        title={textTitle!}
        text={textPlaceholder!}
        allowVideo={allowVideo}
      />
    );
  }

  if (activeType === VmVideoRecorderType.RECORD) {
    return (
      <RecorderContainerContextProvider>
        <VmVideoRecorderTrigger
          name={name}
          onGoBack={handleBackToChooserView}
          title={videoTitle}
          text={videoText}
          allowText={allowText!}
          allowUpload={allowUpload!}
          showArrowBack={showArrowBack!}
          onStartVideoProgress={onStartVideoProgress!}
          onFinishVideoProgress={onFinishVideoProgress!}
          shouldCallApi={shouldCallApi!}
        >
          {children!}
        </VmVideoRecorderTrigger>
      </RecorderContainerContextProvider>
    );
  }

  return (
    <VmVideoRecorderChooser
      label={label}
      onSwitchToRecorderView={handleSwitchToRecordView}
      onSwitchToTextView={handleSwitchToTextView}
      allowText={allowText!}
      allowVideo={allowVideo}
    />
  );
}

VmVideoRecorder.defaultProps = {
  allowText: false,
  textName: '',
  textTitle: '',
  textPlaceholder: '',
  videoTitle: '',
  videoText: '',
  showArrowBack: true,
  allowUpload: true,
  shouldCallApi: true,
  children: null,
  onStartVideoProgress: emptyFunction,
  onFinishVideoProgress: emptyFunction,
};

export default VmVideoRecorder;
