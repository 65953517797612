import {
  ChangeEvent,
  memo,
  useCallback,
  useRef,
} from 'react';
import { Box, Button, Input } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  onUpload: (file: Blob, fileName: string) => Promise<void>;
  isUploading: boolean;
}

function MediaLibraryUploader({
  onUpload,
  isUploading,
}: Props) {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleUploadFile = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ?? [];
    const file = files[0];
    if (file) {
      const fileExtension = [...file.name.split('.')].pop();
      const allowVideoExtensions = ['video/x-ms-wmv', 'video/mp4', 'video/mpeg', 'video/avi', 'video/quicktime', 'video/webm'];
      const allowImageExtensions = ['image/jpeg', 'image/png'];
      if (
        allowVideoExtensions.includes(file.type)
        || allowImageExtensions.includes(file.type)
        || fileExtension === 'mkv'
      ) {
        const fileName: string = file.name.replace(/[^\w\-.]/g, '');
        await onUpload(
          new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type }),
          fileName,
        );
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      }
    }
  }, [onUpload]);

  return (
    <Box>
      {isUploading ? (
        <Button isDisabled isLoading type="button">{t('ml.labels.upload_new')}</Button>
      ) : (
        <Button isDisabled={false} isLoading={false} as="label" htmlFor="media-upload-new-file">
          {t('ml.labels.upload_new')}
        </Button>
      )}
      <Input
        ref={inputRef}
        onChange={handleUploadFile}
        id="media-upload-new-file"
        hidden
        type="file"
        accept=".png, .jpg, .jpeg, .mp4, .mpg, .avi, .mov, .wmv, .webm, .mkv"
      />
    </Box>
  );
}

export default memo(MediaLibraryUploader);
