import { Icon, IconProps } from '@chakra-ui/react';

function SearchIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 18 18" fill="vmGray.600" {...props}>
      <path
        d="M16.4697 17.5303C16.7626 17.8232 17.2374 17.8232 17.5303 17.5303C17.8232 17.2374 17.8232 16.7626 17.5303 16.4697L16.4697 17.5303ZM14.3676 8.05882C14.3676 11.5431 11.5431 14.3676 8.05882 14.3676V15.8676C12.3715 15.8676 15.8676 12.3715 15.8676 8.05882H14.3676ZM8.05882 14.3676C4.57456 14.3676 1.75 11.5431 1.75 8.05882H0.25C0.25 12.3715 3.74613 15.8676 8.05882 15.8676V14.3676ZM1.75 8.05882C1.75 4.57456 4.57456 1.75 8.05882 1.75V0.25C3.74613 0.25 0.25 3.74613 0.25 8.05882H1.75ZM8.05882 1.75C11.5431 1.75 14.3676 4.57456 14.3676 8.05882H15.8676C15.8676 3.74613 12.3715 0.25 8.05882 0.25V1.75ZM17.5303 16.4697L13.5891 12.5284L12.5284 13.5891L16.4697 17.5303L17.5303 16.4697Z"
      />
    </Icon>
  );
}

export default SearchIcon;
