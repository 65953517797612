import { Box, Flex } from '@chakra-ui/react';
import { IVideoLibraryItem } from '@modules/VideoLibrary/types/video-library.types';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  video: IVideoLibraryItem;
  measure: () => void;
}

function VideoLibraryItem({
  video,
  measure,
}: Props) {
  useEffect(() => {
    measure();
  }, [measure]);

  return (
    <Link to={`/video-library/view/${video.id}`}>
      <Flex direction="column" gap="20px">
        <Box
          borderRadius="10px"
          h="192px"
          bgImage={`https://${video.videoImageUrl}`}
          bgPos="center center"
          bgRepeat="no-repeat"
          bgSize="cover"
        />
        <Box />
      </Flex>
    </Link>
  );
}

export default VideoLibraryItem;
