import { Icon, IconProps } from '@chakra-ui/react';

function CopyIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" fill="none" stroke="vmGray.50" strokeWidth={1.5} {...props}>
      <path
        d="M6.88867 13.1111L13.1109 6.88892M4.55534 9.22225L2.99978 10.7778C1.28156 12.496 1.28156 15.2818 2.99978 17C4.718 18.7182 7.50378 18.7182 9.222 17L10.7776 15.4445M15.4442 10.7778L16.9998 9.22225C18.718 7.50403 18.718 4.71825 16.9998 3.00003C15.2816 1.28182 12.4958 1.28182 10.7776 3.00003L9.222 4.55559"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default CopyIcon;
