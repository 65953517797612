import { Icon, IconProps } from '@chakra-ui/react';

function CloneIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" stroke="vmGray.50" strokeWidth="1.5" fill="none" {...props}>
      <path
        d="M15.5 12H17C18.1046 12 19 11.1046 19 10V3C19 1.89543 18.1046 1 17 1H10C8.89543 1 8 1.89543 8 3V4.5M3 19H10C11.1046 19 12 18.1046 12 17V10C12 8.89543 11.1046 8 10 8H3C1.89543 8 1 8.89543 1 10V17C1 18.1046 1.89543 19 3 19Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default CloneIcon;
