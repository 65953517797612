import { useCallback, useEffect, useState } from 'react';

interface UseVideoEditorControl {
  moveNext5Sec: () => void;
  movePrev5Sec: () => void;
  handleTogglePlay: () => void;
  isPlaying: boolean;
}

function useVideoEditorControl(
  player: any,
  startTime: number,
  endTime: number,
): UseVideoEditorControl {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const handlePlayVideo = useCallback((videoIsPlaying: boolean, timer: number) => {
    const playedTime = player.getPlaybackCount();
    if (!playedTime) {
      player.play();
      return;
    }
    if (!videoIsPlaying) {
      player.play();
    }
    const currentTime = player.getCurrentPosition();
    const timeToSeek = currentTime + timer;
    const seekTime = timeToSeek >= 0 ? timeToSeek : 0;
    if (seekTime >= endTime) {
      player.seek(endTime);
      return;
    }
    if (seekTime < startTime) {
      player.seek(startTime);
      return;
    }
    player.seek(seekTime);
  }, [endTime, player, startTime]);

  const moveNext5Sec = useCallback(() => {
    const videoIsPlaying = player.get('playing');
    handlePlayVideo(videoIsPlaying, 5);
  }, [player, handlePlayVideo]);

  const movePrev5Sec = useCallback(() => {
    const videoIsPlaying = player.get('playing');
    handlePlayVideo(videoIsPlaying, -5);
  }, [player, handlePlayVideo]);

  const handleTogglePlay = useCallback(() => {
    if (isPlaying) {
      player.pause();
    } else {
      const currentTime = player.getCurrentPosition() * 1000;
      const isPlayingInLeftPosition = currentTime <= startTime * 1000;
      // if (!(currentTime >= startTime * 1000 && currentTime <= endTime * 1000)) {
      //   player.seek(startTime);
      // }
      if (isPlayingInLeftPosition) {
        player.seek(startTime);
      }
      player.play();
    }
  }, [isPlaying, player, startTime]);

  useEffect(() => {
    player.on('ended', () => {
      setIsPlaying(false);
      player.seek(startTime);
    });
    player.on('playing', () => {
      setIsPlaying(true);
    });
    player.on('paused', () => {
      setIsPlaying(false);
    });
  }, [player, startTime]);

  return {
    moveNext5Sec,
    movePrev5Sec,
    handleTogglePlay,
    isPlaying,
  };
}

export default useVideoEditorControl;
