import {
  memo,
} from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import VideoEditorSendProgress from './VideoEditorSendProgress';

interface Props {
  isOpen: boolean;
  onClose: (redirect?: boolean) => void;
  onRenderVideoPreProcess: () => Promise<void>;
  onRenderVideoSuccess: () => Promise<void>;
  answerId: string;
}

function VideoEditorSendVideo({
  isOpen,
  onClose,
  onRenderVideoPreProcess,
  onRenderVideoSuccess,
  answerId,
}: Props) {
  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent w="720px" maxW="720px">
        <ModalBody p="40px" pos="relative">
          <VideoEditorSendProgress
            onClose={onClose}
            answerId={answerId}
            onSendSuccess={onRenderVideoSuccess}
            onRenderVideoPreProcess={onRenderVideoPreProcess}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default memo(VideoEditorSendVideo);
