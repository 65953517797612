function convertSizeToString(bytes: number): string {
  if (bytes < 1000) {
    return `${bytes} B`;
  }

  if (bytes < (1000 * 1000)) {
    return `${Number((bytes / 1000).toFixed(2))} KB`;
  }

  if (bytes < (1000 * 1000 * 1000)) {
    return `${Number((bytes / (1000 * 1000)).toFixed(2))} MB`;
  }

  return `${Number((bytes / (1000 * 1000 * 1000)).toFixed(2))} GB`;
}

export {
  // eslint-disable-next-line import/prefer-default-export
  convertSizeToString,
};
