import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import CloseIcon from '@components/icons/Close';
import VMVideoPlayer from '@components/VMVideoPlayer';
import { memo } from 'react';

interface Props {
  showVideoMessage: boolean;
  videoMessageToken: string | null;
}

function WelcomeVideo({
  showVideoMessage,
  videoMessageToken,
}: Props) {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  if (!showVideoMessage) return null;

  return (
    <Modal
      closeOnOverlayClick
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent w="880px" maxW="100%" bg="transparent">
        <ModalBody pos="relative" p={0}>
          <Box
            pos="absolute"
            cursor="pointer"
            onClick={onClose}
            zIndex={99999}
            top="-28px"
            right="-21px"
          >
            <CloseIcon color="vmWhite" />
          </Box>
          <Box h="480px">
            <VMVideoPlayer
              source={null}
              videoToken={videoMessageToken}
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default memo(WelcomeVideo);
