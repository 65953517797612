import {
  Box,
  Button,
  Fade,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Image,
  Input,
  InputGroup,
  InputRightAddon,
  useDisclosure,
} from '@chakra-ui/react';
import { memo, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import VideoEditorUploader from '@modules/VideoEditor/components/VideoEditorUploader/VideoEditorUploader';
import MediaLibrarySelector from '@modules/VideoEditor/components/MediaLibrarySelector/MediaLibrarySelector';
import { Media } from '@shared/MediaLibrary/types/media';
import useMediaUpload from '@shared/MediaLibrary/hooks/useMediaUpload';
import { VideoEditorScene } from '@modules/VideoEditor/types/video-editor.types';
import { useNavigate } from 'react-router-dom';
import BackIcon from '@components/icons/Back';
import DeleteIcon from '@components/icons/ml/Delete';

interface Props {
  sceneIndex: number;
  brandId: string;
}

function VideoEditorEditImageSceneContainer({
  sceneIndex,
  brandId,
}: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    getValues,
    register,
    setValue,
    watch,
  } = useFormContext();
  const imageUrl = watch(`scenes.${sceneIndex}.imageUrl`);
  const {
    isOpen: isLibraryOpen,
    onClose: closeLibrary,
    onOpen: openLibrary,
  } = useDisclosure();

  const {
    upload,
    isUploading,
  } = useMediaUpload(brandId);

  const handleSetUrlImage = useCallback(async (file: Media) => {
    const scene: VideoEditorScene = getValues(`scenes.${sceneIndex}`);
    const newImage = {
      ...scene,
      fileId: file.id,
      imageDuration: 2,
      imageUrl: file.imageUrl,
    };
    setValue(`scenes.${sceneIndex}`, newImage, { shouldDirty: true });
    closeLibrary();
  }, [closeLibrary, getValues, sceneIndex, setValue]);

  const handleDeleteImage = useCallback(() => {
    const currentScenes: VideoEditorScene[] = getValues('scenes');
    setValue('scenes', currentScenes.filter((sceneItem) => sceneItem.id !== currentScenes[sceneIndex].id), { shouldDirty: true });
    navigate('./../../scenes');
  }, [getValues, setValue, navigate, sceneIndex]);

  const handleUploadFile = useCallback(async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = [...file.name.split('.')].pop();
      const allowVideoExtensions = ['video/x-ms-wmv', 'video/mp4', 'video/mpeg', 'video/avi', 'video/quicktime', 'video/webm'];
      const allowImageExtensions = ['image/jpeg', 'image/png'];
      if (
        allowVideoExtensions.includes(file.type)
        || allowImageExtensions.includes(file.type)
        || fileExtension === 'mkv'
      ) {
        const fileName: string = file.name.replace(/[^\w\-.]/g, '');
        await upload(
          new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type }),
          fileName,
          handleSetUrlImage,
        );
      }
    }
  }, [upload, handleSetUrlImage]);

  return (
    <Box>
      {isLibraryOpen ? (
        <Fade in={isLibraryOpen}>
          <MediaLibrarySelector
            brandId={brandId}
            onClose={closeLibrary}
            onSelectFile={handleSetUrlImage}
            selectedId={imageUrl ?? null}
            specificType="image"
          />
        </Fade>
      ) : (
        <Box pt="64px">
          <Grid w="full" templateColumns="480px auto" minH="calc(100vh - 64px)" pt="20px" px="20px" pb="20px">
            <Flex direction="column" gap="2px" bg="vmGray.450" borderRadius="10px" overflow="hidden">
              <Flex align="center" justify="space-between" pos="relative" bg="vmWhite" px="20px">
                <Button type="button" variant="solidGray" size="iconOnly" onClick={() => navigate('./../../scenes')}>
                  <BackIcon fontSize="14px" stroke="vmPrimary.50" />
                </Button>
                <Box
                  fontSize="24px"
                  lineHeight="32.4px"
                  fontWeight={600}
                  px="20px"
                  py="27px"
                >
                  {t('video_editor.labels.video_scenes')}
                </Box>
                <Button variant="iconOnly" size="iconOnly" borderColor="vmGray.250" bg="vmGray.700" onClick={handleDeleteImage}>
                  <DeleteIcon stroke="vmRed.50" />
                </Button>
              </Flex>
              <Flex direction="column" bg="vmWhite" h="full" py="30px" px="20px" gap="54px">
                <VideoEditorUploader
                  onChooseMedia={openLibrary}
                  isUploading={isUploading}
                  handleUploadFile={handleUploadFile}
                  isImage
                  previewUrl={imageUrl}
                />
                <Box>
                  <FormControl>
                    <FormLabel>{t('video_editor.labels.image_scene_duration')}</FormLabel>
                    <InputGroup>
                      <Input
                        type="number"
                        min={0}
                        placeholder={t('video_editor.labels.image_scene_duration')}
                        {...register(`scenes.${sceneIndex}.imageDuration`)}
                      />
                      <InputRightAddon>s</InputRightAddon>
                    </InputGroup>
                  </FormControl>
                </Box>
              </Flex>
            </Flex>
            <Flex align="center" justify="center">
              {imageUrl && (
                <Image src={imageUrl} alt="" w="auto" maxW="100%" h="640px" />
              )}
            </Flex>
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default memo(VideoEditorEditImageSceneContainer);
