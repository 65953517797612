import { useTranslation } from 'react-i18next';
import { Box, Spinner, useDisclosure } from '@chakra-ui/react';
import WarningConfirm from '@components/WarningConfirm';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useVmStore } from '@root/store';
import { customerIdSelector, customerSubscriptionSelector } from '@store/slices/customer/selectors';
import { deleteCustomerAccount } from '@apis/customer.api';
import { useAuth0 } from '@auth0/auth0-react';
import ROUTES from '@constants/routes';
import { shallow } from 'zustand/shallow';
import SettingBoxTextStyled from '@shared/Setting/styled/SettingBoxTextStyled';

function AccountDelete() {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const customerId = useVmStore(customerIdSelector);
  const customerSubscription = useVmStore(customerSubscriptionSelector, shallow);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeletingAccount,
    onOpen: startDeletingAccount,
    onClose: finishDeletingAccount,
  } = useDisclosure();
  const handleDeleteAccount = useCallback(async () => {
    try {
      onClose();
      startDeletingAccount();
      await deleteCustomerAccount(customerId);
      toast.success(t('account.message.delete_ok'));
      finishDeletingAccount();
      logout({ logoutParams: { returnTo: `${window.location.origin}${ROUTES.AUTH.LOGIN}` } });
    } catch (error: any) {
      toast.error(error?.message);
      finishDeletingAccount();
    }
  }, [customerId, finishDeletingAccount, logout, onClose, startDeletingAccount, t]);

  return (
    <>
      {isDeletingAccount ? (
        <Box><Spinner width="16px" height="16px" /></Box>
      ) : (
        <SettingBoxTextStyled
          color="vmRed.100"
          cursor={customerSubscription?.active ? 'not-allowed' : 'pointer'}
          opacity={customerSubscription?.active ? 0.8 : 1}
          onClick={customerSubscription?.active ? undefined : onOpen}
        >
          {t('account.labels.delete_forever')}
        </SettingBoxTextStyled>
      )}
      <WarningConfirm
        confirmTitle={t('common.texts.caution')}
        confirmDescription={t('account.message.delete_des')}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleDeleteAccount}
      />
    </>
  );
}

export default AccountDelete;
