import { Box, Flex, Image } from '@chakra-ui/react';
import PlayIcon from '@components/TrustRecorder/icons/Play';
import VMVideoPlayer from '@components/VMVideoPlayer';
import useRecorderRef from '@components/VmVideoRecorder/hooks/useRecorderRef';
import useRecorderPlayingState from '@hooks/useRecorderPlayingState';
import { IVmAnswer } from '@typed/answer.types';

interface Props {
  answer: IVmAnswer;
}

function PublicAnswerContainer({
  answer,
}: Props) {
  const { recorder, setRecorderRef } = useRecorderRef();
  const { isPlaying, isReadyToPlay } = useRecorderPlayingState(recorder);

  return (
    <Flex align="center" w="full" minH="100vh" bg="vmWhite">
      <Box w="540px" borderRadius="10px" py="40px" minH="100vh" mx="auto">
        <Box px="60px" mb="40px">
          <Flex align="center" pb="5px" borderBottomWidth="2px" borderColor="vmPrimary.50" w="full">
            <Image maxWidth="75px" src="/static-data/images/logo-green.png" alt="Logo" mx="auto" />
          </Flex>
          <Box fontSize="22px" lineHeight="33px" fontWeight={600} my="20px" textAlign="center">
            Your mentor answered!
          </Box>
          <Box fontSize="16px" lineHeight="24px" fontWeight={400} textAlign="center">
            Here is the answer of your question.
          </Box>
        </Box>
        <Box
          pos="relative"
          sx={{
            '& .ba-videorecorder-container': {
              borderRadius: '10px',
            },
            '& .ba-videoplayer-container': {
              borderRadius: '10px',
            },
            '& .css-19w1vec .ba-videorecorder-chooser-button-container': {
              w: '90%',
            },
            '& .ba-videorecorder-chooser-container': {
              bg: '#15334B',
            },
            '& .ba-videoplayer-rerecord-button-container': {
              display: 'none',
            },
            '& .ba-player-rerecord-frontbar': {
              display: 'none',
            },
            '& .ba-videoplayer-rerecord-bar': {
              display: 'none',
            },
            '& .ba-videoplayer-playbutton-container': {
              display: 'none',
            },
            '& .ba-player-dashboard': {
              display: 'none',
            },
          }}
        >
          <VMVideoPlayer
            source={null}
            videoToken={answer.videoToken}
            setOuterRef={setRecorderRef}
          />
          {!isPlaying && isReadyToPlay && (
            <Flex
              align="center"
              justify="center"
              className="player-f"
              w="44px"
              h="44px"
              bg="rgba(255, 255, 255, 0.25)"
              borderRadius="50%"
              cursor="pointer"
              mb={0}
              onClick={() => {
                recorder.play();
              }}
              pos="absolute"
              bottom={0}
              top={0}
              left={0}
              right={0}
              m="auto"
              zIndex={90}
            >
              <PlayIcon fontSize="22px" />
            </Flex>
          )}
        </Box>
      </Box>
    </Flex>
  );
}

export default PublicAnswerContainer;
