import { Icon, IconProps } from '@chakra-ui/react';

function MediaIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 22 22" stroke="vmGray.600" fill="none" {...props}>
      <path
        d="M17 15.9091L13.1545 12.0636C13.0869 11.9949 13.0062 11.9404 12.9173 11.9031C12.8283 11.8659 12.7328 11.8467 12.6364 11.8467C12.5399 11.8467 12.4444 11.8659 12.3555 11.9031C12.2665 11.9404 12.1859 11.9949 12.1182 12.0636L8.06364 16.1182C7.99597 16.1869 7.9153 16.2415 7.82634 16.2787C7.73738 16.316 7.6419 16.3352 7.54546 16.3352C7.44901 16.3352 7.35353 16.316 7.26457 16.2787C7.17561 16.2415 7.09495 16.1869 7.02728 16.1182L5.15455 14.2455C5.08687 14.1767 5.00621 14.1222 4.91725 14.0849C4.82829 14.0477 4.73281 14.0285 4.63636 14.0285C4.53992 14.0285 4.44444 14.0477 4.35548 14.0849C4.26652 14.1222 4.18586 14.1767 4.11818 14.2455L1 17.3636M6.71929 9.69958H6.72656M21 16V4C21 2.34315 19.6569 1 18 1H6M4.00015 21H14C15.6569 21 17 19.6569 17 18V8C17 6.34315 15.6569 5 14 5L4.00011 5C2.34326 5 1.00011 6.34315 1.00011 8.00001L1.00015 18C1.00015 19.6569 2.3433 21 4.00015 21Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default MediaIcon;
