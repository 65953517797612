import { Icon, IconProps } from '@chakra-ui/react';

function ReloadIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 18 18" fill="none" stroke="#6E8AAC" strokeWidth="1.5" {...props}>
      <path
        d="M1 1V6.33333M1 6.33333H6.33333M1 6.33333L3.34692 4.22726C4.57448 3.00186 6.16707 2.20857 7.88473 1.96692C9.60239 1.72528 11.3521 2.04836 12.8701 2.8875C14.3882 3.72663 15.5924 5.03637 16.3013 6.61935M17 17V11.6667M17 11.6667L11.6667 11.6667M17 11.6667L14.6531 13.7727C13.4255 14.9982 11.8329 15.7914 10.1152 16.0331C8.39758 16.2747 6.64791 15.9516 5.12986 15.1125C3.6118 14.2734 2.4076 12.9636 1.69869 11.3807"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default ReloadIcon;
