import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react';
import ErrorMessage from '@components/ErrorMessage';
import { useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFormValue } from '../../types/form-value';

function Questioner() {
  const { t } = useTranslation();
  const { control, register } = useFormContext<IFormValue>();
  const { errors } = useFormState({ control });
  return (
    <>
      <FormControl pt={0} isInvalid={!!errors?.question?.titleVideoQuestion?.message}>
        <FormLabel>{t('forms.labels.prompt_title_video_question')}</FormLabel>
        <Input type="text" {...register('question.titleVideoQuestion')} placeholder={t('forms.placeholders.prompt_title_video_question')} />
        <ErrorMessage error={errors?.question?.titleVideoQuestion?.message} />
      </FormControl>
      <FormControl isInvalid={!!errors?.question?.textVideoQuestion?.message}>
        <FormLabel>{t('forms.labels.prompt_text_video_question')}</FormLabel>
        <Textarea rows={5} {...register('question.textVideoQuestion')} placeholder={t('forms.placeholders.prompt_text_video_question')} />
        <ErrorMessage error={errors?.question?.textVideoQuestion?.message} />
      </FormControl>
      <FormControl isInvalid={!!errors?.question?.titleTextQuestion?.message}>
        <FormLabel>{t('forms.labels.prompt_title_text_question')}</FormLabel>
        <Input type="text" {...register('question.titleTextQuestion')} placeholder={t('forms.placeholders.prompt_title_text_question')} />
        <ErrorMessage error={errors?.question?.titleTextQuestion?.message} />
      </FormControl>
      <FormControl isInvalid={!!errors?.question?.textTextQuestion?.message}>
        <FormLabel>{t('forms.labels.prompt_text_question')}</FormLabel>
        <Textarea rows={5} {...register('question.textTextQuestion')} placeholder={t('forms.placeholders.prompt_text_question')} />
        <ErrorMessage error={errors?.question?.textTextQuestion?.message} />
      </FormControl>
    </>
  );
}

export default Questioner;
