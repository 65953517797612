import { Icon, IconProps } from '@chakra-ui/react';

function ArrowRightIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 12" fill="none" stroke="vmBlack.100" strokeWidth="1.5" {...props}>
      <path d="M15 6L1 6M15 6L10.3333 11M15 6L10.3333 1" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  );
}

export default ArrowRightIcon;
