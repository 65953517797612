const ROUTES = {
  HOME: '/',
  AUTH: {
    LOGIN: '/login',
    CALLBACK: '/callback',
    SIGNUP: '/signup',
    VERIFY: '/verify',
  },
  CHECKING: '/checking',
  PLAN: '/plan',
  ACCOUNT: '/account',
};

export default ROUTES;
