import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { getItem, setItem } from '@utils/localstorage.util';
import { ListType } from './types';

interface ListTypeContextValues {
  type: ListType;
  onTypeChange: (v: ListType) => void;
}

interface ListTypeContextProps {
  children: ReactNode;
  keyMap: string;
}

const ListTypeContext = createContext<ListTypeContextValues>({
  type: ListType.LIST,
  onTypeChange: () => {},
});

function useListTypeContext() {
  const context = useContext(ListTypeContext);
  if (!context) throw new Error('Must wrap the ListTypeContextProvider in your component');
  return context;
}

function ListTypeContextProvider({ children, keyMap }: ListTypeContextProps) {
  const initialValue = (getItem(keyMap) || ListType.LIST) as unknown as ListType;
  const [selectedType, setSelectedType] = useState<ListType>(initialValue);

  const onTypeChange = useCallback((newType: ListType) => {
    setItem(keyMap, newType);
    setSelectedType(newType);
  }, [keyMap]);

  const values = useMemo<ListTypeContextValues>(() => ({
    type: selectedType,
    onTypeChange,
  }), [selectedType, onTypeChange]);

  return (
    <ListTypeContext.Provider value={values}>
      {children}
    </ListTypeContext.Provider>
  );
}

export { useListTypeContext };
export default ListTypeContextProvider;
