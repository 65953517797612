import { useCallback, useEffect, useState } from 'react';
import { getVideoDetail } from '@apis/video-library.api';
import { useDisclosure } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { IVideoLibraryItem } from '../types/video-library.types';

interface UseVLVideoDetail {
  isLoadingVideoDetail: boolean;
  video: IVideoLibraryItem | null;
}

function useVLVideoDetail(videoId: string): UseVLVideoDetail {
  const [video, setVideo] = useState<IVideoLibraryItem | null>(null);
  const {
    isOpen: isLoadingVideoDetail,
    onOpen: startLoadingVideoDetail,
    onClose: finishLoadingVideoDetail,
  } = useDisclosure({ defaultIsOpen: true });

  const loadVideoDetail = useCallback(async () => {
    try {
      startLoadingVideoDetail();
      const result = await getVideoDetail(videoId);
      setVideo(result);
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishLoadingVideoDetail();
  }, [finishLoadingVideoDetail, startLoadingVideoDetail, videoId]);

  useEffect(() => {
    loadVideoDetail();
  }, [loadVideoDetail]);

  return {
    isLoadingVideoDetail,
    video,
  };
}

export default useVLVideoDetail;
