import { useCallback } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { findItemsInArray } from '@utils/array.util';
import { deleteMedia } from '@apis/media.api';
import { toast } from 'react-toastify';
import { Media } from '../types/media';

function useDeleteMedia(
  selectedFiles: {[key: string]: boolean},
  flattenMedias: Media[],
  callback: (ids: string[]) => void,
) {
  const {
    isOpen: isDeleteingMedia,
    onClose: finishDeleteingMedia,
    onOpen: startDeleteingMedia,
  } = useDisclosure();

  const handleDeleteMedia = useCallback(async () => {
    try {
      startDeleteingMedia();
      const listIds = Object.keys(selectedFiles).filter((id) => selectedFiles[id]);
      if (listIds.length === 0) return;
      const listMedias = findItemsInArray<Media>(flattenMedias, listIds);
      if (listMedias.length > 0) {
        const result = await Promise.allSettled(
          listMedias.map((mediaItem) => deleteMedia(mediaItem.id)),
        );
        const ids = listMedias.map((item, index) => {
          if (result[index].status === 'fulfilled') {
            return item.id;
          }
          return null;
        }).filter(Boolean) as string[];
        await callback(ids);
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishDeleteingMedia();
  }, [finishDeleteingMedia, startDeleteingMedia, selectedFiles, flattenMedias, callback]);

  return {
    isDeleteingMedia,
    handleDeleteMedia,
  };
}

export default useDeleteMedia;
