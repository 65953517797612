import { Box, chakra } from '@chakra-ui/react';

const MenuItem = chakra(Box, {
  baseStyle: {
    mb: 0,
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '19.5px',
    color: 'vmBlack.100',
    textAlign: 'left',
    cursor: 'pointer',
    textDecor: 'none',
    _hover: {
      color: 'vmPrimary.50',
    },
  },
});

export default MenuItem;
