import { Icon, IconProps } from '@chakra-ui/react';

function PlayIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 36 42" fill="vmBlack.50" {...props}>
      <path
        d="M34.1045 17.6088L5.81802 0.538755C3.51974 -0.847517 0 0.497741 0 3.92651V38.0583C0 41.1344 3.27063 42.9882 5.81802 41.4461L34.1045 24.3843C36.6278 22.8668 36.6358 19.1263 34.1045 17.6088Z"
      />
    </Icon>
  );
}

export default PlayIcon;
