import { Box, Flex, Spinner } from '@chakra-ui/react';
import Answer from '@modules/Question/forms/Answer';
import useQuestionAnswer from '@modules/Question/hooks/useQuestionAnswer';
import { IVmQuestion } from '@typed/question.types';
import { memo } from 'react';
import QuestionAnswerContainer from './container';

interface Props {
  question: IVmQuestion;
}

function QuestionAnswer({
  question,
}: Props) {
  const { isLoadingAnswers, answer, refetch } = useQuestionAnswer(question.questionId);

  if (isLoadingAnswers) {
    return (
      <Flex align="center" justify="center" mt="35px">
        <Spinner />
      </Flex>
    );
  }

  if (!answer) {
    return (
      <Box mt="45px">
        <Answer question={question} onRefetch={refetch} />
      </Box>
    );
  }

  return (
    <QuestionAnswerContainer onRefetch={refetch} answer={answer} />
  );
}

export default memo(QuestionAnswer);
