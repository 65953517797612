import React, { memo } from 'react';
import { Box, Image } from '@chakra-ui/react';

function LoginHeader() {
  return (
    <Box textAlign="center" mb="23px">
      <Image maxWidth="140px" src="/static-data/images/logo-green.png" alt="Logo" mx="auto" />
    </Box>
  );
}

export default memo(LoginHeader);
