import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

interface UseVLStatus {
  status: string | null;
  onStatusChange: (status: string) => void;
}

function useVLStatus(): UseVLStatus {
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get('status') ?? null;

  const handleStatusChange = useCallback((value: string) => {
    const newSerchParams = new URLSearchParams(searchParams);
    newSerchParams.set('status', value);
    setSearchParams(newSerchParams);
  }, [searchParams, setSearchParams]);

  return {
    status,
    onStatusChange: handleStatusChange,
  };
}

export default useVLStatus;
