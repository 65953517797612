import { useCallback, useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { getPublicForm } from '@apis/pubic-form';
import { IForm } from '@modules/Forms/types/form.types';

function useQuestionForm(formId: string) {
  const [form, setForm] = useState<IForm | null>(null);
  const {
    isOpen: isLoadingFormConfig,
    onOpen: startLoadingFormConfig,
    onClose: finishLoadingFormConfig,
  } = useDisclosure({ defaultIsOpen: true });

  const loadFormConfig = useCallback(async () => {
    try {
      startLoadingFormConfig();
      const result = await getPublicForm(formId);
      setForm(result.form);
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishLoadingFormConfig();
  }, [finishLoadingFormConfig, formId, startLoadingFormConfig]);

  useEffect(() => {
    loadFormConfig();
  }, [loadFormConfig]);

  return {
    isLoadingFormConfig,
    form,
  };
}

export default useQuestionForm;
