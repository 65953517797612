import { Box, Flex } from '@chakra-ui/react';

function NotFound() {
  return (
    <Flex w="full" minH="100vh" direction="column" align="center" justify="center" bg="vmGray.150">
      <Box
        fontSize="160px"
        letterSpacing="13px"
      >
        404
      </Box>
      <Box
        py="5px"
        px="25px"
        bg="vmWhite"
        mt="-90px"
        fontSize="24px"
        borderRadius="10px"
      >
        We do not find the page that you find
      </Box>
    </Flex>
  );
}

export default NotFound;
