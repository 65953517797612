import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Textarea,
} from '@chakra-ui/react';
import AlertMessage from '@components/AlertMessage/AlertMessage';
import ErrorMessage from '@components/ErrorMessage';
import VmVideoRecorder from '@components/VmVideoRecorder';
import { useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFormValue } from '../../types/form-value';

function Introductory() {
  const { t } = useTranslation();
  const { control, register, watch } = useFormContext<IFormValue>();
  const { errors } = useFormState({ control });

  const showVideoMessage = watch('intro.showVideoMessage');

  return (
    <Box>
      <FormControl pt={0} isInvalid={!!errors?.intro?.title?.message}>
        <FormLabel>{t('forms.labels.introductory_title')}</FormLabel>
        <Input type="text" placeholder={t('forms.placeholders.introductory_title')} {...register('intro.title')} />
        <ErrorMessage error={errors?.intro?.title?.message} />
      </FormControl>
      <FormControl isInvalid={!!errors?.intro?.text?.message}>
        <FormLabel>{t('forms.labels.introductory_text')}</FormLabel>
        <Textarea rows={5} placeholder={t('forms.placeholders.introductory_text')} {...register('intro.text')} />
        <ErrorMessage error={errors?.intro?.text?.message} />
      </FormControl>
      {errors?.intro?.enableVideoQuestion && (
        <Box mt="20px">
          <AlertMessage type="error" message={t(errors?.intro?.enableVideoQuestion?.message ?? '')} />
        </Box>
      )}
      <FormControl>
        <Flex align="center" justify="space-between">
          <FormLabel mb={0}>{t('forms.labels.enable_video_question')}</FormLabel>
          <Switch {...register('intro.enableVideoQuestion')} />
        </Flex>
      </FormControl>
      <FormControl>
        <Flex align="center" justify="space-between">
          <FormLabel mb={0}>{t('forms.labels.enable_text_question')}</FormLabel>
          <Switch {...register('intro.enableTextQuestion')} />
        </Flex>
      </FormControl>
      <FormControl>
        <Flex align="center" justify="space-between">
          <FormLabel mb={0}>{t('forms.labels.use_welcome_video_message')}</FormLabel>
          <Switch {...register('intro.showVideoMessage')} />
        </Flex>
      </FormControl>
      {showVideoMessage && (
        <>
          <FormControl>
            <FormLabel>{t('forms.labels.welcome_video_message_text')}</FormLabel>
            <Input type="text" {...register('intro.videoMessageText')} placeholder={t('forms.placeholders.welcome_video_message_text')} />
          </FormControl>
          <Box mt="30px">
            <VmVideoRecorder label="" name="intro.videoMessageToken" allowVideo />
          </Box>
        </>
      )}
    </Box>
  );
}

export default Introductory;
