import { IForm } from '@modules/Forms/types/form.types';
import { useEffect } from 'react';
import { redirect, Route, Routes } from 'react-router-dom';
import FormQuestion from './containers/FormQuestion';
import FormThankYou from './containers/FormThankYou';

interface Props {
  form: IForm;
}

function PublicQuestionContainer({
  form,
}: Props) {
  useEffect(() => {
    const lastPath = window.location.pathname.split('/').pop();
    if (lastPath === 'thank-you') {
      redirect('');
    }
  }, []);

  return (
    <Routes>
      <Route index element={<FormQuestion form={form} />} />
      <Route path="thank-you" element={<FormThankYou form={form} />} />
    </Routes>
  );
}

export default PublicQuestionContainer;
