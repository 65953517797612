import {
  Grid,
  Stat,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { formatNumberValue } from '@utils/number.util';
import { useVmStore } from '@root/store';
import { customerToolsSelector } from '@store/slices/customer/selectors';
import CheckIcon from '@components/icons/Check';
import NotAllowedIcon from '@components/icons/NotAllowed';

function SubscriptionTools() {
  const { t } = useTranslation();
  const customerTools = useVmStore(customerToolsSelector);
  return (
    <Grid
      justifyContent="center"
      gridTemplateColumns={{
        base: '1fr 1fr 1fr 1fr 1fr',
      }}
      whiteSpace="nowrap"
      gridGap="20px"
      p="20px"
      borderRadius="10px"
    >
      <Stat textAlign="center">
        <StatLabel>{t('plan.tools.labels.video_subtitles')}</StatLabel>
        <StatNumber>
          {customerTools?.videoSubtitles ? (
            <CheckIcon fontSize="22px" color="vmGreen.50" />
          ) : (
            <NotAllowedIcon fontSize="22px" />
          )}
        </StatNumber>
      </Stat>
      <Stat textAlign="center">
        <StatLabel>{t('plan.tools.labels.video_editor')}</StatLabel>
        <StatNumber>
          {customerTools?.videoEditor ? (
            <CheckIcon fontSize="22px" color="vmGreen.50" />
          ) : (
            <NotAllowedIcon fontSize="22px" />
          )}
        </StatNumber>
      </Stat>
      <Stat textAlign="center">
        <StatLabel>{t('plan.tools.labels.max_brands')}</StatLabel>
        <StatNumber>{formatNumberValue(customerTools?.maxBrands)}</StatNumber>
      </Stat>
    </Grid>
  );
}

export default SubscriptionTools;
