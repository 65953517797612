import { useParams } from 'react-router-dom';
import { Spinner } from '@chakra-ui/react';
import useQuestionDetail from '@modules/Question/hooks/useQuestionDetail';
import NotFound from '@components/NotFound';
import QuestionDetailContainer from './container';

function QuestionDetail() {
  const { questionId } = useParams<'questionId'>();
  const { isLoadingQuestion, question } = useQuestionDetail(questionId!);

  if (isLoadingQuestion) {
    return (
      <Spinner />
    );
  }

  if (!question) {
    return (
      <NotFound />
    );
  }

  return (
    <QuestionDetailContainer question={question} />
  );
}

export default QuestionDetail;
