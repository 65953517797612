import { Box, Button, Flex } from '@chakra-ui/react';
import BackIcon from '@components/icons/Back';
import useRecorderRef from '@components/VmVideoRecorder/hooks/useRecorderRef';
import useRecorderReset from '@components/VmVideoRecorder/hooks/useRecorderReset';
import {
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TrustRecorder from '@components/TrustRecorder';
import { useRecorderContainerContext } from '@components/VmVideoRecorder/contexts/RecorderContainerContext';
import RemoveVideoButton from '@components/RemoveVideoButton';
import RecorderYesNoButton from '@components/VmVideoRecorder/styled/RecorderYesNoButton.styled';
import VmVideoUploader from '@components/VmVideoRecorder/components/VmVideoUploader';

interface Props {
  name: string;
  onGoBack: () => void;
  title?: string;
  text?: string;
  allowText: boolean;
  allowUpload: boolean;
  showArrowBack: boolean;
  onStartVideoProgress: () => void;
  onFinishVideoProgress: () => void;
  shouldCallApi: boolean;
  children: JSX.Element;
}

function VmVideoRecorderTrigger({
  name,
  onGoBack,
  title,
  text,
  allowText,
  allowUpload,
  showArrowBack,
  onStartVideoProgress,
  onFinishVideoProgress,
  shouldCallApi,
  children,
}: Props) {
  const { container, handleChangeContainer } = useRecorderContainerContext();
  const { recorder, setRecorderRef } = useRecorderRef();
  const { control, setValue, watch } = useFormContext();
  const [mode, setMode] = useState<'upload' | 'record' | null>(null);
  const videoToken = watch(name);

  const handleSetEmptyVideoToken = useCallback(() => {
    setValue(name, null);
  }, [name, setValue]);

  const {
    key,
    oldVideoToken,
    handleReset,
  } = useRecorderReset(recorder, null, handleSetEmptyVideoToken);

  const handleMoveBack = useCallback(() => {
    handleSetEmptyVideoToken();
    onGoBack();
  }, [handleSetEmptyVideoToken, onGoBack]);

  const handleDeleteVideoSuccess = useCallback(() => {
    handleReset(videoToken);
    handleChangeContainer('record');
    setMode(null);
  }, [handleReset, handleChangeContainer, videoToken]);

  const onConfirmRecordedVideo = useCallback(() => {
    handleChangeContainer('record-video-exist');
    onFinishVideoProgress();
  }, [handleChangeContainer, onFinishVideoProgress]);

  useEffect(() => {
    if (videoToken && window.innerWidth >= 768) {
      onConfirmRecordedVideo();
    }
  }, [onConfirmRecordedVideo, videoToken]);

  return (
    <Box>
      <Flex direction="column" onClick={(event) => event.stopPropagation()} pos="relative">
        <Box pl={(allowText && !videoToken) ? '45px' : 0} pos="relative">
          {!videoToken && allowText && (
            <Box pos="absolute" left="-10px" top="0">
              <Button type="button" variant="iconOnly" bg="vmGray.1050" size="iconOnly" onClick={onGoBack}>
                <BackIcon fontSize="14px" stroke="vmPrimary.50" />
              </Button>
            </Box>
          )}
          {title && (
            <Box
              fontSize="20px"
              fontWeight={700}
              color="#333333"
              mb="0px"
            >
              {title}
            </Box>
          )}
          {text && (
            <Box
              as="p"
              color="vmBlack.100"
              mb="20px"
            >
              {text}
            </Box>
          )}
          {(mode === 'record' || mode === null) && (
            <>
              <Controller
                render={({ field }) => (
                  <TrustRecorder
                    recorderKey={key}
                    previousVideoToken={oldVideoToken}
                    onGoBack={handleMoveBack}
                    onSetRecordRef={setRecorderRef}
                    showArrowBack={showArrowBack}
                    isExist={videoToken && window.innerWidth >= 768}
                    onStartVideoProgress={onStartVideoProgress}
                    {...field}
                    onChange={(data) => {
                      field.onChange(data);
                      setMode('record');
                    }}
                  >
                    {videoToken && oldVideoToken !== '' && (
                      <Flex
                        direction="column"
                        gap="20px"
                        mb="20px"
                        pos="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        align="center"
                        justify="end"
                      >
                        <Flex
                          background="rgba(0, 0, 0, 0.5)"
                          borderRadius="4px"
                          p="4px 10px 4px 5px"
                          color="vmWhite"
                        >
                          Ready to send?
                        </Flex>
                        <Flex
                          align="center"
                          justify="center"
                          gap="20px"
                        >
                          <RecorderYesNoButton
                            onClick={() => {
                              handleReset(videoToken);
                            }}
                          >
                            No
                          </RecorderYesNoButton>
                          <RecorderYesNoButton
                            color="vmPrimary.50"
                            onClick={onConfirmRecordedVideo}
                          >
                            Yes
                          </RecorderYesNoButton>
                        </Flex>
                      </Flex>
                    )}
                  </TrustRecorder>
                )}
                control={control}
                name={name}
              />
              {videoToken && container === 'record-video-exist' && (
                <Flex mt="20px" align="center" justify="center" gap="20px">
                  <RemoveVideoButton
                    shouldCallProvider={shouldCallApi}
                    videoToken={videoToken ?? null}
                    onDeleteSuccess={handleDeleteVideoSuccess}
                  >
                    {children ?? (
                      <Button
                        type="button"
                        variant="solidPrimary"
                      >
                        Delete Video
                      </Button>
                    )}
                  </RemoveVideoButton>
                </Flex>
              )}
            </>
          )}
        </Box>
      </Flex>
      {allowUpload && mode !== 'record' && (
        <Box mt="20px">
          <VmVideoUploader
            name={name}
            onStartUpload={() => setMode('upload')}
            onDeleteUploadFile={() => setMode(null)}
          />
        </Box>
      )}
    </Box>
  );
}

VmVideoRecorderTrigger.defaultProps = {
  title: '',
  text: '',
};

export default memo(VmVideoRecorderTrigger);
