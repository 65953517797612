import { useCallback } from 'react';
import {
  Box,
  Flex,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';
import { EXTERNAL_LINKS } from '@constants/external-links';
import { useTranslation } from 'react-i18next';
import { Crisp } from 'crisp-sdk-web';
import MenuItem from '../../styled/MenuItemStyled';
import SidebarMenu from '../SidebarMenu';

function SidebarSupportMenu() {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleChatOpen = useCallback(() => {
    onClose();
    Crisp.chat.open();
  }, [onClose]);

  return (
    <Popover placement="right-end" isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
      <PopoverTrigger>
        <Box>
          <SidebarMenu title={t('sidebar.menu.title.support')} />
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent ml="10px" _focusVisible={{ outline: 'none' }}>
          <PopoverBody>
            <Box w="360px" p="20px" bg="vmGray.150" borderRadius="8px">
              <Flex direction="column" gap="10px" sx={{ '& .active > p': { color: 'vmPrimary.50' } }}>
                <Box as={Link} _hover={{ textDecoration: 'none' }} target="_blank" href={EXTERNAL_LINKS.TUTORIAL} onClick={onClose}>
                  <MenuItem as="p">{t('sidebar.menu.title.help_desk')}</MenuItem>
                </Box>
                <MenuItem as="p" onClick={handleChatOpen}>{t('sidebar.menu.title.open_chat_widget')}</MenuItem>
              </Flex>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

export default SidebarSupportMenu;
