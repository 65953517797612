function formatNumberValue(value?: number | null): string {
  if (value === null) return '∞';
  return new Intl.NumberFormat().format(value ?? 0);
}

function makeNumberWithZeroAsPrefix(num: number): string {
  if (num < 10) return `0${num}`;
  return num.toString();
}

export {
  formatNumberValue,
  makeNumberWithZeroAsPrefix,
};
