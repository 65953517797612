import { generateTimeByMilisecond } from '@utils/timer.util';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useRef } from 'react';

interface UseTrimerBar {
  handleSeekInitial: (start: number, end: number) => void;
  handleCustomLeftTime: (time: number, startTime: number, endTime: number) => void;
  handleCustomRightTime: (time: number, startTime: number, endTime: number) => void;
}

function useTrimerBar(
  trimerBarLeftElement: HTMLDivElement | null,
  trimerBarRightElement: HTMLDivElement | null,
  progressWrapper: HTMLDivElement | null,
  progressBar: HTMLDivElement | null,
  player: any,
  handleSetCursorTimer: (timer: number, position: 'left' | 'right') => void,
  defaultStartTime: number,
  defaultEndTime: number,
): UseTrimerBar {
  const currentDragElement = useRef<'left' | 'right' | null>(null);
  const currentProgressBarWidth = useRef<number | null>(null);
  const lastOffsetLeft = useRef<number | null>(null);
  const duration = player.get('duration');

  const handleStartDragTrimerBarLeft = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    currentDragElement.current = 'left';
  }, []);

  const handleStartDragTimerBarRight = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    currentDragElement.current = 'right';
  }, []);

  const handleSeek = useCallback(debounce((time: number, needToPlay: boolean) => {
    player.seek(time);
    if (needToPlay) {
      player.play();
    }
  }, 500), [player]);

  const handleSeekInitial = useCallback((start: number, end: number) => {
    if (!progressWrapper || !progressBar) return;
    const barPercentWidth = ((end - start) / duration) * 100;
    const barStartPercent = (start / duration) * 100;
    const barWidth = (barPercentWidth * progressWrapper.clientWidth) / 100;
    progressBar.style.width = `${barWidth}px`;
    progressBar.style.left = `${(barStartPercent * progressWrapper.clientWidth) / 100}px`;
  }, [progressWrapper, progressBar]);

  const handleEndDrag = useCallback(() => {
    currentDragElement.current = null;
    if (progressBar) {
      currentProgressBarWidth.current = progressBar.clientWidth;
      lastOffsetLeft.current = progressBar.offsetLeft;
    }
  }, [progressBar]);

  const handleUpdateTrimerTime = useCallback((distanceMoved: number, cursorPosition: 'left' | 'right') => {
    // if (currentDragElement.current === null) return;
    if (cursorPosition === 'left') {
      const dragElement = document.getElementById('trimer-time-start');
      if (!dragElement) return;
      const percentageMoved = (distanceMoved / progressWrapper!.clientWidth) * 100;
      const timeMoved = (percentageMoved * duration) / 100;
      const time = timeMoved >= 0 ? Number(timeMoved.toFixed(2)) : 0;
      dragElement.textContent = `${generateTimeByMilisecond(time * 1000, 2)}`;
      handleSetCursorTimer(time, 'left');
      handleSeek(time, !player.getPlaybackCount());
      return;
    }
    if (cursorPosition === 'right') {
      const dragElement = document.getElementById('trimer-time-end');
      if (!dragElement) return;
      const percentageMoved = (distanceMoved / progressWrapper!.clientWidth) * 100;
      const timeMoved = (percentageMoved * duration) / 100;
      const time = timeMoved >= 0 ? timeMoved : 0;
      dragElement.textContent = `${generateTimeByMilisecond(time * 1000, 2)}`;
      handleSetCursorTimer(time, 'right');
    }
  }, [progressWrapper, duration, handleSeek, player]);

  const handleCustomRightTime = useCallback((time: number, startTime: number, endTime: number) => {
    if (progressWrapper === null || progressBar === null) return;
    const widthPerSecond = (progressWrapper.clientWidth / duration);
    let timeWillBeMoved = endTime + time;
    if (timeWillBeMoved > duration) {
      timeWillBeMoved = duration;
    }
    if (timeWillBeMoved < (startTime + 2)) {
      timeWillBeMoved = startTime + 2;
    }
    const dragElement = document.getElementById('trimer-time-end');
    handleSetCursorTimer(timeWillBeMoved, 'right');
    progressBar.style.left = `${progressBar.offsetLeft}px`;
    progressBar.style.width = `${(timeWillBeMoved - startTime) * widthPerSecond}px`;
    if (dragElement) {
      dragElement.textContent = `${generateTimeByMilisecond((timeWillBeMoved) * 1000, 2)}`;
    }
  }, [progressWrapper, progressBar, duration, handleSetCursorTimer, handleSeek]);

  const handleCustomLeftTime = useCallback((time: number, startTime: number, endTime: number) => {
    if (progressWrapper === null || progressBar === null) return;
    let timeWillBeMoved = startTime + time;
    if (timeWillBeMoved < 0) {
      timeWillBeMoved = 0;
    }
    if (timeWillBeMoved > (endTime - 2)) {
      timeWillBeMoved = endTime - 2;
    }
    const dragElement = document.getElementById('trimer-time-start');
    const widthPerSecond = (progressWrapper.clientWidth / duration);
    handleSetCursorTimer(timeWillBeMoved, 'left');
    handleSeek(timeWillBeMoved, false);
    progressBar.style.width = `${(endTime - timeWillBeMoved) * widthPerSecond}px`;
    progressBar.style.left = `${timeWillBeMoved * widthPerSecond}px`;
    if (dragElement) {
      dragElement.textContent = `${generateTimeByMilisecond((timeWillBeMoved) * 1000, 2)}`;
    }
  }, [progressWrapper, progressBar, duration, handleSetCursorTimer, handleSeek]);

  const handleMovingTrimerBar = useCallback((event: MouseEvent) => {
    if (
      currentDragElement.current === null
      || progressWrapper === null
      || progressBar === null
      || currentProgressBarWidth.current === null
    ) return;
    const { clientX } = event;
    const progressWrapperBarOffsetLeft = progressWrapper.offsetLeft;
    const progressBarWidth = progressBar.clientWidth;
    const widthPerSecond = (progressWrapper.clientWidth / duration);
    if (currentDragElement.current === 'left') {
      const margin = clientX - progressWrapperBarOffsetLeft - (lastOffsetLeft.current ?? 0);
      if (currentProgressBarWidth.current - margin <= Math.ceil(2 * widthPerSecond)) return;
      progressBar.style.width = `${currentProgressBarWidth.current - margin}px`;
      progressBar.style.left = `${margin + (lastOffsetLeft.current ?? 0)}px`;
      handleUpdateTrimerTime(margin + (lastOffsetLeft.current ?? 0), 'left');
      return;
    }
    if (currentDragElement.current === 'right') {
      const totalOffset = progressWrapperBarOffsetLeft + progressBar.offsetLeft + progressBarWidth;
      const margin = totalOffset - clientX;
      const cWidth = (progressBar.clientWidth - margin);
      if (cWidth <= Math.ceil(2 * widthPerSecond)) return;
      progressBar.style.left = `${progressBar.offsetLeft}px`;
      progressBar.style.width = `${cWidth}px`;
      handleUpdateTrimerTime(progressBar.offsetLeft + (cWidth), 'right');
    }
  }, [progressWrapper, handleUpdateTrimerTime, progressBar]);

  useEffect(() => {
    if (trimerBarLeftElement) {
      trimerBarLeftElement.addEventListener('mousedown', handleStartDragTrimerBarLeft, false);
    }

    return () => {
      if (trimerBarLeftElement) {
        trimerBarLeftElement.removeEventListener('mousedown', handleStartDragTrimerBarLeft, false);
      }
    };
  }, [trimerBarLeftElement, handleStartDragTrimerBarLeft]);

  useEffect(() => {
    if (progressWrapper !== null && progressBar !== null) {
      const progressBarWidth = progressWrapper.clientWidth;
      const widthPerSecond = progressBarWidth / duration;
      progressBar.style.left = `${widthPerSecond * defaultStartTime}px`;
      progressBar.style.width = `${(defaultEndTime - defaultStartTime) * widthPerSecond}px`;
      handleSeek(defaultStartTime, false);
    }
  }, [defaultStartTime, progressWrapper, progressBar]);

  useEffect(() => {
    if (trimerBarRightElement) {
      trimerBarRightElement.addEventListener('mousedown', handleStartDragTimerBarRight, true);
    }

    return () => {
      if (trimerBarRightElement) {
        trimerBarRightElement.removeEventListener('mousedown', handleStartDragTimerBarRight, true);
      }
    };
  }, [trimerBarRightElement, handleStartDragTimerBarRight]);

  useEffect(() => {
    if (progressWrapper) {
      progressWrapper.addEventListener('mousemove', handleMovingTrimerBar, true);
    }

    return () => {
      if (progressWrapper) {
        progressWrapper.removeEventListener('mousemove', handleMovingTrimerBar, true);
      }
    };
  }, [progressWrapper]);

  useEffect(() => {
    document.addEventListener('mouseup', handleEndDrag, true);

    return () => {
      document.removeEventListener('mouseup', handleEndDrag, true);
    };
  }, [handleEndDrag]);

  useEffect(() => {
    if (progressWrapper) {
      currentProgressBarWidth.current = progressWrapper.clientWidth;
    }
  }, [progressWrapper]);

  return {
    handleSeekInitial,
    handleCustomLeftTime,
    handleCustomRightTime,
  };
}

export default useTrimerBar;
