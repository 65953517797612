import {
  RefObject,
  useCallback,
  useMemo,
  useState,
} from 'react';

type UseElementRef<T> = [
  // eslint-disable-next-line no-unused-vars
  (item: T | null) => void,
  T | null,
  RefObject<T | null>
];

function useElementRef<T = HTMLElement>(): UseElementRef<T> {
  const [element, setElement] = useState<T | null>(null);
  const elementRef = useMemo(
    () => ({
      current: element,
    }),
    [element],
  );

  const updateElement = useCallback((node: T | null) => {
    if (node !== null) {
      setElement(node);
    }
  }, []);

  return [updateElement, element, elementRef];
}

export default useElementRef;
