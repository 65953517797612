import { Box, Flex, Skeleton } from '@chakra-ui/react';
import { useListTypeContext } from '@root/contexts/ListType/context';
import { ListType } from '@root/contexts/ListType/types';
import { IFromListItem } from '@modules/Forms/types/form-list.types';
import MoveToFolder from '@shared/Form/modules/MoveToFolder';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { moveFormToFolder } from '@apis/form.api';
import VmFormItem from '../VmFormItem';

interface Props {
  forms: IFromListItem[];
  isLoading: boolean;
  onRemoveSuccess: (folderId: string) => void;
  onMoveSuccess: (formId: string) => void;
}

function VmFormList({
  forms,
  isLoading,
  onRemoveSuccess,
  onMoveSuccess,
}: Props) {
  const { type } = useListTypeContext();
  const [selectedForm, setSelectedForm] = useState<string | null>(null);

  const handleRemoveSelectedForm = useCallback(() => {
    setSelectedForm(null);
  }, []);

  const handleMoveForm = useCallback(async (folderId: string) => {
    if (!selectedForm) return;
    try {
      await moveFormToFolder(selectedForm, folderId === 'root' ? '' : folderId);
      onMoveSuccess(selectedForm);
    } catch (error: any) {
      toast.error(error?.message);
    }
  }, [onMoveSuccess, selectedForm]);

  if (isLoading) {
    return (
      <Box>
        <Box pt="20px" h="84px">
          <Skeleton w="full" h="64px" borderRadius="8px" />
        </Box>
        <Box pt="20px" h="84px">
          <Skeleton w="full" h="64px" borderRadius="8px" />
        </Box>
        <Box pt="20px" h="84px">
          <Skeleton w="full" h="64px" borderRadius="8px" />
        </Box>
      </Box>
    );
  }

  if (forms.length === 0) {
    return (
      <Flex align="center" justify="center" py="40px" h="calc(100vh - 215px - 80px - 128px)">
        There is no forms in this folder
      </Flex>
    );
  }

  return (
    <Box mt="20px">
      <Box
        sx={{
          display: type === ListType.GRID ? 'grid' : 'flex',
          alignItems: 'center',
          flexDirection: type === ListType.GRID ? undefined : 'column',
          gap: '20px',
          gridTemplateColumns: type === ListType.GRID ? '1fr 1fr 1fr' : undefined,
          gridAutoRows: 'min-content',
        }}
      >
        {forms.map((form) => (
          <Box key={form.formId} w="full">
            <VmFormItem
              form={form}
              onRemoveSuccess={onRemoveSuccess}
              onChooseForm={setSelectedForm}
            />
          </Box>
        ))}
      </Box>
      {selectedForm && (
        <MoveToFolder
          isOpen={selectedForm !== null}
          onClose={handleRemoveSelectedForm}
          onSelectFolder={handleMoveForm}
        />
      )}
    </Box>
  );
}

export default VmFormList;
