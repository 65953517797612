import { Box, Flex, Spinner } from '@chakra-ui/react';
import GridViewType from '@components/GridViewType';
import ContactIcon from '@components/icons/contact/Contact';
import ListAction from '@components/ListAction';
import useFilterSearch from '@hooks/useFilterSearch';
import ContactGroup from '@modules/Contact/components/ContactGroup';
import useContacts from '@modules/Contact/hooks/useContacts';
import Fuse from 'fuse.js';
import { useListTypeContext } from '@root/contexts/ListType/context';
import { IVmContact, IVmContactGroup } from '@typed/contact.types';
import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CellMeasurer as _CellMeasurer,
  CellMeasurerProps,
  CellMeasurerCache,
  List as _List, ListProps,
  ListRowProps,
} from 'react-virtualized';

const List = _List as unknown as FC<ListProps>;
const CellMeasurer = _CellMeasurer as unknown as FC<CellMeasurerProps>;

function mapContactToGroup(contacts: IVmContact[]) {
  const groupMapper = contacts.reduce<{[key: string]: IVmContactGroup}>((groupMap, contact) => {
    const contactFirstCharName = `${contact.firstname} ${contact.lastname}`.trim().charAt(0).toUpperCase();
    if (!groupMap[contactFirstCharName]) {
      groupMap[contactFirstCharName] = {
        groupName: contactFirstCharName,
        items: [],
      };
    }
    groupMap[contactFirstCharName].items.push(contact);
    return groupMap;
  }, {});

  return Object.values(groupMapper);
}

const cache = new CellMeasurerCache({
  fixedHeight: false,
  minHeight: 24,
});

function ContactListContainer() {
  const {
    type,
    onTypeChange,
  } = useListTypeContext();
  const { keyword, onKeywordChange } = useFilterSearch();
  const { isLoadingContacts, contacts } = useContacts();
  const [groupedContacts, setGroupedContact] = useState<IVmContactGroup[]>([]);
  const [hiddingContacts, setHiddingContacts] = useState<{[key: string]: boolean}>({});
  const [contactLength, setContactLength] = useState<number>(0);

  const fuse = useMemo(() => new Fuse(contacts, { keys: ['firstname', 'lastname'], threshold: 0.1 }), [contacts]);

  const onRemoveContact = useCallback((contactId: string) => {
    setHiddingContacts((currentHiddingContacts) => ({
      ...currentHiddingContacts,
      [contactId]: true,
    }));
  }, []);

  useEffect(() => {
    if (!keyword) {
      setGroupedContact(mapContactToGroup(contacts));
      setContactLength(contacts.length);
    } else {
      const filteredContacts = fuse.search(keyword).map((item) => item.item);
      setGroupedContact(mapContactToGroup(filteredContacts));
      setContactLength(filteredContacts.length);
    }
  }, [contacts, fuse, keyword]);

  const renderRow = useCallback(({
    key,
    index,
    parent,
    style,
  }: ListRowProps) => (
    <CellMeasurer
      cache={cache}
      columnIndex={0}
      key={key}
      rowIndex={index}
      parent={parent}
    >
      {({ measure, registerChild }) => (
        <Box ref={registerChild as any} style={style} pt="20px">
          <ContactGroup
            onMeasure={measure}
            title={groupedContacts[index].groupName}
            contacts={groupedContacts[index].items}
            onRemoveContact={onRemoveContact}
            hiddingContacts={hiddingContacts}
          />
        </Box>
      )}
    </CellMeasurer>
  ), [groupedContacts, hiddingContacts, onRemoveContact]);

  if (isLoadingContacts) {
    return (
      <Flex align="center" justify="center" w="920px" mx="auto" maxW="full" minH="100vh">
        <Spinner />
      </Flex>
    );
  }

  return (
    <Box w="920px" mx="auto" maxW="full" pt="108px" pb="80px">
      <Flex py="8px" px="20px" align="center" justify="space-between" bg="vmGray.150" borderRadius="10px">
        <Flex align="cennter" gap="20px">
          <Box color="vmBlack.50" fontWeight={600} fontSize="20px" lineHeight="26px">Contacts</Box>
          <Flex align="center" gap="8px">
            <ContactIcon fontSize="18px" />
            <Box as="span" fontWeight={400} fontSize="16px" lineHeight="24px">{contactLength - Object.keys(hiddingContacts).length}</Box>
          </Flex>
        </Flex>
        <Flex align="center" gap="20px">
          <ListAction
            isLoading={false}
            havingSearch
            onKeywordChange={onKeywordChange}
          />
          <GridViewType activeType={type} onChangeType={onTypeChange} />
          {/* <Button type="button" bg="vmGray.300" borderColor="vmGray.300">
            <Flex align="center" gap="10px"><AddIcon fontSize="14px" /></Flex>
            <Box as="span" fontSize="16px" fontWeight={600} lineHeight="24px">Add contact</Box>
          </Button> */}
        </Flex>
      </Flex>
      <Box mt="14px" sx={{ '& > div': { height: 'calc(100vh - 215px - 80px) !important' } }}>
        <List
          width={920}
          overscanRowCount={0}
          height={800}
          rowCount={groupedContacts.length}
          rowRenderer={renderRow}
          rowHeight={cache.rowHeight}
          deferredMeasurementCache={cache}
        />
      </Box>
    </Box>
  );
}

export default ContactListContainer;
