import { memo, SyntheticEvent, useCallback } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import ArrowDownIcon from '@components/icons/ArrowDown';
import CloseIcon from '@components/icons/Close';

interface Props {
  isOpen: boolean;
  onToggle: () => void;
  selected: JSX.Element | null;
  placeholder?: string;
  removeable: boolean;
  handleRemoveItem?: () => void;
  isDisabled: boolean;
}

function getColor(isOpen: boolean, selected: JSX.Element | null) {
  if (isOpen) return '#B5C1CE';
  if (selected === null) {
    return '#B5C1CE';
  }
  return 'vmBlack.50';
}

function SelectHeader({
  isOpen,
  onToggle,
  selected,
  placeholder,
  removeable,
  handleRemoveItem,
  isDisabled,
}: Props) {
  const handleRemoveSource = useCallback((event: SyntheticEvent<HTMLDivElement>) => {
    event.persist();
    event.stopPropagation();
    if (handleRemoveItem) {
      handleRemoveItem();
    }
  }, [handleRemoveItem]);

  const handleToggleDropdown = useCallback(() => {
    if (!isDisabled) onToggle();
  }, [onToggle, isDisabled]);

  return (
    <Flex
      px="15"
      py="10px"
      justify="space-between"
      align="center"
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      onClick={handleToggleDropdown}
      gap="10px"
      overflow="hidden"
    >
      <Box
        color={getColor(isOpen, selected)}
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        overflow="hidden"
        className="select-header"
      >
        {selected === null ? placeholder : selected}
      </Box>
      <Box transform={isOpen ? 'rotate(-180deg)' : 'none'} transition=".3s ease-in-out">
        <ArrowDownIcon stroke="vmPrimary.50" fontSize="12px" />
      </Box>
      {removeable && !isDisabled && (
        <Box position="absolute" right="35px" zIndex="9999" onClick={handleRemoveSource}>
          <CloseIcon stroke="vmPrimary.50" fontSize="12px" />
        </Box>
      )}
    </Flex>
  );
}

SelectHeader.defaultProps = {
  placeholder: '',
  handleRemoveItem: () => {},
};

export default memo(SelectHeader);
