import { IFolderFormValue } from '@modules/Forms/types/folder.types';
import { IFolder } from '@typed/folder.types';
import { ApiResponse } from '@typed/http.types';
import axios, { AxiosResponse } from 'axios';

async function getAllFolders(brandId: string) {
  return axios.get(`/folder/${brandId}/all`)
    .then((response: AxiosResponse<ApiResponse<IFolder[]>>) => response.data.result);
}

async function createFolder(data: IFolderFormValue) {
  return axios.post('/folder', data);
}

async function deleteFolder(folderId: string) {
  return axios.delete(`/folder/${folderId}`);
}

async function updateFolderData(folderId: string, name: string) {
  return axios.put(`/folder/${folderId}/?name=${name}`);
}

export {
  getAllFolders,
  createFolder,
  deleteFolder,
  updateFolderData,
};
