import { Route, Routes } from 'react-router-dom';
import ContactList from './containers/ContactList';
import ContactUpdate from './containers/ContactUpdate';

function Contact() {
  return (
    <Routes>
      <Route index element={<ContactList />} />
      <Route path="view/:contactId" element={<ContactUpdate />} />
    </Routes>
  );
}

export default Contact;
