import {
  Box,
  Button,
  Flex,
  Tooltip,
  useConst,
} from '@chakra-ui/react';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useVideoEditorControl from '@modules/VideoEditor/hooks/useVideoEditorControl';
import useVideoEditorPlayProgress from '@modules/VideoEditor/hooks/useVideoEditorPlayProgress';
import useElementRef from '@hooks/useElementRef';
import usePreviewProgressBarTimer from '@modules/VideoEditor/hooks/usePreviewProgressBarTrimer';
import { generateTimeByMilisecond } from '@utils/timer.util';
import { useFormContext } from 'react-hook-form';
import TrimIcon from '@components/icons/ve/Trim';
import QuestionIcon from '@components/icons/ve/Question';
import Back5SecIcon from '@components/icons/ve/Back5Sec';
import Next5SecIcon from '@components/icons/ve/Next5Sec';
import { PauseIcon, PlayIcon } from '@components/icons/ve';

interface Props {
  player: any;
  isDisableTrimMode: boolean;
  updateKey: string;
  onSwitchToTrimMode: () => void;
}

function VideoEditorSceneControl({
  player,
  updateKey,
  isDisableTrimMode,
  onSwitchToTrimMode,
}: Props) {
  const { t } = useTranslation();
  const { watch, getValues } = useFormContext();
  const hasTrimmed = useConst(() => Boolean(getValues(`${updateKey}.trimmingEnd`) || getValues(`${updateKey}.trimmingStart`)));
  const endTime = watch(`${updateKey}.trimmingEnd`) ?? player.get('duration');
  const startTime = watch(`${updateKey}.trimmingStart`) ?? 0;
  const initPosition = useConst(() => player.getCurrentPosition() ?? 0);
  const [progressElementRef, progressElement] = useElementRef<HTMLDivElement>();
  const [progressWrapperRef, progressWrapper] = useElementRef<HTMLDivElement>();
  const [playedCountRef, playedCountElement] = useElementRef<HTMLDivElement>();
  const [timeLeftRef, timeLeftElement] = useElementRef<HTMLDivElement>();
  const {
    moveNext5Sec,
    movePrev5Sec,
    handleTogglePlay,
    isPlaying,
  } = useVideoEditorControl(player, startTime, endTime);
  const {
    handleUpdatePosition,
  } = useVideoEditorPlayProgress(
    progressElement,
    progressWrapper,
    playedCountElement,
    timeLeftElement,
    player,
    initPosition,
    isPlaying,
    startTime,
    endTime,
    true,
  );
  const {
    handleChangeTime,
  } = usePreviewProgressBarTimer(progressWrapper, handleUpdatePosition, player, startTime, endTime);

  useEffect(() => {
    if (player) {
      handleUpdatePosition(0);
    }
  }, [handleUpdatePosition, player, endTime]);

  return (
    <Box p="20px" bg="vmWhite" borderRadius="10px">
      <Flex align="center" justify="center" gap="82px">
        {!isDisableTrimMode && (
          <Box pos="relative">
            <Button
              type="button"
              bg="vmGray.250"
              borderColor="vmGray.250"
              color="vmBlack.100"
              leftIcon={<TrimIcon fontSize="16px" />}
              _hover={{
                bg: 'vmPrimary.100',
                borderColor: 'vmPrimary.100',
                color: 'vmWhite',
                '& svg': {
                  stroke: 'vmWhite',
                },
              }}
              onClick={onSwitchToTrimMode}
            >
              {t('video_editor.labels.trim_video')}
            </Button>
            <Box pos="absolute" right="-28px" top="-12px">
              <Tooltip hasArrow placement="top" label={t('video_editor.messages.trim_video')}>
                <QuestionIcon fontSize="20px" />
              </Tooltip>
            </Box>
            {hasTrimmed && (
              <Box pos="absolute" right="8px" w="6px" h="6px" bg="vmGreen.50" zIndex={6} borderRadius="50%" top="8px" />
            )}
          </Box>
        )}
        <Flex align="center" gap="51px">
          <Box
            onClick={movePrev5Sec}
            cursor="pointer"
          >
            <Back5SecIcon fontSize="30px" />
          </Box>
          <Box onClick={handleTogglePlay} cursor="pointer">
            {isPlaying ? (
              <PauseIcon fontSize="36px" />
            ) : (
              <PlayIcon fontSize="36px" />
            )}
          </Box>
          <Box
            onClick={moveNext5Sec}
            cursor="pointer"
          >
            <Next5SecIcon fontSize="30px" />
          </Box>
        </Flex>
        {!isDisableTrimMode && (
          <Box w="205px" />
        )}
      </Flex>
      <Box mt="5px">
        <Flex align="center" justify="space-between">
          <Box fontSize="16px" lineHeight="24px" color="#000" fontWeight={400} ref={playedCountRef}>0:00</Box>
          <Box fontSize="16px" lineHeight="24px" color="#000" fontWeight={400} ref={timeLeftRef}>
            -
            {generateTimeByMilisecond(endTime)}
          </Box>
        </Flex>
        <Box mt="12px">
          <Box h="12px" w="full" background="transparent" pos="relative" ref={progressWrapperRef} onClick={handleChangeTime}>
            <Box h="8px" w="full" bg="vmGray.350" borderRadius="2px" cursor="pointer" pos="relative">
              <Box id="progress-preview" display="none" pos="absolute" top="-20px" bg="vmBlack.50" zIndex={90} color="vmWhite" px="10px" py="3px" borderRadius="6px" fontSize="14px" transform="translate(-50%, -50%)">15:25</Box>
            </Box>
            <Box h="8px" w={0} bg="vmGray.950" pos="relative" ref={progressElementRef} top="-8px" borderTopLeftRadius="2px" borderBottomLeftRadius="2px">
              <Box pos="absolute" top="-8px" right="-3px" w="6px" h="24px" borderRadius="2px" bg="vmBlack.50" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default memo(VideoEditorSceneControl);
