import { deleteContact } from '@apis/contact.api';
import {
  Avatar,
  Box,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import ContextItem from '@components/ContextItem';
import EditIcon from '@components/icons/Edit';
import ShareIcon from '@components/icons/Share';
import ThreeDotsIcon from '@components/icons/ThreeDots';
import TrashIcon from '@components/icons/Trash';
import { useListTypeContext } from '@root/contexts/ListType/context';
import { ListType } from '@root/contexts/ListType/types';
import { IVmContact } from '@typed/contact.types';
import { emptyFunction } from '@utils/func.util';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

interface Props {
  contact: IVmContact;
  onRemoveContact: (contactId: string) => void;
  isHidden: boolean;
}

function ContactItem({
  contact,
  onRemoveContact,
  isHidden,
}: Props) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { type } = useListTypeContext();
  const isGridType = type === ListType.GRID;
  const name = `${contact.firstname} ${contact.lastname}`.trim();

  const {
    isOpen: isDeletingContact,
    onOpen: startDeletingContact,
    onClose: finishDeletingContact,
  } = useDisclosure();

  const handleDeleteContact = useCallback(async () => {
    try {
      onClose();
      startDeletingContact();
      await deleteContact(contact.contactId);
      toast.success('A contact has been removed successfully');
      onRemoveContact(contact.contactId);
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishDeletingContact();
  }, [contact.contactId, finishDeletingContact, onClose, onRemoveContact, startDeletingContact]);

  if (isHidden) return null;

  return (
    <Flex
      align="center"
      justify={isGridType ? 'center' : 'space-between'}
      bg="vmGray.150"
      py={isGridType ? '20px' : '8px'}
      px={isGridType ? '20px' : '15px'}
      borderRadius="10px"
      pos="relative"
      w="full"
    >
      <Flex align="center" gap={isGridType ? '4px' : '56px'} direction={isGridType ? 'column' : 'row'}>
        <Flex gap="15px" direction={isGridType ? 'column' : 'row'} align="center">
          <Avatar bg="vmGray.100" color="vmBlack.100" name={name} w="48px" h="48px" />
          <Box as="h5" fontSize="16px" lineHeight="24px" fontWeight={600}>
            {name || 'Anonymous'}
          </Box>
        </Flex>
        <Box fontSize="16px" fontWeight={400} lineHeight="24px" color="vmBlack.50">{contact.email}</Box>
      </Flex>
      <Flex
        align="center"
        gap="35px"
        pos={isGridType ? 'absolute' : 'relative'}
        top={isGridType ? '20px' : undefined}
        width={isGridType ? 'calc(100% - 40px)' : undefined}
        mx={isGridType ? 'auto' : undefined}
        justifyContent={isGridType ? 'space-between' : undefined}
      >
        <Link to={`/questions-c/${contact.contactId}`}>
          <Flex align="center" gap="9px" cursor="pointer">
            <ShareIcon fontSize="16px" />
            <Box as="span" fontSize="16px" fontWeight={400} lineHeight="24px" _hover={{ color: 'vmPrimary.50' }}>
              {contact.amountQuestions}
              {' '}
              <Box as="span" display={isGridType ? 'none' : 'inline-block'}>
                question
                {contact.amountQuestions > 1 ? 's' : ''}
              </Box>
            </Box>
          </Flex>
        </Link>
        <Popover placement="right-start" isOpen={isOpen} onClose={onClose} isLazy>
          <PopoverTrigger>
            {isDeletingContact ? (
              <Spinner w="20px" h="20px" />
            ) : (
              <Box cursor="pointer" onClick={onOpen}>
                <ThreeDotsIcon fontSize="20px" />
              </Box>
            )}
          </PopoverTrigger>
          <Portal>
            <PopoverContent ml="28px" mt="-20px" _focusVisible={{ outline: 'none' }} borderRadius="10px">
              <PopoverBody>
                <Box w="320px" bg="vmWhite" borderRadius="10px" boxShadow="0px 2px 12px rgba(36, 133, 204, 0.15)" overflow="hidden">
                  <Link to={`/contacts/view/${contact.contactId}`}>
                    <ContextItem borderBottomWidth="2px !important">
                      <EditIcon fill="vmGray.50" fontSize="16px" />
                      <Box color="vmGray.400" fontSize="vmNormal" fontWeight={600} lineHeight="24px">Edit contact details</Box>
                    </ContextItem>
                  </Link>
                  {/* <ContextItem>
                    <EmailIcon fontSize="16px" />
                    <Box
                      color="vmGray.400"
                      fontSize="vmNormal"
                      fontWeight={600}
                      lineHeight="24px"
                    >
                      Send email
                    </Box>
                  </ContextItem> */}
                  <ContextItem onClick={isDeletingContact ? emptyFunction : handleDeleteContact}>
                    <TrashIcon fontSize="16px" />
                    <Box color="vmGray.400" fontSize="vmNormal" fontWeight={600} lineHeight="24px">Delete contact</Box>
                  </ContextItem>
                </Box>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </Flex>
    </Flex>
  );
}

export default ContactItem;
