import { useEffect } from 'react';
import { Crisp } from 'crisp-sdk-web';
import { useVmStore } from '@store/index';
import { customerEmailSelector, customerNameSelector } from '@store/slices/customer/selectors';

function useInitCrispChat() {
  const customerEmail = useVmStore(customerEmailSelector);
  const customerName = useVmStore(customerNameSelector);

  useEffect(() => {
    if (!Crisp.isCrispInjected()) {
      Crisp.configure('df6fc578-1cfc-4dd1-ba26-fc6bfd8b5697');
    }
  }, []);

  useEffect(() => {
    if (Crisp.isCrispInjected()) {
      Crisp.user.setEmail(customerEmail ?? '');
      Crisp.user.setNickname(customerName ?? '');
    }
  }, [customerEmail, customerName]);
}

export default useInitCrispChat;
