import * as yup from 'yup';

const videoEditorSettingsSchema = yup.object({
  transistion: yup.boolean().required('common.validation.required'),
  type: yup.string().when('transistion', ([isTransition], schema) => {
    if (isTransition) return yup.string().trim().required('common.validation.required');
    return schema;
  }),
  speed: yup.string().when('transistion', ([isTransition], schema) => {
    if (isTransition) return yup.string().trim().required('common.validation.required');
    return schema;
  }),
  backgroundColor: yup.string().when('transistion', ([isTransition], schema) => {
    if (isTransition) return yup.string().trim().required('common.validation.required');
    return schema;
  }),
});

export default videoEditorSettingsSchema;
