import { useCallback, useRef } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import ArrowLeftIcon from '@components/icons/ArrowLeft';
import EditIcon from '@components/icons/Edit';
import ShareIcon from '@components/icons/forms/Share';
import { Link } from 'react-router-dom';
import useHistoryRoute from '@hooks/useHistoryRoute';
import { useFormContext } from 'react-hook-form';
import { IFormValue } from '../../types/form-value';

interface Props {
  isLoading: boolean;
  isUpdateMode: boolean;
}

function VMFormControlHeader({
  isLoading,
  isUpdateMode,
}: Props) {
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext<IFormValue>();
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = useRef<HTMLInputElement>(null);
  const formName = getValues('name');
  const { backRoute } = useHistoryRoute('/forms');

  const handleSaveFormName = useCallback(() => {
    setValue('name', inputRef.current?.value ?? '');
    onToggle();
  }, [onToggle, setValue]);

  return (
    <Flex align="center" justify="space-between" borderBottomWidth="1px" borderColor="vmGray.1000" px="20px">
      <Link to={backRoute}>
        <Flex role="link" align="center" gap="10px" cursor="pointer">
          <ArrowLeftIcon fontSize="12px" />
          <Box color="vmGray.950" fontSize="16px" lineHeight="24px" fontWeight={500}>{t('forms.labels.forms')}</Box>
        </Flex>
      </Link>
      <Flex align="center" gap="20px">
        <Flex align="center" gap="10px">
          <Box bg="vmGray.100" px="10px" py="10px" borderRadius="full" />
          {isOpen ? (
            <Input type="text" ref={inputRef} placeholder="Type in the form name" defaultValue={formName} />
          ) : (
            <Box fontSize="20px" lineHeight="26px" fontWeight={600} color="vmBlack.50">{formName}</Box>
          )}
        </Flex>
        {isOpen ? (
          <Button type="button" onClick={handleSaveFormName} textTransform="capitalize">Save</Button>
        ) : (
          <Box role="button" lineHeight={1} mb="2px" onClick={onToggle}>
            <EditIcon fontSize="12px" fill="vmGray.600" />
          </Box>
        )}
      </Flex>
      <Flex align="center" gap="12px">
        {isUpdateMode && (
          <Box px="11px" py="10px" bg="vmWhite" borderRadius="full" cursor="pointer">
            <ShareIcon fontSize="18px" />
          </Box>
        )}
        <Button type="submit" bg="vmRed.100" borderColor="vmRed.100" isDisabled={isLoading} isLoading={isLoading}>
          {t('common.button.texts.save_changes')}
        </Button>
      </Flex>
    </Flex>
  );
}

export default VMFormControlHeader;
