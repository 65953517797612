import { Icon, IconProps } from '@chakra-ui/react';

function MoveRightForward(props: IconProps) {
  return (
    <Icon viewBox="0 0 19 18" fill="none" stroke="vmWhite" strokeWidth={2} {...props}>
      <path
        d="M12 1L16 1C17.1046 1 18 1.89543 18 3V15C18 16.1046 17.1046 17 16 17H12M13 9L1 9M13 9L9 13M13 9L9 5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default MoveRightForward;
