import {
  Box,
  Button,
  chakra,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';
import ArrowDownIcon from '@components/icons/ArrowDown';
import FilterIcon from '@components/icons/Filter';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const DropdownItem = chakra(Box, {
  baseStyle: {
    fontSize: 'vmNormal',
    lineHeight: '24px',
    py: '7px',
    px: '20px',
    cursor: 'pointer',
    color: 'vmBlack.50',
    borderTopWidth: '2px',
    borderTopColor: 'vmGray.150',
    _hover: {
      color: 'vmPrimary.50',
    },
    _first: {
      borderTopWidth: 0,
    },
  },
});

const sortData = [
  { key: 'created-desc', title: 'Newest first' },
  { key: 'created-asc', title: 'Oldest first' },
];

interface Props {
  onSortChange: (s: string) => void;
}

function SortDropdown({
  onSortChange,
}: Props) {
  const [searchParams] = useSearchParams();
  const currentSortValue = searchParams.get('sort');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const selectedItem = useMemo(() => {
    if (!currentSortValue) {
      return sortData[0];
    }
    return sortData.find((item) => item.key === currentSortValue) ?? sortData[0];
  }, [currentSortValue]);

  const handleSelectItem = useCallback((value: string) => {
    onClose();
    onSortChange(value);
  }, [onClose, onSortChange]);

  return (
    <Popover isLazy isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
      <PopoverTrigger>
        <Button p={0} bg="transparent" borderColor="transparent" textTransform="unset" _hover={{ bg: 'transparent' }}>
          <Flex align="center" justify="space-between" gap="15px">
            <FilterIcon fontSize="18px" />
            <Box fontSize="16px" lineHeight="24px" fontWeight={400} color="vmBlack.50">{selectedItem.title}</Box>
            <ArrowDownIcon fontSize="10px" stroke="vmGray.300" />
          </Flex>
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody minW="250px">
            <Flex direction="column">
              {sortData.map((item) => (
                <DropdownItem
                  key={item.key}
                  color={item.key === selectedItem.key ? 'vmPrimary.50' : 'vmBlack.50'}
                  onClick={() => handleSelectItem(item.key)}
                >
                  {item.title}
                </DropdownItem>
              ))}
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

export default SortDropdown;
