import {
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box } from '@chakra-ui/react';
import throttle from 'lodash.throttle';
import { generateTimeByMilisecond } from '@utils/timer.util';

interface Props {
  recorder: any;
  onStopRecorder: (isStop: boolean) => void;
}

function TrustRecorderVideoTime({
  recorder,
  onStopRecorder,
}: Props) {
  const [recorderTime, setRecorderTime] = useState('00:00');
  const throttled = useMemo(() => throttle((timer: number) => {
    if (timer > 5000) {
      onStopRecorder(true);
    } else {
      onStopRecorder(false);
    }
    setRecorderTime(generateTimeByMilisecond(timer));
  }, 100), [onStopRecorder]);

  useEffect(() => {
    if (recorder) {
      recorder.on('recording_progress', (time: number) => {
        throttled(time);
      });
    }

    return () => {
      throttled.cancel();
    };
  }, [recorder, throttled]);

  return (
    <Box
      fontSize="trNormal"
      lineHeight="24px"
      fontWeight={400}
      color="vmBlack.50"
    >
      {recorderTime}
    </Box>
  );
}

export default memo(TrustRecorderVideoTime);
