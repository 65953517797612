import { Icon, IconProps } from '@chakra-ui/react';

function WarningIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" stroke="none" fill="vmRed.100" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.99933C0 4.48033 4.48 -0.000671387 10 -0.000671387C15.53 -0.000671387 20 4.48033 20 9.99933C20 15.5203 15.53 19.9993 10 19.9993C4.48 19.9993 0 15.5203 0 9.99933ZM9.12 6.20933C9.12 5.73033 9.52 5.32933 10 5.32933C10.48 5.32933 10.87 5.73033 10.87 6.20933V10.6293C10.87 11.1103 10.48 11.4993 10 11.4993C9.52 11.4993 9.12 11.1103 9.12 10.6293V6.20933ZM10.01 14.6803C9.52 14.6803 9.13 14.2803 9.13 13.8003C9.13 13.3203 9.52 12.9303 10 12.9303C10.49 12.9303 10.88 13.3203 10.88 13.8003C10.88 14.2803 10.49 14.6803 10.01 14.6803Z"
      />
    </Icon>
  );
}

export default WarningIcon;
