import { AppState } from '@store/types';

function customerNameSelector(state: AppState) {
  if (!state.customer) return null;
  return `${state.customer.firstName} ${state.customer.lastName}`;
}

function customerEmailSelector(state: AppState) {
  if (!state.customer) return null;
  return state.customer.email;
}

function isAdminCustomer(state: AppState) {
  if (!state.customer) return null;
  return state.customer.admin;
}

function customerRegisteredSelector(state: AppState) {
  if (!state.customer) return null;
  return state.customer.registered;
}

function customerIdSelector(state: AppState) {
  if (!state.customer) return '';
  return state.customer.customerId;
}

function customerSubscriptionSelector(state: AppState) {
  if (!state.customer) return null;
  return state.customer.subscription;
}

function customerToolsSelector(state: AppState) {
  if (!state.customer) return null;
  return state.customer.tools;
}

export {
  customerNameSelector,
  customerEmailSelector,
  isAdminCustomer,
  customerRegisteredSelector,
  customerIdSelector,
  customerSubscriptionSelector,
  customerToolsSelector,
};
