import { Icon, IconProps } from '@chakra-ui/react';

function ExclaimationIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 22 22" fill="none" stroke="vmRed.50" {...props}>
      <path
        d="M11 4.75V13.5M11 17.2375V17.25M6 21H16C18.7614 21 21 18.7614 21 16V6C21 3.23858 18.7614 1 16 1H6C3.23858 1 1 3.23858 1 6V16C1 18.7614 3.23858 21 6 21Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default ExclaimationIcon;
