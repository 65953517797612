const LANGUAGES = [
  { title: 'Afrikaans', value: 'af-za' },
  { title: 'Albanian', value: 'sq-al' },
  { title: 'Amharic', value: 'am-et' },
  { title: 'Arabic', value: 'ar' },
  { title: 'Armenian', value: 'hy-am' },
  { title: 'Azerbaijani', value: 'az-az' },
  { title: 'Bahasa Indonesia', value: 'id-id' },
  { title: 'Basque', value: 'eu-es' },
  { title: 'Bengali (Bangladesh)', value: 'bn-bd' },
  { title: 'Bengali (India)', value: 'bn-in' },
  { title: 'Bosnian', value: 'bs-ba' },
  { title: 'Bulgarian', value: 'bg' },
  { title: 'Burmese', value: 'my-mm' },
  { title: 'Catalan', value: 'ca' },
  { title: 'Chinese (Mandarin)', value: 'cmn' },
  { title: 'Croatian', value: 'hr' },
  { title: 'Czech', value: 'cs' },
  { title: 'Danish', value: 'da' },
  { title: 'Dutch', value: 'nl' },
  { title: 'English (Australia)', value: 'en-au' },
  { title: 'English (United Kingdom)', value: 'en-uk' },
  { title: 'English (all accents)', value: 'en' },
  { title: 'Estonian', value: 'et-ee' },
  { title: 'Farsi (Iran)', value: 'fa-ir' },
  { title: 'Filipino', value: 'fil-ph' },
  { title: 'Finnish', value: 'fi' },
  { title: 'Flemish', value: 'nl-be' },
  { title: 'French (Canada)', value: 'fr-ca' },
  { title: 'French', value: 'fr' },
  { title: 'Galician', value: 'gl-es' },
  { title: 'Georgian', value: 'ka-ge' },
  { title: 'German (Austria)', value: 'de-at' },
  { title: 'German (Switzerland)', value: 'de-ch' },
  { title: 'German', value: 'de' },
  { title: 'Greek', value: 'el' },
  { title: 'Gujarati', value: 'gu-in' },
  { title: 'Hebrew', value: 'iw-il' },
  { title: 'Hindi', value: 'hi' },
  { title: 'Hungarian', value: 'hu' },
  { title: 'Icelandic', value: 'is-is' },
  { title: 'Italian', value: 'it' },
  { title: 'Japanese', value: 'ja' },
  { title: 'Javanese', value: 'jv-id' },
  { title: 'Kannada', value: 'kn-in' },
  { title: 'Khmer', value: 'km-kh' },
  { title: 'Korean', value: 'ko' },
  { title: 'Lao', value: 'lo-la' },
  { title: 'Latvian', value: 'lv' },
  { title: 'Lithuanian', value: 'lt' },
  { title: 'Macedonian', value: 'mk-mk' },
  { title: 'Malay', value: 'ms' },
  { title: 'Malayalam', value: 'ml-in' },
  { title: 'Marathi', value: 'mr-in' },
  { title: 'Mongolian', value: 'mn-mn' },
  { title: 'Nepali (Nepal)', value: 'ne-np' },
  { title: 'Norwegian', value: 'no' },
  { title: 'Polish', value: 'pl' },
  { title: 'Portuguese (Brazil)', value: 'pt-br' },
  { title: 'Portuguese', value: 'pt' },
  { title: 'Punjabi', value: 'pa-guru-in' },
  { title: 'Romanian', value: 'ro' },
  { title: 'Russian', value: 'ru' },
  { title: 'Serbian', value: 'sr-rs' },
  { title: 'Sinhala', value: 'si-lk' },
  { title: 'Slovakian', value: 'sk' },
  { title: 'Slovenian', value: 'sl' },
  { title: 'Spanish', value: 'es' },
  { title: 'Sundanese', value: 'su-id' },
  { title: 'Swahili (Kenya)', value: 'sw-ke' },
  { title: 'Swahili (Tanzania)', value: 'sw-tz' },
  { title: 'Swedish', value: 'sv' },
  { title: 'Tamil (India)', value: 'ta-in' },
  { title: 'Tamil (Malaysia)', value: 'ta-my' },
  { title: 'Tamil (Singapore)', value: 'ta-sg' },
  { title: 'Tamil (Sri Lanka)', value: 'ta-lk' },
  { title: 'Telugu', value: 'te-in' },
  { title: 'Thai', value: 'th-th' },
  { title: 'Turkish', value: 'tr' },
  { title: 'Ukrainian', value: 'uk-ua' },
  { title: 'Urdu (India)', value: 'ur-in' },
  { title: 'Urdu (Pakistan)', value: 'ur-pk' },
  { title: 'Uzbek', value: 'uz-uz' },
  { title: 'Vietnamese', value: 'vi-vn' },
  { title: 'Zulu', value: 'zu-za' },
];

export default LANGUAGES;
