const AUTH_CONFIG = {
  domain: 'videomentor.eu.auth0.com',
  clientID: 'MpgvFeLLO0sELy7d7ApShoJJqeenxgMB',
  responseType: 'token id_token',
  scope: 'openid profile write:dealmaker',
  defaultLoggedInPath: '/',
  defaultLoggedOutPath: '/login',
  authorizationParams: {
    redirectUri: `${window.location.origin}/callback`,
    audience: 'https://videomentor.eu.auth0.com/api/v2/',
  },
};

export default AUTH_CONFIG;
