import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import CloseIcon from '@components/icons/Close';
import FolderIcon from '@components/icons/Folder';
import useFolders from '@shared/List/hooks/useFolders';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSelectFolder: (folderId: string) => Promise<void>;
}

function MoveToFolder({
  isOpen,
  onClose,
  onSelectFolder,
}: Props) {
  const { t } = useTranslation();
  const [selectedFolder, setSelectedFolder] = useState<string | null | 'root'>(null);
  const { isLoadingFolders, folders } = useFolders();
  const {
    isOpen: isMoving,
    onOpen: startMoving,
    onClose: finishMoving,
  } = useDisclosure();

  const handleClose = useCallback(() => {
    setSelectedFolder(null);
    onClose();
  }, [onClose]);

  const handleMoveFolder = useCallback(async () => {
    if (!selectedFolder) return;
    try {
      startMoving();
      await onSelectFolder(selectedFolder);
      handleClose();
      toast.success('A folder has been moved successfully');
    } catch (error: any) {
      toast.error(error?.message);
    }
    finishMoving();
  }, [finishMoving, handleClose, onSelectFolder, selectedFolder, startMoving]);

  return (
    <Modal
      closeOnOverlayClick
      isCentered
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent w="580px" maxW="580px">
        <ModalBody p="40px" pos="relative">
          <Box pos="absolute" right="16px" top="10px" cursor="pointer" onClick={handleClose}>
            <CloseIcon />
          </Box>
          <Box
            as="h2"
            fontWeight="600"
            fontSize="24px"
            lineHeight="31px"
            color="trOrange.50"
            textAlign="center"
            mb="20px"
            onClick={() => onSelectFolder('')}
          >
            Choose folder
          </Box>
          <Flex my="20px" align="center" justify="center" gap="20px" flexWrap="wrap">
            {isLoadingFolders ? (
              <Spinner />
            ) : (
              <>
                <Flex
                  key="empty-folder-s"
                  align="center"
                  gap="20px"
                  cursor="pointer"
                  px="15px"
                  py="15px"
                  minW="192px"
                  borderRadius="10px"
                  borderWidth="2px"
                  borderColor={selectedFolder === 'root' ? 'vmGreen.50' : 'vmGray.150'}
                  bg="vmWhite"
                  onClick={() => setSelectedFolder('root')}
                >
                  <FolderIcon fontSize="20px" />
                  <Box fontSize="16px" lineHeight="26px" fontWeight={600}>../</Box>
                </Flex>
                {folders.map((folder) => (
                  <Flex
                    key={folder.folderId}
                    align="center"
                    gap="20px"
                    cursor="pointer"
                    px="15px"
                    py="15px"
                    minW="192px"
                    borderRadius="10px"
                    borderWidth="2px"
                    borderColor={selectedFolder === folder.folderId ? 'vmGreen.50' : 'vmGray.150'}
                    bg="vmWhite"
                    onClick={() => setSelectedFolder(folder.folderId)}
                  >
                    <FolderIcon fontSize="24px" />
                    <Box fontSize="16px" lineHeight="26px" fontWeight={600}>{folder.name}</Box>
                  </Flex>
                ))}
              </>
            )}
          </Flex>
          <Flex gap="20px" justify="center" align="center">
            <Button variant="solidPrimary" type="button" isDisabled={isMoving} onClick={handleClose}>{t('common.button.texts.cancel')}</Button>
            <Button type="button" isLoading={isMoving} isDisabled={isMoving} onClick={handleMoveFolder}>Move</Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default MoveToFolder;
