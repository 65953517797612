import { memo, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface Props {
  children: JSX.Element;
  isDisabled?: boolean;
  link: string
}

function NavLink({ children, isDisabled, link }: Props) {
  const location = useLocation();
  const currentPage = useMemo(() => `${location.pathname}${location.search}`, [location]);

  if (isDisabled) {
    return children;
  }

  return (
    <Link to={link} state={{ backRoute: currentPage }}>
      {children}
    </Link>
  );
}

NavLink.defaultProps = {
  isDisabled: false,
};

export default memo(NavLink);
