import { Icon, IconProps } from '@chakra-ui/react';

function DownloadIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 18 19" stroke="vmGray.600" fill="none" {...props}>
      <path
        d="M17 12V16C17 17.1046 16.1046 18 15 18H3C1.89543 18 1 17.1046 1 16V12M9 13L9 1M9 13L5 9M9 13L13 9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default DownloadIcon;
