import { Flex, Spinner, useDisclosure } from '@chakra-ui/react';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { createForm } from '@apis/form.api';
import { useNavigate, useParams } from 'react-router-dom';
import NotFound from '@components/NotFound';
import VMFormControl from '@modules/Forms/forms/VMFormControl';
import { IFormValue } from '@modules/Forms/forms/VMFormControl/types/form-value';
import useFormDetail from '@modules/Forms/hooks/useFormDetail';
import useHistoryRoute from '@hooks/useHistoryRoute';

function FormCloning() {
  const navigate = useNavigate();
  const { formId } = useParams<'formId'>();
  const { backRoute } = useHistoryRoute('/forms');
  const {
    isLoadingForm,
    form,
  } = useFormDetail(formId!);
  const {
    isOpen: isAddingForm,
    onOpen: startAddingForm,
    onClose: finishUpdatingForm,
  } = useDisclosure();

  const onSubmit = useCallback(async (values: IFormValue) => {
    try {
      startAddingForm();
      await createForm(values);
      finishUpdatingForm();
      navigate(backRoute);
    } catch (error: any) {
      toast.error(error?.message);
      finishUpdatingForm();
    }
  }, [backRoute, finishUpdatingForm, navigate, startAddingForm]);

  if (isLoadingForm) {
    return (
      <Flex align="center" justify="center" minH="100vh">
        <Spinner />
      </Flex>
    );
  }

  if (!form) {
    return (
      <NotFound />
    );
  }

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formId: formIdL,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    created,
    ...formValue
  } = form;

  return (
    <VMFormControl isLoading={isAddingForm} onSubmitForm={onSubmit} defaultValues={formValue} />
  );
}

export default FormCloning;
