import { Icon, IconProps } from '@chakra-ui/react';

function BrokenVideoIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 32 29" fill="vmGray.950" stroke="none" {...props}>
      <path
        d="M31.0948 8.23942C30.5406 7.89893 29.828 7.86793 29.2463 8.15736L24.4308 10.5401V7.96039C24.4308 6.92925 23.615 6.11752 22.5774 6.11752H11.3067L7.09963 1.16485C6.42991 0.378073 5.24542 0.272586 4.45182 0.939506C3.66006 1.60557 3.56098 2.7809 4.23163 3.56811L24.9004 27.8397C25.2719 28.276 25.8014 28.5 26.3353 28.5C26.7638 28.5 27.195 28.3546 27.5482 28.0579C28.34 27.3919 28.4268 26.2133 27.7561 25.426L24.4308 21.5352V20.3181L29.2391 22.7008C29.5043 22.8325 29.799 22.8977 30.0862 22.8977C30.4293 22.8977 30.7898 22.8043 31.0917 22.6187C31.6449 22.2787 32 21.6779 32 21.0306V9.82759C32 9.18024 31.648 8.57949 31.0948 8.23942Z"
      />
      <path
        d="M0 7.96039V22.8977C0 23.9289 0.870031 24.7688 1.90769 24.7688H17.9542L1.83871 6.10064C0.827262 6.13006 0 6.94772 0 7.96039Z"
      />
    </Icon>
  );
}

export default BrokenVideoIcon;
