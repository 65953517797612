import { deleteAnswer } from '@apis/answer.api';
import { Box, Button, Flex } from '@chakra-ui/react';
import CopyIcon from '@components/icons/Copy';
import TickIcon from '@components/icons/Tick';
import TrashIcon from '@components/icons/Trash';
import VideoEditorIcon from '@components/icons/VideoEditor';
import RemoveVideoButton from '@components/RemoveVideoButton';
import useVmClipboard from '@hooks/useVmClipboard';
import QuestionVideoView from '@modules/Question/components/QuestionVideoView';
import { IVmAnswer } from '@typed/answer.types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Props {
  answer: IVmAnswer;
  onRefetch: () => Promise<void>;
}

function QuestionAnswerContainer({
  answer,
  onRefetch,
}: Props) {
  const { t } = useTranslation();
  const { hasCopied, onCopy } = useVmClipboard(`${window.location.origin}/answer/${answer.answerId}`);
  const handleRemoveAnswer = useCallback(async () => (
    deleteAnswer(answer!.answerId).then(onRefetch)), [answer, onRefetch]);

  return (
    <Box w="498px" maxW="full" mx="auto" mt="35px">
      <QuestionVideoView videoToken={answer.videoToken} />
      <Flex align="center" justify="center" gap="20px" mt="20px">
        <RemoveVideoButton
          shouldCallProvider
          videoToken={answer.videoToken}
          onDeleteSuccess={handleRemoveAnswer}
        >
          <Button
            type="button"
            variant="solidPrimary"
            leftIcon={<TrashIcon strokeWidth={2} />}
            color="vmRed.50"
            borderColor="vmRed.50"
            textTransform="capitalize"
          >
            Remove Answer
          </Button>
        </RemoveVideoButton>
        <Button type="button" variant="solidPrimary" onClick={onCopy}>
          <Flex align="center" gap="10px" textTransform="capitalize">
            {hasCopied ? <TickIcon stroke="vmGreen.50" fontSize="17px" /> : <CopyIcon strokeWidth={2} fontSize="17px" />}
            <Box as="span" color="vmGray.400">{hasCopied ? 'Copied' : 'Copy'}</Box>
          </Flex>
        </Button>
        <Link to={`/video-editor/${answer.answerId}`}>
          <Button type="button" variant="solidPrimary">
            <Flex align="center" gap="10px" textTransform="capitalize">
              <VideoEditorIcon />
              <Box as="span" color="vmGray.400">{t('video_editor.title')}</Box>
            </Flex>
          </Button>
        </Link>
      </Flex>
    </Box>
  );
}

export default QuestionAnswerContainer;
