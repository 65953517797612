// eslint-disable-next-line import/prefer-default-export
export const EXTERNAL_LINKS = {
  TERMS_AND_CONDITIONS: 'https://usetrust.io/terms-conditions/',
  EXTERNAL_PRIVACY_POLICY: 'https://usetrust.io/data-confidentiality-statement/',
  GDPR_DOCUMENT: {
    GDPR_DOCUMENT_DE: 'https://signnow.com/s/O86vqAWZ',
    GDPR_DOCUMENT_EN: 'https://signnow.com/s/1Ilu9RyQ',
  },
  GDPR_DOCUMENT_PDF: 'https://app.usetrust.app/documents/dpa-contract-facility4.pdf',
  DATA_CONFIDENTIALITY_STATEMENT: 'https://usetrust.io/data-confidentiality-statement/',
  IMPRINT: 'https://usetrust.io/imprint/',
  TUTORIAL: 'https://helpdesk.usetrust.io/',
};
