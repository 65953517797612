import { memo } from 'react';
import {
  Box,
  Flex,
  Grid,
  Image,
  Skeleton,
  Spinner,
} from '@chakra-ui/react';

const SCENES = [1, 2, 3];

function VideoEditorLoading() {
  return (
    <Box bg="vmGray.150">
      <Flex
        pos="fixed"
        justify="space-between"
        align="center"
        w="full"
        py="12px"
        px="20px"
        bg="vmBlack.50"
        zIndex={18}
      >
        <Flex align="center" gap="20px">
          <Skeleton h="40px" w="40px" />
          <Skeleton w="107px" h="24px" />
        </Flex>
        <Box>
          <Image src="/static-data/images/logo-white.png" alt="Logo" maxH="28px" />
        </Box>
        <Flex align="center" justify="space-between" gap="14px">
          <Skeleton w="167px" h="40px" />
          <Box pl="14px" borderLeftWidth="2px" borderColor="vmGray.1000">
            <Skeleton w="119px" h="40px" />
          </Box>
        </Flex>
      </Flex>
      <Grid w="full" templateColumns="480px auto" minH="100vh" pt="104px" px="20px" pb="20px">
        <Flex direction="column" gap="2px" bg="vmGray.450" borderRadius="10px" overflow="hidden">
          <Flex align="center" justify="center" pos="relative" bg="vmWhite">
            <Box
              px="20px"
              py="27px"
              w="184px"
              h="86.4px"
            >
              <Skeleton
                w="full"
                h="full"
              />
            </Box>
            <Box pos="absolute" m="auto" right="20px" top="auto" bottom="auto">
              <Skeleton w="44px" h="44px" />
            </Box>
          </Flex>
          <Flex direction="column" gap="20px" bg="vmWhite" py="20px" px="20px" h="calc(100vh - 87px - 54px - 104px - 20px - 4px)" overflowX="hidden" overflowY="auto">
            <Flex align="center" justify="center" gap="10px" pos="relative">
              <Flex align="center" gap="10px" px="20px" bg="vmWhite" zIndex={2}>
                <Skeleton width="133px" h="24px" />
              </Flex>
            </Flex>
            {SCENES.map((_, index) => (
              <Flex
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                align="center"
                justify="space-between"
                py="18px"
                pl="10px"
                pr="20px"
                bg="vmGray.250"
                borderRadius="10px"
                borderColor="vmGray.700"
                borderWidth="2px"
                cursor="pointer"
              >
                <Flex align="center" gap="20px">
                  <Skeleton w="16px" h="16px" />
                  <Skeleton w="110px" h="64px" />
                  <Flex direction="column" gap="21px">
                    <Skeleton w="22px" h="22px" />
                    <Skeleton w="31px" h="22px" />
                  </Flex>
                </Flex>
                <Box>
                  <Skeleton w="44px" h="44px" />
                </Box>
              </Flex>
            ))}
            <Flex align="center" justify="center" gap="10px" pos="relative">
              <Flex align="center" gap="10px" px="20px" bg="vmWhite" zIndex={2}>
                <Skeleton width="133px" h="24px" />
              </Flex>
            </Flex>
          </Flex>
          <Flex align="center" gap="2px" mt="auto">
            <Flex align="center" justify="center" gap="12px" bg="vmWhite" p="15px" w="full">
              <Skeleton w="128px" h="24px" />
            </Flex>
            <Flex align="center" justify="center" gap="12px" bg="vmWhite" p="15px" w="full">
              <Skeleton w="108px" h="24px" />
            </Flex>
          </Flex>
        </Flex>
        <Flex align="center" justify="center">
          <Spinner />
        </Flex>
      </Grid>
    </Box>
  );
}

export default memo(VideoEditorLoading);
