import * as yup from 'yup';

export default yup.object({
  firstname: yup.string().trim().required('common.validation.required'),
  lastname: yup.string().trim().required('common.validation.required'),
  email: yup.string().required('common.validation.required').email('common.validation.email'),
  // recaptchaResponse: yup.string().required('common.validation.captcha_required'),
  password: yup
    .string()
    .trim()
    .required('common.validation.required')
    .min(8, { key: 'common.validation.password_min', params: { min: 8 } })
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+-=]{8,}$/,
      'common.validation.password_valid',
    ),
}).required();
