import { Button, Flex } from '@chakra-ui/react';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  paymentCancelUrl: string | null;
  paymentUpdateUrl: string | null;
}

function PaymentHandler({
  paymentCancelUrl,
  paymentUpdateUrl,
}: Props) {
  const { t } = useTranslation();
  const handleCancelSubscription = useCallback(() => {
    (window as any).Paddle.Checkout.open({
      override: paymentCancelUrl,
      disableLogout: true,
      successCallback: () => {
        setTimeout(() => window.location.reload(), 1000);
      },
    });
  }, [paymentCancelUrl]);

  const handleUpdateSubscription = useCallback(() => {
    (window as any).Paddle.Checkout.open({
      override: paymentUpdateUrl,
      disableLogout: true,
      successCallback: () => {
        setTimeout(() => window.location.reload(), 1000);
      },
    });
  }, [paymentUpdateUrl]);

  if (!paymentUpdateUrl && paymentCancelUrl) return null;

  return (
    <Flex align="center" justify="center" gap="20px" p="20px" bg="vmGray.150" borderRadius="8px">
      <Button
        bg="vmRed.50"
        color="vmWhite"
        borderColor="vmRed.50"
        _hover={{
          bg: 'vmRed.50',
          color: 'vmWhite',
          borderColor: 'vmRed.50',
        }}
        onClick={handleCancelSubscription}
      >
        {t('plan.purchase.labels.cancel_plan')}
      </Button>
      <Button
        variant="solidPrimary"
        onClick={handleUpdateSubscription}
      >
        {t('plan.purchase.labels.update_payment_detail')}
      </Button>
    </Flex>
  );
}

export default memo(PaymentHandler);
