import { memo, useCallback } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { removeVideoByToken } from '@apis/video.api';
import ConfirmPopup from '@components/ConfirmPopup';

interface Props {
  videoToken: string | null;
  children: JSX.Element;
   text?: string;
  shouldCallProvider?: boolean;
  onDeleteSuccess: () => void | Promise<void>;
}

function RemoveVideoButton({
  videoToken,
  children,
  text,
  shouldCallProvider,
  onDeleteSuccess,
}: Props) {
  const { t } = useTranslation();
  const {
    isOpen: isDeletingVideo,
    onOpen: startDeletingVideo,
    onClose: finishDeletingVideo,
  } = useDisclosure();
  const {
    isOpen,
    onOpen,
    onClose,
  } = useDisclosure();

  const handleRemoveVideo = useCallback(async () => {
    if (!videoToken) {
      onClose();
      return;
    }
    try {
      startDeletingVideo();
      if (shouldCallProvider) {
        await removeVideoByToken(videoToken);
      }
      await onDeleteSuccess();
      finishDeletingVideo();
      onClose();
    } catch (error: any) {
      toast.error(error?.message || 'Something went wrong');
      finishDeletingVideo();
      onClose();
    }
  }, [
    videoToken,
    onClose,
    startDeletingVideo,
    shouldCallProvider,
    onDeleteSuccess,
    finishDeletingVideo,
  ]);

  return (
    <ConfirmPopup
      active
      isLoading={isDeletingVideo}
      onProceed={handleRemoveVideo}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      description={text || t('common.texts.delete_confirm')}
    >
      {children}
    </ConfirmPopup>
  );
}

RemoveVideoButton.defaultProps = {
  text: '',
  shouldCallProvider: true,
};

export default memo(RemoveVideoButton);
