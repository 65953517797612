import { memo } from 'react';
import { VideoEditorScene } from '@modules/VideoEditor/types/video-editor.types';
import { Box, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import ExclaimationIcon from '@components/icons/ve/Exclaimation';
import { isNil } from '@utils/compare.util';

interface Props {
  scene: VideoEditorScene;
}

function SceneDurationWarning({
  scene,
}: Props) {
  const { t } = useTranslation();
  if (scene.videoToken
      && (isNil<number | null>(scene.videoDuration) || scene.videoDuration !== 0)) {
    return (
      <Tooltip hasArrow placement="top" label={t('video_editor.labels.no_video_duration')}>
        <Box maxW="16px">
          <ExclaimationIcon />
        </Box>
      </Tooltip>
    );
  }

  return <Box w="40px" h="22.5px" />;
}

export default memo(SceneDurationWarning);
