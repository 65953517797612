import { Box } from '@chakra-ui/react';
import TrustRecorderWrapper from '@components/TrustRecorder/styled/TrustRecorderWrapper';
import VideoRecorder from '@components/VideoRecorder';
import { ZiggeoProgressType } from '@typed/ziggeo-recorder';
import { useCallback, useState } from 'react';
import TrustRecorderPermissionChecking from './TrustRecorderPermissionChecking';

interface Props {
  onGoBack: () => void;
  oldToken: string | null;
  onChange: (event: { target: { value: string | null } }) => void;
  simulate: boolean;
  showArrowBack: boolean;
}

function TrustRecorderPermissionRequest({
  onGoBack,
  oldToken,
  onChange,
  simulate,
  showArrowBack,
}: Props) {
  const [recorder, setRecorder] = useState<any>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const handleUploadMedia = useCallback(async (file: File) => {
    if (!simulate) return;
    if (recorder) {
      setIsUploading(true);
      recorder.upload_video(file);
    }
  }, [recorder, simulate]);

  return (
    <TrustRecorderWrapper>
      <TrustRecorderPermissionChecking
        onUpload={handleUploadMedia}
        isUploading={isUploading}
        onGoBack={() => onGoBack()}
        showArrowBack={showArrowBack}
      />
      <Box display="none">
        <VideoRecorder
          allowUpload
          allowRecord={false}
          localplayback
          videoToken={oldToken}
          proceedInVerfiedProgress
          onChange={(data) => {
            onChange({ target: { value: data.video } });
          }}
          setOuterRef={setRecorder}
          onProgressAction={(action: ZiggeoProgressType) => {
            if (action === ZiggeoProgressType.UPLOADING) {
              setIsUploading(true);
            }
            if (action === ZiggeoProgressType.VERIFIED) {
              setIsUploading(false);
            }
          }}
          simulate={simulate}
        />
      </Box>
    </TrustRecorderWrapper>
  );
}

export default TrustRecorderPermissionRequest;
