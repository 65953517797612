import { Route, Routes } from 'react-router-dom';
import VLDetail from './containers/VLDetail';
import VLList from './containers/VLList';

function VideoLibrary() {
  return (
    <Routes>
      <Route index element={<VLList />} />
      <Route path="view/:videoId" element={<VLDetail />} />
    </Routes>
  );
}

export default VideoLibrary;
