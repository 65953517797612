import { Icon, IconProps } from '@chakra-ui/react';

function BusinessIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 88 80" fill="none" {...props}>
      <g transform="matrix(1.19421,0,0,1.19421,-2.91323e-11,-0.011938)">
        <rect x="6.041" y="7.053" width="60.917" height="26.699" fill="rgb(72, 220, 107)" />
        <path d="M69.813,13.986L70.126,13.986C71.028,13.986 71.822,13.621 72.36,12.958C72.918,12.271 73.125,11.329 72.926,10.376L71.433,3.192C71.061,1.402 69.466,0 67.802,0L26.066,0L5.199,0C3.535,0 1.94,1.403 1.567,3.193L0.074,10.376C-0.125,11.33 0.082,12.271 0.64,12.958C1.178,13.621 1.972,13.986 2.875,13.986L3.188,13.986C2.395,18.259 1.601,22.531 0.808,26.804L0.744,27.145C-0.01,31.089 1.817,34.732 5.424,36.602L5.424,62.56C5.424,65.008 7.413,67 9.858,67L63.142,67C65.587,67 67.576,65.008 67.576,62.56L67.576,36.609C71.189,34.741 73.016,31.094 72.257,27.151L69.813,13.986ZM23.562,27.414C23.896,22.938 24.23,18.462 24.564,13.986L33.131,13.986C33.071,18.432 33.011,22.878 32.951,27.32C32.901,30.067 30.35,31.774 28.003,31.78C26.706,31.78 25.427,31.235 24.582,30.322C24.059,29.757 23.458,28.794 23.562,27.414ZM10.975,31.78L10.923,31.779L10.858,31.78C9.602,31.78 8.403,31.245 7.649,30.349C7.181,29.793 6.671,28.849 6.901,27.504L9.112,13.986L17.74,13.986L16.389,27.224C16.106,29.762 13.721,31.763 10.975,31.78ZM33.813,48.953C33.813,49.896 33.049,50.661 32.108,50.661L17.443,50.661C16.502,50.661 15.738,49.896 15.738,48.953L15.738,44C15.738,43.057 16.502,42.292 17.443,42.292L32.108,42.292C33.049,42.292 33.813,43.057 33.813,44L33.813,48.953ZM40.133,27.324L39.947,13.986L48.514,13.986C48.843,18.378 49.173,22.77 49.503,27.162L49.522,27.416C49.626,28.795 49.025,29.758 48.502,30.323C47.658,31.235 46.38,31.78 45.071,31.78C42.736,31.774 40.184,30.067 40.133,27.324ZM57.262,58.638C57.262,59.581 56.499,60.346 55.557,60.346L46.435,60.346C45.494,60.346 44.73,59.581 44.73,58.638L44.73,45.659C44.73,44.716 45.494,43.951 46.435,43.951L55.557,43.951C56.499,43.951 57.262,44.716 57.262,45.659L57.262,58.638ZM65.351,30.357C64.602,31.248 63.408,31.78 62.16,31.78L62.128,31.78C59.365,31.76 56.979,29.759 56.696,27.233C56.243,22.818 55.79,18.402 55.338,13.986L63.889,13.986L66.101,27.512C66.331,28.853 65.821,29.799 65.351,30.357Z" fill="rgb(0, 112, 201)" fillRule="nonzero" />
      </g>
    </Icon>
  );
}

export default BusinessIcon;
