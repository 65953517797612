import axios from 'axios';

async function removeVideoByToken(videoToken: string) {
  return axios.delete(`/video?videoToken=${videoToken}`);
}

export {
  // eslint-disable-next-line import/prefer-default-export
  removeVideoByToken,
};
