import {
  Navigate,
  useLocation,
} from 'react-router-dom';
import ROUTES from '@constants/routes';
import { getItem } from '@utils/localstorage.util';
import LOCAL_STORAGE_KEY from '@constants/localstorage';

function GuestGuard({ children }: { children: JSX.Element }): JSX.Element {
  const location = useLocation();
  const token = getItem(LOCAL_STORAGE_KEY.AUTH0_KEY);

  if (token) {
    return (
      <Navigate to={ROUTES.HOME} state={{ from: location }} replace />
    );
  }

  return children;
}
export default GuestGuard;
