import {
  Box,
  Button,
  Flex,
} from '@chakra-ui/react';
import BackIcon from '@components/icons/Back';
import LabelHeader from '@components/LabelHeader';
import { useVmStore } from '@store/index';
import { selectedBrandSelector } from '@store/slices/brand/selectors';
import { emptyFunction } from '@utils/func.util';
import {
  forwardRef,
  memo,
  Ref,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
// import MediaLibraryCount from './components/MediaLibraryCount/MediaLibraryCount';
import MediaLibraryFilter from './components/MediaLibraryFilter/ MediaLibraryFilter';
import MediaLibraryList from './components/MediaLibraryList/MediaLibraryList';
import MediaLibraryUploader from './components/MediaLibraryUploader/MediaLibraryUploader';
import useMediaUpload from './hooks/useMediaUpload';
import { Media } from './types/media';
import { MediaFilterType } from './types/media-filter';

interface Props {
  hasBackButton?: boolean;
  onGoBack?: (() => void) | null;
  onFileSelected: (checkedState: {[key: string]: boolean}) => void;
  medias: Media[][];
  handleKeywordChange: (keyword: string) => void;
  filterType: MediaFilterType;
  isLoadingMedia: boolean;
  itemCountPerRow: number;
  setFilterType: (filterType: MediaFilterType) => void;
  handleUploadSuccess: (file: Media) => Promise<void>;
  handleRefresh: () => void;
  selectedFiles: {[key: string]: boolean};
}

const MediaLibrary = forwardRef(({
  hasBackButton,
  onGoBack,
  onFileSelected,
  medias,
  handleKeywordChange,
  filterType,
  isLoadingMedia,
  itemCountPerRow,
  setFilterType,
  handleUploadSuccess,
  handleRefresh,
  selectedFiles,
}: Props, ref: Ref<HTMLDivElement>) => {
  const { t } = useTranslation();
  const brandId = useVmStore(selectedBrandSelector);
  const {
    upload,
    isUploading,
  } = useMediaUpload(brandId!);

  const handleUpload = useCallback(async (file: Blob, fileName: string) => {
    await upload(file, fileName, handleUploadSuccess);
  }, [upload, handleUploadSuccess]);

  return (
    <>
      <Box px="20px" bg="vmGray.150" py="8px" borderRadius="10px">
        <Flex
          align="center"
          justify="space-between"
          gap={{
            base: '20px',
            lxl: 0,
          }}
          direction={{
            base: 'column',
            nxl: 'row',
          }}
          sx={{
            '& .input-search-jp': {
              w: { base: '180px !important', hxl: '300px !important' },
            },
          }}
        >
          <Flex align="center" gap="20px">
            {hasBackButton && (
              <Button type="button" variant="solidGray" size="iconOnly" onClick={onGoBack ?? emptyFunction} bg="vmWhite">
                <BackIcon fontSize="14px" stroke="vmPrimary.50" />
              </Button>
            )}
            <LabelHeader pl={0}>{t('ml.title')}</LabelHeader>
          </Flex>
          <Flex align="center" gap="20px">
            <MediaLibraryFilter
              currentMediaType={filterType}
              onChange={setFilterType}
              onKeywordChange={handleKeywordChange}
              isDisabled={isUploading}
              handleRefresh={handleRefresh}
            />
            <MediaLibraryUploader
              onUpload={handleUpload}
              isUploading={isUploading}
            />
            {/* <MediaLibraryCount total={total} isLoading={isLoadingMedia} /> */}
          </Flex>
        </Flex>
      </Box>
      <Box
        p="20px"
        bg="vmWhite"
        borderTopLeftRadius="10px"
        borderTopRightRadius="10px"
        className="media-library-list-wrapper"
        overflowX="hidden"
        overflowY="auto"
      >
        <Box ref={ref} h="full" sx={{ '& > div': { w: 'full', h: 'full' } }}>
          <MediaLibraryList
            itemPerRow={itemCountPerRow}
            medias={medias}
            isLoading={isLoadingMedia}
            onSelectFile={onFileSelected}
            selectedFiles={selectedFiles}
          />
        </Box>
      </Box>
    </>
  );
});

MediaLibrary.defaultProps = {
  hasBackButton: false,
  onGoBack: null,
};

export default memo(MediaLibrary);
