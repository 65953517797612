import {
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

type RecorderContainerType = 'normal' | 'record' | 'record-video-exist';

interface RecorderContainerContextType {
  container: RecorderContainerType;
  handleChangeContainer: (newContainerType: RecorderContainerType) => void;
}

const RecorderContainerContext = createContext<RecorderContainerContextType>({
  container: 'normal',
  handleChangeContainer: () => {},
});

function useRecorderContainerContext(): RecorderContainerContextType {
  return useContext(RecorderContainerContext);
}

function RecorderContainerContextProvider({ children }: { children: JSX.Element }) {
  const [container, setContainerType] = useState<RecorderContainerType>('normal');

  const values = useMemo<RecorderContainerContextType>(() => ({
    container,
    handleChangeContainer: setContainerType,
  }), [container]);

  return (
    <RecorderContainerContext.Provider value={values}>
      {children}
    </RecorderContainerContext.Provider>
  );
}

export {
  useRecorderContainerContext,
  RecorderContainerContextProvider,
};
