import { memo, useCallback } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { VideoEditorIntroOutro } from '@modules/VideoEditor/types/video-editor.types';
import AddIcon from '@components/icons/Add';
import Scene from '../Scene/Scene';

interface Props {
  title: string;
  isActive: boolean;
  isOutro: boolean;
}

function Intro({
  title,
  isActive,
  isOutro,
}: Props) {
  const navigate = useNavigate();
  const { setValue, watch } = useFormContext();
  const intro: VideoEditorIntroOutro | null = watch(isOutro ? 'outro' : 'intro');
  const handleNavigate = useCallback(() => {
    navigate(isOutro ? './../outro' : './../intro');
  }, [isOutro, navigate]);

  const handleDelete = useCallback(() => {
    setValue(isOutro ? 'outro' : 'intro', {
      imageUrl: null,
      imageDuration: 0,
      videoVideoUrl: null,
      videoDuration: 0,
      trimmingStart: null,
      trimmingEnd: null,
      videoToken: null,
      videoUrl: null,
      videoImageUrl: null,
      width: 0,
      height: 0,
      codedWidth: 0,
      codedHeight: 0,
      mediaId: null,
    }, { shouldDirty: true });
    if (isActive) {
      navigate('./..');
    }
  }, [setValue, isOutro, isActive, navigate]);

  if (!intro || (!intro.imageUrl && !intro.videoToken)) {
    return (
      <Flex align="center" justify="center" gap="10px" pos="relative">
        <Flex align="center" gap="10px" px="20px" bg="vmWhite" cursor="pointer" zIndex={2} onClick={handleNavigate}>
          <AddIcon fontSize="14px" stroke="vmBlack.50" />
          <Box
            fontSize="16px"
            fontWeight={600}
            lineHeight="24px"
            color="vmBlack.50"
          >
            {title}
          </Box>
        </Flex>
        <Box
          pos="absolute"
          left="auto"
          right="auto"
          top="auto"
          bottom="auto"
          m="auto"
          w="full"
          h="2px"
          bg="vmBlack.50"
          zIndex={1}
        />
      </Flex>
    );
  }

  return (
    <Scene
      isActive={isActive}
      scene={intro}
      sceneIndex={-1}
      isIntro={!isOutro}
      isOutro={isOutro}
      allowDrag={false}
      onChangeScenePosition={() => {}}
      onDelete={handleDelete}
    />
  );
}

export default memo(Intro);
