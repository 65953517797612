import {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Flex,
  Image,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import { VideoEditorScene } from '@modules/VideoEditor/types/video-editor.types';
import VMVideoPlayer from '@components/VMVideoPlayer';
import VideoEditorSceneControl from './VideoEditorSceneControl';
import VideoEditorSceneTrimer from './VideoEditorSceneTrimer';

interface Props {
  scene: VideoEditorScene;
  updateKey: string;
  isDisableTrimMode?: boolean;
}

function VideoEditorScenePreview({
  scene,
  updateKey,
  isDisableTrimMode,
}: Props) {
  const [player, setPlayer] = useState<any>(null);
  const [isPlayerReady, setIsPlayerReady] = useState<boolean>(false);
  const [isPlayerLoaded, setIsPlayerLoaded] = useState<boolean>(false);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const {
    isOpen: isTrimMode,
    onOpen: openTrimMode,
    onClose: closeTrimMode,
  } = useDisclosure();
  const isImage = !!scene.imageUrl;
  const isVideo = !!scene.videoToken;

  const handleSwitchToTrimMode = useCallback(() => {
    if (player) {
      player.pause();
    }
    openTrimMode();
  }, [player, openTrimMode]);

  const handlePlayerGetReady = useCallback(() => {
    if (!player || player.get('duration') === 0) {
      timerRef.current = setTimeout(() => {
        handlePlayerGetReady();
      }, 1000);
    } else {
      setIsPlayerReady(true);
    }
  }, [player]);

  const handleVideoLoaded = useCallback(() => {
    setIsPlayerLoaded(true);
  }, []);

  useEffect(() => {
    if (isPlayerLoaded && player) {
      handlePlayerGetReady();
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isPlayerLoaded, player, handlePlayerGetReady]);

  useEffect(() => {
    setIsPlayerReady(false);
    closeTrimMode();
  }, [closeTrimMode, scene.id]);

  if (isImage && scene.imageUrl) {
    return (
      <Box>
        <Flex align="center" justify="center" maxH="640px" w="900px" px="40px" maxW="full">
          <Image src={scene.imageUrl} />
        </Flex>
      </Box>
    );
  }

  if (isVideo && scene.videoToken) {
    return (
      <Box key={scene.id}>
        {!isPlayerReady && (
          <Flex
            align="center"
            justify="center"
            width="900px"
            px="40px"
            maxW="100%"
          >
            <Spinner fontSize="24px" />
          </Flex>
        )}
        <Flex
          align="center"
          justify="center"
          maxW="calc(100vw - 480px - 40px - 80px)"
          w="900px"
          sx={{
            '& > div': {
              maxH: 'calc(100vh - 177px - 64px - 40px)',
              borderRadius: '10px',
              overflow: 'hidden',
              d: isPlayerReady ? 'inline-block !important' : 'none !important',
            },
            '& .ba-videoplayer-controlbar': {
              display: 'none',
            },
            '& .ba-player-dashboard': {
              display: 'none',
            },
            // '& .ba-videoplayer-playbutton-container': {
            //   display: 'none',
            // },
            '& .ba-player-loader-container': {
              display: 'none',
            },
            '& .ba-videoplayer-player-toggle-overlay': {
              display: 'none',
            },
          }}
        >
          <VMVideoPlayer
            source={null}
            videoToken={scene.videoToken}
            setOuterRef={setPlayer}
            volume={1}
            onLoaded={handleVideoLoaded}
          />
        </Flex>
        {player && isPlayerReady && (
          <Box mt="20px">
            {!isTrimMode ? (
              <VideoEditorSceneControl
                player={player}
                onSwitchToTrimMode={handleSwitchToTrimMode}
                isDisableTrimMode={isDisableTrimMode!}
                updateKey={updateKey}
              />
            ) : (
              <VideoEditorSceneTrimer
                player={player}
                onCloseTrimMode={closeTrimMode}
                updateKey={updateKey}
              />
            )}
          </Box>
        )}
      </Box>
    );
  }

  return null;
}

VideoEditorScenePreview.defaultProps = {
  isDisableTrimMode: false,
};

export default memo(VideoEditorScenePreview);
