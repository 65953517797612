import { Icon, IconProps } from '@chakra-ui/react';

function PendingIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" stroke="none" fill="vmGray.600" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20.0001C4.48 20.0001 0 15.5301 0 10.0001C0 4.48012 4.48 0.00012207 10 0.00012207C15.53 0.00012207 20 4.48012 20 10.0001C20 15.5301 15.53 20.0001 10 20.0001ZM13.1884 13.7102C13.3084 13.7802 13.4384 13.8202 13.5784 13.8202C13.8284 13.8202 14.0784 13.6902 14.2184 13.4502C14.4284 13.1002 14.3184 12.6402 13.9584 12.4202L10.3984 10.3002V5.68018C10.3984 5.26018 10.0584 4.93018 9.64844 4.93018C9.23844 4.93018 8.89844 5.26018 8.89844 5.68018V10.7302C8.89844 10.9902 9.03844 11.2302 9.26844 11.3702L13.1884 13.7102Z"
      />
    </Icon>
  );
}

export default PendingIcon;
