import { memo, useMemo } from 'react';
import {
  Box,
  Checkbox,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { Media } from '@shared/MediaLibrary/types/media';
import { convertSizeToString } from '@utils/size.util';
import PlayIcon from '@components/TrustRecorder/icons/Play';
import VMVideoPlayer from '@components/VMVideoPlayer';
import CloseIcon from '@components/icons/Close';

interface Props {
  media: Media;
  isSelected: boolean;
  onSelectFile: (checkedState: {[key: string]: boolean}) => void;
}

function MediaItem({
  media,
  isSelected,
  onSelectFile,
}: Props) {
  const {
    isOpen,
    onOpen,
    onClose,
  } = useDisclosure();
  const size = useMemo(() => {
    if (media.type === 'video' && media.videoSize) {
      return convertSizeToString(media.videoSize);
    }
    if (media.type === 'image' && media.imageSize) {
      return convertSizeToString(media.imageSize);
    }
    return 0;
  }, [media]);

  const videoImageUrl = media.videoImageUrl ? `https://${media.videoImageUrl}` : '';
  const image = media.type === 'image' ? media.imageThumbnailUrl : videoImageUrl;
  const extension = media.name.split('.').pop();

  return (
    <Flex direction="column" w="full" gap="10px" borderWidth="2px" borderColor="vmWhite" borderRadius="6px" overflow="hidden">
      <Box w="full" borderRadius="4px" overflow="hidden" cursor="pointer" pos="relative" onClick={onOpen}>
        <Box
          w="full"
          h="192px"
          bgImage={image}
          bgSize="contain"
          bgPos="center center"
          bgRepeat="no-repeat"
        />
        {media.type === 'video' && (
          <>
            <Box
              pos="absolute"
              left={0}
              right={0}
              top={0}
              bottom={0}
              bg="vmWhite"
              m="auto"
              opacity={0.5}
              borderRadius="100px"
              w="44px"
              h="44px"
            />
            <PlayIcon
              pos="absolute"
              left={0}
              right={0}
              top={0}
              bottom={0}
              m="auto"
              fontSize="22px"
            />
          </>
        )}
      </Box>
      <Flex align="start" gap="10px">
        <Checkbox
          w="20px"
          h="20px"
          pt="3px"
          isChecked={isSelected}
          onChange={(event) => {
            onSelectFile({ [media.id]: event.target.checked });
          }}
          _focus={{ boxShadow: 'none' }}
          sx={{
            '& .chakra-checkbox__control': {
              w: '20px',
              h: '20px',
              _focus: {
                boxShadow: 'none',
              },
            },
          }}
        />
        <Flex direction="column" gap="5px" w="full">
          <Tooltip label={media.name} placement="bottom-start">
            <Flex
              fontSize="16px"
              lineHeight="22.4px"
              fontWeight={400}
              color="vmBlack.50"
              minW={0}
              w="full"
              alignSelf="flex-start"
            >
              <Box
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                minW={0}
                maxW="calc(100% - 60px)"
              >
                {media.name.split('.').slice(0, -1).join('.')}
              </Box>
              .
              {extension}
            </Flex>
          </Tooltip>
          {size && (
            <Box
              fontWeight={400}
              fontSize="13px"
              lineHeight="19.5px"
              color="vmGray.600"
            >
              {size}
            </Box>
          )}
        </Flex>
      </Flex>
      <Modal
        closeOnOverlayClick
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent w="700px" maxW="100%" bg="transparent" boxShadow="none">
          <ModalBody pos="relative" p={0}>
            <Box
              pos="absolute"
              cursor="pointer"
              onClick={onClose}
              zIndex={99999}
              top="-28px"
              right="-21px"
            >
              <CloseIcon color="vmGray.400" />
            </Box>
            <Box overflow="hidden" sx={{ '& .ba-videoplayer-container': { borderRadius: '10px' } }}>
              {media.type === 'video' && (
                <VMVideoPlayer
                  source={null}
                  videoToken={media.videoToken}
                />
              )}
              {media.type === 'image' && (
                <Image maxH="600px" src={media.imageUrl} alt={media.name} />
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default memo(MediaItem);
