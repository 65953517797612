import { AppState } from '@store/types';

function brandsSelector(state: AppState) {
  return state.brands;
}

function selectedBrandSelector(state: AppState) {
  return state.selectedBrandId;
}

export {
  brandsSelector,
  selectedBrandSelector,
};
