import { Icon, IconProps } from '@chakra-ui/react';

function CloudUploadIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 22 20" fill="none" stroke="vmPrimary.50" {...props}>
      <path
        d="M18.9996 14.2C19.7841 13.6572 20.3757 12.8791 20.6891 11.9781C21.0026 11.0771 21.0216 10.0998 20.7434 9.18729C20.4652 8.2748 19.9042 7.47432 19.1414 6.90143C18.3786 6.32854 17.4535 6.01288 16.4996 6.00002H14.6996C14.3973 4.9803 13.8667 4.04286 13.1479 3.25891C12.4291 2.47496 11.5412 1.8651 10.5514 1.47565C9.56172 1.08621 8.49627 0.92741 7.436 1.01132C6.37573 1.09524 5.34852 1.41966 4.43239 1.95994C3.51625 2.50023 2.73528 3.24217 2.14878 4.12943C1.56227 5.01669 1.18567 6.02593 1.04755 7.08051C0.909439 8.13508 1.01345 9.20727 1.35169 10.2156C1.68993 11.224 2.25351 12.142 2.99961 12.9M10.9996 10V19M10.9996 10L14.9996 14M10.9996 10L6.99961 14"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default CloudUploadIcon;
