import axios, { AxiosResponse } from 'axios';
import { ApiResponse } from '@typed/http.types';
import { VideoEditorRenderedVideo } from '@modules/VideoEditor/types/video-editor.types';

async function getListRenderedVideos(answerId: string): Promise<VideoEditorRenderedVideo[]> {
  return axios.get(`/render-video/${answerId}`)
    .then((response: AxiosResponse<ApiResponse<VideoEditorRenderedVideo>>) => (
      response.data.result ? [response.data.result] : []
    ));
}

async function renderVideo(answerId: string, name: string) {
  const query = new URLSearchParams({ name });
  return axios.post(`/render-video/${answerId}?${query.toString()}`);
}

async function sendVideo(answerId: string, renderedVideoId: string) {
  return axios.post(`/render-video/${answerId}/${renderedVideoId}`);
}

async function deleteRenderedVideo(renderedVideoId: string) {
  return axios.delete(`/render-video/${renderedVideoId}`);
}

async function reRenderVideo(renderedVideoId: string) {
  return axios.put(`/render-video/${renderedVideoId}`);
}

async function setRenderedVideoAsTestimonialVideo(answerId: string, id: string) {
  return axios.put(`/render-video/${answerId}/set/${id}`);
}

async function removeRenderedVideoAsTestimonialVideo(answerId: string) {
  return axios.delete(`/render-video/${answerId}/unset`);
}

async function dismissRenderedVideoMessage(renderedVideoId: string) {
  return axios.put(`/render-video/${renderedVideoId}/msg-closed`);
}

async function getVideoDurations(
  answerId: string,
  videoUrl: string,
): Promise<number> {
  const query = { videoUrl };
  return axios.get(`/render-video/${answerId}/get-duration?${new URLSearchParams(query).toString()}`)
    .then((response: AxiosResponse<ApiResponse<number>>) => response.data.result);
}

export {
  getListRenderedVideos,
  renderVideo,
  sendVideo,
  deleteRenderedVideo,
  reRenderVideo,
  setRenderedVideoAsTestimonialVideo,
  removeRenderedVideoAsTestimonialVideo,
  dismissRenderedVideoMessage,
  getVideoDurations,
};
